import React from "react";
import BackToTopCustom from "components/layout/BackToTopCustom";
import Banner from "components/layout/banner.component";

// images
import PressBanner from "../../images/images/banners/Press-Banner.jpg";

const noBorder = {
  borderTop: 'none',
}

const PressReleasePage = () => {

  return (
    <>
      <BackToTopCustom />
      <head>
        <title>NCG: Press Releases</title>
        <meta name="description" content="NCG works to innovate and continuously improve our series and internal practices. We also strive to give back to our communities and professional circles. Read more about our efforts." />
        <meta name="keywords" content="Business Consulting, Technology Consulting, Mergers & Acquisitions, Cybersecurity, cyber security, Integration, Risk Management, Due Diligence, Business Process Improvement, Compliance, Oversight & Governance, Incident Management, Data Breach, Deployment Management, Program Development, Program Office Support, Policies & Procedures, Vendor Management, System Blueprinting, Safety Management Systems, SMS, Empowering People, DevOps, CI/CD, Continuous Integration, Continuous Deployment, Safety, Security, Program Management, Project Management, Security Programs, Cybersecurity Programs, cyber security programs, information security, InfoSec, system security, privacy" />
      </head>
      <Banner backgroundImage={PressBanner}>Press Releases</Banner>
      <div class="bootstrap crunch">
        <div class="page-wrap ">
          <div class="primary_layout">
            <div itemscope itemtype="http://schema.org/WPSidebar" id="sidebar2" class="midSpy pressMidSpy dataSpy">
              <h5 class="title">Year &amp; Month</h5>
              <ul id="capaMenu" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nav navbar-nav scrollMenu scrollToTop">
                <li class="header active">
                  <ul class="inner col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <li class="active yearContain"><a href="#2016_releases"><b>2016</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#dec_2016">December</a></li>
                        <li class="month"><a href="#october_2016">October</a></li>
                        <li class="month"><a href="#september_2016">September</a></li>
                        <li class="month"><a href="#august_2016">August</a></li>
                        <li class="month"><a href="#may_2016">May</a></li>
                        <li class="month"><a href="#april_2016">April</a></li>
                        <li class="month"><a href="#feb_2016">February</a></li>
                        <li class="month"><a href="#jan_2016">January</a></li>
                      </ul>
                    </li>
                    <li class="yearContain"><a href="#2015_releases"><b>2015</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#dec_2015">December</a></li>
                        <li class="month"><a href="#sept_2015">September</a></li>
                        <li class="month"><a href="#may_2015">May</a></li>
                        <li class="month"><a href="#march_2015">March</a></li>
                      </ul>
                    </li>
                    <li class="yearContain"><a href="#2014_releases"><b>2014</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#nov_2014">November</a></li>
                        <li class="month"><a href="#oct_2014">October</a></li>
                        <li class="month"><a href="#sept_2014">September</a></li>
                        <li class="month"><a href="#aug_2014">August</a></li>
                        <li class="month"><a href="#jul_2014">July</a></li>
                        <li class="month"><a href="#jun_2014">June</a></li>
                        <li class="month"><a href="#march_2014">March</a></li>
                        <li class="month"><a href="#jan_2014">January</a></li>
                      </ul></li>
                    <li class="yearContain"><a href="#2013_releases"><b>2013</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#sept_2013">September</a></li>
                        <li class="month"><a href="#may_2013">May</a></li>
                      </ul></li>
                    <li class="yearContain"><a href="#2012_releases"><b>2012</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#aug_2012">August</a></li>
                        <li class="month"><a href="#april_2012">April</a></li>
                        <li class="month"><a href="#march_2012">March</a></li>
                      </ul></li>
                    <li class="yearContain"><a href="#2011_releases"><b>2011</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#oct_2011">October</a></li>
                        <li class="month"><a href="#sept_2011">September</a></li>
                        <li class="month"><a href="#aug_2011">August</a></li>
                        <li class="month"><a href="#jul_2011">July</a></li>
                        <li class="month"><a href="#march_2011">March</a></li>
                        <li class="month "><a href="#feb_2011">February</a></li>
                        <li class="month "><a href="#jan_2011">January</a></li>
                      </ul></li>
                    <li class="yearContain"><a href="#2010_releases"><b>2010</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#dec_2010">December</a></li>
                        <li class="month"><a href="#sept_2010">September</a></li>
                        <li class="month"><a href="#jun_2010">June</a></li>
                        <li class="month"><a href="#feb_2010">February</a></li>
                      </ul></li>
                    <li class="yearContain"><a href="#2009_releases"><b>2009</b></a>
                      <ul class="monthContain inner">
                        <li class="month"><a href="#jul_2009">July</a></li>
                        <li class="month"><a href="#may_2009">May</a></li>
                      </ul></li>
                  </ul>
                </li>
              </ul>
              <div class="dropdown">
                <button class="btn btn-link dropdown-toggle" type="button" data-toggle="dropdown"><span class="caret"></span></button>
                <ul class="dropdown-menu">
                  <li><a href="#2016_releases">2016</a></li>
                  <li><a href="#2015_releases">2015</a></li>
                  <li><a href="#2014_releases">2014</a></li>
                  <li><a href="#2013_releases">2013</a></li>
                  <li><a href="#2012_releases">2012</a></li>
                  <li><a href="#2011_releases">2011</a></li>
                  <li><a href="#2010_releases">2010</a></li>
                  <li><a href="#2009_releases">2009</a></li>
                </ul>
              </div>
            </div>

            <div class="mainpage-wrapper pressPage">
              <div id="scroller-anchor" ></div>
              <div itemscope itemtype="http://schema.org/WPSidebar" id="sidebar" class="sideBar relative releaseSlider col-lg-4 col-md-4 col-sm-4 hidden-sm hidden-xs dataSpy">
                <div class="wrapper">
                  <h5>Releases By Year</h5>
                  <ul class="nav navbar-nav scrollMenu ">
                    <li class="search">
                      <ul class="inner">
                        <li class=" tierOne active">
                          <h4><a href="#2016_releases">2016</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#dec_2016">Dec<span>ember</span></a></li>
                            <li class="tierTwo_item"><a href="#october_2016">Oct<span>ober</span></a></li>
                            <li class="tierTwo_item"><a href="#september_2016">Sep<span>tember</span></a></li>
                            <li class="tierTwo_item"><a href="#august_2016">Aug<span>ust</span></a></li>
                            <li class="tierTwo_item"><a href="#may_2016">May</a></li>
                            <li class="tierTwo_item"><a href="#april_2016">Apr<span>il</span></a></li>
                            <li class="tierTwo_item"><a href="#feb_2016">Feb<span>ruary</span></a></li>
                            <li class="tierTwo_item"><a href="#jan_2016">Jan<span>uary</span></a></li>
                          </ul>
                        </li>
                        <li class="tierOne">
                          <h4><a href="#2015_releases">2015</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#dec_2015">Dec<span>ember</span></a></li>
                            <li class="tierTwo_item"><a href="#sept_2015">Sept<span>ember</span></a></li>
                            <li class="tierTwo_item"><a href="#may_2015">May</a></li>
                            <li class="tierTwo_item"><a href="#march_2015">Mar<span>ch</span></a></li>
                          </ul>
                        </li>
                        <li class="tierOne">
                          <h4><a href="#2014_releases">2014</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#nov_2014">Nov<span>ember</span></a></li>
                            <li class="tierTwo_item"><a href="#oct_2014">Oct<span>ober</span></a></li>
                            <li class="tierTwo_item"><a href="#sept_2014">Sep<span>tember</span></a></li>
                            <li class="tierTwo_item"><a href="#aug_2014">Aug<span>ust</span></a></li>
                            <li class="tierTwo_item"><a href="#jul_2014">Jul<span>y</span></a></li>
                            <li class="tierTwo_item"><a href="#jun_2014">Jun<span>e</span></a></li>
                            <li class="tierTwo_item"><a href="#march_2014">Mar<span>ch</span></a></li>
                            <li class="tierTwo_item"><a href="#jan_2014">Jan<span>uary</span></a></li>
                          </ul></li>
                        <li class="tierOne">
                          <h4><a href="#2013_releases">2013</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#sept_2013">Sep<span>tember</span></a></li>
                            <li class="tierTwo_item"><a href="#may_2013">May</a></li>
                          </ul></li>
                        <li class="tierOne">
                          <h4><a href="#2012_releases">2012</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#aug_2012">Aug<span>ust</span></a></li>
                            <li class="tierTwo_item"><a href="#april_2012">Apr<span>il</span></a></li>
                            <li class="tierTwo_item"><a href="#march_2012">Mar<span>ch</span></a></li>
                          </ul></li>
                        <li class="tierOne">
                          <h4><a href="#2011_releases">2011</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#oct_2011">Oct<span>ober</span></a></li>
                            <li class="tierTwo_item"><a href="#sept_2011">Sep<span>tember</span></a></li>
                            <li class="tierTwo_item"><a href="#aug_2011">Aug<span>ust</span></a></li>
                            <li class="tierTwo_item"><a href="#jul_2011">Jul<span>y</span></a></li>
                            <li class="tierTwo_item"><a href="#march_2011">Mar<span>ch</span></a></li>
                            <li class="tierTwo_item"><a href="#feb_2011">Feb<span>ruary</span></a></li>
                            <li class="tierTwo_item"><a href="#jan_2011">Jan<span>uary</span></a></li>
                          </ul></li>
                        <li class="tierOne">
                          <h4><a href="#2010_releases">2010</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#dec_2010">Dec<span>ember</span></a></li>
                            <li class="tierTwo_item"><a href="#sept_2010">Sep<span>tember</span></a></li>
                            <li class="tierTwo_item"><a href="#jun_2010">Jun<span>e</span></a></li>
                            <li class="tierTwo_item"><a href="#feb_2010">Feb<span>ruary</span></a></li>
                          </ul></li>
                        <li class="tierOne">
                          <h4><a href="#2009_releases">2009</a></h4>
                          <ul class="tierTwo inner">
                            <li class="tierTwo_item"><a href="#jul_2009">Jul<span>y</span></a></li>
                            <li class="tierTwo_item"><a href="#may_2009">May</a></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                itemprop="mainContentOfPage"
                id="2016_releases"
                class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12"
                style={noBorder}
              >
                <div itemscope itemtype="http://schema.org/Article" id="dec_2016" class="releaseTxt">
                  <h2 class="year">2016 Releases</h2>
                  <h5 itemprop="headline">NCG Participates in Bowdoin College Consulting Panel</h5>
                  <div itemprop="dateCreated" class="date">December 2nd</div>
                  <p itemprop="text">Christopher Bender, Northcross Group president, participated in Bowdoin College’s “Consulting Across Sectors", a panel discussion for students held December 1 that included presenters from across the consulting industry, including health care, politics, and technology. “NCG is always pleased to participate in educational programs within our community," Bender said. </p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Bowdoin_120116.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="october_2016" class="releaseTxt">
                  <h5 itemprop="headline">NCG Supports KeyBank’s Acquisition of First Niagara Bank</h5>
                  <div itemprop="dateCreated" class="date">October 10th</div>
                  <p itemprop="text">Northcross Group successfully completed an engagement providing primary merger and acquisition services for the KeyBank acquisition of First Niagara Bank. “The KeyBank First Niagara acquisition represents NCG’s 12th bank M&A deal," NCG President Christopher Bender said. “We have developed a number of processes and frameworks that we were excited to bring to this effort and help make it a success."</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_FNFG_Key_10102016.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="september_2016" class="releaseTxt">
                  <h5 itemprop="headline">NCG Presented at DrupalCon Ireland</h5>
                  <div itemprop="dateCreated" class="date">September 30th</div>
                  <p itemprop="text">Northcross Group Development Director Chris Porter conducted his popular “Views From the Ground Up" at the DrupalCon Dublin convention held September 26-30. The course is designed to teach the fundamentals of working with views and how to customize such things as displays and layouts to fit individual needs. NCG uses Drupal in its solutions as part of a commitment to openness and standardization for development. </p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_DrupalCon_Dublin_09302016.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="august_2016" class="releaseTxt">
                  <h5 itemprop="headline">NCG is Extended GSA 8(a) STARS II Contract Vehicle</h5>
                  <div itemprop="dateCreated" class="date">August 31st</div>
                  <p itemprop="text">Northcross Group has received an extension of the General Services Administration’s STARS II Government Wide Acquisition Contract for Computer Systems Design Services. STARS II is a multiple-award contract engineered to provide cutting-edge technology solutions from small businesses to federal agencies. “STARS II provides us with an excellent vehicle to connect our services across the federal government," NCG President Christopher Bender said. </p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_STARS_II_16_083016.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="may_2016" class="releaseTxt">
                  <h5 itemprop="headline">NCG Presents at DrupalCon in New Orleans</h5>
                  <div itemprop="dateCreated" class="date" >May 13th</div>
                  <p itemprop="text">Northcross Group was a workshop host at the DrupalCon New Orleans convention held May 9-13. NCG Development Director Chris Porter delivered a beginner-level course that provides participants with a greater understanding of view functionality, letting students take almost any display currently used in Drupal and override it, allowing for customization to individual needs. “NCG is committed to being an active part of the Drupal Community and helping facilitate learning and adoption of open source platforms," Porter said. </p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_DrupalCon_NewOrleans_05132016.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="april_2016" class="releaseTxt">
                  <h5 itemprop="headline">Northcross Group Presented at ASIS Maine Chapter</h5>
                  <div itemprop="dateCreated" class="date" >April 16th</div>
                  <p itemprop="text">Northcross Group President Christopher Bender discussed how to build or enhance comprehensive security programs within an organization to provide economies of scale and cross-purpose support that maximize investment in those efforts at an American Society for Industrial Security International event held April 15 in Portland, Maine. </p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_ASIS_Press_Release_041615.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="feb_2016" class="releaseTxt">
                  <h5 itemprop="headline">NCG Provides Pro Bono Security Assessment for the Center for Grieving Children</h5>
                  <div itemprop="dateCreated" class="date" >February 5th</div>
                  <p itemprop="text">Northcross Group continues its support for the Portland, Maine area and the Center for Grieving Children by conducting a pro bono cybersecurity risk assessment for the non-profit organization. NGC’s effort provided guidance and industry best practices on how to safeguard the Center’s information and technology resources. “We are pleased to provide these services to an organization that does some really great work in the community," NCG President Christopher Bender said.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Center_Grieving_Children_02092016.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jan_2016" class="releaseTxt">
                  <h5 itemprop="headline">NCG Celebrates 10 Years</h5>
                  <div itemprop="dateCreated" class="date" >January 9th</div>
                  <p itemprop="text">Northcross Group is proud to be celebrating the 10-year anniversary of its incorporation in the state of Maine. “Through the past 10 years we have had the opportunity to be part of incredible changes in technology," President Christopher Bender said. The firm provides services that effectively weave technology and business capabilities together to meet complex business challenges. </p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NCG_10Yrs_01092016.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="2015_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12" >
                <div itemscope itemtype="http://schema.org/Article" id="dec_2015" class="releaseTxt">
                  <h2 class="year">2015 Releases</h2>
                  <h5>NCG Tours Center for Grieving Children Facilities</h5>
                  <div class="date" >December 18th</div>
                  <p>As part of its efforts to support local community organizations, Northcross Group visited the Portland, Maine site of the Center for Grieving Children on December 18. For nearly 30 years the Center has provided peer support, outreach, and educational services to children, teens, families, and communities. NCG President Christopher Bender and staff met with volunteers to discuss ways NCG could continue to support the non-profit organization.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Center_Grieving_Children_12182015.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="sept_2015" class="releaseTxt">
                  <h5 itemprop="headline">NCG Presented at DrupalCon Barcelona 2015</h5>
                  <div itemprop="dateCreated" class="date" >September 25th</div>
                  <p itemprop="text">A one-day, beginner-level course, entitled “Views From the Ground Up", was presented by Northcross Group Development Director Chris Porter at the DrupalCon global conference in Barcelona, September 21-25, 2015. NCG uses Drupal in its solutions as part of a commitment to openness and standardization for development. “NCG is committed to the open source community by actively contributing and engaging with other community members," said NCG President Christopher Bender.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_DrupalCon_Barcelona_09252015.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class=" releaseTxt">
                  <h5 itemprop="headline">NCG Wins Department of Commerce Contract</h5>
                  <div itemprop="dateCreated" class="date" >September 21st</div>
                  <p itemprop="text">Northcross Group was a awarded a contract by the U.S. Department of Commerce to aid its Economic Development Administration (EDA) agency in providing small loans for high-risk projects unable to acquire traditional funding. The effort will help EDA establish business, functional, and reporting requirements for the acquisition of a system that will help manage its Revolving Loan Fund.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_RLF_092115.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="may_2015" class="releaseTxt">
                  <h5 itemprop="headline">NCG Supports BIAN’s Establishment of a North American Chapter</h5>
                  <div itemprop="dateCreated" class="date" >May 1st</div>
                  <p itemprop="text">Northcross Group has taken a leadership role as a founding organization for the Banking Industry Architecture Network North American Chapter Planning Meeting held in Pittsburgh on May 1. “The complexity and unique factors in the United States and Canada warrant efforts … to develop content and materials specific to North America," NCG President Christopher Bender said.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_BIAN_05012015.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="march_2015" class=" releaseTxt">
                  <h5 itemprop="headline">NCG Presents at DrupalCon Los Angeles 2015</h5>
                  <div itemprop="dateCreated" id="march_2015_2" class="date" >March 25th</div>
                  <p itemprop="text">Northcross Group (NCG) continues to support the Drupal community. NCG Development Director Chris Porter will be conducting a one-day training session “Views From the Ground Up" at DrupalCon 2015 at the Los Angeles Convention Center on May 11.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_DrupalCon_LA_2015_032515.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
              </div>

              <div itemprop="mainContentOfPage" itemscope itemtype="http://schema.org/Article" id="2014_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12 ">
                <div id="nov_2014" class="releaseTxt">
                  <h2 itemprop="dateCreated" class="year">2014 Releases</h2>
                  <h5 itemprop="headline"> NCG Collaborates on International Banking Technology Framework and Joins BIAN </h5>
                  <div class="date" >November 11th</div>
                  <p itemprop="text">Northcross Group (NCG) has joined BIAN, the Banking Industry Architecture Network, and its work to define standards for banking services. NCG is one of 53 BIAN members consisting of leading banks, service providers, software vendors, and academics; contributing in the areas of core banking, security, and data analytics.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_BIAN_11112014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="oct_2014" class="releaseTxt">
                  <h5 itemprop="headline">NCG Sponsor Workshop at DrupalCon</h5>
                  <div itemprop="dateCreated" class="date" >October 6th</div>
                  <p itemprop="text">Northcross Group (NCG) Development Director Chris Porter conducted a workshop at the 2014 European DrupalCon in Amsterdam, the Netherlands. The workshop was designed to introduce the development, testing, and deployment fundamentals with Drupal.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_DrupalCon_10102014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="sept_2014" class="releaseTxt">
                  <h5 itemprop="headline">Building Information Security Programs - IAPP KnowledgeNet Session </h5>
                  <div itemprop="dateCreated" class="date" >September 15th</div>
                  <p itemprop="text">Northcross Group (NCG) presented at an International Association of Privacy Professionals (IAPP) KnowledgeNet session. NCG's presentation focused on building strong and sustainable security programs that bridge between technical and organizational areas.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_SecurityPrograms_IAPP_09152014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Development Directors Introduces New HR Certification </h5>
                  <div itemprop="dateCreated" class="date" >September 13th</div>
                  <p itemprop="text">Northcross Group (NCG) Development Director Deb Whitworth presented to the Maine Society for Healthcare Human Resources Association on the new Society of Human Resource Management (SHRM) certification.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_MaineHRConference_09132014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Expands Core Banking System Modernization Capabilities</h5>
                  <div itemprop="dateCreated" class="date" >September 2nd</div>
                  <p itemprop="text">Northcross Group (NCG) welcomes Pam McGovern as the newest addition to our team. Ms. McGovern brings over 20 years of experience in the IT and management spheres and most recently served as IT Director at Huggins Hospital.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NewHireMcGovern_0922014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Presents at the European Industrial Engineering Regional Meeting </h5>
                  <div itemprop="dateCreated" class="date" >September 1st</div>
                  <p itemprop="text">Northcross Group (NCG) presented at the European Industrial Engineering Sector regional meeting in Northampton, England. NCG's presentation focused on cybersecurity for industrial control and automation systems.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_EuropeanIndustrialEngineering_09022014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="aug_2014" class="releaseTxt">
                  <h5 itemprop="headline">NCG Continues to Expand Security Team </h5>
                  <div itemprop="dateCreated" class="date" >August 4th</div>
                  <p itemprop="text">Northcross Group (NCG) has hired Andrew Normandeau to join its growing Security Practice Team.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRlease_NewHireNormandeau_08042014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jul_2014" class="releaseTxt">
                  <h5 itemprop="headline">NCG Brings on a new Development Director </h5>
                  <div itemprop="dateCreated" class="date" >July 28th</div>
                  <p itemprop="text">Northcross Group (NCG) has brought on Deb Whitworth as a Development Director working on both developing internal resources and nurturing customer relationships.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NewHireWhitworth_07282014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jun_2014" class="releaseTxt">
                  <h5 itemprop="headline">NCG Presents at Department of Energy Expo </h5>
                  <div itemprop="dateCreated" class="date" >June 2nd</div>
                  <p itemprop="text">Northcross Group (NCG) presented at the 13th Annual Department of Energy Small Business Expo.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_DOE_Expo_06022014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="march_2014" class="releaseTxt">
                  <h5 itemprop="headline">NCG Expands Senior Consulting Team </h5>
                  <div itemprop="dateCreated" class="date" >March 4th</div>
                  <p itemprop="text">Northcross Group (NCG) has hired Mike Crowley, who has 30 years of experience in the Department of Defense and private sector. Mr. Crowley will be part of NCG's Security Practice.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NewHireCrowley_03042014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jan_2014" class="releaseTxt">
                  <h5 itemprop="headline">NCG Brings on a New Graphic Designer </h5>
                  <div itemprop="dateCreated" class="date" >January 27th</div>
                  <p itemprop="text">Northcross Group (NCG) has hired Katie St. Michel as a graphic designer to help with internal collateral design.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NewHireStMichel01272014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="2013_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div itemscope itemtype="http://schema.org/Article" id="sept_2013" class="releaseTxt">
                  <h2 class="year">2013 Releases</h2>
                  <h5 itemprop="headline">NCG contributes to NIST Cybersecurity Framework Development </h5>
                  <div itemprop="dateCreated" class="date" >September 14th</div>
                  <p itemprop="text">Northcross Group (NCG) joins other private and public sector entities in contributing to NIST's Cybersecurity Framework driven by Executive Order 13636. The Framework seeks to be a foundation for security programs and maturity across critical infrastructures.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NIST_Cybersecurity_Framework_09142014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Awarded FAA eFAST Contract </h5>
                  <div itemprop="dateCreated" class="date" >September 2nd</div>
                  <p itemprop="text">Northcross Group (NCG) has been awarded a multiyear contract vehicle with the FAA. The NCG Team has a wealth of transportation experience and looks forward to working with the FAA.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_eFast_Award_09022013.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="may_2013" class="releaseTxt">

                  <h5 itemprop="headline">NCG Grows Security Engineering Team </h5>
                  <div itemprop="dateCreated" class="date" >May 1st</div>
                  <p itemprop="text">Northcross Group (NCG) has added Ryan Wing to our Security Engineer team as a Senior Engineer.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NewHireWing_05012014.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="2012_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div itemscope itemtype="http://schema.org/Article" id="aug_2012" class="releaseTxt">
                  <h2 class="year">2012 Releases</h2>
                  <h5 itemprop="headline">NCG Addresses Security Threat Factors for Safety </h5>
                  <div itemprop="dateCreated" class="date" >August 15th</div>
                  <p itemprop="text">Northcross Group (NCG) presented at the 30th International System Safety Conference in Atlanta, Georgia. NCG's presentation focused on how security factors affect safety in critical infrastructure environments/best practices for communication and cyber tools in an emergency.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_ISSC2012_08152012.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="april_2012" class="releaseTxt">
                  <h5 itemprop="headline">NCG Partners with Fortinet </h5>
                  <div itemprop="dateCreated" class="date" >April 27th</div>
                  <p itemprop="text">Northcross Group (NCG) has partnered with Fortinet, a global provider of network security appliances, to provide full-spectrum security solutions in an easy-to-use and cost-effective manner.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Fortinet_04272012.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="april_2012_2" class="releaseTxt">
                  <h5 itemprop="headline">Securing and Using Communications &amp; Cyber Infrastructure in Emergencies </h5>
                  <div itemprop="dateCreated" class="date" >April 25th</div>
                  <p itemprop="text">Northcross Group (NCG) presented at the 4th Annual Maine Partners in Emergency Preparedness Conference at the Augusta Civic Center. NCG's presentation focused on best practices for communication and cyber tools in an emergency.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_MainePrepares_04242012.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="march_2012" class="releaseTxt">
                  <h5 itemprop="headline">Continuity of Operations and Contingency Planning for New England </h5>
                  <div itemprop="dateCreated" class="date" >March 1st</div>
                  <p itemprop="text">Northcross Group (NCG) presented at the New England Regional Catastrophic Preparedness Initiative (NERCPI) 2012 Cyber Disruption Summit in Boston, Massachusetts, February 21-22, 2012.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Cyber_Disruption_Summit_2012_03012012.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Expands PM Team </h5>
                  <div itemprop="dateCreated" class="date" >March 1st</div>
                  <p itemprop="text">Northcross Group (NCG) has added Rosemary Presnar as a Senior Project Manager to its PM Team, furthering a blend of management and technical skills to engagements to most effectively drive projects that involve people, technology, data, and security.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Presnar_Hire_03012012.pdf"><i class="fa fa-file-text"></i>Full Release</a>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="2011_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div itemscope itemtype="http://schema.org/Article" id="oct_2011" class="releaseTxt">
                  <h2 class="year">2011 Releases</h2>
                  <h5 itemprop="headline">NCG BPM Team Expansion</h5>
                  <div itemprop="dateCreated" class="date" >October 3rd</div>
                  <p itemprop="text">Northcross Group (NCG) has added Megan Sullivan to its team as a Systems Analyst to further expand our Business Process Management capabilities.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Sullivan_10032011.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="sept_2011" class="releaseTxt">
                  <h5 itemprop="headline">NCG Awarded GSA STARS2 GWAC</h5>
                  <div itemprop="dateCreated" class="date" >September 26th</div>
                  <p itemprop="text">Northcross Group (NCG) has received an award under the General Services Administration’s  STARS II Government Wide Acquisition Contract (GWAC) for Computer Systems Design Services.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_STARS2_PR_09262011.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="aug_2011" class="releaseTxt">
                  <h5 itemprop="headline">NCG Team Expansion </h5>
                  <div itemprop="dateCreated" class="date" >August 12th</div>
                  <p itemprop="text">Northcross Group (NCG) has added Mark Hopper to its Team as a lead for network, system, and security engineering.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Hopper_08032011.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">Evolving Cybersecurity and Connections with Safety – NCG International System Safety Conference Presentation </h5>
                  <div itemprop="dateCreated" class="date" >August 3rd</div>
                  <p itemprop="text">Northcross Group (NCG) President Christopher Bender addressed the 29th International System Safety (ISSC) Conference in Las Vegas, Nevada, August 8-12 on cybersecurity and its role in overall system safety.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_ISSC_08122011.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jul_2011" class="releaseTxt">
                  <h5 itemprop="headline">NCG Joins Maine Bankers Association </h5>
                  <div itemprop="dateCreated" class="date" >July 1st</div>
                  <p itemprop="text">Northcross Group (NCG) has joined the Maine Bankers Association, furthering NCG's involvement with the banking community.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_MBA_07012011.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="march_2011" class="releaseTxt">
                  <h5 itemprop="headline">NCG Additions and Changes</h5>
                  <div itemprop="dateCreated" class="date" >March 21</div>
                  <p itemprop="text">Northcross Group (NCG) has promoted Vaida Lowell to an Operations Manager and brought on Brain Bakke as a Business Development Manager.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Staff_Changes_03212011.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="feb_2011" class="releaseTxt">
                  <h5 itemprop="headline">NCG Federal Expansion Highlighted in MaineBiz</h5>
                  <div itemprop="dateCreated" class="date" >February 15</div>
                  <p itemprop="text">Northcross Group (NCG) and its opening of a Washington, D.C. office supporting federal business development was highlighted by MaineBiz.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_portlandbiz_02152011.pdf"><i class="fa fa-file-text"></i>MaineBiz Article</a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jan_2011" class="releaseTxt">
                  <h5 itemprop="headline">NCG Opens Virginia Office </h5>
                  <div itemprop="dateCreated" class="date" >January 3rd</div>
                  <p> itemprop="text"Driving federal market efforts, NCG opens an office in Arlington, Virginia.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NCG_VA_Office.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="2010_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div itemscope itemtype="http://schema.org/Article" id="dec_2010" class="releaseTxt">
                  <h2 class="year">2010 Releases</h2>
                  <h5 itemprop="headline">NCG Supporting The Center for Grieving Children </h5>
                  <div itemprop="dateCreated" class="date" >December 23th</div>
                  <p itemprop="text">Northcross Group (NCG) has noted the exemplary work of the Center for Grieving Children and its important work in the Southern Maine region.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Center_For_Grieving_Children.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Bringing News to the Classroom </h5>
                  <div itemprop="dateCreated" class="date" >December 20th</div>
                  <p itemprop="text">Over the next year, Northcross Group (NCG) will be providing more than 8,000 newspapers to Maine classrooms as part of The Press Herald's Newspapers in Education (NEI) program serving all participating schools in Maine.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NewspapersInEducation.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="sept_2010" class="releaseTxt">
                  <h5 itemprop="headline">NCG Presents Cybersecurity Preparedness at International System Safety Conference </h5>
                  <div itemprop="dateCreated" class="date" >September 3rd</div>
                  <p itemprop="text">Northcross Group (NCG) President Chris Bender presented at the 28th International System Safety (ISSC) Conference. NCG's presentation addressed the application of system safety techniques to cybersecurity programs.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_ISSC_Conference.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="jun_2010" class="releaseTxt">
                  <h5 itemprop="headline">Vulnerability Assessment and Penetration Scans Added to Cybersecurity Services </h5>
                  <div itemprop="dateCreated" class="date" >June  17th</div>
                  <p itemprop="text">Northcross Group (NCG), a Portland, Maine-based management and IT consulting company, is extending its current cybersecurity offerings with the addition of automated scans to perform a variety of network vulnerability assessments and penetration tests including social networking exploits.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_NCG_SAINT.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="feb_2010" class="releaseTxt">
                  <h5 itemprop="headline">Maine Pine Tree Development Zone Certification  </h5>
                  <div itemprop="dateCreated" class="date" >February  17th</div>
                  <p itemprop="text">Northcross Group (NCG) has been approved as a participant in the Maine Pine Tree Development Zone (PTDZ) program. The program is focused on encouraging business growth in Maine.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_Pine_Tree_Development.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
              </div>
              <div id="2009_releases" class="release page-section col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div itemscope itemtype="http://schema.org/Article" id="jul_2009" class="releaseTxt">
                  <h2 class="year">2009 Releases</h2>
                  <h5 itemprop="headline">NCG Meets with SBA Associate Administrator </h5>
                  <div itemprop="dateCreated" class="date" >July  10th</div>
                  <p itemprop="text">Northcross Group (NCG) had the opportunity to meet with the recently appointed Small Business Administration (SBA) Associate Administrator for the Office of Field Operations, Jess Knox.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_SBA_Knox.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" id="may_2009" class="releaseTxt">
                  <h5 itemprop="headline">NCG Receives SBA 8(a) Certification </h5>
                  <div itemprop="dateCreated" class="date" >May 19th</div>
                  <p itemprop="text">Northcross  Group (NCG) has been certified as a participant in the U.S. Small Business  Administration’s (SBA) 8(a) Business Development Program.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_SBA_Cert.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
                <div itemscope itemtype="http://schema.org/Article" class="releaseTxt">
                  <h5 itemprop="headline">NCG Demystifies Business Continuity Planning </h5>
                  <div itemprop="dateCreated" class="date" >May 13th</div>
                  <p itemprop="text">Northcross  Group (NCG) conducted a workshop on developing and implementing Business  Continuity Planning (BCP) programs at the 14th Annual Maine Human  Resources Convention.</p>
                  <a class="dwnFull" target="_blank" href="/content/docs/pressreleases/NCG_PressRelease_BCP_MaineHR.pdf"><i class="fa fa-file-text"></i>Full Release
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </PageWrapper> */}
    </>
  )
}

export default PressReleasePage;