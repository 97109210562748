import { Grid, makeStyles, Typography } from "@material-ui/core";
import EFSectionContainer from "../shared/EFSectionContainer";
import splashGraphic from "../../../../images/microsite/educationFoundation/splashGraphic.png";
import waveDivider from "../../../../images/microsite/educationFoundation/waveDivider.png";
import EFSectionNoPaddingGridItem from "../shared/EFSectionNoPaddingGridItem";
import { EF_NAV_BAR_HEIGHT } from "../EFNavBar";
import classNames from "classnames";

const white = "#F5F8FF";
const periwinkle = "#5359E6";
const neonGradient = (
  `linear-gradient(
    101.88deg,
    #2FABDA 1.7%, #398AE5 33.22%, #5359E6 61.13%, #7C2DE1 91.75%
  )`
);


const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      marginTop: -8,
      marginBottom: -80
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: -48,
      marginBottom: -80
    }
  },
  splashImageOverlay: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 140,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backgroundImage: `linear-gradient(180deg, ${white} 0%, transparent 30%)`,
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      top: 150
    }
  },
  splashImage: {
    width: "100%",
    display: "block"
  },
  stretchImageColumn: {
    position: "relative",
  },
  headingGridItem: {
    position: "relative",
    zIndex: 2,

    // Last-minute hack to get this thing working
    [theme.breakpoints.down("xs")]: {
      height: 100,
    }
  },
  mainHeading: {
    // Allow heading to stretch beyond grid column if needed
    width: "200%",
    maxWidth: 720,
    marginTop: 0,
    position: "relative",
    zIndex: 2,
    color: periwinkle,
    fontSize: 48,
    fontWeight: 700,
    textShadow: "none",
    filter: "drop-shadow(-2px -2px 2px #060B3D4D)",

    [`@supports (
      (background-clip: text) or (-webkit-background-clip: text)
    ) and (
      (text-fill-color: transparent) or (-webkit-text-fill-color: transparent)
    )`]: {
      "background": neonGradient,
      "background-clip": "text",
      "text-fill-color": "transparent",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 40
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "135%",
      fontSize: 32
    }
  },
  imageGridItem: {
    height: `calc(50vh - ${EF_NAV_BAR_HEIGHT}px)`,
    [theme.breakpoints.only("sm")]: {
      height: "initial",
      minHeight: `calc(50vh - ${EF_NAV_BAR_HEIGHT}px)`,
      marginTop: 110,
    },
    [theme.breakpoints.down("xs")]: {
      height: 540,
      width: "70%",

      // In case of Emergency:
      //
      // Hand-measured, matches heading's xs grid prop
      // marginLeft: "-50%",
      // marginTop: 80,
    },
  },
  stretchImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  splashImageContainer: {
    [theme.breakpoints.down("md")]: {
      bottom: -200
    },
    [theme.breakpoints.down("sm")]: {
      bottom: -150,
    },
    [theme.breakpoints.down("xs")]: {
      bottom: 0
    }
  },
  stretchImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      // Prevent image from being clipped while remaining `object-fit: cover`.
      width: "125%",
      marginLeft: "-25%",
      objectPosition: "left top"
    },
    [theme.breakpoints.down("sm")]: {
      width: "175%",
      marginLeft: "-75%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% + 20vw)",
      marginLeft: "-20vw",
      objectFit: "cover"
    }
  },
  bottomGraphic: {
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 320,
      marginTop: -88,
      marginLeft: -80
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: -40,
      marginLeft: -200
    }
  },
  bottomGraphicImage: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      objectFit: "fill"
    }
  },
  dividerGridItem: {
    height: 300,
    marginTop: -50,
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      marginTop: -75,
    },
    [theme.breakpoints.only("md")]: {
      maxHeight: 270,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 80
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: 280,
      marginTop: 120
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: -88
    }
  },
}));


export default function EFSplashSection() {
  const classes = useStyles();
  return (
    <EFSectionContainer className={classes.container} maxWidth="none">
      <div className={classes.splashImageOverlay} />
      <Grid container>
        <Grid className={classes.headingGridItem} item xs={9} sm={6}>
          <Typography variant="h1" className={classes.mainHeading}>
            Technology &amp; Cybersecurity Skill&nbsp;Development and&nbsp;Education
          </Typography>
        </Grid>
        <EFSectionNoPaddingGridItem
          className={classes.imageGridItem}
          position="right"
          xs={0}
          sm={6}
        >
          <div
            className={
              classNames(
                classes.stretchImageContainer, classes.splashImageContainer
              )
            }
          >
            <img
              src={splashGraphic}
              className={classes.stretchImage}
              alt=""
              role="presentation"
            />
          </div>
        </EFSectionNoPaddingGridItem>
        <EFSectionNoPaddingGridItem
          className={classes.dividerGridItem}
          position="bottom"
          xs={12}
        >
          <div
            className={
              classNames(classes.stretchImageContainer, classes.bottomGraphic)
            }
          >
            <img
              src={waveDivider}
              className={classes.bottomGraphicImage}
              alt=""
              role="presentation"
            />
          </div>
        </EFSectionNoPaddingGridItem>
      </Grid>
    </EFSectionContainer>
  );
}
