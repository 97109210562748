import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container, Link } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import Banner from "components/layout/banner.component";
import ContentContainer from "components/layout/contentContainer.component";
import Image from "components/layout/image.component";
import classNames from "classnames";
import BackToTopCustom from "components/layout/BackToTopCustom";
import variables from "../../../style/variables.module.scss";
// IMAGES
import nmuBanner from "../../../images/images/nmu-partnership/nmu-splash.png";
import studentWorking from "../../../images/images/nmu-partnership/student-working.jpg";
import upciLogo from "../../../images/images/nmu-partnership/upci-logo.png";
import nmuLighthouse from "../../../images/images/nmu-partnership/lighthouse.jpg";
import foundationalTraning from "../../../images/images/nmu-partnership/overview-foundational-training.png";
import handsOn from "../../../images/images/nmu-partnership/overview-hand-on-work.png";
import qualityControl from "../../../images/images/nmu-partnership/overview-quality-control-framework.png";
import cybersecurityEssentials from "../../../images/images/nmu-partnership/practice-areas-essentials.png";
import cyberriskAssessments from "../../../images/images/nmu-partnership/practice-areas-ra.png";
import cyberriskManagementPrograms from "../../../images/images/nmu-partnership/practice-areas-rm-programs.png";
import systemHardening from "../../../images/images/nmu-partnership/practice-areas-system-hardening.png";

const useStyles = makeStyles((theme) => ({
  // BANNER
  nmuLogoContainer: {
    display: "inline-flex",
    alignItems: "center",
    marginRight: 30,
    maxWidth: 100,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 80,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 60,
    },
  },
  pageSection: {
    paddingTop: 55,
    paddingBottom: 55,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
      paddingBottom: 50,
    },

  },
  pageSectionStriped: {
    paddingTop: 100,
    paddingBottom: 100,
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none",
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse",
    },
  },
  // INTRO
  textWrapper: {
    paddingRight: 40,
    [theme.breakpoints.down('xs')]: {
      margin: "0px auto",
      textAlign: "center",
      paddingRight: 0,
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "relative",
  },
  imageContainerRight: {
    marginLeft: "auto",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  caption: {
    paddingTop: 10,
  },
  // PHOTO BANNER
  photoBanner: {
    position: "relative",
    display: "block",
    width: "100%",
    backgroundImage: "url(" + studentWorking + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    '&::after': {
      display: "block",
      height: "100%",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(to right, rgba(0, 84, 154, 0.68) 30%, rgba(39, 115, 189, 0) 100%)",
    },
  },
  bannerWrapper: {
    paddingTop: 160,
    paddingBottom: 160,
  },
  headerWhite: {
    color: "white",
    margin: 0,
    padding: 0,
    zIndex: 10,
  },
  headerWhiteShadow: {
    textShadow: "1px 1px 1px #00549a",
  },
  // OVERVIEW
  overviewSection: {
    textAlign: "center",
  },
  overviewWrapper: {
    flexDirection: "row",
    marginTop: 60,
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  overviewCell: {
    maxWidth: 300,
    display: "inline-block",
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
    [theme.breakpoints.down('xs')]: {
      margin: "0px auto",
    },
  },
  imageWrapper: {
    height: 120,
    justifyContent: "center",
    margin: "0px auto",
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
  },
  foundationalTraining: {
    maxHeight: 110,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 90,
    },
  },
  qualityControl: {
    maxHeight: 110,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 90,
    },
  },
  cellTextWrapper: {
    textAlign: "center",
    paddingTop: 30,
  },
  linkWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
    },

  },
  // FOCUS AREAS
  bannerBarTitle: {
    width: "100%",
    display: "block",
    padding: 20,
    textAlign: "center",
    backgroundColor: variables.textBlue,
  },
  introWrapper: {
    display: "block",
    width: "100%",
    paddingBottom: 20,
    paddingTop: 60,
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  blueText: {
    color: variables.textBlue,
    fontWeight: 600,
    fontSize: 16,
  },
  focusAreaSection: {
    display: "block",
  },
  focusAreaContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 20px",
    [theme.breakpoints.down('xs')]: {
      padding: "0px 20px",
    },
  },
  focusRowWrapper: {
    display: "flex",
    justifyContent: "space-around",
    padding: "0px 30px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      padding: "0px 20px",
    },
  },
  focusAreaTile: {
    paddingLeft: 80,
    marginBottom: 50,
    position: "relative",
    '&::before': {
      height: 60,
      width: 60,
      display: "inline-block",
      position: "absolute",
      content: '""',
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      top: 20,
      left: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-of-type': {
        paddingRight: 40,
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 60,
      marginBottom: 30,
      '&:first-of-type': {
        paddingRight: 0,
      },
      '&::before': {
        height: 40,
        width: 40,
        top: 30,
      },
    },
  },
  cybersecurityEssentials: {
    '&::before': {
      backgroundImage: "url(" + cybersecurityEssentials + ")",
    },
  },
  cyberriskAssessments: {
    '&::before': {
      backgroundImage: "url(" + cyberriskAssessments + ")",
    },
  },
  cyberriskManagementPrograms: {
    '&::before': {
      height: 70,
      width: 80,
      left: -13,
      top: 17,
      backgroundImage: "url(" + cyberriskManagementPrograms + ")",
    },
    [theme.breakpoints.down('xs')]: {
      '&::before': {
        height: 50,
        width: 60,
        left: -13,
        top: 17,
      },
    },
  },
  systemHardening: {
    '&::before': {
      backgroundImage: "url(" + systemHardening + ")",
    },
  },
  // MORE INFO
  moreInfoWrapper: {
    display: "block",
    textAlign: "center",
    marginTop: 40,
  },
}));

const NmuPartnershipPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={nmuBanner} variant="xl" top={260} header="Upper Peninsula Cybersecurity Institute at Northern Michigan University Academic Partnership">
        <Grid item xs={2} sm={2} md={2} lg={2} className={classNames(classes.nmuLogoContainer)}>
          <ContentContainer variant="customWidth">
            <Image url={upciLogo} variant="customWidth" width={"100%"} />
          </ContentContainer>
        </Grid>
      </Banner>
      <PageWrapper >
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="body1">
                NCG is working with Northern Michigan University’s (NMU) Upper Peninsula Cybersecurity Institute to develop cybersecurity, computer science and risk management capabilities in the next generation of cybersecurity professionals. NCG is bringing its business centric approach to security and technology that scales and adjusts to meet the needs of each unique company—based on its industry, size, and complexity.
              </Typography>
              <Typography variant="body1">
                NCG is working with the Cybersecurity Institute faculty and staff, and focusing NCG internship opportunities on risk management and a systems approach to securing data and operations for today and tomorrow.
              </Typography>
              <LinkDefault target="_blank" href="https://www.prnewswire.com/news-releases/northcross-group-ncg-launches-academic-partnership-with-northern-michigan-universitys-cybersecurity-institute-301139288.html">Read the NCG NMU Academic Partnership Press Release</LinkDefault>
            </Grid>
            <Grid item xs={8} sm={4} md={3} lg={3} className={classNames(classes.imageContainer, classes.imageContainerRight)}>
              <ContentContainer variant="customWidth" width={"100%"}>
                <Image url={nmuLighthouse} variant="customWidth" width={"100%"} />
              </ContentContainer>
              <Typography variant="caption" className={classes.caption}>
                Crisp Point Lighthouse Newberry, Michigan
              </Typography>
            </Grid>
          </Container>
        </div>
        <div className={classes.photoBanner}>
          <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.bannerWrapper)}>
            <Typography variant="h1" className={classNames(classes.headerWhite, classes.headerWhiteShadow)}>
              NCG Internship opportunities with hands-on client experience working with cyber experts from across the nation.
            </Typography>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.overviewSection)}>
          <Container maxWidth="lg">
            <Typography variant="h3">
              NCG Cybersecurity Internship Program Overview
            </Typography>
            <Grid item lg={12} className={classes.overviewWrapper}>
              <Grid item xs={12} sm={4} md={4} lg={4} className={classes.overviewCell}>
                <div className={classNames(classes.imageWrapper, classes.foundationalTraining)} >
                  <Image url={foundationalTraning} height={"inherit"} className={classNames(classes.image)} />
                </div>
                <div className={classes.cellTextWrapper}>
                  <Typography variant="body1">
                    Foundational training in NCG’s business centric approach to security, supported with micro and distance learning frameworks.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} className={classes.overviewCell}>
                <div className={classNames(classes.imageWrapper, classes.handOn)} >
                  <Image url={handsOn} height={"inherit"} className={classNames(classes.image)} />
                </div>
                <div className={classes.cellTextWrapper}>
                  <Typography variant="body1">
                    Working on real client engagements hands-on with NCG cyber experts.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} className={classes.overviewCell}>
                <div className={classNames(classes.imageWrapper, classes.qualityControl)} >
                  <Image url={qualityControl} height={"inherit"} className={classNames(classes.image)} />
                </div>
                <div className={classes.cellTextWrapper}>
                  <Typography variant="body1">
                    Opportunity to leverage and work on NCG’s new IBM Watson driven service quality control framework.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <div className={classes.linkWrapper}>
              <LinkDefault className={classes.link} target="_blank" variant="download" href="../../../../content/docs/careers/NCG_Internship_Program_2021.pdf">Download NCG’s Internship Program Description</LinkDefault>
            </div>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.focusAreaSection)}>
          <div className={classes.bannerBarTitle}>
            <Typography variant="h3" className={classes.headerWhite}>
              Internship Focus Areas
            </Typography>
          </div>
          <div className={classes.introWrapper}>
            <Typography variant="body1" className={classes.blueText}>
              NMU Cybersecurity Interns will be focusing on one or more of the following NCG practice areas:
            </Typography>
          </div>
          <Container maxWidth="lg" className={classes.focusAreaContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.focusRowWrapper}>
              <Grid item xs={12} sm={6} md={5} lg={4} className={classNames(classes.focusAreaTile, classes.cybersecurityEssentials)}>
                <Typography variant="h5">
                  CYBERSECURITY ESSENTIALS
                </Typography>
                <Typography variant="body1">
                  Learn about the essential cybersecurity practices and processes needed in every business, and how to implement them in a sustainable way that makes business sense for the organization.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4} className={classNames(classes.focusAreaTile, classes.cyberriskAssessments)}>
                <Typography variant="h5">
                  CYBER RISK ASSESSMENTS
                </Typography>
                <Typography variant="body1">
                  Learn to conduct cyber risk assessments to give businesses insight to their strengths and weaknesses, and how to provide best value recommendations to improve overall security posture.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.focusRowWrapper}>
              <Grid item xs={12} sm={6} md={5} lg={4} className={classNames(classes.focusAreaTile, classes.cyberriskManagementPrograms)}>
                <Typography variant="h5">
                  CYBER RISK MANAGEMENT PROGRAMS
                </Typography>
                <Typography variant="body1">
                  Learn to build and facilitate effective risk management programs tailored to an organization.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4} className={classNames(classes.focusAreaTile, classes.systemHardening)}>
                <Typography variant="h5">
                  SYSTEM HARDENING
                </Typography>
                <Typography variant="body1">
                  Learn how to lock down systems to mitigate against both internal and external threats.
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.moreInfoWrapper}>
              <Typography variant="body1" className={classes.blueText}>
                For more information on the U.P. Cybersecurity Institute visit:
              </Typography>
              <div className={classes.linkWrapper}>
                <Link target="_blank" href="https://www.nmu.edu/continuingeducation/cyber-home">
                  https://www.nmu.edu/continuingeducation/cyber-home
                </Link>
              </div>
              <div className={classes.linkWrapper}>
                <Link target="_blank" href="https://www.nmu.edu/business/cyberdefense">
                  https://www.nmu.edu/business/cyberdefense
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </PageWrapper>
    </>
  )
}

export default NmuPartnershipPage;