import http from "./http-common";

class ContactServiceBase {
  submitContactInfo(formData) {
    return http
      .post(`/contact/submitContactInfo/`, formData)
    }
}

const ContactService = new ContactServiceBase();

export default ContactService;