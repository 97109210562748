import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";


const useStyles = makeStyles((theme) => ({
  gridItem: {
    position: "relative"
  },
  // Must match EFSectionContainer's values
  reversedSectionPadding: {
    position: "absolute",
    [theme.breakpoints.down("xl")]: {
      top: -150,
      bottom: -150,
      right: -170,
      left: -170,
    },
    [theme.breakpoints.down("lg")]: {
      top: -120,
      bottom: -120,
      right: -140,
      left: -140,
    },
    [theme.breakpoints.down("md")]: {
      top: -80,
      bottom: -80,
      right: -100,
      left: -100
    },
    [theme.breakpoints.down("sm")]: {
      top: -40,
      bottom: -40,
      right: -40,
      left: -40
    },
    [theme.breakpoints.down("xs")]: {
      top: -20,
      bottom: -20,
      right: -170,
      left: -20
    }
  },
  positionRight: {
    "&$reversedSectionPadding": {
      left: 0
    },
  },
  positionBottom: {
    "&$reversedSectionPadding": {
      top: 0
    },
  },
  positionLeft: {
    "&$reversedSectionPadding": {
      right: 0
    },
  },
  positionCenter: {
    "&$reversedSectionPadding": {
      top: 0,
      bottom: 0
    },
  },
}));


export const POSITION_RIGHT = "right";
export const POSITION_BOTTOM = "bottom";
export const POSITION_LEFT = "left";
export const POSITION_CENTER = "center";


export default function EFSectionNoPaddingGridItem(props) {
  const classes = useStyles();
  const { children, className, position, ...gridProps } = props;

  return (
      <Grid
        className={classNames(classes.gridItem, className)}
        {...gridProps}
        item
      >
      <div
        className={
          classNames(
            classes.reversedSectionPadding,
            position === POSITION_RIGHT && classes.positionRight,
            position === POSITION_BOTTOM && classes.positionBottom,
            position === POSITION_LEFT && classes.positionLeft,
            position === POSITION_CENTER && classes.positionCenter,
          )
        }
      >
        {children}
      </div>
    </Grid>
  )
};
