//images
import introIMG from "images/images/banking/vendor/intro.jpg";
import cards from "images/images/banking/vendor/cards.jpg";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const VendorManagementMicrosite = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap">
        <div class="crunch">
          <div id="top" class="banner banner--vendor padZero">
            <h1 class="bannerTitle">Vendor Management</h1></div>
          <div class="page-wrap">
            <div class="secondary_layout secondary_layout--full">
              <div itemprop="breadcrumb" class="breadcrumb-wrapper">
                <ul class="breadcrumb">
                  <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                  <li class="active">Vendor Management</li>
                </ul>
              </div>
              <div itemprop="mainContentOfPage" class="page-section page-section--striped">
                <div class="mainpage-wrapper">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 class="heading-h3">Overview</h3>
                  </div>
                  <div id="introIMG" class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <img src={introIMG} class="img-responsive graphic" alt="Searching website" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>The Vendor Management life cycles starts with the selection of a third party that meets your requirements with the same control and care that you would provide. This is codified in the terms and conditions of your agreement. NCG will also help organizations outline the necessary supplier and security controls, audit types, privacy for data handling, and technical controls.</p>
                    <p>Getting a strong agreement in place is just the beginning of managing the relationship with the third party. Day-to-day activities need to be follow agreement terms. Responsible and accountable parties need to be aware and take advantage of scheduled events such as annual audits and the scope of a third party’s reach across your organization.</p>
                  </div>
                </div>
              </div>
              <div class="page-section  page-section--bordered">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 " id="cards">
                    <img src={cards} class="img-responsive graphic marginT" alt="Credit Cards" />
                  </div>
                  <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2 class="heading-h2">Vendor Management: Provided Services</h2>
                    <p>NCG helps organizations implement operational procedures to proactively manage third-party relationships. NCG implements reporting and processes to measure performance so that corrective action can be taken quickly and effectively.</p>
                    <p>By working with vendor management offices and strategic sourcing offices NCG provides support and advice. NCG also utilizes technology and different lines of business to implement the framework into other daily programs such as incident management, change management, and PMLC/SDLC processes.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VendorManagementMicrosite;