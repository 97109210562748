import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import slide1Image from "../../../images/images/microsite/imp/slider/female-computer-600.png";
import slide2Image from "../../../images/images/microsite/imp/slider/compass-maze-600.png";
import slide3Image from "../../../images/images/microsite/imp/slider/male-computer-600.png";
import slide4Image from "../../../images/images/microsite/imp/slider/piggy-bank-600.png";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 1000,
    width: "100%",
    display: "block",
    position: "relative",
  },
  slideBackground: {
    height: 850,
    width: "50%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "block",
    position: "absolute",
    right: 50,
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down('md')]: {
      right: 30,
    },
    [theme.breakpoints.down('sm')]: {
      top: "unset",
      bottom: 40,
      width: "80%",
      backgroundPosition: "left 75%",
      transform: "unset",
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      backgroundPosition: "left 75%",
    },
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
    [theme.breakpoints.down('sm')]: {
      width: "75%",
    },
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
    [theme.breakpoints.down('sm')]: {
      width: "75%",
    },
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
  },
  innerContent: {
    display: "flex",
    height: 1000,
    width: "100%",
    position: "relative",
  },
  textContainer: {
    display: "flex",
    height: 1000,
    zIndex: 100,
    position: "relative",
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 60,
      marginRight: 30,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 40,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  textWrapper: {
    paddingTop: 120,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingTop: 230,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 60,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 600,
    lineHeight: "1.2em",
    fontSize: 50,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 44,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 38,
    },
  },
  slideHeaderDark: {
    fontFamily: "Helvetica, sans-serif",
    color: "#092c58",
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
    marginBottom: 5
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "#00549a",
    fontWeight: "bold",
    lineHeight: "1.2em",
    fontSize: "30px",
    paddingTop: 30,
    paddingLeft: 50,
    marginTop: 30,
    maxWidth: 650,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  slideSubCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: variables.textPrimary,
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "1.5em",
    paddingLeft: "15%",
    maxWidth: 550,
    marginTop: 50,
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
}));


const IncidentManagementCarousel = () => {
  const classes = useStyles();

  const Slides =
    [{
      content: "Is your team ready for an incident that could impact your operations, customers, or regulatory commitments?",
      content2: "We empower your team to be better prepared to identify incidents, respond, and recover.",
    },
    {
      content: "Are you prepared with a plan to coordinate incident response with your internal and external parties?",
      content2: "We provide services for system incidents and data breaches to enable effective coordination.",
    },
    {
      content: "Do you have the tools, resources and expertise required to efficiently prepare your incident reporting?",
      content2: "We offer automated, cloud-based tools to collect and organize information about all incidents, large or small.",
    },
    {
      content: "Are your incident management, business continuity, and disaster recovery plans coordinated?",
      content2: "We bring our experience to help you with a plan to take on your biggest challenges and transition back to normal.",
    },
    ]

  return (
    <CustomCarousel sliderShadow={true}>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide1Background)} />
        <div className={classNames(classes.innerContent)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={9} sm={7} md={6} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Incident Management for System Incidents &amp; Data Breaches</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption)}>{Slides[0].content}</Typography>
              <Typography variant="h4" className={classNames(classes.slideSubCaption)}>{Slides[0].content2}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide2Background)} />
        <div className={classNames(classes.innerContent)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={9} sm={7} md={6} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Incident Management for System Incidents &amp; Data Breaches</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption)}>{Slides[1].content}</Typography>
              <Typography variant="h4" className={classNames(classes.slideSubCaption)}>{Slides[1].content2}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide3Background)} />
        <div className={classNames(classes.innerContent)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={9} sm={7} md={6} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Incident Management for System Incidents &amp; Data Breaches</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption)}>{Slides[2].content}</Typography>
              <Typography variant="h4" className={classNames(classes.slideSubCaption)}>{Slides[2].content2}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide4Background)} />
        <div className={classNames(classes.innerContent)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={9} sm={7} md={6} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Incident Management for System Incidents &amp; Data Breaches</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption)}>{Slides[3].content}</Typography>
              <Typography variant="h4" className={classNames(classes.slideSubCaption)}>{Slides[3].content2}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
    </CustomCarousel>
  )
}

export default IncidentManagementCarousel;