import React, { useState } from 'react';
import { makeStyles, styled } from "@material-ui/core/styles";
import { Grid, Link, Divider, Input, InputLabel, FormControl, Radio, RadioGroup, FormControlLabel, IconButton, Typography, TextField } from "@material-ui/core";
import classNames from "classnames";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintIcon from '@material-ui/icons/Print';
import variables from "style/variables.module.scss";
import MailerService from "../../services/mailer.service";
import ButtonCustom from "components/layout/buttonCustom.component";

// recaptcha
import ReCAPTCHA from "react-google-recaptcha";
import CustomModal from "../utils/CustomModal";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 890,
    display: "flex",
    justifyContent: "center",
    borderRadius: 5,
    maxHeight: "100vh",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  // HEADER
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    position: "relative",
    justifyContent: "center",
  },
  modalHeader: {
    color: variables.textSecondary,
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
      fontSize: variables.fontH5,
      paddingTop: 20,
    },
  },
  closeButtonWrapper: {
    display: "flex",
  },
  closeButton: {
    '&:hover': {
      backgroundColor: "white",
    },
    position: "absolute",
    right: 15,
  },
  closeIcon: {
    fontSize: 24,
    opacity: .6,
    '&:hover': {
      opacity: 1,
    },
  },
  // INTRO
  introSection: {
    padding: 20,
  },
  introText: {
    maxWidth: "80%",
    display: "block",
    margin: "0px auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "90%",
      fontSize: ".9em",
    },
  },
  // FORM
  form: {
    maxWidth: 750,
    margin: "0px auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "unset",
    },
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 40px",
  },
  row: {
    display: "flex",
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  componentWrapper: {
    padding: "10px 20px",
  },
  componentWrapperCustom: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20,
    },
  },
  labelText: {
    fontSize: 12,
    paddingTop: 0,
    fontWeight: 600,
    marginBottom: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  justifyRight: {
    justifyContent: "right",
  },
  justifyLeft: {
    justifyContent: "left",
  },
  confirmation: {
    color: "#ffffff",
    textAlign: "center",
    paddingBottom: 10,
    content: "",
    '&::after': {
      color: "#019fa1",
      content: "Message Submitted!"
    },
  },
  radioControl: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
  },
  radioControlLabel: {
    padding: 0,
    lineHeight: "normal",
  },
  inputBaseDateTime: {
    height: "fit-content",
  },
  inputBaseUpload: {
    height: "fit-content",
  },
  submitWrapper: {
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
  },
  buttonWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  // FOOTER
  footer: {
    backgroundColor: variables.ncgGray2,
    padding: 40,
  },
  footerWrapper: {
    maxWidth: 750,
    margin: "0px auto",
    justifyContent: "space-around",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "unset",
      textAlign: "center",
    },
  },
  footerHeader: {
    width: "100%",
    display: "block",
    marginBottom: 20,
    marginTop: 0,
    color: variables.textSecondary,
  },
  address: {
    paddingTop: 15,
    maxWidth: "fit-content",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      margin: "0px auto",
    },
  },
  footerList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    position: "relative",
  },
  listTitle: {
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: "normal",
    textTransform: "uppercase",
    margin: 0,
    color: variables.fontColorH5,
    [theme.breakpoints.down('sm')]: {
      width: "fit-content",
      margin: "0px auto",
    },
  },
  footerListItem: {
    paddingTop: 0,
    lineHeight: "1.5em",
  },
  locationTitle: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "flex-end",

  },
  footerListItemText: {
    fontSize: 12,
    padding: 0,
    color: variables.textSecondary,
    lineHeight: "1.5em",
  },
  locationLink: {
    display: "flex",
    alignItems: "center",
  },
  locationIcon: {
    fontSize: "1.3rem",
    marginLeft: 5,
    fill: variables.ncgTeal1,
  },
  phoneIcon: {
    fontSize: "1rem",
    marginLeft: 5,
    fill: variables.ncgTeal1,
  },
  faxIcon: {
    fontSize: "1rem",
    marginLeft: 5,
    fill: variables.ncgTeal1,
  },
  phoneNumber: {
    fontWeight: "bold",
    fontSize: 12,
    display: "inline",
    color: variables.ncgTeal2,
    paddingLeft: 5,
  },
  flexListItem: {
    display: "flex",
    alignItems: "center",
    paddingTop: 5,
    [theme.breakpoints.down('sm')]: {
      width: "fit-content",
      margin: "0px auto",
    },
  },
}));
const ValidationTextField = styled(TextField)({
  '& input:valid + fieldset': {
    borderColor: variables.ncgBlue1,
    borderWidth: 2,
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2,
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 6,
    padding: '4px !important', // override inline-style
  },
});
const RFPForm = (props) => {
  // form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [preferred, setPreferred] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [upload, setUpload] = useState("");

  const [validReCaptcha, setValidReCaptcha] = useState(false);

  const classes = useStyles();

  const onReCaptchaChange = (value) => {
    setValidReCaptcha(value);
  }

  const onReCaptchaExpire = (value) => {
    setValidReCaptcha(null);
  }

  const clearState = () => {
    setName(null);
    setEmail(null);
    setOrg(null);
    setPhone(null);
    setPhone(null);
    setType(null);
    setDateTime(null);
    setUpload(null);
    setPreferred(null);
  }

  const handleSubmit = (e) => {
    if (name && email && org && validReCaptcha) {
      const formData = new FormData();
      formData.append("file", upload)
      formData.append("name", name)
      formData.append("email", email)
      formData.append("org", org)
      formData.append("phone", phone)
      formData.append("type_of_assistance", type)
      formData.append("date_time", dateTime)
      formData.append("preferred", preferred)
      formData.append("type", `${props.rfpType}`)
      formData.append("reCaptchaResponse", validReCaptcha)

      MailerService.sendMail(formData)
        .then(() => {
          clearState();
          props.closeForm();
        })
    }
  }

  return (
    <CustomModal
      open={props.open}
      onClose={() => props.closeForm()}
    >
      <Grid container className={classes.modalContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.headerWrapper}>
          <Typography variant="h3" className={classNames(classes.modalHeader)}>Submit a {props.rfpType} RFP or Request Contact</Typography>
          <div className={classes.closeButtonWrapper}>
            <IconButton
              className={classes.closeButton}
              data-dismiss="modal"
              onClick={() => props.closeForm()}
            ><CloseRoundedIcon className={classNames(classes.closeIcon)} />
            </IconButton>
          </div>
        </Grid>
        <Divider />
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.introSection}>
          <Typography variant="body1" className={classes.introText}>Please fill out our contact form below or upload a Request for Proposal.
            A member of our {props.rfpType} Team will be in contact with to answer any questions and confirm next steps:</Typography>
        </Grid>
        <Divider />
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.mainContent}>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            component="fieldset"
            id="contact-form"
            data-toggle="validator"
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.confirmation, classes.bold)}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.row)}>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <ValidationTextField
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    label="First &amp; Last Name"
                    type="text"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <ValidationTextField
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    type="email"
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.row)}>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <ValidationTextField
                    id="org"
                    onChange={(e) => setOrg(e.target.value)}
                    label="Organization"
                    type="text"
                    required
                    variant="outlined"
                    defaultValue=" "
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <TextField
                    id="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    label="Phone"
                    type="tel"
                    variant="outlined"
                    defaultValue=" "
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.row)}>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <TextField
                    id="assistance"
                    onChange={(e) => setType(e.target.value)}
                    label="Assistance"
                    multiline
                    variant="outlined"
                    defaultValue=" "
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <InputLabel
                    for="prefferedContact"
                    className={classNames(classes.labelText)}
                  >Preferred Contact Method
                  </InputLabel>
                  <FormControl component="fieldset" cl>
                    <RadioGroup
                      row
                      aria-label="Preferred contact method"
                      defaultValue="email"
                      id="preferredContact"
                      name="radio-buttons-group"
                      onChange={(e) => { setPreferred(e.target.value) }}
                    >
                      <FormControlLabel
                        classes={{ label: classes.radioControlLabel }}
                        className={classes.radioControl}
                        value="phone"
                        control={<Radio className={classes.radioButton} />}
                        label="Phone"
                      />
                      <FormControlLabel
                        classes={{ label: classes.radioControlLabel }}
                        className={classes.radioControl}
                        value="email"
                        control={<Radio />}
                        label="Email"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.row)}>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classNames(classes.componentWrapper, classes.componentWrapperCustom)}>
                  <InputLabel
                    for="dueDate"
                    className={classNames(classes.labelText)}
                  >RFP Due Date/Time
                    <span class="help-text">Select Future Date</span>
                  </InputLabel>
                  <Input
                    classes={{ input: classes.inputBaseDateTime }}
                    onChange={(e) => setDateTime(e.target.value)}
                    formControl
                    type="datetime-local"
                    name="dueDate"
                    id="dueDate" />
                </Grid>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classes.componentWrapper}>
                  <InputLabel
                    className={classNames(classes.labelText)}
                    for="upload"
                  >RFP Upload
                    <span class="help-text">File Format: jpg, png, doc, pdf</span>
                  </InputLabel>
                  <Input
                    classes={{ input: classes.inputBaseUpload }}
                    onChange={(e) => setUpload(e.target.files[0])}
                    type="file"
                    name="upload"
                    id="upload"
                    size="30"
                    accept="image/jpg,image/png,image/jpeg,image/gif,application/pdf,application/msword"
                    data-error="Upload Size/Type is invalid." />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.row, classes.justifyRight)}>
                <Grid item xs={6} sm={12} md={6} lg={6} className={classNames(classes.componentWrapper, classes.submitWrapper)}>
                  <ReCAPTCHA
                    sitekey="6LdRxoAcAAAAAHQOmPpABlFV4hrPtNXEa-X-W258"
                    onChange={onReCaptchaChange}
                    onExpired={onReCaptchaExpire} />
                  <div className={classes.buttonWrapper}>
                    <ButtonCustom
                      onClick={() => { handleSubmit() }}
                      name="submit">
                      Send RFP Request
                    </ButtonCustom>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid container className={classNames(classes.footer)}>
          <Grid xs={12} sm={12} md={12} lg={12} className={classNames(classes.footerWrapper)}>
            <Typography variant="h4" className={classes.footerHeader}>NCG Contact Information</Typography>
            <Grid xs={12} sm={12} md={12} lg={12} className={classNames(classes.row, classes.footerRow)}>
              {/* PORTLAND ADDRESS */}
              <Grid item xs={4} sm={4} md={4} lg={4} className={classNames(classes.address, classes.mainAddress)}>
                <ul className={classNames(classes.footerList, classes.addressList)}>
                  <li className={classNames(classes.footerListItem, classes.locationTitle)}>
                    <Typography variant="h5" className={classes.listTitle}>
                      Portland, ME
                    </Typography>
                      <Link className={classes.locationLink} href="http://maps.google.com/maps?f=q&hl=en&geocode=&q=100+Middle+Street%3B+Portland,+Maine+04101&sll=37.0625,-95.677068&sspn=40.324283,92.8125&ie=UTF8&t=h&ll=43.667313,-70.247698&spn=0.02173,0.036564&z=14&iwloc=addr&source=embed">
                        <LocationOnIcon className={classes.locationIcon} />
                      </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      100 Middle St.
                    </Typography>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      East Tower, Suite #203
                    </Typography>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      Portland, ME 04101
                    </Typography>
                  </li>
                  <li className={classNames(classes.footerListItem, classes.flexListItem)}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      207.699.5540
                    </Typography>
                    <PhoneIcon className={classes.phoneIcon} />
                  </li>
                  <li className={classNames(classes.footerListItem, classes.flexListItem)}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      207.699.2113
                    </Typography>
                    <PrintIcon className={classes.faxIcon} />
                  </li>
                </ul>
              </Grid>
              {/* VA ADDRESS */}
              <Grid item xs={4} sm={4} md={4} lg={4} className={classNames(classes.address, classes.vaAddress)}>
                <ul className={classNames(classes.footerList, classes.addressList)}>
                  <li className={classNames(classes.footerListItem, classes.locationTitle)}>
                    <Typography variant="h5" className={classes.listTitle}>
                      DC METRO
                    </Typography>
                      <Link href="https://www.google.com/maps/place/1101+Wilson+Blvd,+Arlington,+VA+22209/@38.8954841,-77.0748085,892m/data=!3m2!1e3!4b1!4m6!3m5!1s0x89b7b65a3baef5b7:0x9e310e7ea6ce34df!8m2!3d38.89548!4d-77.0699376">
                        <LocationOnIcon className={classNames(classes.locationLink, classes.locationIcon)} />
                      </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      1101 Wilson Blvd.
                    </Typography>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      6th Floor
                    </Typography>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      Arlington, VA 22209
                    </Typography>
                  </li>
                  <li className={classNames(classes.footerListItem, classes.flexListItem)}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      703.351.3397
                    </Typography>
                    <PhoneIcon className={classes.phoneIcon} />
                  </li>
                  <li className={classNames(classes.footerListItem, classes.flexListItem)}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      703.351.335298
                    </Typography>
                    <PrintIcon className={classes.faxIcon} />
                  </li>
                </ul>
              </Grid>
              {/* MI ADDRESS */}
              <Grid item xs={4} sm={4} md={4} lg={4} className={classNames(classes.address, classes.MIAddress)}>
                <ul className={classes.footerList}>
                  <li className={classNames(classes.footerListItem, classes.locationTitle)}>
                    <Typography variant="h5" className={classes.listTitle}>
                      MARQUETTE, MI
                    </Typography>
                      <Link className={classes.locationLink} href="https://www.google.com/maps/place/101+W+Washington+St,+Marquette,+MI+49855/@46.5430296,-87.3949799,17z/data=!3m1!4b1!4m5!3m4!1s0x4d4e2098f28ccb47:0xf6801a0c971aa464!8m2!3d46.5430296!4d-87.3927912">
                        <LocationOnIcon className={classes.locationIcon} />
                      </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      101 W. Washington St.
                    </Typography>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      Suite #4
                    </Typography>
                  </li>
                  <li className={classes.footerListItem}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      Marquette, MI 49855
                    </Typography>
                  </li>
                  <li className={classNames(classes.footerListItem, classes.flexListItem)}>
                    <Typography variant="body2" className={classes.footerListItemText}>
                      906.451.5910
                    </Typography>
                    <PhoneIcon className={classes.phoneIcon} />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
}
export default RFPForm;