import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import ButtonCustom from "components/layout/buttonCustom.component";
import Banner from "components/layout/banner.component";
import ContentContainer from "components/layout/contentContainer.component";
import Image from "components/layout/image.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import careersBanner from "../../../images/images/banners/career.jpg";
import seniorConsultants from "../../../images/images/careers/seniorConsultants.jpg";
import consultants from "../../../images/images/careers/consultants.jpg";
import internships from "../../../images/images/careers/internship.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

const useStyles = makeStyles((theme) => ({
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    width: "100%",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 240,
      minWidth: 240,
    },
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 10,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 15,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  sideBarListItemAddress: {
    listStyleType: "none",
    paddingTop: 0,
  },
  buttonWrapper: {
    display: "block",
    marginTop: 5,
    marginBottom: 20,
  },
  icon: {
    marginLeft: 6,
    fontSize: "1.3em",
  },
  // MAIN CONTENT
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  introSection: {
    paddingTop: "80px!important;",
    paddingBottom: "80px!important;",
    [theme.breakpoints.down('md')]: {
      paddingTop: "20px!important;",
      paddingBottom: "20px!important;",
    },
  },
  subSection: {
    paddingTop: 30,
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  pageSectionStriped: {
    paddingTop: 60,
    paddingBottom: 60,
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none",
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  pageSectionWrapper: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  imageRight: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse",
    },
  },
  imageLeft: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  textWrapper: {
    paddingRight: 40,
  },
  textWrapperRight: {
    paddingRight: 0,
    paddingLeft: 40,
    marginLeft: "auto",
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  imageContainerRight: {
    marginLeft: "auto",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "unset",
    },
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 20,
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "2.5em",
    lineHeight: "1.125em",
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  marginBottom: {
    marginBottom: 40,
    display: "block"
  },
}));

const CareersPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={careersBanner}>
        Career Opportunities</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/about" variant="link">About NCG</BreadcrumbItem>
          <BreadcrumbItem href="/about-ncg/career-opportunities/careers" variant="active">Career Opportunities</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3} className={classes.sideBar}>
            <div className={classes.sideBarWrapper}>
              <Typography variant="h5" className={classes.sideBarH5}>Core Values</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Making a Tangible and Positive Impact
                </li>
                <li className={classes.sideBarListItem}>
                  Accounting for Today and Tomorrow
                </li>
                <li className={classes.sideBarListItem}>
                  Inspiring Success In Others
                </li>
                <li className={classes.sideBarListItem}>
                  Recognizing Strengths and Weaknesses
                </li>
              </ul>
            </div>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>Apply for a Position</Typography>
              <ul className={classes.sideBarList}>
                <li className={classNames(classes.sideBarListItem, classes.sideBarListItemAddress)}>
                  NCG Recruiting
                </li>
                <li className={classNames(classes.sideBarListItem, classes.sideBarListItemAddress)}>
                  100 Middle St
                </li>
                <li className={classNames(classes.sideBarListItem, classes.sideBarListItemAddress)}>
                  East Tower #203
                </li>
                <li className={classNames(classes.sideBarListItem, classes.sideBarListItemAddress)}>
                  Portland, ME 04101
                </li>
              </ul>
              <div className={classes.buttonWrapper}>
                <ButtonCustom href="mailto:careers@northcrossgroup.com">
                  Send Resume
                  <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
                </ButtonCustom>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8} className={classNames(classes.introSection)}>
            <Typography variant="h2">
              Professional Principles &amp; Philosophy
            </Typography>
            <Typography variant="body1">
              Each Northcross Group team member shares a common professional approach to commitment. Northcross Group’s principles and philosophies guide its engagements and internal evaluations, ensuring a continuous improvement process.
            </Typography>
            <Typography variant="body1">
              The Northcross Group is an Equal Opportunity Employer. We do not discriminate on the grounds of race, ethnicity, color, national origin, religion, sex, age, disability, or sexual orientation.
            </Typography>
            <Typography variant="body1">
              NCG welcomes general employment inquiries.
            </Typography>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Benefits
              </Typography>
              <Typography variant="body1">
                NCG provides employees full health insurance benefits, short and long term disability, and life insurance. Employees receive three weeks of personal time off along with observance of ten government holidays. Tuition and education benefits are also available.
              </Typography>
            </div>
          </Grid>
        </Container>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Typography variant="h2" className={classes.marginBottom}>
              Currently Recruiting
            </Typography>
            <div className={classNames(classes.rowWrapper, classes.imageRight)}>
              <Grid item xs={12} sm={11} md={8} lg={8} className={classes.textWrapper}>
                <Typography variant="h3">
                  Senior Consultants
                </Typography>
                <Typography variant="body1">
                  NCG Senior Consultants have a demonstrable track record of experience and driving results in both technical and management endeavors. Senior Consultants have the skills and capabilities to manage efforts with significant technology components and industry parameters. They have a focus on execution and the ability to help both resource teams and management utilize that perspective.
                </Typography>
                <Typography variant="body1">
                  Senior Consultants have experience managing programs, multiple project teams, and other project managers. All NCG employees must be able to submit for a comprehensive background check.
                </Typography>
                <Typography variant="h4">
                  Expectations &amp; Responsibilities
                </Typography>
                <Typography variant="body1">
                  Senior Consultants provide direct support to NCG clients. These positions are responsible for the management and execution of projects, as well as client relationship management.
                </Typography>
                <Typography variant="body1">
                  Senior Consultants possess experience in the overall structuring of a project, performing the detailed planning, and managing project execution and completion of moderate or large projects. This includes defining phase deliverables, tracking milestones and incurred expenses versus planned expenses, scheduling roles and resources, evaluating risks and recommending contingency plans.
                </Typography>
                <Typography variant="body1">
                  Senior Consultants are able to facilitate workshops, such as JAD (Joint Application Design), RAD (Rapid Application Development) and DRP (Disaster Recovery Planning) sessions. Candidates must have proficiency in directing, managing, planning and administering project activities. Experience managing multiple projects, professional and technical staff is preferred. Excellent planning, conceptualizing and consulting skills are mandatory.
                </Typography>
                <Grid xs={12} sm={12} md={12} lg={12} className={classes.row}>
                  <Grid item xs={12} sm={9} md={6} lg={6} className={classes.listContainer}>
                    <Typography variant="h5">
                      EXPERIENCE REQUIRED
                    </Typography>
                    <ul>
                      <li>Ten Years of Direct Experience
                      </li>
                      <li>Technical &amp; Management Capabilities
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={9} md={6} lg={6} className={classes.listContainer}>
                    <Typography variant="h5">
                      EDUCATION REQUIRED
                    </Typography>
                    <ul>
                      <li>Bachelor’s Degree
                      </li>
                      <li>Master’s Degree Preferred
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} className={classNames(classes.imageContainer, classes.imageContainerRight)}>
                <ContentContainer variant="customWidth">
                  <Image url={seniorConsultants} variant="customWidth" width={"100%"} />
                </ContentContainer>
              </Grid>
            </div>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <div className={classNames(classes.rowWrapper, classes.imageLeft)}>
              <Grid item xs={12} sm={6} md={3} lg={3} className={classes.imageContainer}>
                <ContentContainer variant="customWidth">
                  <Image url={consultants} variant="customWidth" width={"100%"} />
                </ContentContainer>
              </Grid>
              <Grid item xs={12} sm={11} md={8} lg={8} className={classNames(classes.textWrapper, classes.textWrapperRight)}>
                <Typography variant="h3">
                  Consultants
                </Typography>
                <Typography variant="body1">
                  NCG Consultants have demonstrable experience supporting both technology and management projects and programs. NCG Consultants have the ability to critically assess situations, define actions, and follow through on implementation. Our consultants have skills and capabilities to analyze system components across technologies, data, and people based processes. All NCG employees must be able to submit for a comprehensive background check.
                </Typography>
                <Typography variant="h4">
                  Expectations &amp; Responsibilities
                </Typography>
                <Typography variant="body1">
                  NCG Consultants work directly with client resources and management. Consultants provide a number of support services across system requirements, design, testing, training, and implementation.
                </Typography>
                <Typography variant="body1">
                  Consultants can document algorithms, identify logical system architectures, specify system interfaces, document operational requirements, and develop test plans. They can oversee acceptance testing, develop user guides, provide user training, and support users in the development of work processes.
                </Typography>
                <Grid xs={12} sm={12} md={12} lg={12} className={classes.row}>
                  <Grid item xs={12} sm={9} md={6} lg={6} className={classes.listContainer}>
                    <Typography variant="h5">
                      EXPERIENCE REQUIRED
                    </Typography>
                    <ul>
                      <li>Five years of System Analysis or System Audit/Assessment Experience
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={9} md={6} lg={6} className={classes.listContainer}>
                    <Typography variant="h5">
                      EDUCATION REQUIRED
                    </Typography>
                    <ul>
                      <li>Bachelor’s Degree in Management
                      </li>
                      <li>Or Technical Discipline
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <div className={classNames(classes.rowWrapper, classes.imageLeft)}>
              <Grid item xs={12} sm={6} md={3} lg={3} className={classNames(classes.imageContainer)}>
                <ContentContainer variant="customWidth">
                  <Image url={internships} variant="customWidth" width={"100%"} />
                </ContentContainer>
              </Grid>
              <Grid item xs={12} sm={11} md={8} lg={8} className={classNames(classes.textWrapper, classes.textWrapperRight)}>
                <Typography variant="h3" className={classes.headingOver}>
                  Internship Programs
                </Typography>
                <Typography variant="h5" className={classes.headingUnder}>
                  Portland, Maine Office
                </Typography>
                <Typography variant="body1">
                  NCG’s Internship Program offers professional experience and opportunities that are tailored to meet interns’ educational and professional goals and interests. The Program is designed to provide unique exposure and opportunities in the Management Consulting and IT fields, serving both private and federal clients. NCG provides a mix of developmental on-the-job assignments, research assignments, and mentoring that will develop the skills needed for professional success and growth
                </Typography>
                <Typography variant="body1">
                  NCG offers equal internship and employment opportunities for all qualified students without distinction or discrimination because of race, color, gender, religion, age, national origin, disability, pregnancy, marital or family status, veteran status, or other basis prohibited by law. This policy extends to all employees and applicants and in all aspects of the employment relationship.
                </Typography>
                <Typography variant="body1">
                  Because we work in highly sensitive and regulated industries, all NCG interns will be a subject to a background check which gathers information on areas including criminal checks, past employment, educational history, and credit records.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault target="_blank" variant="download" href="../../../../content/docs/careers/NCG_Internship_Program_2021.pdf">
                    Learn about the Internship Program
                  </LinkDefault>
                </div>
                <div className={classes.linkWrapper}>
                  <LinkDefault target="_blank" variant="download" href="../../../../content/docs/careers/NCG_Cybersecurity_Internship_2021.pdf">
                    Cybersecurity Internship Opportunity
                  </LinkDefault>
                </div>
                <div className={classes.linkWrapper}>
                  <LinkDefault target="_blank" variant="download" href="../../../../content/docs/careers/NCG_Safety_Internship_2021.pdf">
                    Safety Management System Internship Opportunity
                  </LinkDefault>
                </div>
              </Grid>
            </div>
          </Container>
        </div>

      </PageWrapper>
    </>

  )
}

export default CareersPage;