import React, { useState } from 'react';
import { Button } from "@material-ui/core";

//images
import cybersecurity from "images/images/orchestration/portal/Orchestration-Cybersecurity.png"
import knowProblem from "images/images/orchestration/portal/know-problem.jpg"
import focusArea from "images/images/orchestration/portal/focus-areas.jpg"
import orgWorkFlow from "images/images/orchestration/portal/organization-workflow.jpg"
import intro from "images/images/orchestration/portal/intro.jpg"
import introMobile from "images/images/orchestration/portal/intro-mobile.jpg"
import chain from "images/images/orchestration/portal/chain.jpg"
import chain2 from "images/images/orchestration/portal/chain-horizontal.jpg"

//modal
import SecurityPortalModal from "./securityPortalModal.component";
import CustomModal from "../../utils/CustomModal";

//fontawesome Icons
import ExpandIcon from "../../utils/expandIcon";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const SecurityPortalPage = (props) => {

  //Modal
  const [openModal, setOpenModal] = useState(false);

  const toggleForm = () => {
    setOpenModal(!openModal);
  };

  const openForm = () => {
    setOpenModal(true);
  };
  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap crunch securityPortal">
        <div id="top" class="banner banner--cyber-security padZero"   >
          <h1 class="bannerTitle">Security Portal</h1>
          <div class="securityPortal-wrapper">
            <img src={cybersecurity} class="securityPortal-img" alt="Cybersecurity" />
          </div>
        </div>
        <div class="page-wrap">
          <div class="secondary_layout">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                <li><a href="/capabilities/cybersecurity">Cybersecurity</a></li>
                <li class="active">Security Portal</li>
              </ul>
            </div>
            <div class="mainpage-wrapper">
              <div class="sideBar orchPortalSidebar">
                <div class="wrapper">
                  <h4>User Groups</h4>
                  <div itemprop="specialty" class="col-pad-none col-lg-12 col-md-12 col-sm-6 col-xs-12">
                    <h5>General User</h5>
                    <ul class="list disc">
                      <li>Access to Policies &amp; Procedures</li>
                      <li>Training Materials</li>
                      <li>Business Continuity Resources</li>
                      <li>Help Desk</li>
                      <li>FAQs</li>
                    </ul>
                  </div>
                  <div itemprop="specialty" class="col-pad-none col-lg-12 col-md-12 col-sm-6 col-xs-12">
                    <h5>Security User</h5>
                    <ul class="list disc ">
                      <li>Oversight &amp; Governance</li>
                      <li>Policy &amp; Procedure Development</li>
                      <li>Risk Management</li>
                      <li>Incident Response</li>
                      <li>Roadmap Management</li>
                    </ul>
                  </div>
                  <div itemprop="specialty" class="col-pad-none col-lg-12 col-md-12 col-sm-6 col-xs-12">
                    <h5>Executive User</h5>
                    <ul class="list disc ">
                      <li>Dashboard Views</li>
                      <li>Compliance Scorecards</li>
                      <li>Portfolio Management</li>
                      <li>Health &amp; Risk Escalation</li>
                    </ul>
                  </div>
                  <div itemprop="specialty" class="col-pad-none col-lg-12 col-md-12 col-sm-6 col-xs-12">
                    <h5>Key Third Party User</h5>
                    <ul class="list disc capa">
                      <li>Awareness &amp; Oversight of External Entities</li>
                      <li>Confidential Asset Handling</li>
                      <li>Audit and Review Rights</li>
                      <li>Interaction Coordination</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="intro" class="page-section ">
                <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h3 class="heading-h3">Overview</h3>
                  <p>The Orchestration's Cybersecurity allows businesses to engage and connect different user groups both inside and outside an organization and equip them with data and workflows for company objectives. This web and mobile-based tool helps organizations deliver on key business actions. NCG developed the Orchestration's Cybersecurity after decades of experience and has implemented it successfully in both private and public enterprise.</p>
                  <p>The Orchestration's Cybersecurity brings people, processes, infrastructure, and data together for a specific purpose. The tool incorporates intuitive and secure interfaces that facilitate communication across different user groups such as accountable parties, executives, workforce members, and key third parties. The Orchestration's Cybersecurity is available independently or in conjunction with the other professional services NCG offers.</p>
                </div>
              </div>
              <div itemprop="mainContentOfPage" class="page-section ">
                <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h2 class="heading-h2">Orchestration Smart Security</h2>
                  <h5 class="headings--under">A Human Interface to Security Threats and Vulnerabilities</h5>
                  <p>Addressing security across the enterprise requires spanning many different functional areas with a range of technical and procedural details. The breadth and depth of this challenge can be daunting in knowing where to start, how to proceed, and what to do over time. The Orchestration's Cybersecurity uses technology called Smart Security that helps puts a human interface on the security challenge by helping you through the process. Orchestration Smart Security will ping the appropriate person in the organization so they can take action. Orchestration Smart Security also lets your own management set parameters to ping personnel to ensure they are focused and doing the things that need to get done.</p>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="delivers" class="page-section ">
                <div class="graphic-wrap graphic-wrap--right marginT col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={knowProblem} class="img-responsive graphic" alt="Orchestration Security Portal" />
                </div>
                <div class=" col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 class="heading-h2">The Orchestration Security Portal Delivers</h2>
                  <p>The Orchestration Security Portal facilitates and enables a proactive enterprise security framework. With a combination of process facilitation, technology tools, governance functionality, and closed loop measurement of effectiveness, the Security Portal helps organizations understand their complete security posture on a real-time basis. The Portal provides the capabilities to engage and mobilize the organization and third parties when needed. The Portal not only brings together data and information but connects processes, requirements and people to streamline efforts and increase effectiveness. The Security Portal connects people, data, processes, and technology in complex and highly regulated environments that are often separated by physical and/or logical divides. Being able to bridge these divisions and the different business functions is key for readiness and the ability to respond and recover. </p>
                </div>
              </div>
              <div itemprop="mainContentOfPage" class="page-section">
                <div class="graphic-wrap graphic-wrap--left marginT col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={focusArea} class="img-responsive graphic" alt="Program Development and Operations" />
                </div>
                <div itemprop="specialty" class=" col-lg-7 col-md-7 col-sm-8 col-xs-12">
                  <h2 class="heading-h2" >Program Development and Operations</h2>
                  <p> As organizational and industry needs change, the Orchestration's Cybersecurity also evolves to accommodate company objectives.</p>
                </div>
                <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div itemprop="specialty" class="keyAreas">
                    <h5>The Orchestration's Cybersecurity emphasizes four key areas:</h5>
                    <div class="topic col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <p><b>Strategy and Direction</b><br /> The interface allows businesses to plan objectives and coordinate action with other enterprise processes.</p>
                    </div>
                    <div class="topic col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <p><b>Action and Implementation</b><br />The interface also helps businesses establish roles and responsibilities as well as take proactive steps toward their goals.</p>
                    </div>
                    <div class="topic col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <p><b>Day-To-Day Operations</b><br /> The Orchestration's Cybersecurity coordinates day-to-day actions and integrates key tasks across the enterprise.</p>
                    </div>
                    <div class="topic col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <p><b>Oversight and Assessment</b><br /> The portal allows businesses to actively monitor, evaluate, and even correct actions to ensure success.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="problem" class="page-section ">
                <div class="graphic-wrap graphic-wrap--right  col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <img src={orgWorkFlow} class="img-responsive graphic" alt="Organization Workflow" />
                  <Button onClick={() => openForm()} class="btn btn-expand btn-lg">Click to Expand <ExpandIcon /></Button>
                </div>
                <div class=" col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <h2 class="heading-h2">Knowing When You Have a Problem</h2>
                  <p>Organizations struggle to understand their current security posture and measure how effective steps are to improve their capabilities to address threats. Most organizations would not even know when they are under attack until after the effects are being noticed by their employees and customers. Even with sophisticated systems for monitoring and detection, organizations struggle to understand the data provided. We interpret this data and help organizations stay proactive and prevent security issues from happening.</p>
                </div>
                <div itemprop="mainContentOfPage" class=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h5>Do you have the Ability to Respond and Recover?</h5>
                  <p>Hardly a day goes by without word of another cybersecurity incident. Dealing with a cyber event is not a matter of if, but a matter of when. In the midst of an event is not the time to be working on a response and continuity of operations plans or figuring out how to communicate and coordinate with internal and external parties. Most organizations do not have comprehensive capabilities to respond and recover gracefully from a cyber event- leaving their viability at risk.</p>
                  <h5>Are you in Compliance with Industry Standards?</h5>
                  <p>Critical infrastructure industries operate under a myriad of regulations, guidance and industry standards. These are in place due to the very nature of the role these industries play in our nation’s safety, security, and economy. This overhead to regular operations must be managed effectively and efficiently so that it does not compromise the day-to-day business or neglect requirements.</p>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="challenges" class="page-section" style={{ paddingBottom: "5px" }}>
                <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={intro} class="img-responsive graphic hidden-sm hidden-xs" alt="Meet Challenges" />
                  <img src={introMobile} class="img-responsive graphic hidden-lg hidden-md" alt="Meet Challenges" />
                </div>
                <div itemprop="specialty" class=" col-lg-7 col-md-7 col-sm-8 col-xs-12">
                  <h2 class="heading-h2">How to Meet These Challenges</h2>
                  <p>Critical infrastructure industries need a place to start, definition of what to do, and ways to keep it going that are specific to their complex high-risk operating environments. Leveraging of best practices is essential, while acknowledging that cookie-cutter solutions will quickly fall to the wayside.</p>
                  <p>Security is personal to an organization; owning it needs to belong with the organization and be part of its corporate culture. No matter how sophisticated or nascent an organization's security posture, it needs the following capabilities and the agility to evolve over time.</p>
                </div>
              </div>
              <div class=" col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div class="knowContainer col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <ul id="knowList">
                    <li>Know</li>
                    <li>Prevent</li>
                    <li>Detect</li>
                    <li>Respond</li>
                    <li>Recover</li>
                    <li>Comply</li>
                  </ul>
                  <div itemprop="specialty" class="flex-page-section">
                    <div class="tile col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <p>Account for Key Partners and Business Associates</p>
                    </div>
                    <div class="tile col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <p>Be Industry and Organization Specific</p>
                    </div>
                    <div class="tile col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <p>Support Core Business Functions</p>
                    </div>
                    <div class="tile col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <p> Oversight and Governance for Security Needs</p>
                    </div>
                  </div>
                </div>
              </div>
              <div itemprop="mainContentOfPage" id="securityDifference" class="page-section ">
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img id="chainImg" src={chain} class="img-responsive graphic  hidden-print hidden-xs" alt="Chain" />
                  <img id="chainImg_2" src={chain2} class="img-responsive graphic hidden-lg hidden-md hidden-sm" alt="Chain" />
                </div>
                <div class=" col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 class="heading-h2">What Makes This Security Portal Different?</h2>
                  <p>There are many different security systems and tools on the market, but there is nothing that brings them all together for an organization and helps the organization’s people use that data and information. The adage of a chain only being as strong as its weakest link applies to enterprise cybersecurity. The most robust system and technology will be rendered useless if a threat finds a way to exploit a weak link elsewhere in the enterprise. The security portal leverages the Orchestration framework developed by NCG to bring people, data, and technology together and facilitates efforts to meet objectives.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal open={openModal} onClose={toggleForm}>
        <SecurityPortalModal setOpenModal={setOpenModal} />
      </CustomModal>
    </>
  );
}
export default SecurityPortalPage;