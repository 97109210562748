import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    paddingBottom: 30,
    [theme.breakpoints.down(763)]: {
      paddingBottom: 0,
    },
  }
}));
const menuItems =
  [
    { label: 'CMMC Services', href: '#cmmc-services' },
    { label: 'Orchestration Portal', href: '#cmmc-orchestration-portal' },
    { label: 'CMMC Questionnaire', href: '#cmmc-questionnaire' },
    { label: 'Contact', href: '#request', rfpType: 'CMMC', contactForm: true }
  ]
const CMMCNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar bgColor="white" menuItems={menuItems} elementInView={props.elementInView}/>
    </div>
  );
}
export default CMMCNavBar;