//fontawesome Icons
import AdobeFileLogo from "../../utils/AdobeFileLogo";
import AngleDoubleRightIcon from "../../utils/angleDoubleRightIcon";

//images
import epCyber from "images/images/cyber/sections/enterprise.jpg";
import industry from "images/images/cyber/sections/industry.jpg";
import culture from "images/images/cyber/sections/culture.jpg";
import system from "images/images/cyber/sections/system.jpg";
import legal from "images/images/cyber/sections/legal.jpg";
import bcp from "images/images/cyber/sections/bcp.jpg"
import vendor from "images/images/cyber/sections/vendor.jpg"
import portal from "images/images/cyber/sections/portal.png"

//carousel
import CybersecurityCarousel from "./CybersecurityCarousel"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const CybersecurityMicrosite = (props) => {

  return (
    <>
      <BackToTopCustom />
      <CybersecurityCarousel />
      <div class="bootstrap">
        <div class="crunch">
          <div itemprop="mainContentOfPage" class="page-wrap page-wrap--no-pad">
            <div class="microsite_layout cyber_microsite">
              <div itemprop="breadcrumb" class="breadcrumb-wrapper">
                <ul class="breadcrumb">
                  <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                  <li class="active">Cybersecurity</li>
                </ul>
              </div>
              <div id="intro" class="page-section page-section--striped col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mainpage-wrapper ">
                  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <h1>Cybersecurity</h1>
                    <p>As business dependence on technology grows, the risks and threats associated with cybersecurity increase. In response, regulatory requirements, industry standards, and insurance considerations are increasing to meet this challenge; but they can be confusing or even contradictory to an organization trying to increase its cybersecurity posture.</p>
                    <p>To address evolving cybersecurity challenges, it is critical that businesses have a methodical approach. NCG works with organizations to enhance their cybersecurity programs to meet their specific needs. Cybersecurity threats take on physical, technical, and people based approaches. Thus, it is critical that these programs cover all these aspects for the entire enterprise.</p>
                    <p>NCG designs programs and outlines daily steps for businesses to ensure the integrity of critical assets. NCG consultants have decades of experience building cybersecurity programs and assessing them across industries and the Federal government. With this experience and capabilities, NCG can quickly understand your business needs and design tailored cybersecurity solutions that encompass all aspects of security, development life cycles, management, and governance practices. NCG enables cybersecurity programs to deal with insider threats as well as with key third parties that handle sensitive data.</p>
                    <p>NCG cybersecurity assessments begin with an individualized IT discovery process that identifies the scope of the current technology infrastructure, data, connected third parties, controls, policies and procedures, and resources. After the scope is defined, NCG identifies security risks that require immediate action as well as mid- and long-term goals so that businesses have a sustainable cybersecurity framework. NCG uses multiple compliance and conformance frameworks based on the industry and operations of the organization such as NIST, FISMA, SCADA, NRC, PCI, HIPAA, and NERC CIP.</p>
                    <p>NCG Penetration Testing (PenTest) and Social Engineering testing provide an additional level of insight into your organizational cybersecurity posture.  These tests and assessments provide insight and details for steps and actions that can help thwart or mitigate the impact of threats to the enterprise.</p>
                  </div>
                  <div itemscope itemtype="http://schema.org/WPSidebar" class="sideBar col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="wrapper">
                      <h5>Service Cards</h5>
                      <ul class="list">
                        <li><a target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Cybersecurity_Services.pdf">Cybersecurity Services</a></li>
                        <li><a target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_HC_InfoTech_092014.pdf">Healthcare Information</a></li>
                        <li><a target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_HealthcareSecurity.pdf">Healthcare Security</a></li>
                      </ul>
                      <a id="adobe" target="_blank" rel="noreferrer" href="https://get.adobe.com/reader/"><h5><AdobeFileLogo />Download Adobe Reader</h5></a>
                      </div>
                      <div class="wrapper last">
                      <h5>Related Services</h5>
                      <ul class="list  bulleted">
                        <li>Application and Network Scanning</li>
                        <li>Policy and Procedure Development</li>
                        <li>Hardware and Software Hardening</li>
                        <li>Risk Assessment and Management</li>
                        <li>Third-party Audits and Assessments</li>
                        <li>IT Security Training</li>
                        <li>Penetration Testing</li>
                        <li>Security Monitoring Program</li>
                        <li>Resources</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="enterprise" class="page-section page-section--striped col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="img-responsive img-rounded" src={epCyber} alt="Enterprise for Cybersecurity" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2 class="headings--over">Focus on the Enterprise for Cybersecurity</h2>
                    <h5 class="headings--under">Security covers practically every aspect of the enterprise. </h5>
                    <p>Cybersecurity needs to be comprehensive and cover the entire organization.  The old adage that says a chain is only as strong as its weakest link holds true for cybersecurity.  Gaps in your capabilities can and will be exploited.</p>
                    <p>NCG helps organizations expand cybersecurity capabilities across the enterprise and integrate efforts with other risk and control frameworks. NCG works with all parts of the organization to work through complexity and disconnects and provide steps that can be taken on a daily basis and longer term initiatives to implement the tools and procedures to improve the overall enterprise security posture.   </p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="infrastructure" class="page-section page-section--striped">
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="img-responsive img-rounded" src={industry} alt="ritical Infrastructure Support" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2>Critical Infrastructure Support</h2>
                    <p>Worried your infrastructure will fall victim to the next cyber breach? Cyber criminals are methodically looking for ways to exploit your systems to steal or hijack  information. NCG understands these risks and develops a roadmap to prepare your organization for these attacks.</p>
                    <a class="  btn-link " href="/capabilities/cybersecurity/critical-industries">Learn more about Critical Infrastructure Support <AngleDoubleRightIcon /> </a>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="culture" class="page-section page-section--striped">
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="img-responsive img-rounded" src={culture} alt="Corporate Culture of Security" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2 class="headings--over">Corporate Culture of Security</h2>
                    <h5 class="headings--under">Integrating security across the enterprise, from people to processes and systems.</h5>
                    <p>More than policies and procedures, security must become intuitive at every level of the organization. Security needs to be a primary part of the corporate culture just like quality, safety, and innovation.</p>
                    <p>Borne out of awareness and training, everyone needs to understand the risks and what actions to take in today’s environment. Executive and management leadership plays a significant role in the tenor and attitudes of the organization. Security considerations need to become the way of doing things—second nature type of activities.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="systems" class="page-section page-section--striped">
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={system} class="img-responsive graphic" alt="Systems &amp; Infrastructure" />
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-8 col-xs-12">
                    <h2 class="headings--over">Systems &amp; Infrastructure</h2>
                    <h5 class="headings--under">Cybersecurity Mechanics and Infrastructure</h5>
                    <p>The backbone of the cyber infrastructure, networks, systems, hardware and software and how it operates is foundational to your cybersecurity posture.  Robust infrastructure and processes to manage its currency and updates as needed are primary elements to maintaining confidentiality, integrity, and availability of your information resources.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="legal" class="page-section page-section--striped">
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={legal} class="img-responsive graphic" alt="Legal &amp; Compliance" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12  ">
                    <h2 class="headings--over">Legal &amp; Compliance</h2>
                    <h5 class="headings--under">Managing Compliance Requirements </h5>
                    <p>Navigating multiple regulatory frameworks and ensuring adequate protection and provisions in agreements with key third parties requires a blend of technical and subject matter expertise. NCG helps organizations craft the requisite technical and operational constructs to ensure compliance and conformance to regulatory and industry requirements.   NCG helps organizations develop supporting policies and procedures to implement and guide efforts across the enterprise.  With tools to oversee and demonstrate compliance, NCG helps organizations manage requirements efficiently and to the benefit of operations. </p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="disaster" class="page-section page-section--striped" style={{ backgroundColor: "#fff" }}>
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="img-responsive graphic" src={bcp} alt="BCP" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <h2 class="headings--over"> Business Contingency Planning &amp; Disaster Recovery</h2>
                    <h5 class="headings--under">When things happen; be ready</h5>
                    <p >Preparedness is an ongoing process that spans operations and new solutions to make sure the organization can manage and adapt to unplanned occurrences.  The organization needs to not only have back-ups, but the process and ability to operate under challenging circumstances and transition back to normal operations.  Being able to deal with an event can make the difference between surviving and ceasing operations.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="party" class="page-section page-section--striped">
                <div class="mainpage-wrapper ">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img id="enterpriseimg" class="img-responsive graphic " src={vendor} alt="Vendors &amp; Third Parties" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 ">
                    <h2 class="headings--over">Vendors &amp; Third Parties</h2>
                    <h5 class="headings--under">Cybersecurity and your Dependencies – Vendors, Business Associates, and the Supply Chain</h5>
                    <p>Most organizations have a complex set of dependencies on key third parties and supply chains.  Threats and vulnerabilities of these relationships must be understood and managed on a day-to-day basis or daily basis.  These relationships are part of the overall security and preparedness environment. </p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="portal" class="page-section page-section--striped">
                <div class="mainpage-wrapper ">
                  <div id="portalimg" class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="img-responsive graphic" src={portal} alt="Orchestration Security Portal" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2 class="headings--over">Orchestration Security Portal</h2>
                    <h5 class="headings--under">Enterprise level ability to track, manage, and address security needs for your organization.</h5>
                    <p>The NCG Orchestration suite of tools provides secure cloud based automation to help the people in your organization be more effective in accomplishing important business objectives. Orchestration for Cybersecurity helps your organization keep on top of all aspects of your cybersecurity program. Orchestration helps people across your organization keeping track of things that need to be done on any given day.</p>
                    <p>Orchestration for Cybersecurity is an integrated solution that provides key tools and the ability to connect to your existing systems and infrastructure to help your organization stay on top of its security needs. Orchestration tools include:</p>
                    <ul >
                      <li>Real-time tracking of security elements across networks, data, process, and procedures.</li>
                      <li>Tools to meet compliance requirements, address threats, and provide efficiencies and scalability for security across the enterprise. </li>
                      <li>Manage and oversee workflows and efforts to make sure you are getting the most out of your security investments.</li>
                    </ul>
                    <a href="/capabilities/orchestration-platform/security-portal" class="btn-link">Learn more about the Orchestration Security Portal <AngleDoubleRightIcon /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CybersecurityMicrosite;