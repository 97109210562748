import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme) => ({
  banner: {
    display: "block",
    height: 350,
    width: "100%",
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center -200px",
    backgroundAttachment: "fixed",
    transition: "height 0.35s ease-in-out",
    borderBottom: "2px solid #6fb3ce",
    zIndex: 1,
    [theme.breakpoints.down('1279')]: {
      height: 220,
    },
    '&::before': {
      display: "block",
      height: "inherit",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(to right, rgba(209, 237, 255, 0.68) 30%, rgba(23, 133, 210, 0) 100%)",
    },
  },
  bannerSmall: {
    [theme.breakpoints.down('sm')]: {
      height: 240,
    },
  },
  bannerXS: {
    [theme.breakpoints.down('sm')]: {
      height: 290,
    },
  },
  bannerXL: {
    height: 420,
    '&::before': {
      background: "linear-gradient(to right, rgba(0, 84, 154, 0.68) 0%, rgba(60,143,182, 0) 100%)",
      mixBlendMode: "darken",
    },
    [theme.breakpoints.down('md')]: {
      height: 340,
    },
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },
  headerWrapper: {
    height: "inherit",
  },
  primaryHeader: {
    lineHeight: ".8em",
    marginTop: 0,

  },
  smallHeader: {
    fontSize: "40px",
    lineHeight: "48px",
    maxWidth: 900,
    [theme.breakpoints.down('md')]: {
      fontSize: "28px",
      lineHeight: "2.3rem",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "26px",
      lineHeight: "2rem",
    },
  },
  imageHeader: {
    fontSize: "40px",
    lineHeight: "48px",
    maxWidth: 900,
    textShadow: "1px 1px 1px #00549a",
    color: "white",
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: "28px",
      lineHeight: "2.3rem",
      maxWidth: "80%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "25px",
    },
  },
  subheader: {
    fontSize: 27,
    letterSpacing: ".06em",
    fontWeight: "normal",
    lineheight: "1",
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  textContainer: {
    position: "absolute",
    bottom: 0,
  },
  textContainerXS: {
    [theme.breakpoints.down('md')]: {
      maxWidth: "85%",
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "unset",
    },
  },
  textContainerImage: props => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
}));

const Banner = (props) => {
  const classes = useStyles(props);

  if (props.variant === "subheader") {
    return (
      <div className={classes.banner} style={{ backgroundImage: "url(" + props.backgroundImage + ")" }}>
        <Container maxWidth="lg" className={classes.headerWrapper}>
          <div className={classNames(classes.textContainer)}>
            <Typography variant="h1" className={classes.primaryHeader} >
              {props.children}
            </Typography>
            <Typography variant="h1" className={classes.subheader}>
              {props.subheader}
            </Typography>
          </div>
        </Container>
      </div>
    );
  }
  if (props.variant === "small") {
    return (
      <div className={classNames(classes.banner, classes.bannerSmall)} style={{ backgroundImage: "url(" + props.backgroundImage + ")" }} >
        <Container maxWidth="lg" className={classes.headerWrapper}>
          <div className={classNames(classes.textContainer, classes.textContainerSmall)}>
            <Typography variant="h1" className={classes.smallHeader} >
              {props.children}
            </Typography>
          </div>
        </Container>
      </div>
    );
  }
  if (props.variant === "xs") {
    return (
      <div className={classNames(classes.banner, classes.bannerXS)} style={{ backgroundImage: "url(" + props.backgroundImage + ")" }} >
        <Container maxWidth="lg" className={classes.headerWrapper}>
          <div className={classNames(classes.textContainer, classes.textContainerXS)}>
            <Typography variant="h1" className={classes.smallHeader} >
              {props.children}
            </Typography>
          </div>
        </Container>
      </div>
    );
  }
  if (props.variant === "xl") {
    return (
      <div className={classNames(classes.banner, classes.bannerXL)} style={{ backgroundImage: "url(" + props.backgroundImage + ")" }}>
        <Container maxWidth="lg" className={classes.headerWrapper}>
          <div className={classNames(classes.textContainer, classes.textContainerImage)}>
            {props.children}
            <Typography variant="h1" className={classNames(classes.smallHeader, classes.imageHeader)} >
              {props.header}
            </Typography>
          </div>
        </Container>
      </div>
    );
  }
  else {
    return (
      <div className={classes.banner} style={{ backgroundImage: "url(" + props.backgroundImage + ")" }}>
        <Container maxWidth="lg" className={classes.headerWrapper}>
          <div className={classes.textContainer}>
            <Typography variant="h1" className={classes.header}>
              {props.children}
            </Typography>
          </div>
        </Container>
      </div>
    )
  }
};

export default Banner;