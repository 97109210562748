import { useEffect, useState } from "react";
import EFNavBar from "./EFNavBar";
import BackToTopCustom from "components/layout/BackToTopCustom";
import { makeStyles } from "@material-ui/core";
import EFSplashSection from "./sections/EFSplashSection";
import EFBridgingSection from "./sections/EFBridgingSection";
import EFUnderSplashSection from "./sections/EFUnderSplashSection";
import EFDEISection from "./sections/EFDEISection";
import EFEmpowermentSection from "./sections/EFEmpowermentSection";
import EFEmpowermentCards from "./sections/EFEmpowermentCards";
import EFInitiatives from "./sections/EFInitiatives";

const primaryDark = "#0D2E51"
const primary = "#3F80A8"

const useStyles = makeStyles((theme) => ({
  splashImage: {
    width: "100%",
    display: "block"
  },
  abbTitle: {
    fontSize: "120px",
    color: primaryDark,
    position: "absolute",
    top: 240,
    paddingLeft: 20,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      top: 260,
      fontSize: "90px",
    },
    [theme.breakpoints.down('xs')]: {
      top: 180,
      fontSize: "70px",
    },
  },
  title: {
    position: "absolute",
    top: 150,
    maxWidth: "62%",
    fontSize: "32px",
    color: primaryDark,
    fontWeight: 600,
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      top: 160,
      fontSize: "24px",
    },
    [theme.breakpoints.down('xs')]: {
      top: 90,
      fontSize: "18px",
      maxWidth: "50%",
    },
  },
  splashText: {
    position: "absolute",
    color: primaryDark,
    paddingLeft: 30,
    top: 360,
    maxWidth: "33%",
    fontSize: "18px",
    [theme.breakpoints.up('xl')]: {
      maxWidth: "22%",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "21%",
      minWidth: 400
    },
    [theme.breakpoints.down('md')]: {
      top: 370,
      maxWidth: "37%",
      fontSize: "16px",
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  aboutSection: {
    background: "linear-gradient(180deg, #F0F0F0 0%, #B8D8E3 100%)",
    paddingBottom: 20
  },
  programSection: {
    background: "linear-gradient(180deg, #B8D8E3 0%, #F0F0F0 100%)",
  },
  titleBorder: {
    width: "100%",
    height: 30,
    backgroundColor: "#3F80A8",
    position: "relative",
    top: -10
  },
  sectionTitle: {
    backgroundColor: "#3F80A8",
    maxWidth: 400,
    color: "#FFFFFF",
    padding: 20,
    textAlign: "center",
    marginTop: -25,
    marginBottom: 100,
    marginLeft: 65,
    borderRadius: 10,
    whiteSpace: "nowrap"
  },
  text: {
    color: "#0E3359",
  },
  textLight: {
    color: "#FFFFFF",
  },
  bold: {
    fontWeight: 600
  },
  textSection: {
    margin: 50
  },
  textPadding: {
    paddingBottom: 30,
  },
  textRight: {
    color: "#0E3359",
    width: "100%",
    maxWidth: "75%",
    [theme.breakpoints.down('lg')]: {
      maxWidth: "35%",
      minWidth: 450,
    },
  },
  cycleImage: {
    width: "100%",
    height: "auto",
    position: "relative",
    [theme.breakpoints.up('md')]: {
      width: "100%",
      top: -30
    },
  },
  programApproachSection: {
    background: "linear-gradient(180deg, #C9ECFF 0%, #73C8F8 97.5%)",
  },
  programImage: {
    width: "120%",
    position: "relative",
    left: -250,
  },

  cardListContainer: {
    paddingTop: 70,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  cardContainer: {
    display: "flex",
    paddingBottom: 30,
    [theme.breakpoints.down('xl')]: {
      maxWidth: "20%"
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: "20%"
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: "30%"
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "50%"
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%"
    },
  },
  center: {
    display: "flex",
    justifyContent: "center"
  },
  serviceTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center"
  },
  serviceSection: {
    background: "linear-gradient(180deg, #F3FBFF 0%, #C9ECFF 100%)",
    paddingBottom: 70,
  },
  serviceCycleContainer: {
    backgroundColor: primary,
    padding: 15,
    borderRadius: 10
  }
}));


const menuItems = [
  { label: "Bridging Fields of Study", href: "#Bridging-Fields" },
  { label: "Addressing Obstacles & Barriers", href: "#Addressing-Obstacles" },
  { label: "Empowerment Targets", href: "#Empowerment-Targets" },
  { label: "Current Initiatives", href: "#Initiatives" }
];


export default function EFMicrositePage(props) {
  const classes = useStyles(props);

  const [elementId, setElementId] = useState("");

  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  }, [])

  return (
    <>
      <EFNavBar
        menuItems={menuItems}
        elementInView={elementId}
      />
      <BackToTopCustom />

      {/* Splash */}
      <div className={classes.splashContainer}>
        <EFSplashSection />
      </div>

      <EFUnderSplashSection />
      <EFBridgingSection />
      <EFDEISection />
      <EFEmpowermentSection />
      <EFEmpowermentCards />
      <EFInitiatives />
    </>
  )
}
