import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "2.5em",
    lineHeight: "1.125em",
    marginTop: 30,
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
}));

const PPManageKnowledge = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Manage Knowledge</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/manage-knowledge" variant="active">Manage Knowledge</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Knowledge Management (KM) Systems and Processes
                </li>
                <li className={classes.sideBarListItem}>
                  Darwin Information Typing Architecture (DITA)
                </li>
                <li className={classes.sideBarListItem}>
                  XML/XSL
                </li>
                <li className={classes.sideBarListItem}>
                  Web Services
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2" className={classes.headingOver}>
                Contractor Management Process
              </Typography>
              <Typography variant="h2" className={classes.headingUnder}>
                TD Banknorth
              </Typography>
            </Grid>
            <Grid sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                TD Banknorth utilizes a large pool of staff augmentation contractors as part of its internal technology group, but had no process in place for knowledge capture or management. As contractors rolled off projects or left for other engagements all their experience and knowledge left with them.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG instituted a contractor management process that included a knowledge transfer process, framework and infrastructure. NCG then formalized the memorialization process of contractor work products and information. Formal cross-training and information exchanges with Bank personnel were instituted.
              </Typography>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                NCG delivered TD Banknorth a Knowledge Management framework and implemented the collection of artifacts and information transfer sessions. With the conclusion of their most recent acquisition of Interchange Bank, TD Banknorth had dozens of contractors leave after years of service. The closeout and knowledge management processes implemented by NCG helped ensure that Bank retained as much of that intellectual capital as possible. It also provides a framework that can grow with the Bank over time.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default PPManageKnowledge;