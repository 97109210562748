import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Link } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import classNames from "classnames";
import Banner from "components/layout/banner.component";
import MediaDate from "components/layout/mediaDate.component";
import PageWrapper from "components/layout/pageWrapper.component";
import variables from "../../../../style/variables.module.scss";
// IMAGES
import blogBanner from "../../../../images/images/banners/blog.jpg";
import NcgLogo from "../../ncgLogo";

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    paddingTop: 20,
  },
  dateWrapper: {
    paddingBottom: 10,
  },
  contentWrapper: {
    display: "block",
    margin: "0px auto",
    paddingTop: 55,
    paddingBottom: 55,
  },
  listItem: {
    fontSize: variables.fontBody,
    letterSpacing: ".08em",
    color: variables.textBlue,
    fontWeight: 600,
  },
  unorderedList: {
    paddingLeft: 50,
    marginTop: 0,
    marginBottom: 10,
    paddingBottom: 0,
  },
  listBreak: {
    paddingBottom: 20,
  },
  logoWrapper: {
    height: 100,
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blogFooter: {
    display: "flex",
    padding: "40px 15px 0px 15px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  link: {
    display: "block",
    padding: "2px 0px",
  },
}));

const BlogPostFederalFinancial = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner variant="xs" backgroundImage={blogBanner}>
        Federal Financial Program Risk Management and Analytics Services for Crisis Management: COVID-19</Banner>
      <PageWrapper>
        <Container maxWidth="lg">
          <div className={classes.linkWrapper}>
            <LinkDefault variant="defaultBack" href="/markets/commercial-services">Return to NCG: Federal Sector</LinkDefault>
          </div>
          <Container maxWidth="md" className={classes.contentWrapper}>
            <div className={classes.dateWrapper}>
              <MediaDate>July 2, 2020</MediaDate>
            </div>
            <Typography variant="body1">
              Federal Financial Programs will provide a critical recovery role from the COVID-19 crisis. These Programs are key to supporting individual businesses and repairing damage to the overall economy. To play this role well in such unprecedented times, Federal Programs will need enhanced analytical and risk management capabilities to navigate these unique financial circumstances.
            </Typography>
            <Typography variant="body1">
              Federal programs for grants, lending, payments, backing, and other financial instruments will need to respond to increased demands and requests for special considerations. This will require a data driven balance of providing this vital support without compromising program integrity.
            </Typography>
            <Typography variant="body1">
              Northcross Group (NCG) provides expert services and automation tools to provide Federal Financial Programs with the insight and capabilities to successfully take on the wide range of challenges presented by this crisis. We start with validation of the current financial state and analysis of risk exposure with individual entities and at the overall program level. With that knowledge we model and forecast the impacts of various strategies aligned with the Program mission and objectives. NCG brings a powerful set of capabilities and services to the evolving range of COVID-19 challenges:
            </Typography>
            <ul className={classNames(classes.unorderedList, classes.listBreak)}>
              <li className={classes.listItem}>Combination of commercial financial and Federal Program expertise</li>
              <li className={classes.listItem}>Automation to manage data and put it into the hands of the people that need it</li>
              <li className={classes.listItem}>Lending, investment, and risk management expertise</li>
              <li className={classes.listItem}>Training and micro/distance learning expertise to empower Program staff</li>
            </ul>
            <Typography variant="h3">
              Micro and Macro Insight to Program and Portfolio Exposure
            </Typography>
            <Typography variant="body1">
              NCG provides qualified and independent assessments of program portfolios that validate past and current models and practices. We evaluate the details of individual recipient initial positions, performance history, and future outlooks. We assess current evaluation and risk management controls and processes using applicable Federal equivalents and industry standards.
            </Typography>
            <Typography variant="body1">
              Starting with individual recipients, we then conduct multiple aggregations to understand patterns and deviations at the overall program levels. In conducting this analysis, we define analytical markers and processes to automate ongoing performance monitoring and validation controls. This provides the ability to automate monitoring at both the recipient and overall Program level. This helps measure and demonstrate program effectiveness and improvement opportunities.
            </Typography>
            <Typography variant="h3">
              Understanding of Risk and Managing Changes in a Crisis Event: COVID-19
            </Typography>
            <Typography variant="body1">
              The micro and macro economic impacts of the COVID-19 are still unfolding. Federal Financial Programs need the ability to meet these evolving challenges without compromise to their core mission and objectives. Accomplishing this balance requires three essential elements:
            </Typography>
            <ul className={classNames(classes.unorderedList)}>
              <li className={classes.listItem}>Detailed and verified current state insight at the micro and macro levels</li>
              <li className={classes.listItem}>Dynamic forecasting and risk management frameworks and supporting analytics</li>
              <li className={classes.listItem}>Real-time performance monitoring and risk analysis to support program decision making</li>
            </ul>
            <Typography variant="body1">
              NCG helps program offices implement robust model management that provides traceability and accountability of changes over time. This fosters better forecasting ability and provides accounting across points-in-time to support lookbacks and proofs to support audit and verification efforts.
            </Typography>
            <Typography variant="h3">
              Foundational Program Risk Management and Analytics Improvements
            </Typography>
            <Typography variant="body1">
              While crisis events test the constructs and agility of programs, they also provide the opportunity to institutionalize improvements and capabilities to be better prepared for future challenges. NCG helps leverage interim measures to make sustainable program improvements. This includes the integration of analytical and risk management processes and automation into overall program workflows and reporting systems.
            </Typography>
            <Typography variant="body1">
              NCG model validation and performance monitoring functions are leveraged to support ongoing measurement and evolution of metrics to identify continuous improvements. Our model management capabilities can be used to make adjustment across the program or to support specific segment needs, with complete auditability and traceability of these changes.
            </Typography>
            <Typography variant="body1">
              Program Office staff skill and capability improvement is key to crisis management and resiliency. NCG provides tailored training and education services to empower people to best leverage data and automation within the specific constructs of their Program. To support retention and reinforcement of concepts over time, NCG’s micro and distance learning platforms help people maintain and improve skills, while also providing management with insight to team strengths and weaknesses.
            </Typography>
            <Typography variant="h3">
              About Northcross Group (NCG)
            </Typography>
            <Typography variant="body1">
              The NCG Team is comprised of financial risk management experts that have a proven track record in the Federal and commercial sectors. We have supported Federal Program Offices, national and regional commercial banks, brokerage and investment firms, along with SEC, OCC, FDIC, and Treasury Regulators. We operate independently and autonomously to retain a low degree of client conflicts.
            </Typography>
            <Typography variant="body1">
              We have a proven track record evaluating, building, and improving financial models with supporting analytics to substantiate forecasts and implement continuous improvements in a controlled manner. We calibrate analytical tools and reporting to meet the specific program and risk management needs for each individual effort. We have been recognized for our training and empowerment of Program Office Staff to better leverage data for decision making and overall program support.
            </Typography>
            <Typography variant="body1">
              We have supported Federal programs in Departments including Treasury, Defense, Commerce, Health and Human Service, and Transportation implementing risk management capabilities with supporting data management and analytic capabilities. We have supported programs that faced the challenges of the financial crisis of 2008/2009, impacts of 9/11, the factors of the information-based economy, and the Savings and Loan crisis in the late ‘80s and early ‘90s. While all very different circumstances, we have helped Federal programs address these challenges with the ability to adapt and use data and analytical capabilities to best manage risk.
            </Typography>
            <div className={classes.blogFooter}>
              <div item lg={4} className={classes.logoWrapper}>
                <NcgLogo />
              </div>
              <Link className={classes.link} href="/">www.northcrossgroup.com</Link>
              <Link className={classes.link} href="mailto:info@northcrossgroup.com">info@northcrossgroup.com</Link>
            </div>
          </Container>
        </Container>
      </PageWrapper>
    </>
  )
}

export default BlogPostFederalFinancial;