import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import Banner from "components/layout/banner.component";
import Image from "components/layout/image.component";
import ContentContainer from "components/layout/contentContainer.component";
import classNames from "classnames";
import BackToTopCustom from "components/layout/BackToTopCustom";
// IMAGES
import performanceBanner from "../../../images/images/banners/performance.jpg";
import overviewImage from "../../../images/images/performance/security.jpg";
import bostonImage from "../../../images/images/performance/boston.jpg";
import sustainableImage from "../../../images/images/performance/sustainable.png";
import bcpImage from "../../../images/images/performance/bcp.jpg";
import disasterImage from "../../../images/images/performance/disaster.jpg";
import riskImage from "../../../images/images/performance/risk.jpg";
import contractImage from "../../../images/images/performance/contract.jpg";
import vulnerabilityImage from "../../../images/images/performance/vulnerability.jpg";
import integrationImage from "../../../images/images/performance/integration.png";
import pentestingImage from "../../../images/images/performance/pentesting.jpg";
import enurePerformanceImage from "../../../images/images/performance/ensure-performance.jpg";
import revenueImage from "../../../images/images/performance/revenue.jpg";
import improvementsImage from "../../../images/images/performance/improvements.jpg";
import healthcareImage from "../../../images/images/performance/healthcare.jpg";
import technologyAutomateImage from "../../../images/images/performance/technology-automate.jpg";
import knowledgeImage from "../../../images/images/performance/knowledge.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 0,
    padding: theme.spacing(2),
    paddingTop: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  introSection: {
    paddingTop: "80px!important;",
    [theme.breakpoints.down('xs')]: {
      paddingTop: "50px!important;",
    },
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  pageSectionStriped: {
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none!important;",
    },
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      paddingBottom: 20,
      paddingTop: 20,
    },
  },
  columnReverse: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse"
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      margin: "0px auto",
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  textLeft: {
    paddingRight: 50,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 20,
    },
  },
  textRight: {
    paddingLeft: 50,
    alignItems: "flex-start",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
    },
  },
  textWrapperH2: {
    marginBottom: 20,
  },
  imageWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  imageWrapperRight: {
    marginLeft: "auto",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      margin: "0px auto",
    },
  },
  imageWrapperLeft: {
    marginRight: "auto",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      margin: "0px auto",
    },
  },
}));

const PastPerformancePage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Past Performance</Banner>
      <PageWrapper >
        <div maxWidth="lg" className={classes.mainContent}>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped, classes.introSection)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Overview
                </Typography>
                <Typography variant="body1">
                  NCG consultants work to build strong client relationships; working with our clients to accomplish some of their most important challenges. We strive to understand each individual client’s goals, the specifics of their market, and how their organization is structured. By tailoring our efforts to what each client needs, we have been successful in delivering value.
                </Typography>
                <Typography variant="body1">
                  NCG’s past performance has ranged from some of the US’s largest firms to regionally focused organizations. We have supported some of the nation’s largest banks, as well as regional hospital groups through a range of activities from cybersecurity to acquisition support. Following are some examples of how NCG has worked with clients from a range of critical infrastructure industries and the public sector.
                </Typography>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={overviewImage}/>
                </ContentContainer>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Greater Boston Area to Regional Risk Assessments
                </Typography>
                <Typography variant="body1">
                  Under a grant from the Department of Homeland Security, NCG developed a NIST-based framework and conducted cybersecurity risk assessments for government services across the Boston region (Massachusetts, New Hampshire, and Rhode Island). These assessments outlined current threats and vulnerabilities, as well as provided insight into resiliency capabilities locally and in conjunction with federal resources. NCG worked with stakeholders across the region to develop remediation roadmaps and establish a Cybersecurity Working Group to coordinate ongoing efforts and collaboration.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/info-security">Checkout another example of our Risk Assessment efforts</LinkDefault>
                </div>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={bostonImage}/>
                </ContentContainer>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={sustainableImage} />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Sustainable Security Program
                </Typography>
                <Typography variant="body1">
                  In light of rapid expansion of both retail and distribution channels, Stonewall Kitchen turned to NCG to establish a sustainable security program to meet current needs and have the ability to scale for future growth. NCG helped Stonewall Kitchen develop internal capabilities and relationships with key vendors designed to scale with the organization as it grows from a regional to an international operation. NCG provided a process to evaluate and select technological tools, implement and manage security training, and institute more robust processes and procedures.
                </Typography>
              </Grid>
            </Container>
          </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
              <Typography variant="h2">
                Business Continuity
              </Typography>
              <Typography variant="body1">
                A national banking client looked to NCG to guide the development of a Business Continuity Program. This Program allows operations, from the teller line to back-end processing, to continue in the event connectivity or system availability is lost. NCG designed and architected the procedural and technical elements of a continuity program that would ensure customers could get their deposits in the event of a disruption.
              </Typography>
              <Typography variant="h5">
                MAP OF CRITICAL BUSINESS PROCESSES
              </Typography>
              <Typography variant="body1">
                Beyond the recovery of computer systems and access to data, Volk wanted a detailed plan that provided for the complete resumption of critical business processes across the organization.
              </Typography>
              <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/business-continuity">Read more about this BCP effort</LinkDefault>
              </div>
            </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
              <ContentContainer variant="customWidth" >
                <Image variant="customWidth" url={bcpImage} />
              </ContentContainer>
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
              <Typography variant="h2">
                  Disaster Recovery Programs
              </Typography>
              <Typography variant="body1">
                  The national banking client engaged NCG to develop a Disaster Recovery (DR) currency and testing program for its Branch Network automation program, which was comprised of multiple systems utilizing different infrastructures and networks. NCG developed a current baseline of functionality, service level agreements, and a transition threshold process to support transition to DR operations. NCG managed efforts between various business lines and vendors to develop DR exercises and evaluation criteria to conduct annual drills and tests.
              </Typography>
            </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
              <ContentContainer variant="customWidth" >
                <Image variant="customWidth" url={disasterImage} />
              </ContentContainer>
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={riskImage} />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
              <Typography variant="h2">
                  Risk Assessment to Compliance Based Risk Assessments
              </Typography>
              <Typography variant="body1">
                  NCG works with MaineHealth and its member groups to maintain a robust security posture while ensuring compliance with CMS Meaningful Use requirements. NCG has supported MaineHealth and its member groups with third-party assessments as well as technical consulting to develop local and enterprise-level programs in addition to implementing practices to protect patient health information across a diverse, heterogeneous operating environment.
              </Typography>
            </Grid>

          </Container>
        </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Contract Management to Vendor and Third Party Security Controls
                </Typography>
                <Typography variant="body1">
                  NCG has worked with the national banking client to create enterprise Master Service Agreements and SOWs to meet the latest OCC/FDIC guidelines for supplier privacy, confidentiality, audit rights, security controls, and other essential terms and conditions for key third-party suppliers. With the targeting of financial institutions and their vendors, NCG helped TD establish frameworks to manage security and performance in day-to-day operations as well as for strategic growth.
                </Typography>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={contractImage} />
                </ContentContainer>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={pentestingImage} />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Pentesting &amp; NIST Based Methodology
                  PenTesting &amp; NIST Based Methodology to PenTesting and Social Engineering Testing
                </Typography>
                <Typography variant="body1">
                  NCG helped Cianbro evaluate the effectiveness of their perimeter defense and monitoring systems using a combined pentest Execution Standard (PTES) and NIST-based methodology. NCG developed a comprehensive report of findings and recommendations describing evidence of compromise and attack vectors to provide Cianbro with information to take remediation action as needed.
                </Typography>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={vulnerabilityImage} />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Vulnerability Testing to Vulnerability Scanning
                </Typography>
                <Typography variant="body1">
                  NCG provided the City of Portland, Maine, with a comprehensive vulnerability assessment of its current technology infrastructure and capabilities. The assessment included a roadmap to address technical and procedural opportunities based on priorities specific to a city government. NCG provided security and engineering consultation to help with defining and architecting solutions that were comprised of near-term, as well as longer-term, initiatives. NCG worked with stakeholders to evaluate options and incorporate security into the capital planning process.
                </Typography>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Growing Operations
                </Typography>
                <Typography variant="body1">
                  During acquisitions and divestitures, unique security challenges arose between the buyer and seller, their third parties, as well as vendors and contractors. The integration process involved data conversions and platform migrations that depended on data for development and testing. NCG has worked with the national banking client through nine acquisitions/divestitures, ensuring the secure and effective management of data between various parities while ensuring the ability for required efforts to execute properly.
                </Typography>
                <Typography variant="h5">
                  ACQUISITION SUPPORT
                </Typography>
                <Typography variant="body1">
                  The national banking client needed control and management of the various technology components of an acquisition. They also needed technical leadership to help bridge technology related activities with their various business lines.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/growing-operations">Read more about this Acquisition effort</LinkDefault>
                </div>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={integrationImage} />
                </ContentContainer>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={enurePerformanceImage } />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Ensuring Performance
                </Typography>
                <Typography variant="body1">
                  The State of Maine’s Department of Professional and Financial Regulations needed an Independent Verification and Validation (IV&V) of their Agency License Management System (ALMS). ALMS is used to manage the application, fulfillment, and tracking of licenses and permits. Drivers behind the IV&V need ensured proper project level risk management and scalability in the future.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/ensuring-performance">Read more about this IV&amp;V effort</LinkDefault>
                </div>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Revenue Improvements
                </Typography>
                <Typography variant="body1">
                  Franklin Community Health Network includes Franklin Memorial Hospital as well as Franklin Community Physicians. Both entities provide services that are billed through a central business office (CBO) although each entity has dedicated software and billing teams. The past years had seen significant management turnover within the CBO with corresponding alternate department structures attempted.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/revenue-improvements">Read more about this Revenue Improvements effort</LinkDefault>
                </div>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={revenueImage } />
                </ContentContainer>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={improvementsImage} />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
                <Typography variant="h2">
                  System Improvements
                </Typography>
                <Typography variant="body1">
                  The State of Maine’s Office of MaineCare Services (OMS) Claims Management System (MECMS) was plagued by numerous issues. MECMS issues impacted OMS operations as well as significantly impacting the State’s general health care system and program Members.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/system-improvements">Read more about this System Improvement effort</LinkDefault>
                </div>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classes.pageSectionWrapper}>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperLeft)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={healthcareImage} />
                </ContentContainer>
              </Grid>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textRight, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Healthcare Management
                </Typography>
                <Typography variant="body1">
                  At Cayuga Medical Center, patient access and registration had been a growing area of concern. With Denials in billing on the rise, Cayuga decided to implement and evaluate changes in their registration department.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/healthcare-management">Read more about this Healthcare Management effort</LinkDefault>
                </div>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Technology to Further Business
                </Typography>
                <Typography variant="body1">
                  The national banking client had struggled to implement a branch automation system that would help streamline their ability to service customers and help increase product sales. The impetus for this project began in the early 1990’s going through a number of false starts, vendor changes, and management changes.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/technology-business">Read more about this Branch Automation effort</LinkDefault>
                </div>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={technologyAutomateImage} />
                </ContentContainer>
              </Grid>
            </Container>
          </div>
          <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
            <Container maxWidth="lg" className={classNames(classes.pageSectionWrapper, classes.columnReverse)}>
              <Grid item xs={11} sm={9} md={8} lg={8} className={classNames(classes.textWrapper, classes.textLeft, classes.textWrapperH2)}>
                <Typography variant="h2">
                  Manage Knowledge
                </Typography>
                <Typography variant="body1">
                  The national banking client utilizes a large pool of staff augmentation contractors as part of its internal technology group, but had no process in place for knowledge capture or management. As contractors rolled off projects or left for other engagements all their experience and knowledge left with them.
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault href="/performance/manage-knowledge">Read more about this Knowledge Management effort</LinkDefault>
                </div>
              </Grid>
              <Grid item xs={10} sm={7} md={4} lg={3} className={classNames(classes.imageWrapper, classes.imageWrapperRight)}>
                <ContentContainer variant="customWidth" >
                  <Image variant="customWidth" url={knowledgeImage} />
                </ContentContainer>
              </Grid>
            </Container>
          </div>
      </div>
      </PageWrapper>
    </>
  )
}

export default PastPerformancePage;