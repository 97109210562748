import React from 'react';

//images
import CyberEngineerAnalyst from "../../../images/images/careers/cybersecurity-engineer-analysis.jpg";
import CyberPrivacyAnalyst from "../../../images/images/careers/cybersecurity-privacy-analyst.jpg";
import SeniorProgramManagerConsultant from "../../../images/images/careers/senior-program-manager-consultant.jpg";
import SeniorProgramBusinessAnalyst from "../../../images/images/careers/senior-program-business-analyst.jpg";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";


const MarquetteCareers= (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap crunch">
        <div id="top" class="banner banner--mq-careers padZero"   >
          <h1 class="bannerTitle">NCG Marquette</h1>
        </div>
        <div class="page-wrap page-wrap--no-pad">
          <div class="secondary_layout">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/about">About NCG</a></li>
                <li><a href="/about-ncg/career-opportunities/careers">Career Opportunites</a></li>
                <li class="active">Career Opportunities</li>
              </ul>
            </div>
            <div itemprop="mainContentOfPage" class="mainpage-wrapper mq-careers">
              <div itemprop="specialty" class="intro page-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <p>Northcross Group (NCG) is recruiting for positions in its new downtown location at 101 W. Washington. Positions are entry level and up.</p>
                  <p>NCG is system integration and cybersecurity firm that supports clients in the private and public sectors (financial services, healthcare, transportation, energy, and the Federal Sector) addressing a variety of technology integration and cybersecurity initiatives. NCG is recognized for its expertise in helping organizations to connect business process, technology, people, and data. NCG follows a programmatic approach that spans technology platforms, organizational management, and project management. NCG actively engages with clients bridging the gap between technical and management subject matter areas, manage the development of key initiatives, and conducting deep dive sessions on technical details to find solutions and manage risks.</p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 add-padding">
                  <h3>Active Recruiting:</h3>
                  <p>The Northcross Group is an Equal Opportunity Employer. We do not discriminate on the grounds of race, ethnicity, color, national origin, religion, sex, age, disability, or sexual orientation.</p>
                  <p>NCG welcomes general employment inquiries.</p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 add-padding">
                  <h3>Benefits</h3>
                  <p>NCG provides competitive benefits, work/life balance, and opportunities for advancement:</p>
                  <ul>
                    <li>Employer provided Health Insurance (including vision)</li>
                    <li>Dental Insurance</li>
                    <li>Dental Insurance </li>
                    <li>Employer provided Life Insurance and Short- &amp; Long-Term Disability Insurance</li>
                    <li>Three weeks personal time off, plus ten government holidays are observed</li>
                    <li>Tuition and education benefits</li>
                  </ul>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="current-positions" class="page-section page-section--full">
              <div class="mainpage-wrapper clearfix currently-recruiting">
                <h2>Currently Recruiting</h2>
                <div class="career-info cybersecurity-engineer-analyst">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 current-position">
                    <h3>Cybersecurity Engineer/Analyst</h3>
                    <p>NCG seeks a Cybersecurity Engineer with the programmatic and technical experience to provide direct client support and assessment functions aligned to applicable compliance frameworks. The Cybersecurity Engineer will work with our team helping clients develop and manage compliant infrastructures honed to meet the current remote/virtual workforce needs while addressing requirements for data and system security and privacy. This position will play a lead role in defining NCG corporate strategy in the cybersecurity space and alignment with other NCG system integration service offerings.</p>
                    <a class="mailto-link" href="mailto:careers@northcrossgroup.com">Submit a cover letter and resume for the Cybersecurity Engineer/Analyst Opportunity</a>
                  </div>
                  <div class="hexagon-wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
                    <span class="hex-image medium">
                      <img src={CyberEngineerAnalyst}  class="img-responsive" alt="Cybersecurity Internship" />
                    </span>
                    <span class="hex xsmall"></span>
                    <span class="hex small"></span>
                    <span class="hex medium"></span>

                  </div>
                </div>
                <div class="career-info cybersecurity-privacy-analyst">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 current-position">
                    <h3>Cybersecurity and Privacy Analyst</h3>
                    <p>The Cybersecurity and Privacy Analyst will work as part of a team providing direct client support across programmatic and technical focus areas in a range of industries. Task areas will include current state assessments, analyzing system environments, and determining alignment with a range of compliance and industry requirement frameworks. Understanding of client operations and structures will be captured and used to help craft sustainable programmatic solutions.</p>
                    <a class="mailto-link" href="mailto:careers@northcrossgroup.com">Submit a cover letter and resume for the Cybersecurity and Privacy Analyst Opportunity</a>
                  </div>
                  <div class="hexagon-wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
                    <span class="hex-image small">
                      <img src={CyberPrivacyAnalyst}  class="img-responsive" alt="Cybersecurity Internship" />
                    </span>

                    <span class="hex small second"></span>
                  </div>
                </div>
                <div class="career-info senior-program-manager-consultant">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 current-position">
                    <h3>Senior Program Manager/Consultant</h3>
                    <p>The Northcross Group (NCG) is seeking an experienced, hands-on program/project manager with banking and financial services consulting expertise to join our team. This position is responsible for the management and execution of projects, as well as direct client relationship management.</p>
                    <p>Tasks include defining phase deliverables, tracking milestones and incurred expenses versus planned expenses, scheduling roles and resources, evaluating risks and recommending contingency plans often following a structured Project Management Life Cycle (PMLC) / System Development Life Cycle (SDLC). Experience in mergers and acquisitions, information security, auditing, business process management, and assessments is strongly preferred.</p>
                    <a class="mailto-link" href="mailto:careers@northcrossgroup.com">Submit a cover letter and resume for the Senior Program Manager/Consultant Opportunity</a>
                  </div>
                  <div class="hexagon-wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
                    <span class="hex-image large">
                      <img src={SeniorProgramManagerConsultant} class="img-responsive" alt="Cybersecurity Internship" />
                    </span>
                    <span class="hex xsmall first"></span>
                    <span class="hex large"></span>
                    <span class="hex medium"></span>
                    <span class="hex xsmall"></span>
                  </div>
                </div>
                <div class="career-info senior-program-bus-analyst">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 current-position">
                    <h3>Senior Program/Business Analyst</h3>
                    <p>The Northcross Group (NCG) is seeking an experienced, hands-on Systems and Business Analyst with banking and financial services consulting expertise to join our team. Applicants must have a strong technical background, as well as significant business acumen. The position is responsible for critically assessing situations, defining actions, and providing support through to implementation. Experience in mergers and acquisitions, information security, auditing, business process management, and assessments is strongly preferred.</p>
                    <a class="mailto-link" href="mailto:careers@northcrossgroup.com">Submit a cover letter and resume for the Senior Program/Business Analyst Opportunity</a>
                  </div>
                  <div class="hexagon-wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
                    <span class="hex-image medium">
                      <img src={SeniorProgramBusinessAnalyst} class="img-responsive" alt="Cybersecurity Internship" />
                    </span>
                    <span class="hex medium"></span>
                    <span class="hex xsmall"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </>
  )
};

export default MarquetteCareers;