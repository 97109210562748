import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classNames from "classnames";

//image
import tailorSupport from "images/images/mergers/section/topics/tailor-support.png"

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    borderRadius: 8,
    margin: 10,
    [theme.breakpoints.down(768)]:{
      fontSize: "1em !important",
    },
    [theme.breakpoints.down(568)]:{
      fontSize: "0.8em !important",
    },
  },
  closeButtonSection: {
    textAlign: "right",
    "& button": {
      [theme.breakpoints.down(768)]:{
        fontSize: "0.8em !important",
      },
      [theme.breakpoints.down(568)]:{
        fontSize: "0.6em !important",
      },
    },
  },
  customPadding: {
    paddingLeft: "25px !important",
    paddingRight: "25px !important"
  }
}));
export const TailorSupportModal = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.formContainer}>
      <h2>{`Tailor Your Support`}</h2>
      <div className={classNames("page-section",classes.customPadding)}>
        <img src={tailorSupport} class="img-responsive" alt="Tailor Support" />
      </div>
      <div className={classes.closeButtonSection}>
        <Button type="button" class="btn btn-default" onClick={() => props.setOpenTailorModal(false)}>Close</Button>
      </div>
    </Grid>
  );
}

export default TailorSupportModal;
