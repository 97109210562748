import React, { useState } from 'react';
import { Button } from "@material-ui/core";

//images
import orchestrationPlatform from "images/images/orchestration/page/orchestration-framework.png"
import platform from "images/images/orchestration/page/tools-support.png"
import cybersecurity from "images/images/orchestration/page/tools/cybersecurityLogo.png"
import cyberImage from "images/images/orchestration/page/tools_images/cybersecurity.jpg"
import riskAssessment from "images/images/orchestration/page/tools_images/risk-assess.jpg"
import riskLogo from "images/images/orchestration/page/tools/riskLogo.png"
import policy from "images/images/orchestration/page/tools_images/policy.jpg"
import policyLogo from "images/images/orchestration/page/tools/policyLogo.png"
import toolLogo from "images/images/orchestration/page/tools/project-logo.png"
import project from "images/images/orchestration/page/tools_images/project.jpg"
import deployLogo from "images/images/orchestration/page/tools/deploy-logo.png"
import deploy from "images/images/orchestration/page/tools_images/deploy.jpg"
import merger from "images/images/orchestration/page/tools_images/mergers.jpg"
import mergerLogo from "images/images/orchestration/page/tools/mergerLogo.png"
import blueprintLogo from "images/images/orchestration/page/tools/blueprintLogo.png"
import blueprint from "images/images/orchestration/page/tools_images/blueprint.jpg"
import compliance from "images/images/orchestration/page/tools_images/compliance.jpg"
import complianceLogo from "images/images/orchestration/page/tools/complianceLogo.png"
import vendor from "images/images/orchestration/page/tools_images/vendor.jpg"
import vendorLogo from "images/images/orchestration/page/tools/vendorLogo.png"
import incidentManagement from "images/images/orchestration/page/tools_images/incidentManagement.jpg"
import incidentMgmt from "images/images/orchestration/page/tools/incident-management.png"
import bcpDr from "images/images/orchestration/page/tools_images/bcp-dr.jpg"
import bcpDrLogo from "images/images/orchestration/page/tools/bcp-dr.png"
import toolSupport from "images/images/orchestration/page/tools-support.png"

//modal
import OrchestrationFrameworkModal from "./orchestrationFrameworkModal.component"
import OrchestrationToolModal from "./orchestrationToolModal.component"
import CustomModal from "../../utils/CustomModal";

//icon
import ExpandIcon from "../../utils/expandIcon";

//carousel
import OrchestrationFrameworkCarousel from "./OrchestrationPlatformCarousel"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const OrchestrationPlatformMicrosite = (props) => {
  //Modal
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const toggleForm = () => {
    setOpenModal(!openModal);
  };

  const openForm = () => {
    setOpenModal(true);
  };

  const toggleForm2 = () => {
    setOpenModal2(!openModal2);
  };

  const openForm2 = () => {
    setOpenModal2(true);
  };

  return (
    <>
      <BackToTopCustom />
      <OrchestrationFrameworkCarousel />
      <div class="bootstrap">
        <div class="crunch">
          <div class="page-wrap page-wrap--no-pad">
            <div class="secondary_layout secondary_layout--full orchLayout">
              <div itemprop="breadcrumb" class="breadcrumb-wrapper">
                <ul class="breadcrumb">
                  <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                  <li class="active">Orchestration Platform</li>
                </ul>
              </div>
              <div itemprop="mainContentOfPage" id="intro" class="page-section page-section--striped">
                <div class="mainpage-wrapper">
                  <h1 style={{paddingLeft: '15px'}}>Orchestration Platform</h1>
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="expand-image img-responsive" src={orchestrationPlatform} alt="Orchestration Platform" />
                    <div class="expand-details col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Button onClick={() => openForm()} class="btn btn-expand btn-lg" data-toggle="modal" data-target="#frameworkModal">Click to Expand <ExpandIcon /></Button>
                        <p class="imgDetails">The Orchestration framework is a continuous process that keeps people across the organization working efficiently and effectively.</p>
                      </div>
                    </div>
                  </div>
                  <div itemprop="specialty" id="sectionOne" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2>Increasing Your Team's Effectiveness</h2>
                    <p>NCG’s Orchestration uses technology and data to focus organizations on the most important tasks.</p>
                    <p>Orchestration helps organizations accomplish tasks by providing the right tools and information. It transforms plans and procedures from a collection of documents into an easy to use interface to save time, reduce frustration, and mitigate risk.</p>
                    <p>Orchestration tools are designed to bring people, processes, infrastructure, and data together for a specific purpose. This business purpose may be a one-time event, or ongoing activities that support sustainable programs.</p>
                    <p>Orchestration encourages an execution focus so that individuals at all levels of the organization can get involved in important tasks.  It also harmoniously weaves industry requirements with the structure of your team.</p>
                  </div>
                </div>
              </div>
              <div id="slide_three" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div itemprop="specialty" class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <h2>Business Challenge Focus</h2>
                    <p>The Orchestration suite of tools are designed from extensive experience to efficiently solve today’s complex business challenges. It provides the process and steps that are proven to make an organization successful.</p>
                    <p>Orchestration provides specific guidance and support to start initiatives for people in all parts of the organization. For instance, what role do they play in the organization in regards to security, working with third party vendors, or managing risk?</p>
                    <p>It helps individual keep track of their own tasks so they know what to do and when to do it which ultimately helps maintains progress across the organization.</p>
                    <p>Orchestration keeps track of everyone’s efforts and progress towards the business objective to help with management and oversight. It provides secure ways for people to work together and collaborate with third parties.</p>
                    <p>There are Orchestration tools for some of today’s biggest business challenges:</p>
                    <ul>
                      <li><a itemprop="significantLink" href="#securingTab" >Securing Today’s Digital Business Environment</a></li>
                      <li><a itemprop="significantLink" href="#drivingTab" >Driving and Executing Strategic Change</a></li>
                      <li><a itemprop="significantLink" href="#supportingTab" >Supporting Stability, Performance, and Scalability</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="slide_four" class="page-section page-section--striped  ">
                <div class="mainpage-wrapper">
                  <div id="key" class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img class="expand-image  img-responsive" src={platform} alt="Orchestration Platform" />
                    <div itemprop="specialty" class=" expand-details col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Button onClick={() => openForm2()} class=" btn btn-expand">Click to Expand  <ExpandIcon /></Button>
                        <p class="imgDetails">The Orchestration suite of tools use data about your specific organization and combine it with requirements for your organization and its industry.  This is combined with standards and best practices for the business objective you are trying to achieve.  The combination is translated into specific tasking for specific people in your organization.</p>
                      </div>
                    </div>
                  </div>
                  <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2>Tools &amp; Support</h2>
                    <p>Orchestration tools are designed to be simple and intuitive to use.  The focus is to help people accomplish the business objective; not learn another piece of software.</p>
                    <p>The Orchestration tools provide a range of functions tuned to specific user groups for:</p>
                    <ul>
                      <li>Analytics</li>
                      <li>Communication</li>
                      <li>Collaboration</li>
                      <li>Oversight &amp; Governance</li>
                      <li>Data &amp; Document Management</li>
                    </ul>
                    <p>The tools are designed to help people work together and share information to keep things moving forward.  Orchestration keeps track of the roles and responsibilities of each user providing the framework for each person to effectively do their part such as: </p>
                    <ul>
                      <li>Those responsible for efforts</li>
                      <li>Accountable parties &amp; executives</li>
                      <li>General organizational workforce</li>
                      <li>Key third parties</li>
                    </ul>
                    <p>The Orchestration tools use your data to better align with your needs over time.  Orchestration tools grow with the organization’s capabilities and processes.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="securingTab" class="page-section page-section--striped  ">
                <div class="mainpage-wrapper">
                  <div class="col-lg-12 col-sm-12 col-sm-12 col-xs-12">
                    <h2 >Securing Today’s Digital Business Environment</h2>
                    <img src={cybersecurity} style={{width: "280px"}} class="img-responsive graphic toolLogo" alt="Cybersecurity" />
                  </div>
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={cyberImage} class="img-responsive graphic marginT" alt="Cybersecurity" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Cybersecurity is a complete security program package with the tools to support specific functions and overall program management.  Orchestration has interfaces for the entire organization and key third parties.</p>
                    <p>Orchestration features a patent pending process applying a comprehensive, industry specific security program scope and framework for an organization.</p>
                    <p>Orchestration delivers tasks and actions based on events for all levels of the organization in a concise and directed manner.  It tracks actions at a user level, presenting data based on prioritization and takes the guess work out for people.</p>
                    <p>Orchestration has an open architecture and connects to existing systems; then fills in any gaps in your overall security picture.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <img src={riskAssessment} class="img-responsive img-rounded marginT " alt="Orchestration for Risk Assessment" />
                  </div>
                  <div class="col-lg-8 col-sm-8 col-sm-8 col-xs-12">
                    <img src={riskLogo} style={{width: "350px"}} class="img-responsive toolLogo" alt="Risk Management" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Risk Assessment supports the planning, execution, and authoring of risk assessments.  It is designed to support either internal or third party assessments to conduct standard template or ad hoc assessments.</p>
                    <p>Interfaces exist for those being assessed, those managing, and those conducting  assessments to coordinate schedules and securely exchange information.</p>
                    <p>A tablet based App makes collecting data in the field easy. Conflicts in data collected between assessors and automated testing can be easily correlated as part of the analysis process.</p>
                    <p>Final reporting and roadmaps are built and shared to complete the assessment process.  This data is also available for subsequent assessments.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={policy} class="img-responsive img-rounded marginT" alt="Orchestration for Policy & Procedures" />
                  </div>
                  <div class="col-lg-8 col-sm-12 col-sm-8 col-xs-12">
                    <img src={policyLogo} style={{width: "403px"}} class="img-responsive toolLogo" alt="Orchestration for Policy & Procedures" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Policy &amp; Procedures supports the development and maintenance of policies and procedures which are tailored to the organization and its industry.</p>
                    <p>Using a database driven model to develop content, maintenance and correlation of controls and performance metrics are accomplished. Performance data can be interfaced to measure effectiveness and track progress.</p>
                    <p>Orchestration captures the full RACI (responsible, accountable, communicated, and informed) matrix for policies to automate workflows and notifications.</p>
                    <p>When rules or regulations change, areas needing review are easily identified because all content are mapped. If changes are needed, they are easily made with an audit trail tracking changes over time.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="drivingTab" class="page-section page-section--striped  ">
                <div class="mainpage-wrapper">
                  <div class="col-lg-12 col-sm-12 col-sm-12 col-xs-12">
                    <img src={toolLogo} style={{width: "389px"}} class="img-responsive toolLogo" alt="Orchestration for Project Management" />
                    <h2 >Driving and Executing Strategic Change</h2>
                  </div>
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={project} class="img-responsive graphic" alt="Orchestration for Project Management" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Project Management is a simple and comprehensive low- and high-level project management tool.</p>
                    <p>Orchestration has interfaces for managers, project teams, and executives to make it easily accessible to all parts of the organization.</p>
                    <p>Orchestration generates executive level reporting from tasks, decisions, risks, and issues being worked.</p>
                    <p>Simple interfaces allow managers to collect and manage project and program information. Orchestration connects data between projects within programs, and can report based on organizational portfolios and people resources. Orchestration has an open architecture that can share data with enterprise project management systems such as Primavera and MS Project.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="col-lg-12 col-sm-12 col-sm-12 col-xs-12">
                    <img src={deployLogo} style={{width: "403px"}} class="img-responsive toolLogo" alt="Orchestration for Deployment Management" />
                  </div>
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={deploy} class="img-responsive graphic" alt="Orchestration for Deployment Management" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Deployment Management supports the planning, mock exercises, and execution of major deployments.</p>
                    <p>Orchestration helps build detailed runbooks with input from many different parties. Audit trails of changes and updates is kept for traceability and restore capability.</p>
                    <p>Orchestration helps manage Command Center planning and staffing for mock exercises as well as the actual event.</p>
                    <p>Incident Management functions are built in to work issues to resolution or support transition to regular support.</p>
                    <p>Lessons learned and best practices can be captured for after action reporting and future application.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={merger} class="img-responsive img-rounded marginT" alt="Orchestration for Mergers &amp; Acquisitions (M&amp;A)" />
                  </div>
                  <div class="col-lg-8 col-sm-8 col-sm-12 col-xs-12">
                    <img src={mergerLogo} style={{width: "420px"}} class="img-responsive toolLogo" alt="Orchestration for Mergers &amp; Acquisitions" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Mergers &amp; Acquisitions (M&amp;A) supports the full investment/divestiture life cycle. It supports a structured and detail oriented approach to due diligence and development of transition service agreements.</p>
                    <p>Orchestration tracks and helps manage integration efforts, transition timeframes, and preparation for legal day-one and go-live activities.</p>
                    <p>Orchestration helps plan and exercise preparation for go-live events, with support for post-implementation activities and incident management.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="supportingTab" class="page-section page-section--striped  ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap col-lg-12 col-sm-12 col-sm-12 col-xs-12">
                    <img src={blueprintLogo} style={{width: "263px"}} class="img-responsive graphic toolLogo" alt="Orchestration Tools" />
                    <h2>Supporting Stability, Performance &amp; Scalability</h2>
                  </div>
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={blueprint} class="img-responsive graphic" alt="Orchestration for Blueprinting" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Blueprinting provides a standards based approach to collecting, organizing and presenting data about how core systems process data, business process, and system interfaces interact with the rest of the enterprise.</p>
                    <p>Enterprise processing is typically a system of systems, that has evolved over time.  Automated processes are coupled with manual steps.  Interfaces to both internal and external entities have varying controls in performing critical functions.</p>
                    <p>Orchestration provides a structured framework to collect information about how your systems work that goes beyond typical Enterprise Architecture.  It captures the details and life cycle of data and processes.  This insight can be leveraged to reduce the risks and costs of integration and conversion efforts.  It also supports operations with incident response.</p>
                    <p>For financial institutions, Orchestration Blueprinting uses the BIAN (Banking Industry Architecture Network) standards.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={compliance} class="img-responsive img-rounded marginT" alt="Orchestration for Compliance & Oversight" />
                  </div>
                  <div class="col-lg-8 col-sm-8 col-sm-12 col-xs-12">
                    <img src={complianceLogo} style={{width: "438px"}} class="img-responsive toolLogo" alt="Orchestration for Compliance & Oversight" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Compliance &amp; Oversight is a closed loop vulnerability assessment tool for complex programs and portfolio risk management.</p>
                    <p>Orchestration helps you maintain organizational as well as system, sub-system, and element views of the portfolio over time. Orchestration captures risk factors, prioritization, and organizes assessment plans that assess system design and performance.</p>
                    <p>Orchestration provides functions to create ad hoc audit actions and retarget efforts based on findings as data is collected.  Risk analysis functions can be performed throughout the assessment process, and risk management plans for areas of concern can be developed.</p>
                    <p>Orchestration helps program offices create and manage compliance statements along with supporting proofs.  Track findings and remediation efforts across audits and assessment to measure progress.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={vendor} class="img-responsive img-rounded marginT" alt="Orchestration for Vendor Manager" />
                  </div>
                  <div class="col-lg-8 col-sm-8 col-sm-12 col-xs-12">
                    <img src={vendorLogo} style={{width: "389px"}} class="img-responsive toolLogo" alt="Orchestration for Vendor Manager" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Orchestration for Vendor Manager covers the full acquisition life cycle with interfaces to support sourcing, legal, risk, and owning line of business efforts.</p>
                    <p>Orchestration helps the different parts of the organization manage go-to-market activities through to contract negotiations with support to help make sure you have the right terms conditions in your agreements. It securely manages document exchanges and track revisions based on your negotiation strategy.</p>
                    <p>Orchestration helps operational oversight for day-to-day tasks, and incorporate service level agreement and key performance indicator tracking interfaces are available for owning LOBs.</p>
                    <p>Functions to plan and execute transition and repatriation processes are provided that connect data from across the life cycle to support efforts.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <img src={incidentManagement} class="img-responsive img-rounded marginT" alt="Manage Knowledge" />
                  </div>
                  <div class="col-lg-8 col-sm-8 col-sm-12 col-xs-12">
                    <img src={incidentMgmt} style={{width:"402px"}} class="img-responsive toolLogo" alt="Incident Management" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>Any organization needs the capability to deal with data breaches and threats to its operating environment. The ability to manage and recover from an incident can mean the survival of the organization. Loss of functionality or customer data can have immediate monetary impact and cause long-term damage to brand and reputation.</p>
                    <p>NCG has developed a system for incident management based on industry standards, including NIST SP 800-61, and best practices from decades of providing services in the public and private sectors.</p>
                    <p>The NCG Orchestration Suite’s Incident Management system is designed to work for people across your organization to address a range of adverse events.  Orchestration provides a framework with tailorable tools for your organization and key third parties to manage and work through an incident.</p>
                    <p>Any internet-connected device can be used securely with the Incident Management cloud-based system. The Incident Management system facilitates communication and collaboration between team members, while helping determine what steps to take. It tracks actions and provides a structure to collect and organize data.</p>
                    <p>The Incident Management system increases the effectiveness and efficiencies of your incident response teams.  Its data management and reporting functions provide essential documentation of efforts that is critical when working with law enforcement, regulators, and insurance companies.</p>
                    <p>Orchestration’s Incident Management architecture grows and evolves with your organization’s incident response capability.  It provides oversight and governance for leadership, along with the step-by-step guidance for responders throughout the organization.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section page-section--striped ">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <img src={bcpDr} class="img-responsive img-rounded marginT" alt="business continuity planning (BCP)" />
                  </div>
                  <div class="col-lg-8 col-sm-12 col-sm-4 col-xs-12">
                    <img src={bcpDrLogo} style={{width: "300.656px"}} class="img-responsive toolLogo" alt="business continuity planning (BCP)" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <p>The companies that survive major outages and disasters are the ones that have been proactive in developing policies, plans, and capabilities to get through such events. They have spent time and resources to make sure they have the infrastructure and controls to mitigate adverse impacts, and have practiced using that capability at all levels of the organization. This combination of preparation and practice gives the organization what it needs to function in a crisis and after.</p>
                    <p>Northcross Group has developed a system for business continuity planning (BCP) and disaster recovery (DR) based on industry standards, including NIST SP 800-44 rev 1, and best practices from decades of providing incident management services in the public and private sectors.</p>
                    <p>The NCG Orchestration Suite’s BCP/DR Management system is designed to engage your entire organization to prepare, practice, and conduct business continuity and disaster recovery actions. BCP/DR Management provides a framework with tailorable tools for your organization and key third parties to manage and work through a range of scenarios and keep you operating.  BCP/DR Management guides you through the planning and preparation steps to help ensure you always have the most up-to-date infrastructure and processes. It provides a platform to test and validate your efforts, and train your personnel.</p>
                    <p>Any internet-connected device can be used securely with Orchestration’s Incident Management cloud-based system. This facilitates communication and collaboration among members of your team, while helping determine what steps to take. It tracks actions and provides a structured process to collect and organize data.</p>
                    <p>The BCP/DR Management system helps you keep BCP/DR information up to date and accessible to your team members when they need it. It provides command-and-control functionality to track efforts and deal with evolving scenarios. The centralized platform keeps all members of your team informed and helps them work together to get through an event and recover from it successfully.</p>
                    <p>The scalable architecture of Orchestration’s BCP/DR Management system grows and evolves with your organization’s incident response capability. It provides oversight and governance for leadership, along with step-by-step guidance for responders throughout the organization. BCP/DR Management provides the framework for proactive and reactive capabilities that can see your organization through a disaster.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CustomModal open={openModal} onClose={toggleForm}>
            <OrchestrationFrameworkModal setOpenModal={setOpenModal} />
          </CustomModal>

          <CustomModal open={openModal2} onClose={toggleForm2}>
            <OrchestrationToolModal setOpenModal={setOpenModal2} />
          </CustomModal>

          <div id="toolsModal" class="modal fade" role="dialog">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                  <h4 class="modal-title">Tools &amp; Support</h4>
                </div>
                <div class="modal-body">
                  <div class="page-section   ">
                    <img src={toolSupport} class="img-responsive " alt="Orchestration Tools" />
                  </div>
                </div>
                <div class="modal-footer" style={{ border: "none" }}>
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OrchestrationPlatformMicrosite;