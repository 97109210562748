import { Grid, makeStyles, Typography } from "@material-ui/core";
import EFSectionContainer from "../shared/EFSectionContainer";
import bridgingBackgroundComposite from "../../../../images/microsite/educationFoundation/bridgingBackgroundComposite.jpg";

const white = "#F5F8FF";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    overflow: "hidden",
  },
  sectionGrid: {
    position: "relative",
    color: white,
    textShadow: "2px 2px 1px #2E2BAC4D",
    zIndex: 1
  },
  heading: {
    marginBottom: 32,
    color: white,
    fontSize: 42,
    fontWeight: 700,
    textShadow: "-2px -2px 2px 0px #060B3D4D",
    [theme.breakpoints.up("sm")]: {
      marginRight: -128
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: -64
    }
  },
  backgroundImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    [theme.breakpoints.up("lg")]: {
      left: `calc(640px - 50vw)`,
      right: `calc(640px - 50vw)`
    },
    [theme.breakpoints.only("md")]: {
      right: "calc(-50px - 10%)"
    },
  },
  backgroundImageScreen: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    [theme.breakpoints.up("lg")]: {
      left: `calc(640px - 50vw)`,
      right: `calc(640px - 50vw)`
    },
    [theme.breakpoints.down("sm")]: {
      right: "-20%"
    },
    [theme.breakpoints.down("xs")]: {
      right: "-100%"
    },
    backgroundImage: (
      `linear-gradient(
        90deg,
        rgba(46, 43, 172, 0.9) 0.79%,
        rgba(46, 43, 172, 0.7) 50.4%,
        rgba(46, 43, 172, 0.1) 100%
      )`
    ),
    boxShadow: (
      `0px -14px 20px -18px #000000 inset,
      0px 14px 20px -18px #000000 inset,
      0px 14px 12px -14px #000000 inset,
      0px -14px 12px -14px #000000 inset`
    )
  },
  backgroundImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.only("sm")]: {
      objectPosition: "60% center"
    },
    [theme.breakpoints.only("xs")]: {
      objectPosition: "30% center"
    }
  },
}));

export default function EFBridgingSection() {
  const classes = useStyles();
  return (
    <EFSectionContainer
      className={classes.sectionContainer}
      scrollId="Bridging-Fields"
      centered
    >
      <Grid className={classes.sectionGrid} container>
        <Grid item xs={12} sm={7} lg={7}>
          <Typography variant="h3" className={classes.heading}>
            Bridging Technology &amp; Cybersecurity Across Fields of Study
          </Typography>
          <Typography color="inherit" variant="body1" paragraph>
            Technology and Cybersecurity are not just separate fields of
            study where those with an interest or background go.
            They are foundational elements for business and how society interacts.
            We need to properly empower the next generation with the tools and
            awareness to work with and be cognizant of these elemental factors.
          </Typography>
          <Typography color="inherit" variant="body1" paragraph>
            Applied technology can empower, speed, and distribute functions
            that apply to any are of business.
            Because of this catalytical capability,
            it is also a threat vector for those looking to harm or exploit.
            The workforce needs to know how to both leverage and protect business
            interests. This is essential for competitiveness and to mitigate the
            impacts of threats.
          </Typography>
        </Grid>
        <Grid item xs={0} sm={5} md={6} />
      </Grid>
      <div className={classes.backgroundContainer}>
        <div
          className={classes.backgroundImageContainer}
        >
          <img
            src={bridgingBackgroundComposite}
            alt=""
            className={classes.backgroundImage}
            role="presentation"
          />
        </div>
        <div className={classes.backgroundImageScreen} />
      </div>
    </EFSectionContainer>
  );
}
