import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    paddingBottom: 75,
    [theme.breakpoints.down(763)]: {
      paddingBottom: 55,
    },
  }
}));
const menuItems =
  [
    { label: 'Preparedness', href: '#prepare' },
    { label: 'Management', href: '#manage' },
    { label: 'Tools', href: '#tools' },
    { label: 'BCP/DR', href: '#bcp-dr' },
    { label: 'Contact', href: '#request', rfpType: 'Incident Management', contactForm: true }
  ]
const IncidentManagementNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar bgColor="blue" menuItems={menuItems} elementInView={props.elementInView} />
    </div>
  );
}
export default IncidentManagementNavBar;