import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    minWidth: 260,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
}));

const PPHealthcareManagement = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Healthcare Management Support</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/healthcare-management" variant="active">Healthcare Management Support</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Hospital Billing
                </li>
                <li className={classes.sideBarListItem}>
                  Revenue Cycle
                </li>
                <li className={classes.sideBarListItem}>
                  Staffing Models
                </li>
                <li className={classes.sideBarListItem}>
                  Project Management
                </li>
                <li className={classes.sideBarListItem}>
                  Meditech Reporting
                </li>
                <li className={classes.sideBarListItem}>
                  Meditech Utilization
                </li>
                <li className={classes.sideBarListItem}>
                  Passport Installation
                </li>
                <li className={classes.sideBarListItem}>
                  Passport Training
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2">
                Cayuga Medical Center
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                At Cayuga Medical Center, patient access and registration had been a growing area of concern. With Denials in billing on the rise, Cayuga decided to implement and evaluate changes in their registration department. Passport (an eligibility checking system) was chosen as the patient insurance eligibility checker.
              </Typography>
              <Typography variant="body1">
                Cayuga Medical Center wanted to ensure that careful attention was paid to the patient experience—using patient satisfaction surveys as a measure. Interactions with hospital staff, especially registration, are primary drivers of a patient’s experience. That experience impacts patient satisfaction and even the hospital's revenue cycle.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG opened and structured communications with the various departments on registration uniformity. Since Cayuga has a decentralized registration process, performance and process standardization was a core foundation for implementing the new system.
              </Typography>
              <Typography variant="body1">
                Management training was implemented to help managers facilitate new goals for their teams and set new accountability standards. Training manuals, policies and procedures were written and presented to staff. Staffing ratios and models were validated and adjusted as necessary. Quality assurance measures were designed and implemented. Recurrent and new employee training was updated to reflect the new system, policies and procedures.
              </Typography>
              <Typography variant="body1">
                ID verification processes and procedures were updated for the security and safety of the patients. An article was written in the Ithaca Journal as well as Cortland Times for recognition of this new process. Passport Eligibility was installed and the training was done with the help and coordination of Cayuga Medical’s IS support.
              </Typography>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                Staffing level adjustments and streamlined processes resulted in increased patient satisfaction as measured by patient satisfaction surveys. At the same time, a decrease in insurance billing denials was realized.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>

  )
}

export default PPHealthcareManagement;