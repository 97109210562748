import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
  },
  cardContainer: {
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "2px 2px 13px -7px #000000",
    margin: 10,
    maxWidth: 450,
    height: "100%"
  },
  description: {
    textAlign: "left",
    display: "flex",
    justifyContent: "center",
    color: "#3F80A8",
    paddingTop: 10,
    fontSize: "16px",
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 25,
    paddingRight: 25
  },
  title: {
    paddingRight: 5,
    paddingLeft: 5,
    height: 100,
    maxWidth: "82%",
    fontSize: "18px",
    textAlign: "left",
    color: "#3F80A8",
    position: "relative",
    left: 60
  },
  phaseNumber: {
    color: "#D64343",
    position: "relative",
    fontSize: 65,
    left: 15,
    top: 30,
    height: 0,
    margin: 0
  },
  imageContainer: {
    height: 140,
    marginBottom: 20,
    marginTop: 20,
    backgroundColor: "#EFEFEF",
    padding: 15
  },
  smallImage: {
    height: 100,
    marginBottom: 20
  }
}));

const GRCPhaseCard = (props) => {
  const classes = useStyles(props);

  const { title, description, image, phaseNumber, smallImage } = props;
  return (
    <div className={classNames(classes.cardContainer)}>
      <h1 className={classes.phaseNumber}>
        {phaseNumber}
      </h1>
      <div>
        <h2 className={classes.title}>
          {title?.toUpperCase()}
        </h2>
      </div>
      {image && (
        <div
          className={classNames(
            classes.imageContainer,
            classes.center,
            smallImage && classes.smallImageContainer
          )}
        >
          <img src={image} className={smallImage ? classes.smallImage : classes.image} alt={`${title}`} />
        </div>
      )}

      <div className={classNames(classes.textContainer)}>
        <p className={classes.description}>
          {description}
        </p>
      </div>
    </div>
  )
}
export default GRCPhaseCard;
