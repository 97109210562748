import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

export const EF_NAV_BAR_HEIGHT = 75;
export const EF_NAV_BAR_HEIGHT_MOBILE = 55;

const useStyles = makeStyles((theme) => ({
  root: {
    height: EF_NAV_BAR_HEIGHT,
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      height: EF_NAV_BAR_HEIGHT_MOBILE,
      paddingBottom: EF_NAV_BAR_HEIGHT_MOBILE,
    },
  }
}));

export default function EFNavBar(props) {
  const classes = useStyles();
  const { menuItems } = props;

  return (
    <div class={classes.root}>
      <MicrositeNavBar
        bgColor="neon"
        menuItems={menuItems}
        elementInView={props.elementInView}
      />
    </div>
  );
}
