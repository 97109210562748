//images
import deploy from "images/images/banking/section/deploy.jpg"
import merger from "images/images/banking/section/mergers.png"
import cybersecurityMobile from "images/images/banking/section/cybersecurity.jpg"
import cybersecurityDesktop from "images/images/banking/section/cybersecurity.jpg"
import platform from "images/images/banking/section/platform.jpg"
import vendorMobile from "images/images/banking/section/vendor.jpg"
import vendorDesktop from "images/images/banking/section/vendor.jpg"
import bcpDesktop from "images/images/banking/section/coreBP.jpg"
import bcpMobile from "images/images/banking/section/coreBP-mobile.jpg"
import protectDesktop from "images/images/banking/section/project.jpg"
import protectMobile from "images/images/banking/section/project-mobile.jpg"
import analytics from "images/images/banking/section/analytics.jpg"

//carousel
import BankingFinancialCarousel from "./BankingFinancialCarousel"

//fontawesome Icons
import AdobeFileLogo from "../../utils/AdobeFileLogo";
import AngleDoubleRightIcon from "../../utils/angleDoubleRightIcon";
import DownloadIcon from "../../utils/DownloadIcon"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const BankingFinancialMicrosite = (props) => {
  return (
    <>
    <BackToTopCustom />
      <BankingFinancialCarousel />
      <div class="bootstrap">
        <div class="crunch">
          <div itemprop="mainContentOfPage" class="page-wrap page-wrap--no-pad">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                <li class="active">Banking &amp; Financial Services</li>
              </ul>
            </div>
            <div itemprop="specialty" id="intro" class="page-section page-section--striped" >
              <div class="mainpage-wrapper ">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12" >
                  <h1>Banking &amp; Financial</h1><br />
                  <h2 class="headings--over" >Managing Growth</h2>
                  <h5 class="headings--under">Ensuring the Success of Critical Events</h5>
                  <p>Customer expectations and regulatory oversight drive financial institutions and determine their reputations. From acquisitions to new system implementations, NCG offers services to plan and exercise each detail during complex projects. Consultants have experience in project preparation and testing as well as contingency planning and event execution. NCG has successfully managed or supported more than a dozen merger and acquisition deals, totaling more than $100 million in value. As consultants offer their services, they work with stakeholders to ensure business processes and resources can accommodate change without impacting customers, employees, or other partners.</p>
                </div>
                <div itemscope itemtype="http://schema.org/WPSidebar" class="sideBar  col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div class="wrapper">
                    <h5>Service Cards</h5>
                    <ul class="list">
                      <li> <a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Banking_Services_2014.pdf">Banking Overview</a></li>
                      <li> <a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Financial_Services_032011.pdf">Financial Capabilities</a></li>
                      <li> <a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_M_A_Integration_Services_2014.pdf" >Merger &amp; Acquisition</a></li>
                      <li> <a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Data_Services.pdf">Data Conversions</a></li>
                      <li> <a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Cybersecurity_Services.pdf">Information &amp; Cybersecurity</a></li>
                      <li> <a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Project_Managment_Execution.pdf">Project &amp; Program</a></li>
                    </ul>
                    <a id="adobe" target="_blank" rel="noreferrer" href="https://get.adobe.com/reader/"><h5><AdobeFileLogo />Download Adobe Reader</h5></a>
                  </div>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="mergers" class="page-section page-section--striped" >
              <div class="mainpage-wrapper ">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h2>Mergers and Acquisitions</h2>
                </div>
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img class="img-responsive  graphic" src={merger} alt="Mergers and Acquisitions" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <p>NCG has a team of experienced consultants who oversee initial decision-making processes as well as systemic processes for replacement and integration. Consultants also work with legal and acquisition teams to ensure that service agreements between parties transition effectively and securely.</p>
                  <p>NCG orchestrates and facilitates mock exercises to test preparedness efforts and procedures designed to support execution, communication, and incident management. Each mock exercise provides crucial insight and risk management data that NCG helps the organization process and apply for the go live event.</p>
                  <p>NCG has a proven methodology and accelerators to help organizations improve and streamline processes and facilitate an effective transition. NCG has established techniques to connect technical and business lines to combine their capabilities and deliver their full potential.</p>
                  <a itemprop="relatedLink" href="/capabilities/merger-acquisition" class="btn-link" >Learn more about our Mergers and Acquisitions Capabilities <AngleDoubleRightIcon /></a>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="resources" class="page-section page-section--striped" >
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img class="img-responsive graphic marginT" src={deploy} alt="Deployment Command Center" />
                </div>
                <div id="deploy" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2> Deployment Command Center</h2>
                  <p>Getting ready for production changes needs careful preparation and consideration of a wide spectrum of variables to ensure readiness. When it comes time for the event, you need experienced management to orchestrate and handle issues as they arise.</p>
                  <p>Consultants at NCG help businesses build deployment programs that engage all appropriate parties and proactively manage risks. NCG’s proactive approach provides lasting benefits, ensuring that procedures are reusable for future efforts.</p>
                  <p>NCG helps build an integrated approach that bridges between technology, lines of business, and other stakeholders. Connections and interfaces on data and procedural levels are highlighted to ensure there are no negative impacts.</p>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="cybersecurity" class="page-section page-section--striped " style={{ backgroundColor: "#fff" }} >
              <div class="mainpage-wrapper ">
                <div class="col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="graphic-wrap  mobile col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <img class="img-responsive graphic graphic--mobile" src={cybersecurityMobile} alt="Cybersecurity" />
                  </div>
                  <div class=" col-lg-8 col-md-8 col-sm-8 col-xs-12 pull-right">
                    <h2>Cybersecurity</h2>
                  </div>
                </div>
                <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img src={cybersecurityDesktop} class="img-responsive graphic graphic--desktop" alt="Cybersecurity" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <p>The NCG Team has information security capabilities honed in the private and public sectors including defense and intelligence coupled with extensive financial systems expertise. We are uniquely qualified to provide guidance and expertise in financial institution processes and systems to fortify infrastructure while still providing the access and capabilities to deliver services as needed.</p>
                  <p>NCG helps build or enhance programs inclusive of specific security project execution. NCG also offers third party assessment services to measure and validate the effectiveness of your current controls and infrastructure. NCG provides support from policy and procedure development to integration of systems and application to help organizations manage risk and fortify for doing business in this cyber connected world.</p>
                  <a itemprop="relatedLink" href="/capabilities/cybersecurity" class="btn-link" >Learn more about our Cybersecurity Capabilities <AngleDoubleRightIcon /></a>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="platform" class="page-section page-section--striped " >
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img class="img-responsive  graphic" src={platform} alt="Platform Migration &amp; Data Conversions" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2>Platform Migration &amp; Data Conversions</h2>
                  <p>Automating manual processes or implementing differentiating technology is more than a technical exercise. It requires organizational preparation, process updates, training, communication, and alignment of the various parties accountable and responsible for these areas.</p>
                  <p>NCG has the program execution and banking subject matter expertise to address the technical and procedural elements and ensure success in platform migrations and data conversions. NCG not only makes sure the applications, databases, and middleware are ready—we help make sure the organization is ready and able to support the new or expanded systems.</p>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="vendor" class="page-section page-section--striped" style={{ backgroundColor: "#fff" }}  >
              <div class="mainpage-wrapper ">
                <div class="col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="graphic-wrap  mobile col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <img class="img-responsive graphic graphic--mobile" src={vendorMobile} alt="Vendor Management" />
                  </div>
                  <div class=" col-lg-8 col-md-8 col-sm-8 col-xs-12 pull-right">
                    <h2>Vendor Management</h2>
                  </div>
                </div>
                <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img src={vendorDesktop} class="img-responsive graphic graphic--desktop" alt="Vendor Management" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <p>Third parties can play key roles in your operations and their associated risks must be thoughtfully managed across the vendor management life cycle. The Northcross Group (NCG) Team helps financial institutions define the right agreement provisions and operational procedures to help protect the security, confidentiality, privacy and integrity of their data. NCG helps organizations implement the tools and processes to oversee and govern third party relationships on a day-to-day basis and effectively manage transition processes. </p>
                  <p> NCG works with vendor management offices and strategic sourcing offices to support and advise businesses as they cultivate their business relationships. Consultants also implement frameworks to handle incident management, change management, and PMLC/SDLC processes.</p>
                  <div class="col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <a class="btn-download" href="/content/docs/ServiceCards/NCG_3rdParty_Advisement.pdf" target="_blank" rel="noreferrer">Download Third Party Privacy and Security Service Card <DownloadIcon /></a>
                    <a itemprop="relatedLink" class="btn-link" href="/capabilities/vendor-management">Learn more about our Vendor Management Capabilities <AngleDoubleRightIcon /></a>
                  </div>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="core" class="page-section page-section--striped " >
              <div class="mainpage-wrapper ">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h2>Core System Blueprinting and Assessment</h2>
                </div>
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img class="img-responsive graphic graphic--desktop" src={bcpDesktop} alt="Core System Blueprinting and Assessment" />
                  <img class="img-responsive graphic graphic--mobile" src={bcpMobile} alt="Core System Blueprinting and Assessment" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <p>Many core systems have been successfully in place for decades—evolving along the way.
                    NCG helps financial institutions capture the details of how those systems work: from data flows, program critical path, to downstream dependencies and requirements. This knowledge is critical to successfully planning changes and enhancements.</p>
                  <p>When an institution looks to replace core systems, the choice of a new platform may only be part of the integration process to get to the target operating model. Processes and procedures will change and impact both customer and employees. To guarantee the same level of care in making sure the data moves from one system to another and that required processing continues to occur, the different elements of the organization need to be ready for the change.</p>
                  <p>NCG starts with helping an organization understand what its core systems are and where lines between channels, reporting, data management and back office processing lie or should lie. NCG, a member of BIAN, uses a BIAN based methodology for blueprinting current processing and functions so you have the detailed understanding of where you are to know how to get to where you want to go. While this detail is critical for core replacement, it is also invaluable for measuring current performance, stability, and scalability levels.</p>
                  <a href="/content/docs/ServiceCards/NCG_Core_Blueprinting.pdf" target="_blank" rel="noreferrer" class="btn btn-download" >Download our Blueprinting Service Card <DownloadIcon /></a>
                </div>
              </div>
            </div>
            <div id="project" class="page-section page-section--striped" >
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img class="img-responsive graphic graphic--desktop" src={protectDesktop} alt="User checking data on device" />
                  <img class="img-responsive graphic graphic--mobile" src={protectMobile} alt="User checking data on device" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2>Project &amp; Program Services</h2>
                  <p>The Northcross Group has a proven track record driving system deployments, platform migrations, and upgrades in the high availability/high visibility banking industry. NCG project managers, architects, analysts, and banking subject matter experts have a blend of technology and subject matter expertise to ensure success in even the most complex projects.</p>
                  <p>NCG has helped organizations stand-up and enhance existing Project Management Offices (PMO) and Enterprise PMO (EPMO) functions. As part of that support, NCG Project Managers, Tech Leads, Architects, and Analysts have stepped in to assume or orchestrate these key functions to turnaround projects that have fallen off track.</p>
                  <p>NCG has implemented Enterprise Risk Management (ERM) functions portfolio widely to take a proactive approach to addressing problems before significant impacts to budget, timeline, or quality are realized. Standing functions to help projects along and nurture best of class capabilities in project teams creates an atmosphere of increasing levels of organizational capability and maturity.</p>
                  <p>Some projects require special handling due to unique constraints or challenges. NCG provides special project services with teams that work with your key stakeholders to deliver projects under even the most demanding circumstances. Leveraging NCG’s technical and banking expertise, NCG will identify and engage elements of your organization in an effective and efficient manner to ensure execution and delivery. NCG’s special project office services provide the specific skillsets and capabilities as needed, while also establishing sustainable processes for the organization to adopt.</p>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="analytics" class="page-section page-section--striped" >
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <img class="img-responsive graphic" src={analytics} alt="Analytics" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2>Analytics</h2>
                  <p>As NCG consultants assess and review Anti Money Laundering (AML) and Bank Secrecy Act (BSA) systems and processing forensics, they also support investigations and continuous improvement efforts for analytical support and development. Consultants help businesses conduct regulatory compliance research and development as well.</p>
                  <a itemprop="relatedLink" class="btn-link" href="/capabilities/banking-financial/analytics">Learn more about our Analytics Capabilities <AngleDoubleRightIcon /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BankingFinancialMicrosite;