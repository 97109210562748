import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    maxHeight: "90%",
    outline: "none",
    borderRadius: 3,
  },
}));

const CustomModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <Modal open={props.open} onClose={props.onClose} BackdropProps={{invisible: props.invisible}}>
      <div style={modalStyle} className={classes.paper}>
        {props.children}
      </div>
    </Modal>
  );
};

export default CustomModal;
