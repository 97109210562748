import Star2NavBar from "components/microsites/stars2/stars2NavBar";

//images
import s2Banner from "images/microsite/stars2/S2_Banner.jpg";
import getAdobe from "images/microsite/stars2/get_adobe_reader.gif";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const Star2Microsite = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="stars2">

        <div id="container" align="center">
          <Star2NavBar />
          <div id="srv_leftbar">
            <h2>NCG  STARS II Contract <br />
        #GS-06F-1153Z</h2>
            <h2>STARS II Constellation 1 Functional Area 2: NAICS 541512 Computer Systems Design Services</h2>
            <h2>NCG  DUNS:<br />
        808835826</h2>
            <h2>NCG CAGE Code:<br />
#59CU6</h2>
            <p><a target="_blank" rel="noreferrer" href="https://www.sam.gov/SAMPortal/sam/helpPage/SAM_Reg_Status_Help_Page.html">NCG&rsquo;S Central Contractor Record (CCR) Record</a></p>
            <p><a target="_blank" rel="noreferrer" href="http://www.gsa.gov/portal/content/209173">8(a) STARS II  Contract</a></p>
            <p><a target="_blank" rel="noreferrer" href="http://www.gsa.gov/portal/category/25901">GSA STARS II Website</a></p>
            <p>&nbsp;</p>
            <p align="left">&nbsp;</p>
            <p align="left">&nbsp;</p>
            <p align="left">&nbsp;</p>
          </div>

          <div id="contentbanner"><img src={s2Banner} width="680" height="200" alt="NCG STARS2 Banner" /></div>
          <div id="contents">

            <p>Northcross Group (NCG) is a GSA STARS II GWAC awardee.  STARS II is an easy to use vehicle for  Federal Agencies to work with NCG.  NCG  provides highly skilled and targeted services under the STARS II Functional  Area 2 – Computer Systems Design Services.   These services include:</p>
            <p><strong>Computer software consulting  services or consultants</strong><br />
  Weighing technology options, along with an organization&rsquo;s  mission and how it works NCG supports the process of picking the right  technology solution.  Whether automating  manual processes, introducing new functionality, or changing platforms; NCG  provides the complete support o drive success. </p>
            <ul class="square">
              <li>Understanding       what you need and having the right requirements</li>
              <li>Picking the       right technologies</li>
              <li>Planning and       preparing for integration</li>
              <li>Communication,       training, and  process       re-engineering</li>
            </ul>

            <p><strong>Computer systems integration  analysis and design services</strong><br />
          Making systems work together, sharing data  and information to meet organizational needs is the foundation of how NCG  approaches integration efforts.  We  account for how the technology, data, and people using those systems come  together securely and effectively.    Governance is supported by comprehensive plans to guide efforts and  traceability of efforts throughout the  project life cycle.          </p>
            <ul class="square">
              <li>System and       enterprise architecture</li>
              <li>Secure and       controlled Interface designs</li>
              <li>Data exchange       and management between systems</li>
              <li>Middleware       design and architecture</li>
            </ul>
            <p><strong>Computer integrator services</strong><br />
          When computer systems work together, the value  provided to organizational missions is increased exponentially.  Work together is more than just network connections—it  requires interface between the people using the hardware, the data, and functionality   that is focused on helping the  organization operate. </p>
            <ul class="square">
              <li>Oversight and       governance of integration efforts</li>
              <li>Defining,       designing, and implementing secure system interconnectivity</li>
              <li>Business       interface design and implementation</li>
              <li>Data       interaction and controls</li>
              <li>Reporting and       information generation</li>
            </ul>
            <p><strong>Information management computer  systems integration design services</strong><br />
          NCG works with organizations to leverage  data and create information and knowledge tailored to specific needs.  Data fuels technology infrastructure and must  be accessible and usable by people to provide value.  NCG closes the loop with people, data, and  the technology infrastructure.          </p>
            <ul class="square">
              <li>Managing data       to create information and knowledge</li>
              <li>Business       Intelligence</li>
              <li>System data       integration definition, security, and controls</li>
              <li>Enterprise data modeling       and architecture</li>
            </ul>
            <p><strong>Computer network systems design  services</strong><br />
          NCG designs technology infrastructure to  meet today&rsquo;s performance and security needs.   Access to information and functionality keeps an organization moving  forward.  NCG ensures network designs  provide the capability and scalability for today and the future.          </p>
            <ul class="square">
              <li>Secure and       controlled system interface design and architecture</li>
              <li>Cyber Security       control design and monitoring</li>
              <li>Integrated       voice, data, media network design and </li>
              <li>Network       performance modeling and forecasting</li>
            </ul>
            <div class="footer">
              <p class="footer_style">&copy;2011 Northcross Group Corporation. All rights reserved. </p>
            </div>
          </div>

          <div id="rightbar">
            <p><strong>NCG  Contract Program Manager</strong>:<br />
Christopher  Bender<br />
Tel.:  207.699.5540<br />
              <a href="mailto:cjbender@northcrossgroup.com">Email</a> - cjbender@ northcrossgroup.com</p>
            <p> <a target="_blank" href="/content/docs/stars2/NCG_STARS2_Overview.pdf">NCG STARS II Flyer</a></p>
            <p><a href="http://www.adobe.com/products/acrobat/readstep2.html" target="_blank" rel="noreferrer"><img src={getAdobe} alt="Get Adobe Acrobat Reader" width="112" height="33" border="0" /></a></p>

            <p><strong>NCG Accessibility Statement:</strong><br />
              <br />
              <em>Northcross Group (NCG) is committed to making its websites  accessible to all NCG customers and staff in accordance with provisions of  Section 508 of the Rehabilitation Act Amendments of 1998.</em></p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Star2Microsite;