import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container, Link, MenuList, Drawer } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import React, { useState } from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import Banner from "components/layout/banner.component";
import Image from "components/layout/image.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import ButtonCustom from "components/layout/buttonCustom.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
// IMAGES
import capabilitiesBanner from "../../../images/images/banners/process.jpg";
import programLogo from "../../../images/images/capabilities/programLogo.png";
import cybersecurityLogo from "../../../images/images/capabilities/cybersecurityLogo.png";
import mergersLogo from "../../../images/images/capabilities/mergerLogo.png";
import complianceLogo from "../../../images/images/capabilities/complianceLogo.png";
import riskLogo from "../../../images/images/capabilities/riskLogo.png";
import vendorLogo from "../../../images/images/capabilities/vendorLogo.png";
import blueprintingLogo from "../../../images/images/capabilities/blueprintLogo.png";
import orchLogo from "../../../images/images/orchestration/orchestration-logo.png";
import "style/sidebar-custom.css";

function pxToRem(value) {
  return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});
const useStyles = makeStyles((theme) => ({
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    minWidth: 300,
    width: "100%",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    alignSelf: "flex-start",
    left: 0,
    position: "sticky",
    top: 70,
    maxHeight: "90vh",
    overflow: "auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 240,
      minWidth: 240,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%!important",
      width: "100%",
      minWidth: "unset",
      position: "relative",
      top: 0,
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      paddingTop: 10,
      textAlign: "left",
      '&:first-of-type': {
        borderBottom: "none",
        paddingBottom: 20,
      },
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  // SIDEBAR DRAWER
  drawerList: {
    padding: "30px 40px",
    [theme.breakpoints.down('sm')]: {
      padding: 30,
    },
  },
  sideBarList: {
    paddingLeft: 15,
    marginTop: 10,
    paddingBottom: 15,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "1.6em",
    lineHeight: "1.125em",
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  // SERVICES LIST
  solutionsHeader: {
    paddingLeft: 30,
  },
  sideBarWrapperServices: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sideBarServicesList: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    width: "100%",
  },
  serviceItem: {
    width: "100%",
    listStyleType: "none",
    paddingTop: 0,
  },
  serviceItemLink: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: "1px solid #d8dde3",
    alignItems: "flex-start",
    justifyContent: "center",
    '&:active': {
      backgroundColor: "white",
      borderBottom: "1px solid #ADD9F1",
    },
  },
  serviceLabel: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "normal",
    paddingTop: 0,
    paddingBottom: 0,
    color: variables.textPrimary,
    '&:active': {
      color: variables.ncgblue5,
    },
  },
  // SECTIONS
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    padding: theme.spacing(2),
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  introSection: {
    paddingTop: "80px!important;",
    backgroundColor: "white",
    [theme.breakpoints.down('xs')]: {
      paddingTop: "40px!important;",
      paddingBottom: "40px!important;",
    },
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  pageSectionStriped: {
    padding: "60px",
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "40px",
      '&:nth-of-type(even)': {
        backgroundColor: '#f7f9fc',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: "white",
      },
    },
  },
  // ORCHESTRATION CARDS
  capabilityCard: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    marginTop: 20,
    marginBottom: 30,
    backgroundColor: "white",
    borderRadius: 6,
    boxShadow: "3px 3px 7px 0px rgb(0 0 0 / 30%)",
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "fit-content",
    width: "100%",
    position: "relative",
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column-reverse",
      marginBottom: 10,
    },
  },
  cardLogo: {
    display: "inline-block",
    height: 100,
    maxWidth: 400,
    width: "100%",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    [theme.breakpoints.down('sm')]: {
      height: 50,
      backgroundPosition: "left center",
    },
  },
  cybersecurity: {
    backgroundImage: "url(" + cybersecurityLogo + ")",
  },
  program: {
    backgroundImage: "url(" + programLogo + ")",
  },
  risk: {
    backgroundImage: "url(" + riskLogo + ")",
  },
  mergers: {
    backgroundImage: "url(" + mergersLogo + ")",
  },
  compliance: {
    backgroundImage: "url(" + complianceLogo + ")",
  },
  vendor: {
    backgroundImage: "url(" + vendorLogo + ")",
  },
  blueprinting: {
    backgroundImage: "url(" + blueprintingLogo + ")",
  },
  cardLabel: {
    display: "inline-block",
    marginLeft: "auto",
    maxWidth: 160,
    textAlign: "right",
    lineHeight: "1.5em",
    opacity: .8,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  cardListItem: {
    [breakpoints.down("sm")]: {
      fontSize: pxToRem(14),
      paddingTop: 5,
    },
  },
  // ORCHESTRATION LOGO
  orchLogoWrapper: {
    display: "block",
    maxWidth: 500,
    width: "100%",
    height: "fit-content",
    marginBottom: 20,
    marginTop: 20,
  },
}));

const ServicesCapabilitiesPage = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const openNav = (event) => {
    setOpen(true);
  }
  const closeNav = () => {
    setOpen(false);
  }

  useEffect(() => {
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top <= 300 && rect.bottom >= 300
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor-section")

      for (let item of anchorList) {
        let sectionLink = document.querySelectorAll(`a[href="#${item.id}"]`)       
        let sectionWrapper = sectionLink[0].parentElement
        if (isElementInViewport(item)) {
          sectionWrapper.classList.add("active-listitem")
        }
        else {
          if (sectionWrapper.classList.contains("active-listitem")) {
            sectionWrapper.classList.remove("active-listitem")
          }
        }
      }
    }
  })
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={capabilitiesBanner}>
        Capabilities</Banner>
      <PageWrapper >
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={3} md={3} lg={3} className={classes.sideBar}>
            <div className={classes.sideBarWrapper}>
              <Typography variant="h5" className={classes.sideBarH5}>CORPORATE CAPABILITIES</Typography>
              <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/corporateinfo/NCG_Services_2019.pdf">Corporate Capabilities Statement</LinkDefault>
              <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/corporateinfo/NCGBrochure.pdf">NCG Brochure</LinkDefault>
            </div>

            <div className={classNames(classes.sideBarWrapperServices)}>
              <Typography variant="h5" className={classNames(classes.sideBarH5, classes.solutionsHeader)}>Solutions</Typography>
              <ul className={classes.sideBarServicesList}>
                <li className={classes.serviceItem}>
                  <Link href="#cmmc" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      CMMC Services
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#banking" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Banking &amp; Financial
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#cybersecurity" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Cybersecurity
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#privacy" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Privacy Services
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#programOfficeSupport" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Program Office Support
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#incidentManagement" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Incident Management
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#risk" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Risk Assessments
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#integration" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Integration Services
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#mergersAcquisitions" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Mergers &amp; Acquisitions
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#compliance" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Compliance &amp; Oversight
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#vendorManagement" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Vendor Management
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#systemBlueprinting" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      System Blueprinting
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#orchestration" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Orchestration Platform
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#sms" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      Aviation Safety Management Systems
                    </Typography>
                  </Link>
                </li>
                <li className={classes.serviceItem}>
                  <Link href="#devops" className={classNames(classes.serviceItemLink)}>
                    <Typography variant="body1" className={classes.serviceLabel}>
                      DevOps Integration
                    </Typography>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <ButtonCustom id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => openNav()}>
                Download Service Cards
              </ButtonCustom>
              <Drawer
                anchor='left'
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={closeNav}
              >
                <MenuList
                  className={classes.drawerList}
                >
                  <Typography variant="h4" className={classes.headingOver}>Northcross Group</Typography>
                  <Typography variant="h5" className={classes.headingUnder}>Service Cards</Typography>
                  <ul className={classes.sideBarList}>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_M_A_Integration_Services_2014.pdf">Acquisition &amp; Divestiture Integration Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_SMS_Services.pdf">Aviation Safety Management Systems Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Banking_Services_2014.pdf">Banking Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_CIO_CTO.pdf">CIO/CTO Support Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Compliance_Governance.pdf">Compliance &amp; Governance</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Compliance_RiskMgmt.pdf">Compliance &amp; Risk Management</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Core_Blueprinting.pdf">Core Blueprinting</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Bank_Cyber_Top_4.pdf">Cybersecurity Focus Areas for Banks</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Cybersecurity_Services.pdf">Cybersecurity Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Data_Services.pdf">Data Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Gov_Services_2016.pdf">Federal Government Capabilities</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Financial_Services_032011.pdf">Financial Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_HC_InfoTech_092014.pdf">Healthcare: Information Security</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_HC_Rev_Cycle.pdf">Healthcare Management &amp; Revenue Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_HealthcareSecurity.pdf">Healthcare Security</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_IVV.pdf">Independent Verification &amp; Validation</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Int_Sec_Transport_HL_2014.pdf">Integration &amp; Security Services: Transportation Highlights</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_M_A_Integration_Services_2014.pdf">Integration Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_InvestmentLifecycle.pdf">Investment Life Cycle</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Equity_ITDiligence_2014.pdf">IT Due Diligence Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Manufacturing_Cyber_Workshop.pdf">Manufacturing Workshop</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Preparedness.pdf">Preparedness</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Program_Office_Services.pdf">Program Office Support Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Project_Managment_Execution.pdf">Project Management &amp; Execution</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Project_Oversight.pdf">Project Oversight</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Risk_Management.pdf">Risk Management Services</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Teaming_Success.pdf">Teaming for Success</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Prof_Services_Capabilities_2014.pdf">Technology &amp; Security</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Technology_Assessments.pdf">Technology Assessments</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_3rdParty_Advisement.pdf">Third Party Agreements</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_USDA_Capabilities_012013.pdf">USDA Capabilities</LinkDefault>
                    </li>
                    <li className={classes.sideBarListItem}>
                      <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Your_Analytics_Plan.pdf">Utility Analytics Plan</LinkDefault>
                    </li>

                  </ul>
                </MenuList>
              </Drawer>

            </div>
          </Grid>
          <Grid xs={12} sm={9} md={9} lg={9} className={classes.mainContentWrapper}>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.introSection, classes.pageSectionStriped)}>
              <Typography variant="h2">
                Overview
              </Typography>
              <Typography variant="body1">
                NCG provides services to help organizations meet the challenges of regulatory compliance, bridging between disparate groups, and working with technology and data to accomplish goals.
              </Typography>
              <Typography variant="body1">
                We craft our solutions to meet the needs of each client based on our proven experiences and suite of specific services. Our services include support, consultation, tools and accelerators for all parts of an organization.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/about-ncg/corporate-background">Learn more about our Corporate Background</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="cmmc" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Cybersecurity Maturity Model Certification (CMMC) Preparation and Implementation Services
              </Typography>
              <Typography variant="body1">
                The Cybersecurity Maturity Model Certification (CMMC) is a new Department of Defense (DoD) process to ensure that all suppliers in the Defense Industrial Base have a base level of cybersecurity capabilities. DoD is planning to include CMMC Maturity Level requirements in RFPs starting in late 2020.
              </Typography>
              <Typography variant="body1">
                NCG provides services and tools to help organizations prepare and implement practices for certification specifications. NCG helps organizations accomplish certification objectives in a managed and targeted manner that makes the most business sense for your company’s unique circumstances. NCG’s CMMC Orchestration services follow NCG’s proven delivery model that integrates people, data, technology, and processes—focused on delivery of business objectives.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/cmmc">Learn more about our CMMC Services</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="banking" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Banking &amp; Financial
              </Typography>
              <Typography variant="body1">
                Customer expectations and regulatory oversight drive financial institutions and determine their reputations. From acquisitions to new system implementations, NCG offers services to plan and exercise each detail during complex projects. Consultants have experience in project preparation and testing as well as contingency planning and event execution. NCG has successfully managed or supported more than a dozen merger and acquisition deals, totaling more than $100 million in value. As consultants offer their services, they work with stakeholders to ensure business processes and resources can accommodate change without impacting customers, employees, or other partners.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/banking-financial">Learn more about our Banking &amp; Financial Capabilities</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="cybersecurity" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Cybersecurity
              </Typography>
              <Typography variant="body1">
                Organizations must have robust cybersecurity capabilities to deal with internal and external threats and any factors particular to their organization. Cybersecurity programs must span all facets of the enterprise and engage all its resources and key third parties.
              </Typography>
              <Typography variant="body1">
                NCG helps organization bolster or craft cybersecurity programs that help them get their arms around the full spectrum of cybersecurity to protect the confidentiality, integrity, and availability of their data. NCG provides technical and programmatic support to plan and implement steps to better their overall security posture. NCG security tools help organizations of all sizes and industries get started or enhance their security capabilities.
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.cybersecurity)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Complete security program support.
                  </li>
                  <li className={classes.cardListItem}>
                    Industry specific versions.
                  </li>
                  <li className={classes.cardListItem}>
                    Access for all levels of the organization.
                  </li>
                  <li className={classes.cardListItem}>
                    SMART Technology to help people know what to do and when to do it.
                  </li>
                  <li className={classes.cardListItem}>
                    Secure automation that connects all the elements of security across your enterprise and with key third parties.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/cybersecurity">Learn more about our Cybersecurity Capabilities</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="privacy" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Privacy
              </Typography>
              <Typography variant="body1">
                NCG Privacy Programs &amp; Assessment Services provide organizations with frameworks and detailed insight to protect the privacy of both internal and client data. We design and build sustainable privacy programs that evolve with the organization, streamlining privacy compliance and risk management efforts across engineering, operations, and administration functions. We also provide third-party Privacy Impact Assessments (PIAs) and GDPR compliant Data Protection Impact Assessments (DPIAs).
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/privacy-services">Learn more about our Privacy Services</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="programOfficeSupport" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Program Office Support
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.program)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Manage development and currency of policies, plans and procedures.
                  </li>
                  <li className={classes.cardListItem}>
                    Manage oversight and governance activities.
                  </li>
                  <li className={classes.cardListItem}>
                    Manage risk management processes.
                  </li>
                  <li className={classes.cardListItem}>
                    Direct project engagement and support.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Program_Office_Services.pdf">Download our Program Office Service Card</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="incidentManagement" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Incident Management
              </Typography>
              <Typography variant="body1">
                The middle of an incident is not what you want your people figuring out what to do and how to do it. Having established processes and tools to help people work together and capture information to communicate effectively is key to reducing the time to recovery and minimizing the effort and cost of dealing with incidents. Coordination with third parties needs to be properly managed, whether working with technical experts, engaging with external legal or regulatory contact, working with law enforcement, or dealing with the media or general public. Incidents will continue to happen, organizations should have a way to learn from and improve their capabilities and apply lessons learned.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/incident-management">Learn more about our Incident Management Services</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="risk" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Risk Assessments
              </Typography>
              <Typography variant="body1">
                Third-party risk assessments are a requirement for many compliance frameworks. They are also an effective measure of the design and performance of your controls risk mitigation strategies.
              </Typography>
              <Typography variant="body1">
                NCG provide risk assessments for FISMA, SSAE, SOX, and HIPAA compliance. NCG has collaborative tools to streamline data collection processes and scheduling to minimize the level of effort needed by an organization to support the assessment process. NCG provides detailed findings, recommendations, and road maps with implementation details that are actionable to take corrective and enhancing measures.
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.risk)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Tools for planning and managing assessments.
                  </li>
                  <li className={classes.cardListItem}>
                    Client facing portal tools to support and track assessment efforts.
                  </li>
                  <li className={classes.cardListItem}>
                    Tablet based tools to use in the field for secure and easy data collection on the go.
                  </li>
                  <li className={classes.cardListItem}>
                    Tools to organize and reconcile data.
                  </li>
                  <li className={classes.cardListItem}>
                    Analysis and roadmap development tools.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/risk-management">Learn more about our Risk Management Services</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="integration" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Integration Services
              </Typography>
              <Typography variant="body1">
                As businesses embark on merger, acquisition, and divestiture endeavors, they must integrate their systems, people, and data effectively to succeed. Moreover, they must oversee technical and security planning, testing, and implementation. NCG offers integration services that span the system development lifecycle, supporting technological and organizational needs, human factors, and data requirements.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/integration">Learn more about our Integration Capabilities</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="mergersAcquisitions" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Mergers &amp; Acquisitions
              </Typography>
              <Typography variant="body1">
                Missing the details of information technology and resources can destroy the value of an acquisition. Transition and integration costs can wipe out profitability and strain both the buyer and the seller. Mergers & Acquisitions (M&A) need the correct technical information and processes to secure transition environments throughout the acquisition life cycle.
              </Typography>
              <Typography variant="body1">
                NCG brings a combination of M&amp;A experience and strong technical assessment capabilities to support both acquirers and those divesting. NCG supports due diligence efforts and deal structuring to account for technology and operational factors. NCG provides integration and transition services to connect and convert data and systems. NCG develops and administers special security programs to deal with unique interaction models between the parties and their third parties. For deployment planning, exercising, and execution, NCG has the people and tools to manage the event and deal with incidents as they arise.
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.mergers)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Manage multiple due diligence efforts and data collection.
                  </li>
                  <li className={classes.cardListItem}>
                    Easy-to-use project management tools for integration efforts and testing.
                  </li>
                  <li className={classes.cardListItem}>
                    Plan and prepare for go-live events.
                  </li>
                  <li className={classes.cardListItem}>
                    Manage conversion and deployment events.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/merger-acquisition">Learn more about our Mergers &amp; Acquisitions Capabilities</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="compliance" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Compliance &amp; Oversight
              </Typography>
              <Typography variant="body1">
                Compliance with regulations, industry standards, state and federal laws, and other requirements can be very confusing and expensive. Engaging different parts of the organization can cause duplication of efforts, incongruous data, and reporting challenges.
              </Typography>
              <Typography variant="body1">
                NCG provides services, frameworks, and tools to help organizations better manage and memorialize compliance and oversight efforts. NCG helps organizations leverage existing data sources and implement processes for new data collection and interfaces for different elements across the organization.
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.compliance)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Closed loop oversight and risk management system.
                  </li>
                  <li className={classes.cardListItem}>
                    Plan and execute assessment activities.
                  </li>
                  <li className={classes.cardListItem}>
                    Use data collected and analysis conducted to retarget efforts to provide the most value.
                  </li>
                  <li className={classes.cardListItem}>
                    Follow-up and validate corrective actions to measure effectiveness.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Compliance_Governance.pdf">Download our Compliance &amp; Oversight Service Card</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="vendorManagement" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Vendor Management
              </Typography>
              <Typography variant="body1">
                Most organizations rely on third parties for key support and function. These parties have access to your most critical systems and data. Regulators have focused on these relationships (e.g. OCC 2013-29 and HIPAA Omnibus Rule), reinforcing the accountability a regulated organization has for its third parties.
              </Typography>
              <Typography variant="body1">
                NCG helps organizations develop and implement processes to better manage relationships with third parties. Starting with agreement specifications of controls and responsibilities of third parties, NCG helps organizations make sure they have the right terms and conditions to protect themselves. NCG has developed tools to help organizations engage vendor management, sourcing, and relationship-owning groups in order to collaborate effectively in the vendor management process.
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.vendor)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Full life cycle system to manage vendor relationships.
                  </li>
                  <li className={classes.cardListItem}>
                    Easy-to-use interfaces which can be made available across the organization to those that interact with your vendors.
                  </li>
                  <li className={classes.cardListItem}>
                    Secure interface for vendors to share data and information.
                  </li>
                  <li className={classes.cardListItem}>
                    Manage agreement drafting processes and documentation.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/vendor-management">Learn more about our Vendor Management Capabilities</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="systemBlueprinting" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                System Enterprise &amp; Blueprinting
              </Typography>
              <Typography variant="body1">
                Over the years, an organization’s operating environment can grow in complexity. Banks, for example, may have core systems that are decades old and supported by multiple level channels and other systems. No single source exists for all these systems to interact with each other. Some systems may have come through acquisitions and have no documentation. Other systems may have a key resource that has a lot of operational information that only exists in the resource’s head.
              </Typography>
              <Typography variant="body1">
                NCG has helped organizations develop a blueprint of how their manual and automated systems work together. NCG uses industry standard approaches, such as BIAN banking standards, to capture this data. NCG has developed tools to streamline data collection and provide ways to easily keep this information current over time.
              </Typography>
              <div className={classes.capabilityCard}>
                <div className={classNames(classes.cardHeader)}>
                  <div className={classNames(classes.cardLogo, classes.blueprinting)} />
                  <Typography variant="body2" className={classes.cardLabel}>
                    Orchestration Framework Tool
                  </Typography>
                </div>
                <ul className={classes.cardList}>
                  <li className={classes.cardListItem}>
                    Analyst, Executive, and Technical level views of blueprint information puts data in the hands of people at all levels of the organization that need it.
                  </li>
                  <li className={classes.cardListItem}>
                    Simple data collation tools streamline data management and updates.
                  </li>
                  <li className={classes.cardListItem}>
                    Open source structures support integration and connectivity with change management systems to keep data current.
                  </li>
                </ul>
              </div>
              <div className={classes.linkWrapper}>
                <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Core_Blueprinting.pdf">Download our Blueprinting/System Enterprise Service Card</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="orchestration" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <div className={classes.orchLogoWrapper}>
                <Image url={orchLogo} variant="customWidth" width={"100%"} />
              </div>
              <Typography variant="body1">
                The Orchestration suite of tools are designed from extensive experience to efficiently solve today’s complex business challenges. It provides the process and steps that are proven to make an organization successful.
              </Typography>
              <Typography variant="body1">
                Orchestration provides specific guidance and support to start initiatives for people in all parts of the organization. For instance, what role do they play in the organization in regards to security, working with third party vendors, or managing risk?
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/orchestration-platform">Learn more about our Orchestration Platform</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="sms" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                Aviation Safety Management Systems
              </Typography>
              <Typography variant="body1">
                The NCG Team has decades of experience building compliant programs and helping organizations of all sizes and complexities develop programs for safety, risk management, security, and compliance.
              </Typography>
              <Typography variant="body1">
                We have designed and built frameworks that have grown with the organization. We have helped organizations develop the frameworks with incentives and controls to successfully influence corporate culture to align with strategic objectives.            </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/capabilities/sms">Learn more about SMS</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} id="devops" className={classNames(classes.pageSection, classes.pageSectionStriped, "anchor-section")}>
              <Typography variant="h2">
                DevOps for your Organization
              </Typography>
              <Typography variant="body1">
                DevOps is a different approach to how you manage and conduct development and operations in your organization. Adopting DevOps in your organization is about changing the way you approach development and operations of code.
              </Typography>
              <Typography variant="body1">
                We help you sift through the different tools, platforms, and offerings to understand what makes the most business sense for your organization and application portfolio.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/devops">Learn more about DevOps</LinkDefault>
              </div>
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
    </>

  )
}

export default ServicesCapabilitiesPage;