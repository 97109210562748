import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
  };
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "block",
    overflowX: "hidden",
    overflowY: "auto",
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1050,
  },
  dialogue: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: '30px auto',
    width: '100%',
    maxWidth: 1200,
    borderRadius: 3,
    [theme.breakpoints.down(1200)]:{
      maxWidth: 992,
    },
    [theme.breakpoints.down(992)]:{
      maxWidth: 768,
    },
    [theme.breakpoints.down(768)]:{
      maxWidth: '100%',
    },
    [theme.breakpoints.down(768)]:{
      margin: 0,
    },
    [theme.breakpoints.up(1200)]:{
      margin: '30px auto',
    },

  },
}));

const CMMCModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.modal}>
      <div style={modalStyle} className={classes.dialogue}>
        {props.children}
      </div>
    </Modal>
  );
};

export default CMMCModal;