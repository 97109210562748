import { Container, Typography, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import orchestrationLogo from "../../../images/images/orchestration/orchestration-logo.png"
import slide1Image from "../../../images/images/orchestration/slider/slide_one/one.jpg";
import slide2Image from "../../../images/images/orchestration/slider/slide_two/two.jpg";
import slide3Image from "../../../images/images/orchestration/slider/slide_three/three.jpg";
import slide4Image from "../../../images/images/orchestration/slider/slide_four/four.jpg";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
import ButtonCustom from "components/layout/buttonCustom.component";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 800,
    width: "100%",
    backgroundColor: variables.ncgBlue4,
    display: "block",
    position: "relative",
  },
  slideBackground: {
    height: "inherit",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    display: "block",
    position: "absolute",
    right: 0,
    top: 0,
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
  },
  innerContent: {
    display: "flex",
    height: "inherit",
    width: "100%",
    position: "relative",
  },
  innerContentGradient: {
    '&::after': {
      display: "block",
      height: "inherit",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(to right, rgba(231, 237, 242, .95) 0%, rgba(255, 255, 255, 0) 100%);",
    },
  },
  innerContentGradientInvert: {
    '&::after': {
      display: "none",
    },
  },
  textContainer: {
    display: "flex",
    height: "inherit",
    zIndex: 100,
    position: "relative",
    paddingTop: 145,
    [theme.breakpoints.down('md')]: {
      paddingTop: 75,
    },
  },
  textWrapper: {
    marginLeft: 130,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 60,
    paddingRight: 200,
    [theme.breakpoints.down('md')]: {
      paddingRight: 100,
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 500,
    lineHeight: "1.3em",
    fontSize: "40px",
    paddingLeft: 0,
  },
  slideHeaderDark: {
    color: variables.ncgBlue4,
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
    marginBottom: 10,
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "white",
    textShadow: "1px 1px 1px rgb(0 0 0 / 58%)",
    fontSize: "22px",
    fontWeight: 300,
    lineHeight: "1.9em",
    maxWidth: 570,
    [theme.breakpoints.down('sm')]: {
      fontSize: "18px",
    },
  },
  slideCaptionIndent: {
    paddingLeft: 140,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 100,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingTop: 20,
      marginLeft: "auto",
    },
  },
  slideCaptionDark: {
    color: variables.ncgBlue4,
    textShadow: "none",
  },
  buttonWrapper: {
    position: "relative",
    paddingTop: 20,
  },
}));

const OrchestrationPlatformCarousel = () => {
  const classes = useStyles();

  return (
    <CustomCarousel>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide1Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <img class="platform_logo img-responsive" src={orchestrationLogo} alt="orchestration" />
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaptionDark, classes.slideCaptionIndent)}>The Orchestration suite of tools helps your organization accomplish business challenges with technology, data, and control frameworks. Orchestration tools are developed and continually improved by subject matter experts with decades of experience.</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide2Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Link href="#intro">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Improve Your Team</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaptionDark)}>Orchestration helps your team get their arms around the full scope of a business challenge. It helps each person know where to start and how to keep things going.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#intro" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide3Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Link href="#slide_three">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Overcoming Challenges</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaptionDark)}>The Orchestration suite of tools is tuned for specific business challenges. They have been crafted to meet industry specific requirements, match your organization, and leverage best practices to deliver results.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#slide_three" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide4Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Link href="#slide_four">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Support and Tools</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaptionDark)}>Orchestration provides tools for your team to use as they work through various processes and plans. It helps your team members leverage data and work together collaboratively.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#slide_four" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
    </CustomCarousel>
  )
}

export default OrchestrationPlatformCarousel;