import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme) => ({
  pageSection: {
      position: "relative",
      marginTop: 100,
      marginBottom: 100,
      display: "flex",
      alignItems: "center",
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse",
    },
  },
  sectionWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const PageSection = (props) => {
  const classes = useStyles();

  if (props.variant === "absolute") {
  return (
      <div id={props.id} className={classes.pageSection}>
        <Container maxWidth="lg">
        <Grid lg={12} className={classNames(classes.sectionWrapper, classes.flexColumn)}>
            {props.children}
          </Grid>
        </Container>
      </div>
    );
  }
  else {
    return (
      <div id={props.id} className={classes.pageSection}>
        <Container maxWidth="lg">
          <Grid lg={12} className={classNames(classes.sectionWrapper, classes.flexRow)}>
            {props.children}
          </Grid>
        </Container>
      </div>
    )
  }
}

export default PageSection;