import { Grid, makeStyles, Typography } from "@material-ui/core";
import brain from "../../../../images/microsite/educationFoundation/svg/brain.svg";
import cloud from "../../../../images/microsite/educationFoundation/svg/cloud.svg";
import lightbulb from "../../../../images/microsite/educationFoundation/svg/lightbulb.svg";
import people from "../../../../images/microsite/educationFoundation/svg/people.svg";
import thinking from "../../../../images/microsite/educationFoundation/svg/thinking.svg";
import classNames from "classnames";

const white = "#F5F8FF";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: "relative",
    zIndex: 1,
    background: (
      `linear-gradient(
        90deg,
        #37AFA1 0%, #2FABDA 25%, #398AE5 50%, #5359E6 75%, #7C2DE1 100%
      )`
    ),
    boxShadow: (
      `0px 4px 10px 0px #0000001A,
      0px -4px 10px 0px #0000001A,
      0px 4px 30px 0px #7C2DE14D`
    ),
  },
  cardsGrid: {
    minHeight: 400,
    justifyContent: "stretch",
  },
  card: {
    padding: "128px 32px",
    boxShadow: "-1px -1px 9px 2px #00000040",
    color: white,
    fill: white,
    [theme.breakpoints.down("md")]: {
      padding: "128px 24px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "56px 24px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px"
    }
  },
  card1: {
    background: (
      `linear-gradient(
        90deg,
        rgba(55, 175, 161, 0.55) 0%,
        rgba(55, 175, 161, 0.8) 50.5%,
        rgba(55, 175, 161, 0.55) 100%
      )`
    )
  },
  card2: {
    background: (
      `linear-gradient(
        90deg,
        rgba(47, 171, 218, 0.55) 0%,
        rgba(47, 171, 218, 0.85) 50.5%,
        rgba(47, 171, 218, 0.55) 100%
      )`
    )
  },
  card3: {
    background: (
      `linear-gradient(
        90deg,
        rgba(57, 138, 229, 0.55) 0%,
        rgba(57, 138, 229, 0.8) 50.5%,
        rgba(57, 138, 229, 0.55) 100%
      )`
    )
  },
  card4: {
    background: (
      `linear-gradient(
        90deg,
        rgba(83, 89, 230, 0.55) 0%,
        rgba(83, 89, 230, 0.8) 50.5%,
        rgba(83, 89, 230, 0.55) 100%
      )`
    )
  },
  card5: {
    background: (
      `linear-gradient(
        90deg,
        rgba(124, 45, 225, 0.55) 0%,
        rgba(124, 45, 225, 0.82) 50.5%,
        rgba(124, 45, 225, 0.55) 100%
      )`
    )
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    textShadow: "2px 2px 1px 0px #2E2BAC4D",
    [theme.breakpoints.down("xs")]: {
      minWidth: 148
    }
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    height: 60,
    marginTop: 8,
    marginRight: -8,
    marginBottom: 16,
    marginLeft: -8,
    fontWeight: 700,
    textShadow: "3px 2px 2px 0px #2E2BAC4D",
    [theme.breakpoints.only("md")]: {
      height: 96
    }
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: 70,
  },
  targetsGrid: {
    maxWidth: 1000,
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  imageGridItem: {
    position: "relative",
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  containImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  containImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  stretchGrid: {
    alignSelf: "stretch",
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 670
    }
  },
  textColumnContent: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 16,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  textGridItem: {
    alignSelf: "center"
  },
}));


export default function EFEmpowermentCards() {
  const classes = useStyles();
  return (
    <div className={classes.cardContainer}>
      <Grid className={classes.cardsGrid} container>
        <Grid
          className={classNames(classes.card, classes.card1)}
          xs
          sm={4}
          md
          item
        >
          <div className={classes.cardContent}>
            <div className={classes.cardIcon}>
              <img src={cloud} alt="" role="presentation" />
            </div>
            <Typography
              variant="h3"
              align="center"
              color="inherit"
              className={classes.cardHeader}
              paragraph
            >
              Technical Proficiency
            </Typography>
            <Typography variant="body1" align="center" color="inherit">
              Promoting a broad base of technical knowledge and understanding
              of the ways it can be used for good and bad across areas
              and disciplines.
            </Typography>
          </div>
        </Grid>
        <Grid
          className={classNames(classes.card, classes.card2)}
          xs
          sm={4}
          md
          item
        >
          <div className={classes.cardContent}>
            <div className={classes.cardIcon}>
              <img src={thinking} alt="" role="presentation" />
            </div>
            <Typography
              variant="h3"
              align="center"
              color="inherit"
              className={classes.cardHeader}
              paragraph
            >
              Putting Tools to Work
            </Typography>
            <Typography variant="body1" align="center" color="inherit">
              Gaining proficiency in the wealth of technology tools that be
              readily used to tackle challenges and seize opportunities.
            </Typography>
          </div>
        </Grid>
        <Grid
          className={classNames(classes.card, classes.card3)}
          xs
          sm={4}
          md
          item
        >
          <div className={classes.cardContent}>
            <div className={classes.cardIcon}>
              <img src={brain} alt="" role="presentation" />
            </div>
            <Typography
              variant="h3"
              align="center"
              color="inherit"
              className={classes.cardHeader}
              paragraph
            >
              Threat Awareness
            </Typography>
            <Typography variant="body1" align="center" color="inherit">
              The tools we use to progress can be used against us. Ensure
              the ability to identify and respond accordingly to threats.
            </Typography>
          </div>
        </Grid>
        <Grid
          className={classNames(classes.card, classes.card4)}
          xs
          sm={6}
          md
          item
        >
          <div className={classes.cardContent}>
            <div className={classes.cardIcon}>
              <img src={people} alt="" role="presentation" />
            </div>
            <Typography
              variant="h3"
              align="center"
              color="inherit"
              className={classes.cardHeader}
              paragraph
            >
              Inclusiveness
            </Typography>
            <Typography variant="body1" align="center" color="inherit">
              Everyone with an interest in learning and willingness to
              work should have access to resources to develop skills
              and expand their knowledge.
            </Typography>
          </div>
        </Grid>
        <Grid
          className={classNames(classes.card, classes.card5)}
          xs
          sm={6}
          md
          item
        >
          <div className={classes.cardContent}>
            <div className={classes.cardIcon}>
              <img src={lightbulb} alt="" role="presentation" />
            </div>
            <Typography
              variant="h3"
              align="center"
              color="inherit"
              className={classes.cardHeader}
              paragraph
            >
              Creativity &amp; Innovation
            </Typography>
            <Typography variant="body1" align="center" color="inherit">
              Building from today the future is what we make of it.
              Fostering new concepts and directions to progress.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
