import React from 'react';

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";


const MarquetteInternships = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap crunch">
        <div id="top" class="banner banner--mq-internships padZero"   >
          <h1 class="bannerTitle">Marquette Internships</h1>
        </div>
        <div class="page-wrap page-wrap--no-pad">
          <div class="secondary_layout">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/about">About NCG</a></li>
                <li><a href="/about-ncg/career-opportunities/careers">Career Opportunites</a></li>
                <li class="active">Marquette Internships</li>
              </ul>
            </div>
            <div itemprop="mainContentOfPage" class="mainpage-wrapper mq-internships">
              <div itemscope itemtype="http://schema.org/WPSidebar" class="sideBar mq-internships-sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="sidebar-wrapper">
                  <h5>SEND YOUR COVER LETTER &amp; RESUME</h5>
                  <button type="button" class="btn btn-primary btn-sm" onClick={() => window.location.href = 'mailto:careers@northcrossgroup.com'}>Apply</button>
                </div>
              </div>
              <div itemprop="specialty" class="page-section intro col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h2>Internship Opportunities in Marquette</h2>
                  <p>NCG is an integration and cybersecurity firm that works in public, private, and defense sectors. NCG recently opened an office in downtown Marquette (101 W. Washington Street) supporting clients in the region, as well as working with NCG’s clients in the US and Internationally.</p>
                  <p>NCG is focusing internship recruiting in Marquette around cybersecurity and business program development. NCG internships are 90 days in duration and are paid positions. Interns will have opportunity to help define the internship scope to best align with academic objectives and gain introduction to future career pursuits.</p>
                  <a itemprop="relatedLink" href="/content/docs/careers/NCG_Internship_Program_2021.pdf" target="_blank" class="internship-doc download-link">Learn more about the Internship Program at NCG</a>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 add-padding">
                  <h2>NCG &amp; NMU</h2>
                  <p>NCG has an Academic Partnership with NMU focusing efforts with the U.P. Cybersecurity Institute and is focusing a range of cybersecurity internship opportunities for NMU students. NCG provides paid internships and student may be eligible receive academic credit for their internship.</p>
                </div>
              </div>
            </div>
            <div itemprop="specialty" id="internship-focus-areas" class="focus-areas page-section page-section--full page-section--striped">
              <div class="mainpage-wrapper clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix">
                  <h2>Cybersecurity Internship Focus Areas</h2>
                  <p>NCG has cybersecurity internship opportunities for Fall 2021 focusing on four general areas. Interns working in these areas will gain valuable firsthand field experience in the consulting, technology, and cybersecurity landscape.</p>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 focus-area row">
                    <div class="internship-area-tile cybersecurity-essentials">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Cybersecurity Essentials</h3>
                        </div>
                        <p>Learn about the essential cybersecurity practices and processes needed in every business, and how to implement them in a sustainable way that makes business sense for the organization.</p>
                      </div>
                    </div>
                    <div class="internship-area-tile cyber-risk-assessments">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Cyber Risk Assessments</h3>
                        </div>
                        <p>Learn to conduct cyber risk assessments to give businesses insight to their strengths and weaknesses, and how to provide best value recommendations to improve overall security posture.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 focus-area row">
                    <div class="internship-area-tile system-hardening">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>System Hardening</h3>
                        </div>
                        <p>Learn how to lock down systems to mitigate against both internal and external threats.</p>
                      </div>
                    </div>
                    <div class="internship-area-tile cyber-risk-management">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Cyber Risk Management Programs</h3>
                        </div>
                        <p>Learn to build and facilitate effective risk management programs tailored to an organization.</p>
                      </div>
                    </div>
                  </div>

                </div>
                <a itemprop="relatedLink" href="/content/docs/careers/NCG_Cybersecurity_Internship_2021.pdf" target="_blank" class="internship-docs download-link">Learn more about Cybersecurity Internship Opportunities</a>
              </div>
            </div>
            <div itemprop="specialty" id="internship-focus-areas" class="focus-areas page-section page-section--full page-section--striped">
              <div class="mainpage-wrapper">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h2>Business Program Internship Focus Areas</h2>
                  <p>Join the Northcross Group Team supporting clients to design and implement programs business development, governance, and compliances  objectives. Gain experience working across multiple industries and subject matters while learning how to meet unique client requirements. Business program internships will focus on the following areas:</p>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 focus-area row">
                    <div class="internship-area-tile compliance-program-ops">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Compliance Program Operations</h3>
                        </div>
                        <p>Working with corporations to understand the range of legal, industry, and contractual requirements they face and how to make sure they comply without impact to their operations.</p>
                      </div>
                    </div>
                    <div class="internship-area-tile mergers-acquisitions">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Mergers and Acquisitions</h3>
                        </div>
                        <p>Working on the integration and conversion activities when corporations acquire or merge operations.  These actions require range of activities from technical system conversion to business process integration, and human resource management.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 focus-area row">
                    <div class="internship-area-tile risk-management">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Risk Management</h3>
                        </div>
                        <p>Work with organizations to better understand the threats they face across the full range of their operations. Help them assess levels of exposure and take action to mitigate those risks.</p>
                      </div>
                    </div>
                    <div class="internship-area-tile assessments-auditing">
                      <div class="text-wrapper">
                        <div class="header-wrapper">
                          <h3>Assessments and Auditing</h3>
                        </div>
                        <p>Help organizations understand how effectively their systems work—spanning people, processes, data, and technology.</p>
                      </div>
                    </div>
                  </div>
                  <a itemprop="relatedLink" href="/content/docs/careers/NCG_Business_Program_Development_Internship_2021.pdf" target="_blank" class="internship-docs download-link">Learn more about Business Program Internship Opportunities</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </>
  )
};

export default MarquetteInternships;