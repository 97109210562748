import { Container, Typography, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import slide1Image from "../../../images/images/mergers/slider/slide_one/one.jpg";
import slide2Image from "../../../images/images/mergers/slider/slide_two/two.jpg";
import slide3Image from "../../../images/images/mergers/slider/slide_three/three.jpg";
import slide4Image from "../../../images/images/mergers/slider/slide_four/four.jpg";
import slide5Image from "../../../images/images/mergers/slider/slide_five/five.jpg";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
import ButtonCustom from "components/layout/buttonCustom.component";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 800,
    width: "100%",
    backgroundColor: variables.ncgBlue4,
    display: "block",
    position: "relative",
  },
  slideWhite: {
    backgroundColor: "white",
  },
  slideBackground: {
    height: "inherit",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    display: "block",
    position: "absolute",
    right: 0,
    top: 0,
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
  },
  slide5Background: {
    backgroundImage: "url(" + slide5Image + ")",
  },
  innerContent: {
    display: "flex",
    width: "100%",
    position: "relative",
    height: "inherit",
  },
  innerContentGradient: {
    '&::after': {
      display: "block",
      height: "inherit",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 1,
      backgroundImage: "linear-gradient(to right, rgba(0, 75, 129, 0.8) 27%, rgba(134, 218, 255, 0.47) 100%)",
    },
  },
  innerContentGradientInvert: {
    '&::after': {
      display: "none",
    },
  },
  textContainer: {
    display: "flex",
    height: "inherit",
    zIndex: 100,
    position: "relative",
    paddingTop: 145,
    [theme.breakpoints.down('md')]: {
      paddingTop: 75,
    },
  },
  textWrapper: {
    marginLeft: 130,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 90,
    paddingRight: 200,
    [theme.breakpoints.down('md')]: {
      marginLeft: 80,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingRight: 0,
    },
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 500,
    lineHeight: "1.3em",
    fontSize: "34px",
    paddingLeft: 0,
    marginBottom: 0,
  },
  slideHeaderDark: {
    color: variables.ncgBlue4,
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "white",
    textShadow: "1px 1px 1px rgb(0 0 0 / 58%)",
    fontSize: "22px",
    fontWeight: 300,
    lineHeight: "1.9em",
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: "18px",
    },
  },
  slideCaptionDark: {
    color: variables.ncgBlue4,
    textShadow: "none",
  },
  captionList: {
    paddingLeft: 25,
  },
  buttonWrapper: {
    position: "relative",
    paddingTop: 20,
  },
}));

const MergerAcquisitionCarousel = () => {
  const classes = useStyles();

  return (
    <CustomCarousel>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide1Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={12} sm={10} md={10} lg={8} className={classes.textWrapper}>
              <Link href="#intro">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeader)}>M&A IT & Security Support</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaption)}>Supporting Equity Investments, Mergers, Acquisitions, and Divestitures, NCG brings technical, cybersecurity expertise, and experience to better manage risk and deliver on investment objectives.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#intro" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>

      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide2Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={12} sm={10} md={10} lg={8} className={classes.textWrapper}>
              <Link href="#diligence">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeader)}>M&A IT Due Diligence</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaption)}>In evaluating investment options, detailed understanding of a target's technical fitness—stability, performance, scalability, and ease of integration is essential.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#diligence" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>

      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide3Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={12} sm={10} md={10} lg={8} className={classes.textWrapper}>
              <Link href="#TSA">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeader)}>M&A Deal & TSA Strategy</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaption)}>Protection of data and intellectual property, as well as ensuring seamless operations and transition requires specific terms in your deal agreements and Transition Service Agreements (TSAs). The NCG Team helps you eliminate surprises and gaps in support.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#TSA" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>

      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide4Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={12} sm={10} md={10} lg={8} className={classes.textWrapper}>
              <Link href="#integration">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeader)}>M&A Integration & Transition</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaption)}>Bringing systems together and making sure data and functions connect on legal day one needs a secure environment to develop and test all elements end to end.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#integration" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>

      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide5Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={12} sm={10} md={10} lg={8} className={classes.textWrapper}>
              <Link href="#deployment">
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeader)}>M&A Command Center & Go-Live Deployment Management</Typography>
              </Link>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaption)}>Readiness assessments, mock exercises, event preparation, and command & control groundwork manages issues as they arise; are delivered to drive overall success.</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href="#deployment" >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
    </CustomCarousel>
  )
}

export default MergerAcquisitionCarousel;