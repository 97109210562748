import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import ButtonCustom from "components/layout/buttonCustom.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import contactBanner from "../../../images/images/banners/contact.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    width: "100%",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    left: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 215 ,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  addressWrapper: {
    display: "inline-block",
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  sideBarList: {
    paddingLeft: 0,
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    listStyleType: "none",
    paddingTop: 0,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
    [theme.breakpoints.down('xs')]: {
      fontSize: ".8em",
    },
  },
  buttonWrapper: {
    display: "inline-block",
    marginRight: 25,
    marginTop: 10,
  },
  // MAIN CONTENT
  pageSection: {
    paddingTop: 55,
    paddingBottom: 55,
    display: "block",
    marginLeft: "auto",
    paddingLeft: 60,
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 480,
      paddingBottom: 0,
    },
  },
  mapContainer: {
    width: "100%",
    height: 300,
    marginBottom: 20,
    borderRadius: 5,
    border: "none",
    boxShadow: "0px 10px 13px -7px #000000",
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={contactBanner}>
        Contact</Banner>
      <PageWrapper >
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={5} md={4} lg={4} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <div className={classes.addressWrapper}>
                <Typography variant="h5" className={classes.sideBarH5}>PORTLAND, MAINE</Typography>
                <ul className={classes.sideBarList}>
                  <li className={classes.sideBarListItem}>
                    100 Middle St.
                  </li>
                  <li className={classes.sideBarListItem}>
                    East Tower, #203
                  </li>
                  <li className={classes.sideBarListItem}>
                    Portland, ME 04101
                  </li>
                  <li className={classes.sideBarListItem}>
                    207.699.5540
                  </li>
                  <li className={classes.sideBarListItem}>
                    FAX 207.699.2113
                  </li>
                </ul>
              </div>
              <div className={classes.addressWrapper}>
                <Typography variant="h5" className={classes.sideBarH5}>WASHINGTON, DC</Typography>
                <ul className={classes.sideBarList}>
                  <li className={classes.sideBarListItem}>
                    1101 Wilson Blvd.
                  </li>
                  <li className={classes.sideBarListItem}>
                    6th Floor
                  </li>
                  <li className={classes.sideBarListItem}>
                    Arlington, VA 22209
                  </li>
                  <li className={classes.sideBarListItem}>
                    703.351.3397
                  </li>
                  <li className={classes.sideBarListItem}>
                    FAX 703.351.5298
                  </li>
                </ul>
              </div>
              <div className={classes.addressWrapper}>
                <Typography variant="h5" className={classes.sideBarH5}>MARQUETTE, MI</Typography>
                <ul className={classes.sideBarList}>
                  <li className={classes.sideBarListItem}>
                    101 W. Washington St.
                  </li>
                  <li className={classes.sideBarListItem}>
                    Suite #4
                  </li>
                  <li className={classes.sideBarListItem}>
                    Marquette, MI 49855
                  </li>
                  <li className={classes.sideBarListItem}>
                    906.451.5910
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={9} className={classes.pageSection}>
            {/* missing title properties */}
            <iframe className={classes.mapContainer} title="Portland Location" src="https://maps.google.com/maps?f=q&amp;hl=en&amp;geocode=&amp;q=100+Middle+Street%3B+Portland,+Maine+04101&amp;sll=37.0625,-95.677068&amp;sspn=40.324283,92.8125&amp;ie=UTF8&amp;t=h&amp;s=AARTsJp4AHblFlNFfNh89ppZKmrmezx58w&amp;ll=43.667313,-70.247698&amp;spn=0.02173,0.036564&amp;z=14&amp;iwloc=addr&amp;output=embed"></iframe>
            <iframe className={classes.mapContainer} title="Virginia Location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3885.6010838197553!2d-77.0748085!3d38.8954841!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b65a3baef5b7%3A0x9e310e7ea6ce34df!2s1101%20Wilson%20Blvd%2C%20Arlington%2C%20VA%2022209!5e1!3m2!1sen!2sus!4v1707781175723!5m2!1sen!2sus"></iframe>
            <iframe className={classes.mapContainer} title="Marquette Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.076759260796!2d-87.39498524839024!3d46.5430295790261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d4e2098f28ccb47%3A0xf6801a0c971aa464!2s101%20W%20Washington%20St%2C%20Marquette%2C%20MI%2049855!5e1!3m2!1sen!2sus!4v1632419970904!5m2!1sen!2sus"></iframe>
            <Typography variant="h4">Contact By Email</Typography>
            <div className={classes.buttonWrapper}>
              <ButtonCustom href="mailto:info@northcrossgroup.com">
                email general info
              </ButtonCustom>
            </div>
            <div className={classes.buttonWrapper}>
              <ButtonCustom href="mailto:careers@northcrossgroup.com">
                email careers
              </ButtonCustom>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default ContactPage;