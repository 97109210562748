import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import federalBanner from "../../../images/images/banners/federal.jpg";
import cybersecurityImage from "../../../images/images/markets/federal/cyberSecurity.jpg";
import projectManagementImage from "../../../images/images/markets/federal/projectManagement.jpg";
import financialImage from "../../../images/images/markets/federal/financial.jpg";
import solutionIntegrationImage from "../../../images/images/markets/federal/solutionIntegration.jpg";
import hrConsultingImage from "../../../images/images/markets/federal/HR-consulting.png";
import businessImage from "../../../images/images/markets/federal/business.jpg";
import developmentImage from "../../../images/images/markets/federal/development.png";
import orchestrationImage from "../../../images/images/index/page/OrchestrationCloudLogo.png";

const useStyles = makeStyles((theme) => ({
// SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%!important",
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 25,
    },
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  introSection: {
    paddingTop: 80,
    paddingBottom: 80,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 750,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  pageSectionBorderBottom: {
    borderBottom: "1px solid #b0bbcb",
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column-reverse",
    },
  },
  textWrapper: {
    paddingRight: 40,
  },
  list: {
    maxWidth: 650,
  },
  imageWrapper: {
    width: 160,
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    height: "auto",
  },
  cybersecurityImage: {
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 250,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    },
  },
  developmentImage: {
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 170,
    },
  },
  pageSectionLinkWrapper: {
    paddingLeft: 20,
    display: "block",
    maxWidth: 550,
  },
}));

const FederalSectorPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={federalBanner}>
        Federal Sector</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/markets" variant="link">Markets</BreadcrumbItem>
          <BreadcrumbItem href="" variant="active">Federal Sector</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={3} md={3} lg={3} className={classes.sideBar}>
            <div className={classes.sideBarWrapper}>
              <Typography variant="h5" className={classes.sideBarH5}>Service Card</Typography>
              <LinkDefault href="../../../../content/docs/federal/NCG_Gov_Services_2016.pdf" target="_blank" variant="downloadMini">Federal Service Card</LinkDefault>
            </div>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>Corporate Overview</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Founded in 2006 | Offices in Virginia &amp; Maine
                </li>
                <li className={classes.sideBarListItem}>
                  SBA 8(a) Minority Owned, Small Disadvantaged Business
                </li>
                <li className={classes.sideBarListItem}>
                  FAA eFAST Contract: DTFAWA13A‐0169
                </li>
                <li className={classes.sideBarListItem}>
                  DUNS: 808835826 | CAGE: 59CU6
                </li>
                <li className={classes.sideBarListItem}>
                  Supported NAICS: 518210, 541219, 541330, 541511, 541512, 541513, 541519, 541611, 541612, 541614, 541618, 541690, 541712, 541990, 561110, 561499, 611430
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} className={classes.introSection}>
            <Typography variant="h2">
              Overview
            </Typography>
            <Typography variant="body1">
              NCG offers technology, cybersecurity, and business process services to the federal government. Our team has federal experience in the civilian, defense, and intelligence sectors that is furthered with experience in the private sectors. This combination of skills and capabilities results in our team bringing the latest industry standards and best practices to the specific and unique challenges of the federal government.
            </Typography>
            {/* <div className={classes.subSection}>
              <Typography variant="h3">
                Stars II
              </Typography>
              <Typography variant="body1">
                NCG is a GSA STARS II GWAC awardee. STARS II is an easy to use vehicle for Federal Agencies to work with NCG. NCG provides highly skilled and targeted services under the STARS II Functional Area 2 – Computer Systems Design Services
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault target="_blank" href="/stars2">Visit NCG's Stars II Website</LinkDefault>
              </div>
            </div> */}
            <div className={classes.subSection}>
              <Typography variant="h3">
                Key Past Performance
              </Typography>
              <ul>
                <li>
                  Private sector expertise in financial services, healthcare, manufacturing, and energy.
                </li>
                <li>
                  FEMA Regional Catastrophic Preparedness Grant Program.
                </li>
                <li>
                  Chief Information Officer, U.S. Navy.
                </li>
                <li>
                  CIO Department of Defense Strategic Weapons Delivery Program.
                </li>
                <li>
                  FAA AFS Acting ISSO.
                </li>
                <li>
                  IV&amp;V at federal, state, and local levels.
                </li>
                <li>
                  Commercial IV&amp;V for safety, security, and scalability.
                </li>
              </ul>
            </div>
          </Grid>
        </Container>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Cybersecurity Program Development and Assessments
              </Typography>
              <ul className={classes.list}>
                <li>
                  Security program development and support.
                </li>
                <li>
                  Policy and procedure development.
                </li>
                <li>
                  Tailored security services for specific capabilities and vulnerabilities.
                </li>
                <li>
                  Infrastructure hardening and continuity preparedness.
                </li>
                <li>
                  Risk and compliance assessments, scans, pen tests, training, and awareness.
                </li>
              </ul>
            </Grid>
            <Grid item xs={5} sm={4} md={3} lg={3} className={classes.imageWrapper}>
              <img src={cybersecurityImage} className={classNames(classes.image, classes.cybersecurityImage)} alt="cybersecurity" />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Project Management
              </Typography>
              <ul className={classes.list}>
                <li>
                  Technical program and project management.
                </li>
                <li>
                  Change management and facilitation.
                </li>
                <li>
                  Deployment command center management.
                </li>
                <li>
                  Operational transition management.
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={5} md={3} g={3} className={classes.imageWrapper}>
              <img src={projectManagementImage} className={classNames(classes.image, classes.projectManagementImage)} alt="project management" />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Financial System Services
              </Typography>
              <ul className={classes.list}>
                <li>
                  Financial system and processing expertise.
                </li>
                <li>
                  Integration of banking services and tools for government programs.
                </li>
                <li>
                  Financial and performance analytics.
                </li>
              </ul>
              <div className={classes.pageSectionLinkWrapper}>
                <LinkDefault href="/blog/blog-post-federal-financial">NCG Blog Post: Federal Financial Program Risk Management and Analytics Services for Crisis Management: COVID-19 </LinkDefault>
              </div>
            </Grid>
            <Grid item xs={6} sm={5} md={3} lg={3} className={classes.imageWrapper}>
              <img src={financialImage} className={classNames(classes.image, classes.financialImage)} alt="financial risk management" />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Solution Integration
              </Typography>
              <ul className={classes.list}>
                <li>
                  Life cycle solution design and integration.
                </li>
                <li>
                  Data conversions and platform migrations.
                </li>
                <li>
                  Deployment and readiness management.
                </li>
                <li>
                  Technical and management oversight and governance.
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={5} md={3} lg={3} className={classes.imageWrapper}>
              <img src={solutionIntegrationImage} className={classNames(classes.image, classes.solutionIntegrationImage)} alt="solution integration" />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Human Resources Consulting Services
              </Typography>
              <ul className={classes.list}>
                <li>
                  HR functions to streamline and improve processes and systems.
                </li>
                <li>
                  Recruiting, analytics, training, and data management.
                </li>
                <li>
                  Continuous improvement and application of best practices for HR functions.
                </li>
                <li>
                  Compliance and assessment services.
                </li>
                <li>
                  Onboarding and resource integration.
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={5} md={3} lg={3} className={classes.imageWrapper}>
              <img src={hrConsultingImage} className={classNames(classes.image, classes.hrConsultingImage)} alt="HR consulting"/>
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Business Process Management (BPM)
              </Typography>
              <ul className={classes.list}>
                <li>
                  Organizational skills and tools to capitalize on opportunities and innovation.
                </li>
                <li>
                  Tailoring processes and functions for internal and external factors.
                </li>
                <li>
                  Analytics to measure effectiveness and drive continuous improvements.
                </li>
                <li>
                  Manage change and enhance capabilities.
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={5} md={3} lg={3} className={classes.imageWrapper}>
              <img src={businessImage} className={classNames(classes.image, classes.businessManagementImage)} alt="business" />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionBorderBottom)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Development Services and Training
              </Typography>
              <ul className={classes.list}>
                <li>
                  Development process framework and control implementation and improvement.
                </li>
                <li>
                  Deployment of management processes.
                </li>
                <li>
                  System integration services.
                </li>
              </ul>
            </Grid>
            <Grid item xs={5} sm={5} md={3} lg={3} className={classes.imageWrapper}>
              <img src={developmentImage} className={classNames(classes.image, classes.developmentImage)} alt="development" />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h3">
                Cloud Based Tools and Technology Templates
              </Typography>
              <ul className={classes.list}>
                <li>
                  Enterprisewide communication, collaboration, and coordination tools.
                </li>
                <li>
                  Program orchestration and facilitation.
                </li>
                <li>
                  Key function support: cybersecurity, vendor management, assessment management, project management, system blueprinting, and policy and procedure building.
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={5} md={3} lg={3} className={classes.imageWrapper}>
              <img src={orchestrationImage} className={classNames(classes.image, classes.orchestrationImage)} alt="orchestration" />
            </Grid>
          </Container>
        </div>
      </PageWrapper>
    </>

  )
}

export default FederalSectorPage;