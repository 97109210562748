import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style/custom-css/slick-theme-custom.css";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  slider: {
    width: "100%",
  },
  sliderShadow: {
    boxShadow: "0 3px 18px 0 rgb(0 0 0 / 45%)",
    zIndex: 180
  }
}));

const CustomCarousel = (props) => {
  const classes = useStyles();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings} className={classNames(classes.slider, (props.sliderShadow != null) ? classes.sliderShadow : "")} style={{ height: props.height }}>
      {props.children}
    </Slider>
  )
}

export default CustomCarousel;