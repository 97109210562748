import Star2NavBar from "components/microsites/stars2/stars2NavBar";
import getAdobe from "images/microsite/stars2/get_adobe_reader.gif";
import aboutStar2 from "images/microsite/stars2/H_Dotted_Line.gif"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const Star2Service = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="stars2">
        <div id="container" align="center">
          <Star2NavBar href="/stars2/about_stars2" />
          <div id="srv_leftbar">
            <h2>How To Order:</h2>
            <p>Prior to issuing orders under 8(a)  STARS II, federal contracting officers are required to receive training on the  use of the 8(a) STARS II contract and be granted a written delegation of procurement  authority from the <a href="http://www.gsa.gov/8astars2">GSA Small Business GWAC Center</a>.</p>
            <p>To received delegation of  procurement authority please contact GSA Small Business GWAC Center:</p>
            <p>Easy Training is offered at no  cost viaA self-paced, online course; <br />
        Onsite training conferences and  events; <br />
        Web or video conferences;<br />
        Conference calls. </p>
            <p><a href="http://www.gsa.gov/graphics/fas/S2OrderingGuide.pdf">More about GSA's ordering information</a>
            </p>
            <p align="right"><a href="http://www.adobe.com/products/acrobat/readstep2.html" target="_blank" rel="noreferrer"><img src={getAdobe} alt="Get Adobe Acrobat Reader" width="112" height="33" border="0" /></a></p>
          </div>

          <div id="contents">
            <h1>About GSA's STARS II</h1>
            <img src={aboutStar2} alt="Spacer Line" width="480" height="2" />
            <p>          STARS II is a multiple-award contract engineered  to provide cutting-edge technology solutions from award-winning 8(a) small businesses  to federal agencies. Using 8(a) STARS II enables federal agencies to access  8(a) firms through an established contract and meet small business contracting  goals. </p>
            <p>
              The  8(a) STARS II GWAC is designed to promote small business utilization when  purchasing information technology (IT) services or IT services-based solutions  for the federal government. The 8(a) STARS II program is reserved exclusively for  qualifying certified 8(a) small business concerns as the prime contractors per  Federal Acquisition Regulation (FAR) 19.8.</p>
            <p><strong>Features</strong></p>
            <ul class="square">
              <li>$10B program ceiling and five-year  base period with one, five-year option</li>
              <li>Directed task orders up to $4M</li>
              <li>Contract types include: fixed  price, labor hour, and time and material terms</li>
              <li>Permits other direct costs when it  is integral and necessary to the IT effort</li>
              <li>Access to exceptionally qualified  8(a) small business industry partners</li>
              <li>Pre-competed, easy-to-use contract  with streamlined ordering procedures based on FAR 16.505</li>
              <li>Complimentary scope compatibility  reviews</li>
              <li>Compliance with Section 843 of the  National Defense Authorization Act (NDAA) of 2008 and Section 863 of NDAA 2009</li>
              <li>No protests on orders under $10M,  except on the grounds that the order increases the scope, period, or maximum value  of the GWAC </li>
            </ul>

            <p><strong>Benefits</strong></p>
            <ul class="square">
              <li>Allows for long term planning of  large scale program requirements</li>
              <li>Enhanced opportunities for 8(a)  firms</li>
              <li>Offers flexibility of contract  types to mitigate risk</li>
              <li>Offers access to a pool of  contractors with additional industry credentials</li>
              <li>Facilitates integrated IT  solutions</li>
              <li>Enables federal clients to earn  8(a) small business credit </li>
              <li>Saves time and money by reducing  procurement lead time</li>
              <li>Promotes contract compliance and  reduces risk of adverse audits</li>
              <li>FAR 16.505 Fair Opportunity  process supports best value awards</li>
              <li>Minimizes protest risk and supports  timely order award for optimal mission support </li>
            </ul>
            <p align="center">&nbsp;</p>

            <div class="footer">
              <p class="footer_style">&copy;2011 Northcross Group Corporation. All rights reserved. </p>
            </div>
          </div>

          <div id="rightbar">
            <h2>STARS II Contacts:</h2>
            <p>8(a)  STARS II <br />
1500 East  Bannister Road<br />
Room 1076/QTBAC<br />
Kansas City , MO 64131<br />
              <strong>Phone:</strong> (877)  327-8732 <br />
Fax: (816)  823-1608<br />
              <strong>E-mail:</strong> <a href="mailto:s2@gsa.gov">s2@gsa.gov</a> <br />
              <a href="http://www.gsa.gov/s2">www.gsa.gov/s2</a></p>
            <h2>8(a) STARS  II GWAC Program Team</h2>
            <p>Procuring  Contracting Officers: <br />
         Misty  Dunnington<br />
         (816)  823-4356<br />
       misty.dunnington@gsa.gov</p>
            <p>Todd Tekesky<br />
         (816)  823-2468<br />
         todd.tekesky@gsa.gov</p>
            <p>Contract  Specialist: <br />
         Vicki McReynolds<br />
         (816)  926-1387<br />
         vicki.mcreynolds@gsa.gov</p>

            <p>Business  Development Specialists:<br />
       	 Herman Lyons <br />
       	 (816) 823-2469 <br />
       	 herman.lyons@gsa.gov<br /><br />

         Lori Hanavan<br />
		(816)  823-1492<br />
		lori.hanavan@gsa.gov</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Star2Service;