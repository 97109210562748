import http from "./http-common";

class MailerServiceBase {
  sendMail(formData) {
    return http
      .post(`/mailer/sendMail/`, formData)
    }
}

const MailerService = new MailerServiceBase();

export default MailerService;