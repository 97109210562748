import { Container, Typography, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import slide1Image from "../../../images/images/cyber/slider/slide_one/one.jpg";
import slide2Image from "../../../images/images/cyber/slider/slide_two/two.jpg";
import slide3Image from "../../../images/images/cyber/slider/slide_three/three.jpg";
import slide4Image from "../../../images/images/cyber/slider/slide_four/four.jpg";
import slide5Image from "../../../images/images/cyber/slider/slide_five/five.jpg";
import slide6Image from "../../../images/images/cyber/slider/slide_six/six.jpg";
import slide7Image from "../../../images/images/cyber/slider/slide_seven/seven.jpg";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
import ButtonCustom from "components/layout/buttonCustom.component";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 800,
    width: "100%",
    backgroundColor: variables.ncgBlue4,
    display: "block",
    position: "relative",
  },
  slideBackground: {
    height: "inherit",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    display: "block",
    position: "absolute",
    right: 0,
    top: 0,
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
    backgroundPosition: "right center",
    [theme.breakpoints.down('lg')]: {
      backgroundPosition: "center center",
    },
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "right center",
    },
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "right center",
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "84% center",
    },
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "right center",
    },
  },
  slide5Background: {
    backgroundImage: "url(" + slide5Image + ")",
    backgroundPosition: "right top",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "right center",
    },
  },
  slide6Background: {
    backgroundImage: "url(" + slide6Image + ")",
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "right center",
    },
  },
  slide7Background: {
    backgroundImage: "url(" + slide7Image + ")",

    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "right center",
    },
  },
  innerContent: {
    display: "flex",
    height: "inherit",
    width: "100%",
    position: "relative",
  },
  innerContentGradient: {
    '&::after': {
      display: "block",
      height: "inherit",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(to right, rgba(231, 237, 242, .95) 0%, rgba(255, 255, 255, 0) 100%);",
    },
    [theme.breakpoints.down('md')]: {
      '&::after': {
        background: "linear-gradient(to right, rgba(231, 237, 242, .9) 0%, rgba(255, 255, 255, .5) 90%);",
      },
    },
  },
  textContainer: {
    display: "flex",
    height: "inherit",
    zIndex: 100,
    position: "relative",
    paddingTop: 145,
    [theme.breakpoints.down('md')]: {
      paddingTop: 75,
    },
  },
  textWrapper: {
    marginLeft: 130,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 90,
    paddingRight: 200,
    [theme.breakpoints.down('md')]: {
      paddingRight: 100,
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 500,
    lineHeight: "1.3em",
    fontSize: "34px",
    paddingLeft: 0,
  },
  slideHeaderDark: {
    color: variables.ncgBlue4,
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
    marginBottom: 10,
  },
  slideSubTitle: {
    fontFamily: "Helvetica, sans-serif",
    fontSize: "24px",
    fontWeight: 400,
    textShadow: "1px 1px 1px rgb(0 0 0 / 15%)",
    lineHeight: "1.5em",
    maxWidth: 650,
    color: "#2d8adf",
    marginTop: 5,
    marginBottom: 5,
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "white",
    textShadow: "1px 1px 1px rgb(0 0 0 / 58%)",
    fontSize: "22px",
    fontWeight: 300,
    lineHeight: "1.9em",
    maxWidth: 590,
    [theme.breakpoints.down('md')]: {
      maxWidth: 460,
    },
  },
  sliderCaptionGreen: {
    color: "#019fa1",
    textShadow: "none",
    marginTop: 5,
    lineHeight: "1.8em",
    "& > ul": {
      marginBottom: 0,
    },
    "& > ul >li": {
      color: "#7c818c",
      fontSize: "20px",
      paddingTop: 5,
      paddingBottom: 5,
      lineHeight: "1.5em",
    },
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  buttonWrapper: {
    position: "relative",
    paddingTop: 20,
  },
}));


const CybersecurityCarousel = () => {
  const classes = useStyles();

  const Slides =
    [{
      class: classes.slide1Background,
      title: "Enterprise Cybersecurity",
      subTitle: (<>Find your Focus - <i>Where do you start?</i></>),
      content: "Security covers almost every aspect of the enterprise. It is hard to get your arms around everything.",
      href: "#enterprise"
    },
    {
      class: classes.slide2Background,
      title: "Corporate Culture of Security",
      subTitle: "NCG integrates security across your enterprise, from people to processes and systems.",
      content: "Everyone must understand the risks and know what actions to take in today's environment.",
      href: "#culture"
    },
    {
      class: classes.slide3Background,
      title: "Systems & Infrastructure",
      subTitle: "Organizations depend on a secure system and infrastructure to be there when needed.",
      content: (
        <ul>
          <li>Enterprise Security Architecture and Design </li>
          <li>Operational Security and Physical Security Systems</li>
        </ul>),
      href: "#systems"
    },
    {
      class: classes.slide4Background,
      title: "Legal & Compliance",
      subTitle: "Meeting security needs for you and your customers and managing compliance requirements.",
      content: (
        <ul>
          <li>Govern compliance requirements </li>
          <li>Track compliance and risk management plans </li>
        </ul>),
      href: "#legal"
    },
    {
      class: classes.slide5Background,
      title: "Business Continuity Planning & Disaster Recovery",
      subTitle: "Be ready when things happen...",
      content: (
        <ul>
          <li>Address scenarios with NCG's Spectrum Response</li>
          <li>Create communication plans with key parties</li>
        </ul>
      ),
      href: "#disaster"
    },
    {
      class: classes.slide6Background,
      title: "Vendors & Third Parties",
      subTitle: "Ensuring your key partners are keeping their security posture aligned with yours.",
      content: (
        <ul>
          <li>Control of third parties and vendor security</li>
          <li>Keep Track of multiple key third parties </li>
        </ul>
      ),
      href: "#party"
    },
    {
      class: classes.slide7Background,
      title: "Orchestration Security Portal",
      subTitle: "Enterprise level ability to track, manage, and address security needs for your organization.",
      content: (
        <ul>
          <li>Oversee workflows and manage your security investments</li>
          <li>Real-time tracking of security elements </li>
        </ul>
      ),
      href: "#portal"
    }
    ]


  function GetSlide(slideBackground, title, subTitle, content, href) {
    return (
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, slideBackground)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Link href={href}>
                <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>{title}</Typography>
              </Link>
              <Typography variant="h2" className={classNames(classes.slideSubTitle)}>{subTitle}</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.sliderCaptionGreen)}>{content}</Typography>
              <div className={classes.buttonWrapper}>
                <ButtonCustom typeOf="bigButtonPrimary" href={href} >Learn More</ButtonCustom>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <CustomCarousel>
      {Slides.map((slide) => {
        return GetSlide(slide.class, slide.title, slide.subTitle, slide.content, slide.href)
      })}
    </CustomCarousel>
  )
}

export default CybersecurityCarousel;