import { Container, Breadcrumbs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme) => ({
breadcrumbRow: {
    borderTop: "2px solid #fff",
    borderBottom: "2px solid #fff",
    background: "linear-gradient(to right, rgba(72, 197, 253, 0.11) 0%, rgba(75, 145, 206, 0.37) 100%)",
    boxShadow: "2px 2px 2px rgb(0 63 97 / 22%)",
    width: "100%",
    padding: 0,
    position: "relative",
},
  listContainer: {
    padding: "4px 0px",
    width: "100%",
    margin: 0,
  },
}));

const BreadcrumbMenu = (props) => {
  const classes = useStyles(props);

    return (
      <div className={classes.breadcrumbRow}>
        <Container maxWidth="lg">
          <ul className={classNames(classes.listContainer)}>

            <Breadcrumbs>
              {props.children}
            </Breadcrumbs>
          </ul>
        </Container>
      </div>
    );
};

export default BreadcrumbMenu;