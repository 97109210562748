import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    paddingBottom: 0,
  }
}));
const menuItems =
  [
    { label: 'What is DevOps', href: '#what-is-devops' },
    { label: 'DevOps Approach', href: '#devops-approach' },
    { label: 'DevOps Services', href: '#devops-services' },
    { label: 'Get Started', href: '#request', rfpType: 'DevOps', contactForm: true }
  ]
const DevopsNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar bgColor="white" menuItems={menuItems} elementInView={props.elementInView}/>
    </div>
  );
}
export default DevopsNavBar;