import { useEffect, useState } from "react";
import GRCNavBar from "./GRCNavBar";
import BackToTopCustom from "components/layout/BackToTopCustom";
import { Container, Divider, Grid, makeStyles } from "@material-ui/core";
import GRCPhaseCard from "./GRCPhaseCard";
import classNames from "classnames";
import grcCycle from "../../../images/microsite/grc/grcCycle.png"
import splash from "../../../images/microsite/grc/grcSplash.png"
import grcProgram from "../../../images/microsite/grc/grcProgram.png"

import programIcon1 from "../../../images/microsite/grc/programIcon1.png"
import programIcon2 from "../../../images/microsite/grc/programIcon2.png"
import programIcon3 from "../../../images/microsite/grc/programIcon3.png"


import started1 from "../../../images/microsite/grc/grcStarted1.png"
import started2 from "../../../images/microsite/grc/grcStarted2.png"
import started3 from "../../../images/microsite/grc/grcStarted3.png"
import LinkDefault from "components/layout/linkDefault.component";


const primaryDark = "#0D2E51"
const primaryDark2 = "#225c80"
const primary = "#3F80A8"
const primaryLight = "#4b92bd"

const useStyles = makeStyles((theme) => ({
  splashContainer: {
    background: primaryDark,
    backgroundImage: splash,
  },
  splashImageContainer: {
    width: "100%",
    display: "block",
    [theme.breakpoints.up('md')]: {
      minHeight: 600
    },
  },
  splashImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  abbTitle: {
    fontSize: "120px",
    color: primaryDark,
    position: "absolute",
    top: 240,
    paddingLeft: 20,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      top: 260,
      fontSize: "90px",
    },
    [theme.breakpoints.down('xs')]: {
      top: 130,
      fontSize: "70px",
      paddingLeft: 5,
    },
    textShadow: "2px 2px -7px #000000;",
  },
  title: {
    position: "absolute",
    top: 150,
    width: "62%",
    fontSize: "32px",
    color: primaryDark,
    fontWeight: 600,
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      top: 160,
      fontSize: "24px",
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      width: "90%",
      top: 60,
      fontSize: "18px",
    },
  },
  splashText: {
    position: "absolute",
    color: primaryDark,
    paddingLeft: 30,
    top: 360,
    maxWidth: "33%",
    fontSize: "18px",
    [theme.breakpoints.up('xl')]: {
      maxWidth: "22%",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "21%",
      minWidth: 400
    },
    [theme.breakpoints.down('md')]: {
      top: 370,
      maxWidth: "37%",
      fontSize: "16px",
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  aboutSection: {
    background: "linear-gradient(180deg, #F0F0F0 0%, #B8D8E3 100%)",
    paddingBottom: 20
  },
  titleBorder: {
    width: "100%",
    height: 30,
    backgroundColor: "#3F80A8",
    position: "relative",
    top: -10,
    zIndex: 5
  },
  sectionTitleContainer: {
    width: "100%",

    [theme.breakpoints.down('sm')]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  sectionTitle: {
    backgroundColor: "#3F80A8",
    maxWidth: 400,
    color: "#FFFFFF",
    padding: 20,
    textAlign: "center",
    marginTop: -25,
    marginBottom: 50,
    marginLeft: 65,
    borderRadius: 10,
    boxShadow: "2px 2px 13px -7px #000000",
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,

    },
  },
  centerSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    color: "#0E3359",
    fontSize: "18px"
  },
  paragraphHeader: {
    color: "#0E3359",
    fontSize: "24px"
  },
  programParagraphHeader: {
    color: "#FFFFFF",
    fontSize: "18px",
    padding: 10,
    minHeight: 75,
    display: "flex",
    alignItems: "center"
  },
  programParagraph: {
    color: "#FFFFFF",
    fontSize: "16px",
    padding: 15,
    paddingTop: 25,

  },
  textLight: {
    fontSize: "12px",
    color: "#FFFFFF",
  },
  programServiceHeader: {
    marginTop: 50,
  },
  contactHeader: {
    marginTop: 40
  },
  emailLink: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 40
  }, 
  programServiceCards: {
    marginBottom: 120,
    display: "flex",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      flexWrap: "wrap"
    },
  },
  serviceCycleContainer: {
    backgroundColor: primary,
    margin: 5,
    borderRadius: 5,
    boxShadow: "2px 2px 13px -5px #000000",
    width: "20%",
    [theme.breakpoints.down('sm')]: {
      width: "45%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      '&:nth-of-type(odd)': {
        backgroundColor: primaryLight,
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(odd)': {
        backgroundColor: primaryLight,
      },
    },

  },
  serviceCycleHeader: {
    fontSize: 18
  },
  serviceCycleBody: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  serviceCardContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center"
    },
  },
  serviceCard: {
    backgroundColor: primary,
    boxShadow: "2px 2px 13px -5px #000000",
    borderRadius: 10,
    margin: 15,
    width: "30%",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "95%",
    },
  },
  programApproachText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 40,
    [theme.breakpoints.up('sm')]: {
      display: "none",
    },
  },
  programApproachTextItem: {
    width: "90%",
    boxShadow: "2px 2px 5px -5px #000000",
    backgroundColor: primary,
    padding: 15,
    fontWeight: 600,
    borderRadius: 5,
    marginTop: 20,
    color: "#fff",
    '&:nth-of-type(3n-1)': {
      backgroundColor: "#64d1da",
    },
    '&:nth-of-type(3n-2)': {
      backgroundColor: "#e6e6e6",
      color: primaryDark
    },
    '&:nth-of-type(3n)': {
      backgroundColor: "#3cb8e8",
    },
    '&:nth-of-type(4n)': {
      backgroundColor: "#00639c",
      color: "#fff",
    },
    '&:nth-of-type(5n)': {
      backgroundColor: "#054166",
      color: "#fff",
    },
  },
  bold: {
    fontWeight: 600
  },
  textSection: {
    padding: 10
  },
  textPadding: {
    paddingBottom: 30,
  },
  textPaddingTop: {
    paddingTop: 40,
  },
  textRight: {
    color: "#0E3359",
    width: "100%",
    maxWidth: "90%",
    [theme.breakpoints.up('lg')]: {
      maxWidth: "35%",
      minWidth: 450,
    },
  },
  cycleImage: {
    width: "100%",
    height: "auto",
    maxWidth: 550,
    position: "relative",
    [theme.breakpoints.up('md')]: {
      top: -30,
      minWidth: 480,
    },
  },
  cycleImageContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: 400,
    },
  },
  programApproachSection: {
    background: "linear-gradient(180deg, #B8D8E3 0%, #F0F0F0 100%)",
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 100
    },
  },
  programImage: {
    width: "100%",
    position: "relative",
    left: -200,
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
  },
  serviceIconContainer: {
    maxHeight: 200,
    marginTop: 10,
    backgroundColor: primaryDark2,
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  serviceIcon: {
    height: 100,
    margin: 20,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  centerText: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center"
  },
  startedCardContainer: {
    paddingTop: 40,
    display: "flex",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center"
    },
  },
  startingText: {
    maxWidth: "90%",
    display: "flex",
    textAlign: "center",
    justifyContent: "center"
  },
  startingCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  scrollElement: {
    position: "relative",
    top: -80
  },
  gettingStartedSection: {
    background: "linear-gradient(180deg, #B8D8E3 0%, #ecedee 100%)",
    
  }
}));

const GRCMicrosite = (props) => {
  const classes = useStyles(props);

  const [elementId, setElementId] = useState("");

  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  }, [])

  return (
    <>
      <GRCNavBar elementInView={elementId} />
      <BackToTopCustom />

      {/* Splash */}
      <div className={classes.splashContainer}>
        <Container maxWidth="lg">
            <div className={classes.splashImageContainer}>
              <img src={splash} className={classes.splashImage} alt="Construction Workers on Mountain" />
            </div>
            <p className={classes.abbTitle}>
              GRC
            </p>
            <p className={classes.title}>
              Governance, Risk, & Compliance
            </p>
            <p className={classes.splashText}>
              GRC Programs help organizations operate and produce in ways to best manage risks and maintain compliance with regulatory, legal, contractual, and corporate objectives.
            </p>
        </Container>
      </div>

      <div className={classes.aboutSection}>
        {/* About GRC */}
        <div className={classes.titleBorder} />
        <Container maxWidth="lg" >
          <div className={classes.sectionTitleContainer}>
            <h1 className={classes.sectionTitle}>
              What is GRC
            </h1>
          </div>
          <div className={classNames(classes.text, classes.textSection, classes.textPadding)}>
            <p>
              GRC programs define and frame out a strategy for an organization’s governance, enterprise risk management, and compliance with legal and contractual requirements.
            </p>
            <p>
              This will mean different things for companies of different sizes and operations, and their industries and markets served.
            </p>
          </div>

          <div className={classNames(classes.text, classes.textSection, classes.textPaddingTop)}>
            <h2 className={classes.paragraphHeader}>
              What GRC Includes
            </h2>
            <Grid container>
              <Grid item xs={12} md={6}>
                <p className={classes.textRight}>
                  Establishment of frameworks and processes tailored to the organization driving daily operations and tactical efforts are accomplished across:
                </p>
                <ul className={classNames(classes.text, classes.bold)}>
                  <li>
                  Information Security
                  </li>
                  <li>
                    Privacy
                  </li>
                  <li>
                    Compliance Management
                  </li>
                  <li>
                    Enterprise Risk Management
                  </li>
                  <li>
                    Vendor & Supply Chain
                  </li>
                  <li>
                    Data Governance
                  </li>
                  <li>
                    System Safety
                  </li>
                  <li>
                    Program Office Functions
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6} className={classNames(classes.center, classes.cycleImageContainer)}>
                <img src={grcCycle} className={classes.cycleImage} alt="Building Blocks: Policies & Procedures, Training, Monitoring & Assessing, Controls, Maintainance & Currency" />
              </Grid>
            </Grid>
          </div>
          <div className={classNames(classes.textSection, classes.text, classes.textPadding)}>
            <h2 className={classes.paragraphHeader}>
              GRC Outputs
            </h2>
            <p>
              GRC programs provide the design and implementation of key capabilities, along with tools and subject matter support, for  an organization’s oversight and management of efforts on an ongoing basis.
            </p>
            <ul className={classNames(classes.text, classes.bold, classes.textPadding)}>
              <li>
                Policies & Procedures
              </li>
              <li>
                Controls
              </li>
              <li>
                Training
              </li>
              <li>
                Monitoring & Auditing
              </li>
              <li>
                Continuous Improvements & Currency
              </li>
            </ul>
          </div>
        </Container>
      </div>

      <div id="Programmatic-Approach" className={classes.scrollElement} />
      <div className={classes.programApproachSection}>
        <div className={classes.titleBorder} />
        <Container maxWidth="lg">
          {/* Programmatic Approach */}
          <div className={classes.sectionTitleContainer}>
            <h1 className={classes.sectionTitle}>
              Programmatic Approach
            </h1>
          </div>

          <div className={classNames(classes.textSection, classes.text)}>
            <h2 className={classes.paragraphHeader}> 
              GRC Program is not a piece of software you buy and deploy….
            </h2>
            <p>
              GRC programs are a sustainable set of functions and operational frameworks that work across the enterprise and evolves with the enterprise as it grows.
            </p>
          </div>
          <div className={classes.programImageContainer}>
            <img src={grcProgram} className={classes.programImage} alt="Mountain with Program Goals" />
          </div>
          <div className={classes.programApproachText}>
            <p className={classes.programApproachTextItem}>
              Define the Program Goal - What you need to accomplish
            </p>
            <p className={classes.programApproachTextItem}>
              Leverage applicable industry standards and practices...
            </p>
            <p className={classes.programApproachTextItem}>
              Subject matter expertise and advisement to tailor the right constructs for your organization...
            </p>
            <p className={classes.programApproachTextItem}>
              Tools and Accelerators to help drive success...
            </p>
            <p className={classes.programApproachTextItem}>
              Technical and management resources to drive and keep efforts on track...
            </p>
          </div>
        </Container>
      </div>

      <div id="Services" className={classes.scrollElement} />
      <div className={classes.aboutSection} id="Services">
        <div className={classes.titleBorder} />
        <Container maxWidth="lg">
          {/* Program Services */}
          <div className={classes.sectionTitleContainer}>
            <h1 className={classes.sectionTitle}>
              Program Services
            </h1>
          </div>
          <div className={classNames(classes.serviceCardContainer)}>
            <div className={classNames(classes.text, classes.serviceCard)}>
              <div>
                <h2 className={classNames(classes.programParagraphHeader, classes.centerText)}>
                  Advisement
                </h2>
                <div className={classes.serviceIconContainer}>
                  <img src={programIcon2} className={classes.serviceIcon} alt="blueprint" />
                </div>
                <p className={classNames(classes.centerText, classes.programParagraph)}>
                  Industry and market experts with decades of experience to set strategy guide efforts to ensure compliance and accomplishment of business objectives.
                </p>
              </div>
            </div>
            <div className={classNames(classes.text, classes.serviceCard)}>
              <div>
                <h2 className={classNames(classes.programParagraphHeader, classes.centerText)}>
                  Automated Tools & Accelerators
                </h2>
                <div className={classes.serviceIconContainer}>
                  <img src={programIcon3} className={classes.serviceIcon} alt="blueprint" />
                </div>
                <p className={classNames(classes.centerText, classes.programParagraph)}>
                  NCG’s Orchestration is a secure, cloud-based platform that provides tools and automation to drive efforts and centrally manage data and information with role-based controls.
                </p>
              </div>
            </div>
            <div className={classNames(classes.text, classes.serviceCard)}>
              <div>
                <h2 className={classNames(classes.programParagraphHeader, classes.centerText)}>
                  Technical, Management, and Subject Matter Expert Resources
                </h2>
                <div className={classes.serviceIconContainer}>
                  <img src={programIcon1} className={classes.serviceIcon} alt="blueprint" />
                </div>
                <p className={classNames(classes.centerText, classes.programParagraph)}>
                  NCG’s provides management, technical and subject matter experts to work with your team to establish, operate, and enhance GRC program functions.
                </p>
              </div>
            </div>
          </div>
          <h2 className={classNames(classes.centerText, classes.programServiceHeader)}>
            Advisement, Automation, and Resources Across the GRC Life Cycle
          </h2>
          <div className={classNames(classes.centerText, classes.programServiceCards)}>
            <div className={classNames(classes.serviceCycleContainer, classes.textLight)}>
                <h3 className={classes.serviceCycleHeader}>
                  Design
                </h3>
                <Divider />
                <p className={classes.serviceCycleBody}> 
                  Design program constructs and controls tailored to your industry and current state operations and scale.
                </p>
            </div>
            <div className={classNames(classes.serviceCycleContainer, classes.textLight)}>
                <h3 className={classes.serviceCycleHeader}>
                  Implement
                </h3>
                <Divider />
                <p className={classes.serviceCycleBody}>
                  Initiate program processes and controls with the training and tooling to support your people and systems.
                </p>
            </div>
            <div className={classNames(classes.serviceCycleContainer, classes.textLight)}>
                <h3 className={classes.serviceCycleHeader}>
                  Operate
                </h3>
                <Divider />
                <p className={classes.serviceCycleBody}>
                  Operationalize program functions and maintain currency of tooling and actions.
                </p>
            </div>
            <div className={classNames(classes.serviceCycleContainer, classes.textLight)}>
                <h3 className={classes.serviceCycleHeader}>
                  Assess
                </h3>
                <Divider />
                <p className={classes.serviceCycleBody}>
                  Measure the effectiveness of program designs and performance to identify opportunities for improvement and enhancements.
                </p>
            </div>
            <div className={classNames(classes.serviceCycleContainer, classes.textLight)}>
                <h3 className={classes.serviceCycleHeader}>
                  Maintain
                </h3>
                <Divider />
                <p className={classes.serviceCycleBody}>
                  Updates and continuous improvements to meet evolving internal and external challenges.
                </p>
            </div>
          </div>
        </Container>
      </div>

      {/* Getting Started */}
      <div id="Getting-Started" className={classes.scrollElement} />
      <div className={classes.gettingStartedSection}>
        <div className={classes.titleBorder} />
        <Container maxWidth="lg">
          <div className={classes.sectionTitleContainer}>
            <h1 className={classes.sectionTitle}>
              Getting Started
            </h1>
          </div>
          <div className={classNames(classes.textSection, classes.text, classes.center)}>
            <p className={classes.startingText}>
              NCG tailors each engagement to our clients needs with a mix of advisement, professional services, and automation that is the best fit. We do this in three steps:
            </p>
          </div>
          <div className={classes.startedCardContainer}>
            <div className={classNames(classes.center, classes.startingCard)}>
              <GRCPhaseCard
                phaseNumber="1"
                smallImage
                image={started1}
                title="Understanding Acquisition & Scoping"
                description="Stakeholder session to help us understand what you are trying achieve and where you need assistance or support."
              />
            </div>
            <div className={classNames(classes.center, classes.startingCard)}>
              <GRCPhaseCard
                phaseNumber="2"
                image={started2}
                title="Timings, Costs, & Tangible Value"
                description="Based on what we learn about your needs, we create a work plan outlining what we will do, how long it will take, and how much it will cost."
              />
            </div>
            <div className={classNames(classes.center, classes.startingCard)}>
              <GRCPhaseCard
                phaseNumber="3"
                image={started3}
                title="Kick-off and Set Expectations & Responsibilities on All Sides"
                description="Get efforts started with a clear understanding of who is doing what and how things will work. This includes when to throttle support up or down as needed over time."
              />
            </div>
            </div>
            <h2 className={classNames(classes.centerText, classes.contactHeader)}>
              Contact At
            </h2>
              <div className={classes.emailLink}>
                <LinkDefault
                  href="mailto:info@northcrossgroup.com"
                >
                  info@northcrossgroup.com
                </LinkDefault>
              </div>
        </Container>
      </div>
    </>
  )
}
export default GRCMicrosite;
