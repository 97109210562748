//images
import nist from "images/images/cyber/industries/NIST.png";
import workshop from "images/images/cyber/industries/workshop-industries.jpg";

//fontawesome Icons
import AngleDoubleRightIcon from "../../utils/angleDoubleRightIcon";
import DownloadIcon from "../../utils/DownloadIcon"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const CriticalIndustriesPage = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap crunch">
        <div id="top" class="banner banner--cyber-industries"  >
          <h1 class="bannerTitle " >Cybersecurity in Critical Infrastructure Industries</h1>
        </div>
        <div class="page-wrap ">
          <div class="secondary_layout secondary_layout--full">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                <li><a href="/capabilities/cybersecurity">Cybersecurity</a></li>
                <li class="active">Critical Industries</li>
              </ul>
            </div>
            <div itemprop="mainContentOfPage" class="mainpage-wrapper">
              <div id="intro" class="page-section col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h3 class="heading-h3">Overview</h3>
                  <p >When working with Critical Infrastructure Industries we focus on balancing regulatory compliance, industry standards, and best practices that make the most sense for the organization.</p>
                  <p>Many companies want the advantages of online payment and other e-commerce capabilities and, therefore, must comply with commercial and government regulations, such as Payment Card Industry (PCI) and Sarbanes-Oxley (SOX). Failure to comply can forbid violators from performing online credit card transactions, which can severely damage both profitability and customer trust. </p>
                  <p>Forty-seven states and the District of Columbia now have laws mandating public disclosure of breaches that may compromise customers’ personal information. Congress is debating a national law. Disclosure often brings negative public reaction, government scrutiny and hefty fines. </p>
                  <div itemprop="specialty" class="page-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3>Cybersecurity for Critical Infrastructures</h3>
                    <p>Providing protection for industries that drive our economy, quality of life, and defense.</p>
                    <p>Cybersecurity is important for every organization—even more so in critical infrastructure industries. Because of this importance, they are governed by a web of regulations and marked as high value targets by those looking to harm or seek illicit gain.</p>
                    <p>Northcross Group (NCG) Cybersecurity Services are specifically tuned to address the compliance and operational requirements of critical infrastructure industries. We work with organizations to understand their security posture and execute programs to enhance capabilities without impacting their core business.</p>
                    <p>NCG takes a holistic view, looking at all areas and facets of an organization and its partners. We take care to understand the specifics of a given organization and how things come together across the enterprise. NCG then tailors efforts to effectively execute and provide value.</p>
                  </div>
                </div>
                <div class="col-pad-none col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-right">
                  <div itemscope itemtype="http://schema.org/WPSidebar" class="sideBar critSidebar">
                    <ul class="list col-lg-12 col-md-12 col-sm-12 col-xs-12 scrollMenu scrollToTop">
                      <li id="nist" class="header">
                        <div class="graphic-wrap ">
                          <img class=" img-responsive graphic" src={nist} alt="NIST" />
                        </div>
                      </li>
                      <li>
                        <p>NCG was a contributing member of the NIST Critical Infrastructure Framework working group.</p>
                        <div class="margint">
                          <a href="http://www.nist.gov/cyberframework/index.cfm" class="btn-link" target="_blank" rel="noreferrer" >NIST.gov <AngleDoubleRightIcon /></a>
                          <a href="http://www.dhs.gov/critical-infrastructure-sectors" class="btn-link" target="_blank" rel="noreferrer" >DHS.gov <AngleDoubleRightIcon /></a>
                        </div>
                      </li>
                    </ul>
                    <hr />
                    <h5>Critical Infrastructures</h5>
                    <ul class="list capa" >
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/chemical-sector" target="_blank" rel="noreferrer">Chemical Sector</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/financial-services-sector" target="_blank" rel="noreferrer">Financial Services </a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/commercial-facilities-sector" target="_blank" rel="noreferrer"> Commercial Facilities</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/food-and-agriculture-sector" target="_blank" rel="noreferrer"> Food and Agriculture</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/government-facilities-sector" target="_blank" rel="noreferrer"> Government Facilities</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/critical-manufacturing-sector" target="_blank" rel="noreferrer"> Critical Manufacturing</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="https://www.dhs.gov/healthcare-public-health-sector" target="_blank" rel="noreferrer"> Healthcare and Public Health</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/dams-sector" target="_blank" rel="noreferrer">Dams Sector</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/defense-industrial-base-sector" target="_blank" rel="noreferrer"> Defense Industrial Base</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/nuclear-reactors-materials-and-waste-sector" target="_blank" rel="noreferrer"> Nuclear Reactors, Materials, &amp; Waste</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/emergency-services-sector" target="_blank" rel="noreferrer">Emergency Services</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/transportation-systems-sector" target="_blank" rel="noreferrer">Transportation Systems</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/communications-sector" target="_blank" rel="noreferrer"> Communications Sector</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/information-technology-sector" target="_blank" rel="noreferrer"> Information Technology</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/energy-sector" target="_blank" rel="noreferrer">Energy Sector</a>
                      </li>
                      <li>
                        <a itemprop="relatedLink" href="http://www.dhs.gov/water-and-wastewater-systems-sector" target="_blank" rel="noreferrer">Water and Wastewater Systems</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" id="manufacturing" class="page-section col-pad-none">
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12 marginT">
                  <img src={workshop} class="img-responsive img-rounded " alt="Manufacturing Cybersecurity Workshops" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 class="heading-h2">Manufacturing Cybersecurity Workshops</h2>
                  <p>Manufacturers balance safety, quality,
                  standards and compliance with regulations as
                  part of everyday operations. Cybersecurity,
                  with threats from a variety of external and
                  internal sources, is a growing area of concern
                  with both direct and indirect impacts that can
                        target practically any aspect of the organization.</p>
                  <p>In light of the growing cyber threats to critical infrastructure and the overall national economy, the Administration has proposed incentives for Manufacturers in the form of tax breaks, grant programs, and other efforts recognizing the criticality to the economy manufacturing plays.</p>
                  <a href="/content/docs/cybersecurity/workshop_industries.pdf" target="_blank" rel="noreferrer" class="btn btn-download" >Download our Manufacturing Workshop Service Card <DownloadIcon /></a>
                </div>
              </div>
              <div itemprop="specialty" class="page-section">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <h2 class="heading-h2">Roadmaps to Drive Action</h2>
                  <p>NCG crafts roadmaps for critical infrastructure industries that balance day-to-day operations with addressing immediate and longer term security objectives. We prioritize items for mandatory items versus longer term and model options, and support them with business justifications that include budget and resource planning</p>
                  <ul class="circle">
                    <li>Roadmaps should account for the realities of timelines and resource availability.</li>
                    <li>Prioritization, accountability, timeframes, and defined responsibility are essential.</li>
                    <li>Processes to track efforts and manage change as necessary.</li>
                    <li>Roadmap efforts need to be tracked and revisited to adjust as necessary.</li>
                    <li>Orchestration Security Portal provides a secure Cloud-based automation to manage Roadmaps.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CriticalIndustriesPage;