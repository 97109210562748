import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container, Link, MenuList, Drawer } from "@material-ui/core";
import React, { useState } from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import Banner from "components/layout/banner.component";
import ContentContainer from "components/layout/contentContainer.component";
import Image from "components/layout/image.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import aboutBanner from "../../../images/images/banners/about.jpg";
import nmuLogo from "../../../images/images/nmu-partnership/upci-logo.png";
import chrisBender from "../../../images/images/about/management/bender.jpg";
import davidWininger from "../../../images/images/about/management/wininger.jpg";
import wayneMilstead from "../../../images/images/about/management/milstead.jpg";

const useStyles = makeStyles((theme) => ({
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    minWidth: 300,
    width: "100%",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    alignSelf: "flex-start",
    left: 0,
    position: "sticky",
    top: 70,
    maxHeight: "90vh",
    overflow: "auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
      minWidth: 260,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%!important",
      width: "100%",
      minWidth: "unset",
      position: "relative",
      top: 0,
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      paddingTop: 10,
      textAlign: "left",
    },
  },
  teamInfo: {
    visibility: "hidden",
    position: "fixed",
    width: 250,
    transition: "all 300ms ease 0s",
    height: "100%",
    top: 150,
    left: 0,
    zIndex: 1049,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 25,
    },
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  sideBarBody: {
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  // TEAM MEMBER LIST
  sideBarWrapperTeam: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sideBarTeamList: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    width: "100%",
  },
  memberItem: {
    width: "100%",
    listStyleType: "none",
    paddingTop: 0,
  },
  memberItemLink: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderTop: "1px solid transparent",
    borderBottom: "1px solid #d8dde3",
    alignItems: "flex-start",
    justifyContent: "center",
    '&:active': {
      backgroundColor: "white",
      borderBottom: "1px solid #ADD9F1",
      borderTop: "1px solid #ADD9F1",
    },
  },
  memberWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 60,
    position: "relative",
    alignItems: "flex-start",
    '&::before': {
      display: "inline-block",
      height: 40,
      width: 40,
      content: '""',
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  },
  bender: {
    '&::before': {
      backgroundImage: "url(" + chrisBender + ")",
    },
  },
  wininger: {
    '&::before': {
      backgroundImage: "url(" + davidWininger + ")",
    }
  },
  milstead: {
    '&::before': {
      backgroundImage: "url(" + wayneMilstead + ")",
    }
  },
  memberNameHeader: {
    fontSize: 11,
    paddingLeft: 0,
    display: "inline-block",
    fontWeight: "bold",
    color: variables.ncgTeal2,
    lineHeight: "1.6em",
    marginTop: 0,
    marginBottom: 5,
    letterSpacing: ".15em",
    textAlign: "left",
  },
  memberPosition: {
    fontWeight: 400,
    color: variables.textPrimary,
    marginBottom: 0,
  },
  // TEAM INFORMATION DRAWER
  drawerList: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 30,
    },
  },

  // SECTIONS
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    padding: theme.spacing(2),
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "auto",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  introSection: {
    paddingTop: "80px!important;",
    paddingBottom: "80px!important;",
    [theme.breakpoints.down('xs')]: {
      paddingTop: "40px!important;",
      paddingBottom: "40px!important;",
    },
  },
  subSection: {
    paddingTop: 30,
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  pageSectionStriped: {
    padding: "60px",
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "40px",
    },
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  noMargin: {
    marginBottom: 0,
    marginTop: 0,
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: 40,
    maxWidth: 85,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 10,
    },
  },
  headingOver: {
    fontSize: "2.5em",
    lineHeight: "1.125em",
    color: variables.ncgBlue3,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  // OFFICE LOCATIONS
  locationsContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  locationCell: {
    padding: 25,
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: "25px 25px 25px 0px",
    },
  },
  listAddress: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
  },
  listAddressItem: {
    padding: "3px 0px",
    fontSize: 13,
    lineHeight: "1.1em",
  },
  // TEAM
  teamMemberName: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  nameHeader: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 50,
    marginTop: 20,
  },
  profileLink: {
    color: "#2773bd",
    fontWeight: 300,
    display: "flex",
    alignItems: "center",
    lineHeight: "normal",
    marginTop: 10,
  },
  overviewIcon: {
    fontSize: "1.2em",
    marginRight: 5,
  },
}));

const AboutPage = () => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openNav = (event) => {
    setOpen(true);
  }
  const closeNav = () => {
    setOpen(false);
  }
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={aboutBanner}>
        About NCG</Banner>
      <PageWrapper >
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={3} md={3} lg={3} className={classes.sideBar}>
            <div className={classes.sideBarWrapper}>
              <Typography variant="h5" className={classes.sideBarH5}>Career Opportunities</Typography>
              <Typography variant="body2" className={classes.sideBarBody}>We are currently looking for experienced developers and consultants!</Typography>
              <LinkDefault variant="defaultMini" href="/about-ncg/career-opportunities/careers">Learn More About NCG Careers</LinkDefault>
              <Typography variant="h5" className={classes.sideBarH5}>Team Information</Typography>
              <LinkDefault variant="drawerLeft" className={classes.profileLink}
                disableElevation
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => openNav()}> Profile Overview</LinkDefault>
              <Drawer
                anchor='left'
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={closeNav}
              >
                <MenuList
                  className={classes.drawerList}
                >
                  <Typography variant="h5" className={classes.sideBarH5}>Team Profile</Typography>
                  <ul className={classes.sideBarList}>
                    <li className={classes.sideBarListItem}>
                      Private, Federal, and Defense Management Experience
                    </li>
                    <li className={classes.sideBarListItem}>
                      Data Analysis and Assessment Expertise
                    </li>
                    <li className={classes.sideBarListItem}>
                      System Design, Development, and Implementation Expertise
                    </li>
                    <li className={classes.sideBarListItem}>
                      Enterprise and Mission Critical System Operations Experience
                    </li>
                    <li className={classes.sideBarListItem}>
                      Business and Technology Integration Expertise
                    </li>
                    <li className={classes.sideBarListItem}>
                      SBA 8(a) Certified
                    </li>
                  </ul>
                </MenuList>
              </Drawer>
            </div>
            <div className={classNames(classes.sideBarWrapperTeam)}>
              <Typography variant="h5" className={classes.sideBarH5}>Leadership Team</Typography>
              <ul className={classes.sideBarTeamList}>
                <li className={classes.memberItem}>
                  <Link href="#bender" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.bender)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        Chris Bender
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        President
                      </Typography>
                    </div>
                  </Link>
                </li>
                <li className={classes.memberItem}>
                  <Link href="#wininger" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.wininger)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        David Wininger
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        Chief Technology Officer
                      </Typography>
                    </div>
                  </Link>
                </li>
                <li className={classes.memberItem}>
                  <Link href="#milstead" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.milstead)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        Wayne Milstead
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        Client Services Director
                      </Typography>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid xs={12} sm={9} md={9} lg={9} className={classes.mainContentWrapper}>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.introSection, classes.pageSectionStriped)}>
              <Typography variant="h2">
                Overview
              </Typography>
              <Typography variant="body1">
                Since 2006, Northcross Group (NCG) has been empowering its clients to use technology to improve operations, expand market positions, and increase revenues. NCG consultants have decades of experience and achieve tangible results using a collaborative and responsive approach to problem-solving. Team members have contract experience at federal and state levels as well as civilian, defense, and intelligence clearances. Consequently, NCG consultants have the perspective to assess needs, risks, and issues; the skills to prioritize work items; and the expertise to deliver results.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/about-ncg/corporate-background">Learn more about our Corporate Background</LinkDefault>
              </div>
            </Grid>

            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <Grid item md={12} lg={12} className={classes.headerWrapper}>
                <Grid item md={2} lg={2} className={classes.logoWrapper}>
                  <ContentContainer variant="customWidth">
                    <Image url={nmuLogo} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Grid item md={9} lg={9} className={classes.header}>
                  <Typography variant="h3" className={classes.noMargin}>
                    NCG Academic Partnership with the Upper Peninsula Cybersecurity Institute at Northern Michigan University
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1">
                NCG has an Academic Partnership with Northern Michigan University's Upper Peninsula Cybersecurity Institute. This partnership began in September 2020 and is focused on helping develop the next generation of cybersecurity professionals with NCG's business centric approach to cybersecurity that tailors efforts to a client's industry, size, and complexity.
              </Typography>

              <div className={classes.linkWrapper}>
                <LinkDefault href="/nmu-partnership">Learn more about our Academic Partnership</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <Typography variant="h2" className={classes.headingOver}>
                NCG - Office Locations
              </Typography>
              <Grid xs={12} sm={11} md={11} lg={11} className={classes.locationsContainer}>
                <Grid lg={4} className={classes.locationCell}>
                  <Typography variant="h5" className={classes.headingUnder}>
                    PORTLAND, MAINE OFFICE
                  </Typography>
                  <Typography variant="body1">
                    The NCG headquarters are located in
                    Portland, Maine on 100 Middle Street.
                  </Typography>
                  <ul className={classes.listAddress}>
                    <li className={classes.listAddressItem}>
                      100 Middle St.
                    </li>
                    <li className={classes.listAddressItem}>
                      East Tower, #203
                    </li>
                    <li className={classes.listAddressItem}>
                      Portland, ME 04101
                    </li>
                    <li className={classes.listAddressItem}>
                      207.699.5540
                    </li>
                    <li className={classes.listAddressItem}>
                      FAX 207.699.2113
                    </li>
                  </ul>
                </Grid>
                <Grid lg={4} className={classes.locationCell}>
                  <Typography variant="h5" className={classes.headingUnder}>
                    DC METRO OFFICE
                  </Typography>
                  <Typography variant="body1">
                    NCG has an office located in Arlington, VA on Wilson Boulevard.
                  </Typography>
                  <ul className={classes.listAddress}>
                    <li className={classes.listAddressItem}>
                      1101 Wilson Blvd.
                    </li>
                    <li className={classes.listAddressItem}>
                      6th Floor
                    </li>
                    <li className={classes.listAddressItem}>
                      Arlington, VA 22209
                    </li>
                    <li className={classes.listAddressItem}>
                      703.351.3397
                    </li>
                    <li className={classes.listAddressItem}>
                      FAX 703.351.5298
                    </li>
                  </ul>
                </Grid>
                <Grid lg={4} className={classes.locationCell}>
                  <Typography variant="h5" className={classes.headingUnder}>
                    MARQUETTE, MI OFFICE
                  </Typography>
                  <Typography variant="body1">
                    NCG has an office located in Marquette, MI on Washington Street.
                  </Typography>
                  <ul className={classes.listAddress}>
                    <li className={classes.listAddressItem}>
                      101 W. Washington St.
                    </li>
                    <li className={classes.listAddressItem}>
                      Suite #4
                    </li>
                    <li className={classes.listAddressItem}>
                      Marquette, MI 49855
                    </li>
                    <li className={classes.listAddressItem}>
                      906.451.5910
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/about-ncg/contact">View NCG Office Locations</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <Typography variant="h2" className={classes.marginBottom}>
                Leadership Team
              </Typography>
              <div className={classes.teamMemberName} id="bender">
                <Grid item lg={2} className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={chrisBender} variant="customWidth" width={"100%"} />

                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  Christopher Bender
                </Typography>
              </div>
              <Typography variant="body1">
                Christopher Bender has accumulated over twenty-two years of management and technology consulting experience, specializing in efficiency, profits, agility, and corporate responsibility in both the public and private sectors. As the founder of NCG, he has built a team of experts that unite business and technology to deliver results and value.
              </Typography>
              <Typography variant="body1">
                Mr. Bender worked with mainframe and midtier computing until he transitioned to internet-based technology in the early 1990s at which point he began to focus on data-driven systems and decision-making. He has executed project management efforts in excess of $30 million per year and mergers and integrations as large as $40 billion per year. Additionally, he has served as the lead architect for multiple enterprise-level system developments. Mr. Bender has a Master of Science in Management Information Systems and a BA in Economics from George Washington University.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <div className={classes.teamMemberName} id="wininger">
                <Grid item lg={2} className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={davidWininger} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  David Wininger
                </Typography>
              </div>
              <Typography variant="body1">
                David Wininger is the Chief Technology Officer of Northcross Group. He has gained notoriety in the public and private sectors as an expert and seasoned practitioner of systems implementation, business continuity planning, disaster recover, operations, and security. He has held executive positions in private firms and in the federal government, maintaining a hands-on command of current technologies and management practices.
              </Typography>
              <Typography variant="body1">
                Mr. Wininger began his career with the Marine Corps in 1971. After twenty-one years of service, he worked as the Director of Special Operations Engineering program, managing the development and operational testing of small arms and automatic weapons. Before working at NCG, Mr. Wininger also served as the Director of Information Technology for the U.S. Strategic Weapons Program, including time as the Director of the Space and Special Welfare Systems Command Information Technology. Mr. Wininger received a BS in Engineering from the University of Maryland and a Master of Science in Computer and Electrical Engineering from Johns Hopkins University. He also graduated from the Federal Law Enforcement Training Center in Glynco, Georgia.
              </Typography>
            </Grid>
            <Grid itemxs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <div className={classes.teamMemberName} id="milstead">
                <Grid item lg={2} className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={wayneMilstead} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  Wayne Milstead
                </Typography>
              </div>
              <Typography variant="body1">
                Wayne Milstead has over twenty years of experience delivering business operations, systems, communications, and training solutions to a range of clients in both the private and public sectors. Mr. Milstead has managed service delivery on both project and enterprise levels to diverse clients including the Federal Aviation Administration, National Highway Transportation Safety Administration, Turner Broadcasting/CNN, Time Warner, public service nonprofits, and arts organizations. Clients recognize his value as a communicator with strong analytical and technical skills, valuing his ability to articulate and implement solutions for his clients. Mr. Milstead also works within Northcross Group to enable all levels of staff to own and articulate organizational goals so that they can approach challenges with creativity, passion, and confidence.
              </Typography>
              <Typography variant="body1">
                Mr. Milstead has a background in journalism, communications, consulting and law. He holds a BA in dramatic literature from George Washington University and a JD from Catholic University.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default AboutPage;