import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  contentContainer: props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    height: props.height,
  }),
  contentContainerCustomWidth: props => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: props.width,
    height: "auto",
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  }),
}));

const ContentContainer = (props) => {
  const classes = useStyles(props);

if (props.variant === "customWidth") {
  return (
    <div className={classes.contentContainerCustomWidth}>
      {props.children}
    </div>
  );
}
else {
  return (
    <div className={classes.contentContainer}>
      {props.children}
    </div>
  )
}
}

export default ContentContainer;