import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import variables from "../../style/variables.module.scss";
import classNames from "classnames";
// IMAGES
import DoubleArrowIcon from "../../images/images/icons/doubleArrowIcon.png";
import DownloadIcon from "../../images/images/icons/downloadIcon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    display: "inline-flex",
    alignItems: "center",
    marginTop: 10,
    width: "fit-content",
    whiteSpace: "pre-wrap",
  },
  linkWrapperIconRight: {
    paddingRight: 25,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  linkWrapperIconRightMini: {
    paddingRight: 19,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 15,
    },
  },
  linkText: {
    color: variables.ncgBlue3,
    padding: 0,
    lineHeight: "1.7em",
    fontFamily: "Helvetica Neue, Helvetica",
    fontWeight: 500,
    position: "relative",
    fontSize: 15,
    '&:hover:hover': {
      color: variables.ncgBlue2,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1em",
    },
    '&::after': {
      display: "inline-block",
      content: '""',
      height: 15,
      width: 15,
      backgroundImage: "url(" + DoubleArrowIcon + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0px center",
      position: "absolute",
      marginLeft: 8,
      bottom: 4,
      marginTop: 2,
    },
  },
  linkTextMini: {
    fontSize: ".9em",
    '&::after': {
      height: 13,
      width: 13,
      marginLeft: 5,
      bottom: 4,
    },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: ".8em",
    // },
  },
  linkTextDrawer: {
    color: variables.ncgBlue2,
    lineHeight: "normal",
    '&::after': {
      display: "none",
    },
  },
  linkTextBefore: {
    paddingLeft: 30,
    '&::after': {
      display: "none",
    },
  },
  linkTextBack: {
    '&::before': {
      display: "inline-block",
      content: '""',
      height: 15,
      width: 15,
      backgroundImage: "url(" + DoubleArrowIcon + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0px center",
      position: "absolute",
      transform: "scaleX(-1)",
      left: 5,
      bottom: 5,
      marginTop: 2,
    },
  },
  downloadLinkText: {
    color: variables.ncgBlue1,
    '&::after': {
      display: "inline-block",
      content: '""',
      height: 15,
      width: 15,
      backgroundImage: "url(" + DownloadIcon + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0px center",
      position: "absolute",
      marginLeft: 8,
      bottom: 5,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1em",

      '&::after': {
        height: 13,
        width: 13,
        marginLeft: 7,
        bottom: 6,
      },
    },
  },
  downloadLinkTextMini: {
    fontSize: "1em",
    '&::after': {
      height: 13,
      width: 13,
      marginLeft: 5,
      bottom: 6,
    },
    [theme.breakpoints.down('sm')]: {
      '&::after': {
        height: 10,
        width: 10,
        marginLeft: 5,
        bottom: 6,
      },
    },
  },
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    marginTop: 2,
  },
  downloadLinkIcon: {
    fontSize: "1.2rem",
  },
  drawerLeftIcon: {
    fontSize: "1.1em",
    marginRight: 5,
    color: variables.ncgBlue2,
    opacity: .5,
  },
}));

const LinkDefault = (props) => {
  const classes = useStyles();
  if (props.variant === "download") {
    return (
      <Link href={props.href} target={props.target} className={classNames(classes.linkWrapper, classes.linkWrapperIconRight)}>
        <Typography variant="body1" className={classNames(classes.linkText, classes.downloadLinkText)}>
          {props.children}
        </Typography>
      </Link>
    );
  }
  if (props.variant === "downloadMini") {
    return (
      <Link href={props.href} target={props.target} className={classNames(classes.linkWrapper, classes.linkWrapperIconRightMini)}>
        <Typography variant="body1" className={classNames(classes.linkText, classes.downloadLinkText, classes.downloadLinkTextMini)}>
          {props.children}
        </Typography>
      </Link>
    );
  }
  if (props.variant === "defaultMini") {
    return (
      <Link href={props.href} target={props.target} className={classNames(classes.linkWrapper, classes.linkWrapperIconRight)}>
        <Typography variant="body1" className={classNames(classes.linkText, classes.linkTextMini)}>
          {props.children}
        </Typography>
      </Link>
    );
  }
  if (props.variant === "defaultBack") {
    return (
      <Link href={props.href} target={props.target} className={classNames(classes.linkWrapper)}>
        <Typography variant="body1" className={classNames(classes.linkText, classes.linkTextBefore, classes.linkTextBack)}>
          {props.children}
        </Typography>
      </Link>
    );
  }
  if (props.variant === "drawerLeft") {
    return (
      <Link href={props.href} target={props.target} onClick={props.onClick} className={classNames(classes.linkWrapper)}>
        <FontAwesomeIcon icon={faChevronCircleLeft} className={classes.drawerLeftIcon} />
        <Typography variant="body1" className={classNames(classes.linkText, classes.linkTextMini, classes.linkTextDrawer)}>
          {props.children}
        </Typography>
      </Link>
    );
  }
  else {
    return (
      <Link href={props.href} target={props.target} className={classes.linkWrapper}>
        <Typography variant="body1" className={classNames(classes.linkText)}>
          {props.children}
        </Typography>
      </Link>
    )
  }
};

export default LinkDefault;
