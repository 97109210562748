import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import commercialBanner from "../../../images/images/banners/commercial.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { faTractor } from '@fortawesome/free-solid-svg-icons'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'


const useStyles = makeStyles((theme) => ({
// SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%!important",
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    paddingRight: 20,
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      alignItems: "center",
    },
  },
  sideBarListItemNoBullets: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 0,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
    listStyleType: "none",
    [theme.breakpoints.down('xs')]: {
      maxWidth: "unset",
    },
  },
  // MAIN CONTENT
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    },
  },
  introSection: {
    paddingTop: 80,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 410,
      textAlign: "center",
    },
  },
  intro: {
    color: variables.textSecondary,
    fontStyle: "italic",
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  pageSectionStriped: {
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(even)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none!important;",
    },
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  textWrapper: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: "#b9cad0",
    fontSize: "90px",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  cybersecurityImage: {
    maxWidth: 200,
  },
  developmentImage: {
    maxWidth: 200,
  },
  pageSectionLinkWrapper: {
    paddingLeft: 20,
    display: "block",
    maxWidth: 550,
  },
}));

const CommercialSectorPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={commercialBanner}>
        Commercial Sector</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/markets" variant="link">Markets</BreadcrumbItem>
          <BreadcrumbItem href="/markets/commercial-services" variant="active">Commercial Sector</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={3} md={3} lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>Service Cards</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Banking_Services_2014.pdf">Financial Services</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Cybersecurity_Services.pdf">Cybersecurity</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Core_Blueprinting.pdf">Core Blueprinting</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_HC_InfoTech_092014.pdf">Healthcare Information Security</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Your_Analytics_Plan.pdf">Utility Analytics</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Manufacturing_Cyber_Workshop.pdf">Manufacturing Cyber Workshop</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Int_Sec_Transport_HL_2014.pdf">Transportation</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault target="_blank" variant="downloadMini" href="../../../../content/docs/ServiceCards/NCG_Prof_Services_Capabilities_2014.pdf">Information Technology</LinkDefault>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} className={classes.introSection}>
            <Typography variant="h3" className={classes.intro}>
              Working with industries that are highly regulated and have critical service and security requirements for their customers.
            </Typography>
          </Grid>
        </Container>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={12} sm={6} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Financial Services
              </Typography>
              <Typography variant="body1">
                NCG has extensive experience in a range of core financial systems and payment processing. Our team has operational, accounting, and investigation expertise that we bring to support banking programs, mergers and acquisitions, integration efforts, risk management, and cybersecurity. NCG is a contributing member of the Banking Industry Architecture Network (BIAN) and participates in the Linux Foundation Hyper Ledger initiative.
              </Typography>
              <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Banking_Services_2014.pdf">Download our Financial Service Card</LinkDefault>
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={10} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Healthcare
              </Typography>
              <Typography variant="body1">
                NCG works with the healthcare industry supporting cybersecurity, HIPAA Meaningful Use compliance, mergers and acquisitions, and the full revenue cycle. Our team has significant experience with CMS regulations and third-party payer processes.
              </Typography>
              <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_HC_InfoTech_092014.pdf">Download our Healthcare Security Service Card</LinkDefault>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faUserMd} className={classes.icon} />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={10} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Energy
              </Typography>
              <Typography variant="body1">
                NCG works with the energy sector leveraging our expertise in business, SCADA, and other control systems. With experience in system safety engineering and analytics, we support cybersecurity, integration, and data analytics.
              </Typography>
              <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Your_Analytics_Plan.pdf">Download our Utility Analytics Service Card</LinkDefault>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faLightbulb} className={classes.icon} />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={10} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Manufacturing
              </Typography>
              <Typography variant="body1">
                NCG works with manufacturers integrating new data processing and enterprise systems that interface with plant and controls systems. We provide cybersecurity, integration, and analytics support.
              </Typography>
              <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Manufacturing_Cyber_Workshop.pdf">Download our Manufacturing Workshop Service Card</LinkDefault>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faWrench} className={classes.icon} />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={10} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Transportation
              </Typography>
              <Typography variant="body1">
                With experience in aviation and intermodal surface transportation, NCG brings its cybersecurity, integration, and analytics skills to operators, carriers, and agencies.
              </Typography>
              <LinkDefault target="_blank" variant="download" href="../../../../content/docs/ServiceCards/NCG_Int_Sec_Transport_HL_2014.pdf">Download our Transportation Card</LinkDefault>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faTruck} className={classes.icon} />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={10} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Agriculture
              </Typography>
              <Typography variant="body1">
                With experience in food safety, NCG brings its cybersecurity, integration, and analytics skills to producers, processors and distributors.
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faTractor} className={classes.icon} />
            </Grid>
          </Container>
        </div>
        <div className={classNames(classes.pageSection, classes.pageSectionStriped)}>
          <Container maxWidth="lg" className={classes.pageSectionWrapper}>
            <Grid item xs={10} sm={8} md={8} lg={8} className={classes.textWrapper}>
              <Typography variant="h2">
                Information Technology
              </Typography>
              <Typography variant="body1">
                With network, data center, and system expertise, NCG provides a full range of development, operational, and hardening services in the IT industry.
              </Typography>
              <LinkDefault variant="download" target="_blank" href="../../../../content/docs/ServiceCards/NCG_Prof_Services_Capabilities_2014.pdf">Download our Technology and Security Service Card</LinkDefault>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faDesktop} className={classes.icon} />
            </Grid>
          </Container>
        </div>
      </PageWrapper>
    </>
  )
}

export default CommercialSectorPage;