import { makeStyles } from "@material-ui/core/styles";
import slide2Image from "../../images/images/index/featured/two/cmmc-orchestration-spotlight.jpg";
import slide3Image from "../../images/images/index/featured/three/privacy-services.jpg";
import slide4Image from "../../images/images/index/featured/four/rms.jpg";
import slide5Image from "../../images/images/index/featured/five/imp.jpg";
import slide6Image from "../../images/images/index/featured/six/sms.jpg";
import slide1Image from "../../images/images/index/featured/grcMainCard.png";
import slide7Image from "../../images/microsite/educationFoundation/splashGraphic.png";
import devOpsImage from "../../images/images/microsite/devops/splash/splash-v2.jpg"
import ServiceCard from "./serviceCard.component";
import ButtonCustom from "./buttonCustom.component";
import { Grid, Link, Typography } from "@material-ui/core";
import classNames from "classnames";
import nmuLogo from "../../images/images/nmu-partnership/upci-logo.png"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center"      
    },
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: "auto",
      padding: `0 ${50 - 24}px`,
      maxWidth: 400,
    }
  },
  nmuLogo: {
    width: "120px",
    paddingTop: 16,
    paddingBottom: 16
  },
  partnershipContainer:{
    boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.16)",
    borderRadius: 5,
    height: "420px",
    margin: "0px",
    textAlign: "center",
    background: "rgba(0,0,0,0.03)",
    borderTop: "5px solid #095339",
    "&:hover": {
      boxShadow:  "0px 2px 4px 1px rgba(9, 83, 57, 0.36)",
    },
  },
  noMargin: {
    marginTop: -20,
    marginBottom: -30
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center"
  },
  cardButton: {
    height: "40px",
    position: "relative",
    bottom: 13
  },
  paddingNMU:{
    paddingLeft: 35,
    paddingRight: 35,
  }

}))
const MainServiceCards = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.cardContainer}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Governance, Risk, & Compliance"
            description="Building, enhancing, and operating Governance, Risk, and Compliance Programs"
            image={slide1Image}
            link="/grc"
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="DevOps Implementation & Enhancements"
            description="Tooling and automation to better manage and secure development and operations in the Cloud"
            image={devOpsImage}
            link="/devops"
            imageBottomMargin
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Cybersecurity Maturity Model Certification"
            description="Help your organization implement and be prepared for certification"
            image={slide2Image}
            link="/capabilities/cmmc"
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Privacy Programs & Assessment Services"
            description="Program development and Privacy Impact Assessment"
            image={slide3Image}
            link="/capabilities/privacy-services"
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Risk Management & Compliance"
            description="Protect systems and information with our Risk Management Utilities"
            image={slide4Image}
            link="/capabilities/risk-management"
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Tech & Cyber Skills & Education"
            description="Work with k-12, college, and post-graduate students and educators"
            image={slide7Image}
            link="/education"
            imageMargin
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Incident Management & Recovery"
            description="Prepare, identify, and recover incidents with our Incident Management framework"
            image={slide5Image}
            link="/capabilities/incident-management"
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <ServiceCard
            title="Aviation Safety Management Systems"
            description="Empowering your team to be more effective in meeting SMS requirements and goals"
            image={slide6Image}
            link="/capabilities/sms"
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Link
            color="inherit"
            href="/nmu-partnership"
          >
            <Grid container
              className={classes.partnershipContainer}
            >
              <Grid item xs={12}>
                <img src={nmuLogo} className={classes.nmuLogo} alt="partnershipLogo" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.noMargin}>
                Northcross Group
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.noMargin}>
                <Typography variant="h4">
                  +
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.paddingNMU}>
                <Typography variant="h4">
                  Upper Peninsula Cybersecurity Institute at NMU
                </Typography>
              </Grid>
              <Grid item xs={12}
                className={classNames(classes.center, classes.cardButton)}
              >
                <ButtonCustom
                  href="/nmu-partnership"
                  typeOf="smallButtonWhite"
                >
                  Learn More
                </ButtonCustom>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default MainServiceCards;
