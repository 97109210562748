import React, {useEffect, useState} from "react";

//images
import prepareLarge from "images/images/microsite/imp/icons/prepare-large.png"
import manageLarge from "images/images/microsite/imp/icons/manage-large.png"
import toolLarge from "images/images/microsite/imp/icons/tools-large.png"
import bcpLarge from "images/images/microsite/imp/icons/bcp-dr-large.png"
import prepare from "images/images/microsite/imp/icons/prepare.png"
import proactive from "images/images/microsite/imp/prepare/be-proactive.png"
import prepareRespond from "images/images/microsite/imp/prepare/prepare-respond-collab.png"
import assessment from "images/images/microsite/imp/prepare/current-state-assessment.png"
import improvementRoadmap from "images/images/microsite/imp/prepare/improvement-roadmap.png"
import stateAssessmentLine from "images/images/microsite/imp/prepare/state-assessment-infographic-line.png"
import stateAssessment from "images/images/microsite/imp/prepare/state-assessment-infographic.png"
import manage from "images/images/microsite/imp/icons/manage.png"
import connectorLong from "images/images/microsite/imp/manage/connector-first-left-long.png"
import connector from "images/images/microsite/imp/manage/connector-first-left.png"
import connector2 from "images/images/microsite/imp/manage/connector-second-right.png"
import connector2Right from "images/images/microsite/imp/manage/connector-second-right-mobile.png"
import connector3Left from "images/images/microsite/imp/manage/connector-third-left.png"
import measureEff from "images/images/microsite/imp/manage/measure-effectiveness-comp.png"
import improveComp from "images/images/microsite/imp/manage/improve-comp.png"
import importal from "images/images/microsite/imp/logos/importal-logo.png"
import tool from "images/images/microsite/imp/icons/tools.png"
import importalDevice from "images/images/microsite/imp/tools/importal-device-display.png"
import toolsMinimize from "images/images/microsite/imp/tools/tools-minimize-impact-mobile.png"
import toolEngage from "images/images/microsite/imp/tools/tools-engage-mobile.png"
import toolSupport from "images/images/microsite/imp/tools/tools-support-mobile.png"
import fullIncident from "images/images/microsite/imp/tools/full-incident-reporting-comp.png"
import simpleIncident from "images/images/microsite/imp/tools/simple-incident-reporting-comp.png"
import dcpDr from "images/images/microsite/imp/icons/bcp-dr.png"
import businessTreat from "images/images/microsite/imp/bcp-dr/business-threat-toolkit.png"
import impShield from "images/images/microsite/imp/bcp-dr/imp-shield.png"

import IncidentManagementNavBar from "./incidentManagementNavBar";

//carousel
import IncidentManagementCarousel from "./IncidentManagementCarousel"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const IncidentManagementMicrosite = (props) => {
  const [elementId, setElementId] = useState("");
  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  })


  return (
    <>
    <IncidentManagementNavBar elementInView={elementId}/>
      <BackToTopCustom />
      <IncidentManagementCarousel />
      <div class="imp-micro">
        <div class="bootstrap">
          <div class="crunch">
            <div class="micro-new">
              <div class="micro">
                <html lang="en">
                  <body class="preload microsite microsite-imp microsite-new microsite-imp-desktop" data-spy="scroll" data-target="#navbarCollapse" data-offset="50">
                    <div class="page-wrap">
                      <div class="page-section page-section--site-layout col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="mainpage-wrapper">
                          <div class="tile-collection-wrap">
                            <div class="tile-collection">
                              <div id="tilePrepare" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h4>Incident<br />Preparedness</h4>
                                  <div class="tile--graphic">
                                    <img src={prepareLarge} class="img-responsive graphic" alt="Incident Preparedness" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Ensure your company & people are prepared to handle incidents</p>
                                  </div>
                                </div>
                              </div>

                              <div id="tileManage" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h4>Incident <br />Management</h4>
                                  <div class="tile--graphic">
                                    <img src={manageLarge} alt="Incident Management" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Cybersecurity & management expertise to recover from incidents</p>
                                  </div>
                                </div>
                              </div>

                              <div id="tileTools" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h4>Incident <br />Response Tools</h4>
                                  <div class="tile--graphic">
                                    <img src={toolLarge} alt="Incident Response Tool" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Cloud based tools for team coordination, communication & collaboration</p>
                                  </div>
                                </div>
                              </div>

                              <div id="tileBcp-dr" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h4>Business Continuity & Disaster Recovery</h4>
                                  <div class="tile--graphic">
                                    <img src={bcpLarge} alt="Business Continuity & Disaster Recovery" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Business Continuity Planning and Disaster Recovery (BCP/DR)</p>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="prepare" class="page-section page-section--prepare col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={prepare} alt="Preparedness" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Preparedness: <span class="header-tagline">Empower Your Team for Success</span></h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--proactive col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <div class="proactive-graphic col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <img src={proactive} alt="Be proactive" class="img-responsive graphic" />
                          </div>
                          <div class="be-proactive col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <div class="text-wrapper">
                              <h4>Be <span class="prepare-blue">proactive.</span></h4>
                            </div>
                          </div>
                          <div class="people-working"></div>
                          <div class="blue-banner">
                            <div class="middle-of-incident col-xxlg-4 col-xlg-5 col-lg-5 col-md-7 col-sm-10 col-xs-10">
                              <span>
                                <h4>The middle of an incident is not when you want your people figuring out what to do and how to do it. Having established
                                processes and tools to help people work together and capture information to
                                communicate effectively is key to
                                reducing recovery time and minimizing the effort and cost of dealing with incidents.
                  </h4>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--respond">
                          <div class="mainpage-wrapper">
                            <div class="prepare-your-team col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="text-wrapper col-xxlg-12 col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h3>Make sure your team is ready to effectively<br />
                                  <span class="indent">deal with and recover from incidents.</span></h3>
                              </div>
                              <div class="prepare-respond-collab">
                                <img src={prepareRespond} alt="Prepare Respond" class="img-responsive graphic" />
                              </div>
                            </div>
                          </div>
                          <div class="blue-banner">
                            <div class="banner-text col-xxlg-4 col-xlg-4 col-lg-4 col-md-11 col-sm-11 col-xs-12">
                              <h4>NCG incident management
                              services help empower your people to be better
                              prepared to handle incidents.
                </h4>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--assess-improve col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="mainpage-wrapper">
                            <div class="tile-container">
                              <div class="tile-collection-wrap">
                                <div class="tile-collection">
                                  <div class="tile tile--shadow">
                                    <div class="tile-wrap">
                                      <h4>Current State <br />Assessment</h4>
                                      <div class="tile--graphic">
                                        <img src={assessment} alt="Current State Assessment" class="img-responsive graphic" />
                                      </div>
                                      <div class="tile--body">
                                        <p>Understand your current risk posture and where you have exposure—across systems, third parties, and operations.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="tile tile--shadow">
                                    <div class="tile-wrap">
                                      <h4>Improvement<br />Roadmap</h4>
                                      <div class="tile--graphic">
                                        <img src={improvementRoadmap} alt="Improvement Roadmap" class="img-responsive graphic" />
                                      </div>
                                      <div class="tile--body">
                                        <p>A prioritized listing of steps for improvements across technical, business, and compliance areas.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--state-assessment col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="state-assessment-info-wrapper col-xxlg-5 col-xlg-5 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="state-assessment-info">
                              <h3>The Current State <br />Assessment</h3>
                              <p>Our methodology focuses on getting the information we need to provide insight with minimal disruption to your team.</p>
                            </div>
                          </div>
                          <div class="assessment-infographic col-xlg-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            {/* <img src={stateAssessmentXL} alt="Current State Assessment" class="img-responsive graphic image-xlarge" /> */}
                            <img src={stateAssessmentLine} alt="Current State Assessment" class="img-responsive graphic image-large" />
                            <img src={stateAssessment} alt="Current State Assessment" class="img-responsive graphic image-small" />
                          </div>
                        </div>
                        <div class="page-section page-section--improvement-roadmap col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="gradient">
                            <div class="mainpage-wrapper">
                              <div class="improvement-roadmap-info">
                                <h3>Improvement Roadmap</h3>
                                <p>We use the assessment findings to develop a Roadmap of items
                                for consideration. Our methodology includes prioritization of
                                these items and a forecast of the effort to complete them.
                            <br /><br />
                            Some items will be critical items to address as soon as possible. Others can be combined with other efforts over time.
                            Roadmap items will also provide considerations that
                            may be addressed as the organization grows and expands.
                            <br /><br />
                            NCG cybersecurity and integration experts can help your
                            organization implement Roadmap items aligned with business
                            investment decisions and timing that makes sense
                            for the organization.
                  </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="manage" class="page-section page-section--manage col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={manage} alt="Management Plan Your Incident Response" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Management: <span class="header-tagline">Plan Your Incident Response</span></h2>
                            </div>
                          </div>
                        </div>

                        <div class="page-section parallax mountain col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="page-section page-section--incident-management section-one col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="col-lg-6 col-md-7 col-sm-10 col-xs-12">
                                <span>
                                  <h3>Incident Management</h3>
                                  <p>Frameworks to address incidents when they happen with consistency
                                  and facilitate effective coordination and collaboration between
                                  internal and external parties.
                    </p>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="page-section page-section--incident-management section-two col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="float-right text-container col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div class="events">
                                  <p class="col-lg-11 col-md-11 col-sm-11 col-xs-12">Events that impact your operations can range from
                                  simple errors, to malicious attacks, or natural disasters.
                            </p>
                                  <span class="big">These events test your people.</span>
                                  <p>Stress levels rise, some day-to-day processes may no longer be
                                  applicable, and the needs for command and control are never greater.
                                <br /><br />
                                NCG helps your team manage through incidents with
                                proven methodologies and cybersecurity expertise.
                                We help prepare you for major events such as platform migrations, new deployments,
                                and data conversions where incidents are to be expected.
                    </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="page-section parallax arrows col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="page-section page-section--incident-support section-one col-lg-5 col-md-5 col-sm-5 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="col-lg-6 col-md-7 col-sm-10 col-xs-12">
                                <span>
                                  <h3>Incident Support</h3><br/>
                                  <p>Effective incident support coordinates your incident management response, memorializes
                                incident details and evidence chains, and organizes your information for lessons learned and continuous improvement.</p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="page-section page-section--incident-support section-two col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="purple-banner col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="mainpage-wrapper">
                                <div class="col-lg-6 col-md-7 col-sm-8 col-xs-12">
                                  <span>
                                  <p>Data breaches and other system incidents require a number of different groups to work together. </p>
                                <br/>
                                <p>The ability to engage the right people, effectively direct activities, and coordinate actions
                                    are important for all organizations. Efforts need to be measured and evaluated to identify future
                                    improvements and ensure personnel have the right training and skills to be effective.
                                </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section parallax roles col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="page-section page-section--incident-roles section-two col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="float-right text-container col-lg-9 col-md-9 col-sm-11 col-xs-11">
                                <div class="list-container roles-responsibilities">
                                  <span class="icon"></span>
                                  <div>
                                    <h5>Defined Roles and Responsibilities</h5>
                                    <p>Well defined roles and responsibilities manage expectations on all sides
                                    and clarify what people should be doing and what their accountability
                                    is in a given scenario.
                      </p>
                                  </div>
                                </div>
                                <div class="list-container escalation">
                                  <span class="icon"></span>
                                  <div>
                                    <h5>Defined Escalation Processes</h5>
                                    <p>Establish a standard process for escalation that is
                                    recognized and understood across the organization.
                      </p>
                                  </div>
                                </div>
                                <div class="list-container communication">
                                  <span class="icon"></span>
                                  <div>
                                    <h5>Defined Communications Procedures</h5>
                                    <p>Establish a framework and controls to ensure proper
                                    communication and avoid mismanagement of information.
                      </p>
                                  </div>
                                </div>
                                <div class="list-container restore">
                                  <span class="icon"></span>
                                  <div>
                                    <h5>Managed Process to Restore Normalcy</h5>
                                    <p>Establish processes to support a graceful return to normal
                                    operations and minimize any additional service disruption
                                    in that process.
                      </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--connect col-lg-12 col-md-12 col-sm-12 col-xs-12">

                          <div class="first-connector col-lg-3 col-md-4 col-sm-4 col-xs-6">
                            <img src={connectorLong} alt="Connecting Technical" class="img-responsive graphic image-small" />
                            <img src={connector} alt="Connecting Technical" class="img-responsive graphic image-large" />
                          </div>
                          <div class="right-section first">
                            <div class="square right">
                              <div class="right column col-lg-8 col-md-11 col-sm-12 col-xs-12">
                                <div class="connecting">
                                  <h3>Connecting Technical, Business,<br />
                                and Compliance Needs</h3><br />
                                  <p>Response and recovery actions will have technical system level actions that will need to be supported by
                                  communications and reporting functions that are able to engage parties from
                                  law enforcement, regulators, shareholders, key partners, media, and the general public.
                                  Information that covers technical and business level details will need to be organized and managed to meet the needs of all those parties.
                    </p>
                                </div>
                              </div>
                            </div>
                            <span class="second-connector col-lg-8 col-md-9 col-sm-8 col-xs-9">
                              <img src={connector2} alt="Connecting Technical" class="img-responsive graphic large" />
                            </span>
                          </div>
                          <div class="left-section">
                            <div class="square left">
                              <div class="left column col-lg-8 col-md-11 col-sm-12 col-xs-12">
                                <div class="bridging">
                                  <h3>Bridging Between Different <br />
                                Areas of the Organization</h3><br />
                                  <p>Organizations must address differences in how people across different parts of the organization communicate and work.
                                  This helps avoid gaps between groups such as IT, management, lines of business, and third parties.
                    </p>
                                </div>
                              </div>
                            </div>
                            <span class="second-connector mobile col-lg-8 col-md-9 col-sm-8 col-xs-9">
                              <img src={connector2Right} alt="Connecting Technical" class="img-responsive graphic small" />
                            </span>
                            <span class="last-connector col-xlg-3 col-lg-5 col-md-6 col-sm-6 col-xs-8">
                              <img src={connector3Left} alt="Connecting Technical" class="img-responsive graphic" />
                            </span>
                          </div>
                          <div class="right-section">
                            <div class="square right last">
                              <div class="right column col-lg-8 col-md-11 col-sm-12 col-xs-12">
                                <div class="data-management">
                                  <h3>Data Management Frameworks</h3><br />
                                  <p>Define expectations to collect the necessary amount of data and other memorialization efforts to support coordination with law enforcement, compliance investigations, and legal teams.
                    </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--evolve col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="content-container">
                            <div class="mainpage-wrapper col-xxlg-5 col-xlg-7 col-lg-7 col-md-9 col-sm-11 col-xs-12">
                              <span class="big">
                                As an organization grows in size and complexity,
                                incident management functions
                                need to evolve as well.
                              </span>
                            </div>
                            <div class="right-section">
                              <div class="square right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="right column col-lg-9 col-md-11 col-sm-12 col-xs-12">
                                  <div class="connecting">
                                    <h4>Framework to Continuously Improve</h4>
                                    <p>A framework to identify, plan, and implement improvements across
                                    the incident management program and IT component functions.
                      </p>
                                  </div>
                                </div>
                              </div>
                              <div class="background-right col-lg-9 col-md-9 col-sm-11 col-xs-12">
                                <img src={measureEff} alt="Measure Effectiveness" class="img-responsive graphic measure-effectiveness" />
                              </div>
                            </div>
                            <div class="left-section">
                              <div class="background-left col-lg-9 col-md-9 col-sm-11 col-xs-12">
                                <img src={improveComp} alt="Validate and Measure Effectiveness" class="img-responsive graphic improve-graphic" />
                              </div>
                              <div class="square left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="left column col-lg-9 col-md-11 col-sm-12 col-xs-12">
                                  <div class="bridging">
                                    <h4>Validate and Measure Effectiveness</h4>
                                    <p>A formal process with defined measures and metrics to evaluate the effectiveness of actions providing data to support future
                                    improvements.
                      </p>
                                  </div>
                                </div>
                              </div>
                              <div class="background-left mobile col-lg-9 col-md-9 col-sm-11 col-xs-12">
                                <img src={improveComp} alt="Validate and Measure Effectiveness" class="img-responsive graphic improve-graphic" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div id="tools" class="page-section page-section--tools col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={tool} alt="tool" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Tools: <span class="header-tagline">Organize Your Incident Reporting</span></h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--importal col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="mainpage-wrapper">
                            <div class="text-wrapper col-lg-8 col-md-8 col-sm-9 col-xs-12">
                              <div class="importal-logo">
                                <img src={importal} alt="importal" class="img-responsive graphic" />
                              </div>
                              <h4 class="col-lg-12 col-md-11 col-sm-12 col-xs-12">NCG Orchestration Incident Management Portal:<br />
                                <span class="smaller">Tools for Coordination, Communication, and Collaboration</span>
                              </h4>
                            </div>
                            <div class="imp-devices col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <img src={importalDevice} alt="importal device display" class="img-responsive graphic" />
                            </div>
                          </div>
                        </div>
                        <div class="page-section--orchestration col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="banner col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="banner-text">
                                <h3>Incidents happen in all types and sizes.</h3><br />
                                <p>NCG’s automation allows you to separate 
                                   from small using full incident reporting that follows the NIST and standard law enforcement evidence handling steps and a simple incident reporting interface to manage routine issues.</p>
                              </div>
                              <ul class="banner-photos col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                <li class="minimize col-lg-4 col-md-3 col-sm-3 col-xs-12">
                                  <div class="photo">
                                    <img src={toolsMinimize} alt="tools minimize impact" class="img-responsive graphic" />
                                  </div>
                                  <h5>Minimize impact, streamline recovery, and simplify reporting</h5>
                                </li>
                                <li class="engage col-lg-4 col-md-3 col-sm-3 col-xs-12">
                                  <div class="photo">
                                    <img src={toolEngage} alt="tools engage" class="img-responsive graphic" />
                                  </div>
                                  <h5>Engage and share information with legal & technical resources</h5>
                                </li>
                                <li class="tools col-lg-4 col-md-3 col-sm-3 col-xs-12">
                                  <div class="photo">
                                    <img src={toolSupport} alt="tools support" class="img-responsive graphic" />
                                  </div>
                                  <h5>Step-by-step support for the entire organization and trusted third parties</h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--functions col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="background right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="full-incident-reporting right column col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="vector-graphic col-lg-6 col-md-7 col-sm-12 col-xs-12">
                                  <img src={fullIncident} alt="full incident reporting" class="img-responsive graphic" />
                                </div>
                                <div class="text-wrapper col-lg-6 col-md-7 col-sm-12 col-xs-12">
                                  <h3>FULL INCIDENT REPORTING</h3><br />
                                  <p>Robust Processes and Data Collections for Significant Incidents</p><br />
                                  <ul>
                                    <li>All simple incident functions, plus PII impacts</li>
                                    <li>Distributed notification</li>
                                    <li>Reporting frequency management</li>
                                    <li>Impact assessment</li>
                                    <li>Custom tasks management</li>
                                    <li>Team management</li>
                                    <li>Recovery action management</li>
                                    <li>Contingency plan management</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="background left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="simple-incident-reporting left column col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="vector-graphic mobile col-lg-6 col-md-7 col-sm-12 col-xs-12">
                                  <img src={simpleIncident} alt="simple incident reporting" class="img-responsive graphic" />
                                </div>
                                <div class="text-wrapper col-lg-6 col-md-7 col-sm-12 col-xs-12">
                                  <h3>SIMPLE INCIDENT REPORTING</h3><br />
                                  <p>Basic Functions for Most Incidents</p><br />
                                  <ul>
                                    <li>Severity</li>
                                    <li>Ownership</li>
                                    <li>Context</li>
                                    <li>Incident Details</li>
                                    <li>Time to Resolve</li>
                                  </ul>
                                </div>
                                <div class="vector-graphic col-lg-6 col-md-7 col-sm-12 col-xs-12">
                                  <img src={simpleIncident} alt="simple incident reporting" class="img-responsive graphic" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="background right automation col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="list-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="mainpage-wrapper">
                                <div class="automation-functions right column">
                                  <div class="text-wrapper">
                                    <h3>AUTOMATION FUNCTIONS</h3><br />
                                    <ul class="automation">
                                      <li>Demonstrate consistency and completeness in dealing with incidents.</li>
                                      <li>Provide scalability of efforts and documentation based on the type of incident encountered.</li>
                                      <li class="wrap">Create processes to deal with system issues ranging from typical outages to possible data breach scenarios.</li>
                                      <li>Use tools and templates to follow best practices in working through different types of incidents.</li>
                                      <li>Define Teams and Support groups, including external parties, and engage them securely. </li>
                                      <li class="wrap">Streamline and standardize communications and reporting from identification to resolution of incidents.</li>
                                      <li class="wrap">Leverage communication distribution lists to get the right information to the right people at the right time.</li>
                                      <li>Manage and archive information updates and status checkpoints.</li>
                                      <li class="wrap">Create timelines of events and supporting data collection to work with legal, regulatory, law enforcement, and other key third parties.</li>
                                      <li class="wrap">Reference past incidents and lessons learned to improve processes and capabilities for the future.</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="bcp-dr" class="page-section page-section--bcp-dr col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={dcpDr} alt="dcp dr" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>BCP/DR: <span class="header-tagline">Coordinate Essential Business Capabilities</span></h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--intro col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="mainpage-wrapper">
                            <div class="intro-wrapper">
                              <h3>Business Continuity Planning / Disaster Recovery</h3>
                              <p>Incident management, business continuity planning, and disaster recovery are all essential business capabilities.
                            They each have their own functions, but may be needed together in some events.</p>
                            </div>
                          </div>
                          <div class="bc-dr col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <span class="business-continuity col-lg-12">
                                <p><b>Business Continuity</b> processes come into play when critical business functions are interrupted and
                            alternatives are needed  to operate and service
                            customers.</p>
                              </span>
                              <span class="disaster-recovery col-lg-12">
                                <p><b>Disaster Recovery</b> processes are implemented when infrastructure is no longer available and contingency resources are leveraged.</p>
                              </span>
                              <div class="business-threat-toolkit">
                                <img src={businessTreat} alt="business threat toolkit" class="graphic img-responsive" />
                              </div>
                            </div>
                            <div class="blue-banner">
                              <div class="mainpage-wrapper">
                                <div class="text-wrapper col-lg-6 col-md-7 col-sm-11 col-xs-12">
                                  <h4>NCG helps organizations build effective frameworks to design, plan, test, and execute Business Continuity Planning (BCP) and Disaster Recovery (DR) capabilities.</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--services col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="banner col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="header col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <h3>BCP/DR Services</h3>
                                <p>NCG provides business continuity planning (BCP) and disaster recovery (DR) based on industry standards,
                                including NIST SP 800-34 rev 1, and best practices from decades of providing incident management services
                                in the public and private sectors.
                  </p>
                              </div>
                              <div class="shield-container col-lg-2 col-md-2 col-sm-2 col-xs-4">
                                <img src={impShield} alt="incident management shield" class="graphic img-responsive" />
                              </div>
                            </div>
                          </div>
                          <div class="sub-section bridging-gap col-lg-12 col-md-12 col-sm-12 col-xs-11">
                            <div class="gradient">
                              <div class="mainpage-wrapper">
                                <div class="right column col-lg-7 col-md-8 col-sm-8 col-xs-12 clearfix">
                                    <p>The companies that survive major outages and disasters are the ones that have been proactive in developing policies,
                                    plans, and capabilities to get through such events. </p>

                                    <p>These organizations have spent time and resources to make sure they have the infrastructure and controls to mitigate
                                    adverse impacts, and have practiced using that capability at all levels of the organization. This combination of
                                    preparation and practice gives the organization what it needs to function during a crisis and after.</p>
                                </div>
                                <div class="four-steps list-wrapper col-lg-12">
                                  <ul class="clearfix">
                                    <li class="design col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                      <h4>Design & Build</h4>
                                      <div class="graphic"></div>
                                      <p>Starting with business impact assessments, craft the frameworks and processes to keep operations running under a range of circumstances.</p>
                                    </li>
                                    <li class="maintain col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                      <h4>Maintain Currency</h4>
                                      <div class="graphic"></div>
                                      <p>Conduct table-top and simulation exercises to test your plans and build readiness in your teams.</p>
                                    </li>
                                    <li class="test col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                      <h4>Test & Exercise</h4>
                                      <div class="graphic"></div>
                                      <p>Review and update plans and exercises to reflect current operations and factors.</p>
                                    </li>
                                    <li class="activate col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                      <h4>Activate</h4>
                                      <div class="graphic"></div>
                                      <p>Implement BCP/DR plans with supporting coordination, communication, and collaboration using secure cloud based tools.</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </body>
                </html>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default IncidentManagementMicrosite;