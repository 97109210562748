import {useEffect} from "react";

import React, { useState } from 'react';
import { Button } from "@material-ui/core";
import RFPForm from "../rfpForm.component";


//images
import devopsRobot from "images/images/microsite/devops/devops/devops-robot.png";
import devopsInfinity from "images/images/microsite/devops/devops/devops-infinity.png";
import graphicFive from "images/images/microsite/devops/devops/intro_slides/graphic-five.png";
import devopsAnimationReplace from "images/images/microsite/devops/devops/devops-animationReplace.jpg";
import devopsAnimationReplaceMobile from "images/images/microsite/devops/devops/mobile/devops-animationReplace.jpg";
import iconOne from "images/images/microsite/devops/approach/tile_collection/icon-one.png";
import iconTwo from "images/images/microsite/devops/approach/tile_collection/icon-two.png";
import iconThree from "images/images/microsite/devops/approach/tile_collection/icon-three.png";
import iconFour from "images/images/microsite/devops/approach/tile_collection/icon-three.png";
import makeSenseBg from "images/images/microsite/devops/approach/make_sense/make-sense-bg.png";
import makeSenseBgMobile from "images/images/microsite/devops/approach/make_sense/mobile/make-sense-bg.jpg";
import cultureGraphic from "images/images/microsite/devops/approach/culture/culture-graphic.jpg";
import cultureGraphicMobile from "images/images/microsite/devops/approach/culture/mobile/culture-graphic.jpg";

import ciGraphic from "images/images/microsite/devops/approach/ci/ci-graphic.png";
import cloudGraphic from "images/images/microsite/devops/approach/cloud/cloud-graphic.jpg";
import cloudGraphicMobile from "images/images/microsite/devops/approach/cloud/mobile/cloud-graphic.jpg";
import roadmap from "images/images/microsite/devops/approach/roadmap/roadmap.jpg";
import roadmapArrow from "images/images/microsite/devops/approach/roadmap/roadmap-arrow.png";
import services from "images/images/microsite/devops/services/services.png";
import programDev from "images/images/microsite/devops/services/icons/program-dev.png";
import teamDevops from "images/images/microsite/devops/services/icons/team-devops.png";
import roadmapCloud from "images/images/microsite/devops/services/icons/roadmap-cloud.png";

import DevopsNavBar from "./devopsNavBar";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const DevOpsMicrosite = (props) => {
  const [elementId, setElementId] = useState("");
  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  })


  const [modal, setModal] = useState(false);
  const openForm = (event) => {
    setModal(true);
  }
  const closeForm = () => {
    setModal(false);
  }

  return (
    <>
    <DevopsNavBar elementInView={elementId}/>
      <BackToTopCustom />
      <div class="crunch micro-new bootstrap">
        <head>
          <title>Northcross Group - DevOps </title>
        </head>
        <body itemscope itemtype="http://schema.org/WebPage" class="preload microsite microsite-devops" data-spy="scroll" data-target="#navbarCollapse" data-offset="50">

          <div class="page-wrap page-wrap--no-pad">
            <div id="splash" class="page-section splash splash-devops">
              <div class="mainpage-wrapper">
                <div class="splash-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h1 id="page-title">Is DevOps right for your Organization?</h1>
                  <h2 id="concept">The concept of greater speed, agility, and cost savings across development and operations sounds great.
                    But what is it and how can it work for your team and applications?</h2>
                </div>
                <div class="visible-print splash-graphic graphic-wrap col-lg-12 col-md-12 col-sm-12 col-xs-12">
                </div>
              </div>
            </div>
            <div class="three-topics col-lg-12 col-md-12 col-sm-12 col-xs-12" >
              <div class="mainpage-wrapper">
                <ul>
                  <li class="fade-in active">
                    <div class="fade-in-text col-lg-10 col-md-10 col-sm-10 col-xs-12">
                      <p>There are dozens of vendors saying different things about DevOps tools, processes, and offerings.
                            Some of the vendors are the biggest names in technology, while others are brand new and little known.</p>
                    </div>
                  </li>
                  <li class="fade-in">
                    <div class="fade-in-text col-lg-10 col-md-10 col-sm-10 col-xs-12">
                      <p>There are hundreds of tools: traditional licensed software, Open Source, and vendor-customized Open Source.</p>
                    </div>
                  </li>
                  <li class="fade-in">
                    <div class="fade-in-text col-lg-10 col-md-10 col-sm-10 col-xs-12">
                      <p>A given mix of tools may work for one organization but not another. They need to be the right fit for your organization and application portfolio.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div itemprop="mainContentOfPage" id="what-is-devops" class="page-section page-section--devops anchor">
              <div class="main-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mainpage-wrapper">
                  <h2>What is DevOps?</h2>
                </div>
              </div>
              <div class="page-section slide slide-8-9">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap col-lg-5 col-md-5 col-sm-5 col-xs-12 pull-right">
                    <img src={devopsRobot} alt="devops machine agile development" class="graphic img-responsive" />
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                    <p>DevOps is a different approach to how you manage and conduct development and operations in your organization. Adopting DevOps in your organization is about changing the way you approach development and operations of code. People are organized around applications and their full life cycle needs, instead of organizational units based on work functions.</p>
                    <p>DevOps is about new ways to leverage tools and automation across development and operations. A plethora of tools are available to automate nearly all manual process steps from development to operations. The right mix of tools can dramatically speed up the process, increase accuracy, and eliminate the necessity of having duplicative groups of people to support an application.</p>
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section slide slide-10">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap col-lg-5 col-md-5 col-sm-5 col-xs-12 ">
                    <img src={devopsInfinity} class="graphic graphic--desktop img-responsive" alt="infinity loop development lifespan" />
                    <p class="graphic--caption hidden-sm hidden-xs">Architecture, functionality, operations, security, and performance are all important throughout the entire life cycle.</p>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 pull-right">
                    <div class="title ">
                      <h2>With DevOps, teams pay attention to the entire end-to-end process at all times.</h2>
                    </div>
                    <div class="graphic-wrap mobile  ">
                      <img src={devopsInfinity} class="graphic graphic--mobile img-responsive" alt="infinity loop development lifespan" />
                      <p class="graphic--caption hidden-lg hidden-md">Architecture, functionality, operations, security, and performance are all important throughout the entire life cycle.</p>
                    </div>
                    <p>DevOps is about handling roles and responsibilities and segregation of duties between developers and the operations team differently. DevOps removes walls that have risen over the years between development and operations. DevOps is not about losing the concepts of segregation of duties or defined roles. Instead it changes the way they are achieved. </p>
                  </div>
                </div>
              </div>
              <div class="page-section slide slide-11">
                <div class="mainpage-wrapper">
                  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 pull-left">
                    <div class="title">
                      <h2>DevOps is about taking advantage of the Cloud infrastructure opportunities.</h2>
                    </div>
                    <div class="graphic-wrap mobile col-lg-4 col-md-4 col-sm-8 col-xs-12 pull-right" >
                      <img class="graphic graphic--mobile img-responsive" src={graphicFive} alt="devops" />
                    </div>
                    <p>The Cloud provides a number of opportunities for scalability, performance, and elasticity.  It also provides a new model for how we build and run applications.  Cloud Native applications are designed and built to take full advantage of what the Cloud has to offer. </p>
                  </div>
                  <div class="graphic-wrap col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-right" >
                    <img class="graphic graphic--desktop img-responsive" src={graphicFive} alt="devops" />
                  </div>
                </div>
              </div>
              <div itemprop="specialty" class="page-section slide slide-12">
                <div class="mainpage-wrapper">
                  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
                    <h2>Looking past the marketing hype</h2>
                    <h2>— what can DevOps mean for you?</h2>
                    <div class="graphic-wrap mobile">
                      <img src={devopsAnimationReplaceMobile} class="img-responsive graphic graphic--mobile" alt="woman's eye focusing on technology" />
                    </div>
                    <p>DevOps is a different way of approaching application development and operations. It focuses on managing applications across their life cycle with seamless processes and reduced manual efforts.</p>
                    <p>DevOps is about getting from idea to production faster and more effectively. It covers delivery from development through operations, implementing tools and practices designed for speed and agility for high-quality code that meets business objectives.</p>
                    <p>DevOps processes help handle new levels of application load demand and change management. It makes Continuous Delivery and Operations possible without compromising controls or ability to manage risks. DevOps positions the organization to take advantage of Cloud Native approaches that provide additional opportunities for cost management, performance, agility, and security for your applications in the Cloud.</p>
                    <p>NCG helps ensure that there are compromises on controls and managing risks; just reduction of non-value producing elements…</p>
                  </div>
                  <div class="graphic-wrap col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-right">
                    <img src={devopsAnimationReplace} class="img-responsive graphic graphic--desktop " alt="woman's eye focusing on technology" />
                  </div>
                  <div itemprop="specialty" class="tile-collection-wrap col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="title col-lg-12 col-md-12 col-sm-12 col-xs-12">DevOps can improve the overall value proposition from your applications.</h2>
                    <div itemprop="specialty" class="tile-collection col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="tile col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="tile-wrap">
                          <h5>Better &amp; More Effective Controls</h5>
                          <p>Get rid of things done in the name of controls that don’t provide value. Use automation to eliminate user error and costly manual efforts.</p>
                        </div>
                      </div>
                      <div class="tile col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="tile-wrap">
                          <h5>End-to-End Risk Management</h5>
                          <p>Greater speed and agility should not mean more risk. With a better managed life cycle, risk management can be more effective and current.</p>
                        </div>
                      </div>
                      <div itemprop="specialty" class="tile col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="tile-wrap">
                          <h5>Improve Quality</h5>
                          <p>Leverage processes and tools to develop better code that meets its intended purpose.</p>
                        </div>
                      </div>
                      <div itemprop="specialty" class="tile col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="tile-wrap">
                          <h5>Manage &amp; Maintain Compliance</h5>
                          <p>Improve monitoring, data collection, and analysis to maintain or address compliance requirements.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div itemprop="mainContentOfPage" id="devops-approach" class="page-section page-section--approach anchor">
              <div class="main-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mainpage-wrapper">
                  <h2>NCG's Approach to DevOps</h2>
                </div>
              </div>
              <div class="page-section slide slide-15 ">
                <div class="tile-rectangle"></div>
                <div class="mainpage-wrapper">
                  <div class="tile-collection">
                    <div class="tile-collection-wrap col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div id="approach_one" class="tile col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div class="icon-element">
                          <div class="icon col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={iconOne} class="img-responsive" alt="understand icon" />
                          </div>
                        </div>
                        <div itemprop="specialty" class="box-element">
                          <p>Understand your current development and operational processes and teams.</p>
                          <div class="arrow-right"></div>
                        </div>
                      </div>
                      <div id="approach_two" class="tile col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div class="icon-element">
                          <div class="icon col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={iconTwo} class="img-responsive" alt="understand icon" />
                          </div>
                        </div>
                        <div itemprop="specialty" class="box-element">
                          <p>Understand your application base and technologies.</p>
                          <div class="arrow-right"></div>
                        </div>
                      </div>
                      <div id="approach_three" class="tile col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div class="icon-element">
                          <div class="icon col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={iconThree} class="img-responsive" alt="understand icon" />
                          </div>
                        </div>
                        <div itemprop="specialty" class="box-element">
                          <p>Develop a roadmap to address people, processes, procedures, tooling, and technologies.</p>
                          <div class="arrow-right"></div>
                        </div>
                      </div>
                      <div id="approach_four" class="tile col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div class="icon-element">
                          <div class="icon col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={iconFour} class="img-responsive" alt="understand icon" />
                          </div>
                        </div>
                        <div itemprop="specialty" class="box-element">
                          <p>Define and implement a framework to start, manage, and sustain your DevOps efforts.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-section slide slide-16">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap col-lg-5 col-md-5 col-sm-5 col-xs-12">
                    <img src={makeSenseBg} class="img-responsive graphic graphic--desktop" alt="earth from space with devops terminology overlay" />
                  </div>
                  <div itemprop="specialty" class="col-lg-7 col-md-7 col-sm-11 col-xs-12 pull-right">
                    <div class="title">
                      <h2>Helping you make sense of it all</h2>
                    </div>
                    <div class="graphic-wrap mobile">
                      <img src={makeSenseBgMobile} class="img-responsive graphic graphic--mobile" alt="earth from space with devops terminology overlay" />
                    </div>
                    <p>With news tools coming along on nearly a daily basis, different levels of abstraction opportunities (infrastructure, platforms, functions, and containers), and a wide range of terminology, it can be very hard to figure out what everything is and what you need. </p>
                    <p>We help you sift through the different tools, platforms, and offerings to understand what makes the most business sense for your organization and application portfolio. </p>
                    <p>More importantly, we also help you develop frameworks to keep your tooling and patterns up to date as new offerings and technologies become available. This allows your teams to take advantage of the latest tools and technologies in a thoughtful and managed way.</p>
                  </div>
                </div>
              </div>
              {/* <!--  Culture--> */}
              <div itemprop="specialty" class="page-section slide slide-17">
                <div class="mainpage-wrapper">
                  <div class="col-lg-8 col-md-8 col-sm-11 col-xs-12 ">
                    <h2> Integrating DevOps into your Corporate Culture</h2>
                    <div class="graphic-wrap mobile">
                      <img src={cultureGraphicMobile} class="img-responsive graphic graphic--mobile" alt="using technology devices" />
                    </div>
                    <p>Corporate culture is not something you can simply create, but an organization should understand how its actions and processes influence it.</p>
                    <p>The corporate culture is an important element in fostering new ways of doing things such as DevOps. Management actions do foster how the corporate culture develops. Our Team has helped organizations understand the influences and drivers of their corporate culture so that they can influence the integration of DevOps throughout. These efforts include reward and incentive structures, messaging, and reinforcing actions across application owners, developers, operations personnel, and project management teams.</p>
                    <p>We develop programs to help organizations influence their corporate culture and measure progress. By having effective evaluation and measurement, efforts can be refined to ensure maximum value.</p>
                  </div>
                  <div class="graphic-wrap col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-right">
                    <img src={cultureGraphic} class="img-responsive graphic graphic--desktop" alt="using technology devices" />
                  </div>
                </div>
              </div>
              {/* <!--   Continuous Integration--> */}
              <div itemprop="specialty" class="page-section slide slide-18">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap col-lg-4 col-md-4 col-sm-11 col-xs-12">
                    <img src={ciGraphic} class="graphic graphic--desktop img-responsive" alt="infinity loop graphic" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-11 col-xs-12 ">
                    <div class="title">
                      <h2>Developing Continuous Integration and Continuous Delivery Capabilities</h2>
                    </div>
                    <div class="graphic-wrap mobile">
                      <img src={ciGraphic} class="graphic graphic--mobile img-responsive" alt="infinity loop graphic" />
                    </div>
                    <p>The concept of Continuous Integration (CI) and Continuous Delivery (CD) tears down the walls that have been built between development and operations people and processes. The purpose is to deliver better software faster and on an as-needed basis into the production environment.</p>
                    <p>When code is updated by a developer, automated testing runs to find any errors. If the tests pass, processes run to build the needed artifacts.  Automated integration, acceptance, performance, and load testing are run.  If everything is in order, it deploys to production without any downtime.</p>
                    <p>Applications need to be designed and built to take full advantage of CI and CD.</p>
                  </div>
                </div>
              </div>
              {/* <!--  Targeting Cloud Native--> */}
              <div itemprop="specialty" class="page-section slide slide-19">
                <div class="mainpage-wrapper">
                  <div class="graphic-wrap graphic-wrap--left col-lg-4 col-md-4 col-sm-11 col-xs-12">
                    <img src={cloudGraphic} class="graphic graphic--desktop img-responsive " alt="digital cloud abstract" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-11 col-xs-12 pull-right">
                    <div class="title">
                      <h2>Targeting Cloud Native Applications</h2>
                      <h3>We help chart your course to Cloud Native that makes the most business sense.</h3>
                    </div>
                    <div class="graphic-wrap mobile col-pad-none col-lg-4 col-md-4 col-sm-11 col-xs-12">
                      <img src={cloudGraphicMobile} class="graphic graphic--mobile img-responsive" alt="digital cloud abstract" />
                    </div>
                    <p>The Cloud offers tremendous value and scalability potential.  Applications that are designed to take advantage of all that the Cloud has to offer are called Cloud Native.</p>
                    <p>Cloud Native applications depend on DevOps and concepts such as continuous delivery,  micro services, and containerization.</p>
                    <p><b>Determine what parts of your application portfolio should target a Cloud Native environment with seamless and on-demand:</b></p>
                    <ul>
                      <li>Provisioning</li>
                      <li>Deployments</li>
                      <li>Scale and performance</li>
                      <li>Trouble shooting</li>
                      <li>Upgrade and currency updates</li>
                      <li>Firewalls, load balancing, and other network services</li>
                      <li>Middleware and database resources</li>
                      <li>Failover or recovery</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!--  Roadmap--> */}
              <div itemprop="specialty" class="page-break-before  page-section slide slide-20">
                <div class="mainpage-wrapper">
                  <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <div class="title">
                      <h2>Creating Roadmaps for your DevOps and Cloud Journey</h2>
                    </div>
                    <p>Hover for Details</p>
                    <div class="visible-print graphic-wrap">
                      <img src={roadmap} class="img-responsive graphic" alt="Roadmaps for DevOps" />
                    </div>
                    <div class="tile-collection-wrapper tile-collection--desktop hidden-print">
                      <div class="circle circle-top">
                        <div class="circle-graphic"></div>
                        <div class="circle-graphic"></div>
                        <div class="circle-graphic"></div>
                      </div>
                      {/* <!--                        row one--> */}
                      <div class="tile-collection tile-collection--one">
                        <div data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Today, your application portfolio likely has a mix of legacy all the way through to the latest technologies. Adding another set of patterns to support must provide value to make sense. Applicability and benefits across the portfolio would be ideal." class="tile tile--today">
                          <span>Today</span>
                        </div>
                      </div>
                      {/* <!--                        row two--> */}
                      <div class="tile-collection tile-collection--two">
                        <div id="roadmap_2" class="tile tile--two" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="We start by classifying your application based on business, cost, and technical considerations. These groupings organize and help prioritize DevOps and Cloud efforts.">
                          <span>Application Classification</span>
                        </div>
                        <div class="track--bg">
                          <div class="track--arrow track--arrow-down">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="roadmap arrow" />
                          </div>
                        </div>
                      </div>
                      {/* <!--                       row three--> */}
                      <div class="tile-collection tile-collection--three">
                        <div id="roadmap_3" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="With an understanding of how DevOps and the Cloud will apply to each application, we define the transition patterns for your application groups." class="tile tile--three">
                          <span> Application Transition and Shifts</span>
                        </div>
                        <div class="track--bg track--bg-one">
                          <div class="track--arrow track--arrow-right">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap arrow" />
                          </div>
                        </div>
                        <div class="track--bg track--bg-two">
                        </div>
                      </div>
                      {/* <!--                        row bottom--> */}
                      <div class="tile-collection tile-collection--four">
                        <div id="roadmap_5" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Existing policies and procedures will be reviewed and any needed changes to help the workforce will be adopted." class="tile tile--five">
                          <span>Process and Procedure Changes</span>
                        </div>
                        <div id="roadmap_4" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Under DevOps process and leveraging Cloud infrastructure, there will be changes to what people do and how they do it. These changes need to be understood by the responsible parties as well as those providing oversight and auditing of efforts." class="tile">
                          <span>Role and Responsibility Changes</span>
                        </div>
                        <div class="track--bg track--bg">
                          <div class="track--arrow track--arrow-left">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow" />
                          </div>
                          <div class="track--arrow track--arrow-left">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow" />
                          </div>
                        </div>
                      </div>
                      <div class="tile-collection tile-collection--five">
                        <div id="roadmap_6" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="DevOps and the Cloud should not deprecate any security controls, but may highlight duplicative or non-value-producing controls." class="tile">
                          <span>Security Control Changes</span>
                        </div>
                        <div class="track--bg track--bg track--bg-one">
                        </div>
                        <div class="track--bg track--bg track--bg-two">
                          <div class="track--arrow track--arrow-right">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow Right" />
                          </div>
                        </div>
                      </div>
                      <div class="tile-collection tile-collection--six">
                        <div id="roadmap_7" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="DevOps and the Cloud should not deprecate any security controls, but may highlight duplicative or non-value-producing controls." class="tile">
                          <span>Compliance Control Changes</span>
                        </div>
                        <div class="track--bg track--bg track--bg-two">
                          <div class="track--arrow track--arrow-down">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow" />
                          </div>
                        </div>
                      </div>
                      <div class="tile-collection tile-collection--seven">
                        <div id="roadmap_9" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Applications will be able to take advantage of DevOps processes and tools to varying degrees. We will help you find the optimal mix to maximize value." class="tile">
                          <span>DevOps Utilization</span>
                        </div>
                        <div class="track--bg track--bg track--bg-one-top">
                          <div class="track--arrow track--arrow-down">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow Down" />
                          </div>
                          <div class="track--arrow track--arrow-down">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow Down" />
                          </div>
                        </div>
                        <div id="roadmap_8" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Applications may be Cloud ready, friendly, resilient, or native based. Given those groupings, they will leverage the appropriate infrastructure pattern." class="tile tile--eight">
                          <span>Cloud Utilization</span>
                        </div>
                        <div class="track--bg track--bg track--bg-two-top">
                          <div class="track--arrow track--arrow-down">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow" />
                          </div>
                          <div class="track--arrow track--arrow-down">
                            <img src={roadmapArrow} class="img-responsive graphic" alt="Roadmap Arrow" />
                          </div>
                        </div>
                      </div>
                      <div class="tile-collection tile-collection--eight">
                        <div id="roadmap_10" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Sustainable methods to continue to move applications further along the DevOps and Cloud maturity path, with frameworks to keep up with new technologies and practices that can increase value." class="tile tile--target">
                          <span>Target State</span>
                        </div>
                      </div>
                      <div class="circle circle-bottom">
                        <div class="circle-graphic"></div>
                        <div class="circle-graphic"></div>
                        <div class="circle-graphic"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div itemprop="mainContentOfPage" id="devops-services" class="page-section page-section--services anchor">
              <div class="main-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mainpage-wrapper">
                  <h2>DevOps Services</h2>
                </div>
              </div>
              <div class="page-section">
                <div class="mainpage-wrapper">
                  <div itemprop="specialty" id="implement" class="section col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="title">
                      <h2>Services for Implementing DevOps in your Organization</h2>
                    </div>
                    <p>We help organizations sift through the different components of DevOps and determine what path is best for you. We help build the roadmap for getting there and provide the support to help you be successful. Our DevOps services scale for any size organization to enhance and evolve existing processes, or build new ways of doing development and operations.</p>
                  </div>
                  <div class="graphic-wrap col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-right">
                    <img src={services} class="graphic graphic--desktop img-responsive " alt="DevOps Services" />
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <div class="tile-collection-wrap">
                      <div class="tile-collection">
                        <div itemprop="specialty" id="programDev" class="tile col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={programDev} class="img-responsive graphic graphic--icon" alt="DevOps Program Development" />
                              </div>
                              <h3 class="col-pad-none col-lg-8 col-md-8 col-sm-8 col-xs-12">DevOps Program Development</h3>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <p>NCG develops tailored programs to help your organization adopt and operate under a DevOps approach. This includes processes for evaluating and selecting the best tool set, and the framework to evolve toolsets and technology stacks over time. NCG crafts policies and procedures specific to your team, organizational structures and corporate culture.</p>
                              <p>We implement processes to classify applications for how they can work with DevOps and create patterns to guide them. We apply processes to measure progress and use those measurements to refine efforts.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <Button onClick={() => openForm()} class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                              <RFPForm open={modal} closeForm={closeForm} rfpType={"DevOps"}></RFPForm>
                            </div>
                          </div>
                        </div>
                        <div class="tile col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="tile-wrap">
                            <div itemprop="specialty" class="tile--title col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={teamDevops} class="img-responsive graphic graphic--icon" alt="DevOps Training" />
                              </div>
                              <h3 class="col-pad-none col-lg-8 col-md-8 col-sm-8 col-xs-12">DevOps Training</h3>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <p>NCG provides DevOps process training at your facilities in classroom and interactive workshop formats. We also provide informational workshops for key stakeholders: information security, internal audit, compliance, and risk management partners.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <Button onClick={() => openForm()} class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                            </div>
                          </div>
                        </div>
                        <div itemprop="specialty" class="tile  col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <i class="fa fa-road" aria-hidden="true"></i>
                              </div>
                              <h3 class="col-pad-none col-lg-8 col-md-8 col-sm-8 col-xs-12">DevOps Roadmaps</h3>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <p>NCG offers detailed roadmaps to guide the primary areas of the organization (developers, operations personnel, project managers, and application owners) working on DevOps to get from the current state to their targets.</p>
                              <p>NCG roadmaps give your organization the path and initiative level details to get things done and make the business case to support the effort.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <Button onClick={() => openForm()} class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                            </div>
                          </div>
                        </div>
                        <div itemprop="specialty" class="tile  col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={roadmapCloud} class="img-responsive  graphic graphic--icon" alt="Roadmap Cloud" />
                              </div>
                              <h3 class="col-pad-none col-lg-8 col-md-8 col-sm-8 col-xs-12">Cloud Roadmaps</h3>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <p>NCG develops detailed roadmaps to guide migration to the Cloud. Our roadmaps help detail the transition process from administration, support, security, and development functions.</p>
                              <p>NCG roadmaps help you account for changes in run rates and how specific savings are recognized. We detail the progression of process changes and use of hybrid functions.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <Button onClick={() => openForm()} class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    </>
  );
}
export default DevOpsMicrosite;