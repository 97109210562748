import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import React from "react";
import BackToTop from 'react-custom-back-to-top-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import variables from "../../style/variables.module.scss";

const useStyles = makeStyles((theme) => ({
  btt: {
    height: "60px!important",
    width: "60px!important",
    bottom: "80px!important",
    backgroundColor: "#6fb3ce!important",
    opacity: .5,
    '&:hover:hover': {
      opacity: .8,
      '& $label': {
        opacity: 1,
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: "45px!important",
      width: "45px!important",
      right: "35px!important",
      bottom: "50px!important",
      
    },
  },
  icon: {
    color: "white",
    fontSize: "3em",
    marginBottom: 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: "2em",
    },
  },
  label: {
    textTransform: "uppercase",
    color: variables.ncgBlue0,
    fontWeight: 700,
    lineHeight: "1.4em",
    position: "absolute",
    bottom: "-50px",
    opacity: 0,
    fontSize: "12px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
      lineHeight: "1.2em",
      bottom: "-38px",
    },
  },
}));

const BackToTopCustom = (props) => {
  const classes = useStyles(props);
  if (props.variant === "customColor") {
    return (
      <BackToTop icon={<FontAwesomeIcon icon={faAngleUp} className={classes.icon} />} className={classes.btt}>
        <Typography variant="body1" className={classes.label}>Back to Top</Typography>
      </BackToTop>
    );
  }
  else {
    return (
      <BackToTop icon={<FontAwesomeIcon icon={faAngleUp} className={classes.icon} />} className={classes.btt}>
        <Typography variant="body1" className={classes.label}>Back to Top</Typography>
      </BackToTop>
    )
  }
}

export default BackToTopCustom;