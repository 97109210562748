import {useEffect, useState} from "react";

import { Button } from "@material-ui/core";
import AdobeFileLogo from "../../utils/AdobeFileLogo";

//images
import sms from "images/images/microsite/sms/icons/banner.png";
import smsApproach from "images/images/microsite/sms/icons/approach.png";
import experience from "images/images/microsite/sms/icons/experience.png";
import service from "images/images/microsite/sms/icons/services.png";
import pricing from "images/images/microsite/sms/icons/pricing.png";
import approach from "images/images/microsite/sms/icons/approach.png";
import faaSms from "images/images/microsite/sms/approach/approach-mobile.jpg";
import approach4 from "images/images/microsite/sms/approach/approach4.jpg";
import buildingFAA from "images/images/microsite/sms/icons/gear-new.png"
import smsProgram from "images/images/microsite/sms/icons/search-new.png"
import safetyServices from "images/images/microsite/sms/icons/people-new.png"
import safetyTraining from "images/images/microsite/sms/icons/people-gear-new.png"
import federalAdmin from "images/images/microsite/sms/logo/clients/federalAviation.png"
import united from "images/images/microsite/sms/logo/clients/united.png"
import skywest from "images/images/microsite/sms/logo/clients/skywest.jpg"
import expressJet from "images/images/microsite/sms/logo/clients/expressjet.jpg"
import american from "images/images/microsite/sms/logo/clients/american.png"
import eagle from "images/images/microsite/sms/logo/clients/eagle.jpg"
import southwest from "images/images/microsite/sms/logo/clients/southwest.png"
import smsDevelop from "images/images/microsite/sms/services/development.png"
import plane from "images/images/microsite/sms/icons/plane.png"
import gapAnalysis from "images/images/microsite/sms/services/gap-analysis.png"
import smsTraining from "images/images/microsite/sms/services/training.png"
import smsDCT from "images/images/microsite/sms/services/mapping.png"
import smsRoadmap from "images/images/microsite/sms/services/roadmaps.png"
import smsProcedure from "images/images/microsite/sms/services/procedure.png"
import safetyCulture from "images/images/microsite/sms/services/culture.png"
import progress from "images/images/microsite/sms/services/progress.png"
import orgDevelopment from "images/images/microsite/sms/services/organization.png"
import smsGearGreen from "images/images/microsite/sms/icons/gear-green.png"
import search from "images/images/microsite/sms/icons/search.png"
import peopleGreen from "images/images/microsite/sms/icons/people-green.png"
import training from "images/images/microsite/sms/icons/training.png"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

import SMSNavBar from "./smsNavBar";

import RFPForm from "../rfpForm.component";

const SMSMicrosite = (props) => {
  const [modal, setModal] = useState(false);
  const openForm = (event) => {
    setModal(true);
  }
  const closeForm = () => {
    setModal(false);
  }
  const [elementId, setElementId] = useState("");
  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  })

  return (
    <>
    <SMSNavBar elementInView={elementId}/>
      <BackToTopCustom />
      <div class="bootstrap">
        <div class="crunch">
          <div class="micro-new">
            <div className={"microsite-sms"}>
              <div class="splash">
                <div class="splash-wrapper">
                  <div class="content col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <h1>Aviation Safety <br />Management Systems</h1>
                    <h4>Building and enhancing compliant and value producing Safety Management Systems</h4>
                  </div>
                </div>
              </div>
              <div class="page-wrap">
                <div class="banner-sms">
                  <div class="mainpage-wrapper">
                    <div class="banner-sms--graphic">
                      <div class="graphic-wrap">
                        <img src={sms} class="img-responsive graphic" alt="SMS" />
                      </div>
                    </div>
                    <h2>Focused on empowering your team to be more effective in meeting SMS requirements and goals.</h2>
                  </div>
                </div>
                <div itemprop="mainContentOfPage" class="page-section page-section--site-layout col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="mainpage-wrapper">
                    <div class="tile-collection-wrap">
                      <div class="tile-collection">
                        <div id="tileApproach" class="tile tile--shadow col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={smsApproach} class="img-responsive graphic" alt="SMS Approach" />
                              </div>
                              <div class="header">Approach</div>
                            </div>
                            <div itemprop="specialty" class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <h3>Understanding What the FAA is Looking for in Your SMS</h3>
                              <p>NCG helps organizations get their arms around what is really required and what is not. We build sustainable programs and frameworks that set your team for success.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <a itemprop="relatedLink" href="#approach" class="btn btn-default">Learn More...</a>
                            </div>
                          </div>
                        </div>
                        <div itemprop="specialty" id="tileExperience" class="tile tile--shadow col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={experience} class="img-responsive graphic" alt="Experience" />
                              </div>
                              <div class="header">Experience</div>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <h3>Pragmatic Aviation Safety, Risk Management, and Compliance <span style={{ display: 'none' }}>Program Experience</span> </h3>
                              <p>Our team has experience in the aviation industry and with the FAA. Having this dual set of perspectives helps us understand both sides of the process and how to  satisfy both.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <a itemprop="relatedLink" href="#experience" class="btn btn-default">Learn More...</a>
                            </div>
                          </div>
                        </div>
                        <div itemprop="specialty" id="tileServices" class="tile tile--shadow col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={service} class="img-responsive graphic" alt="Service" />
                              </div>
                              <div class="header">Services</div>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <h3>Services Scaled to Your Needs and Current Safety Posture</h3>
                              <p>NCG has services to build compliant SMS programs or enhance existing ones. We have tools and services to connect your existing systems and leverage your current investments. We have developed innovative training approaches and have services to address safety culture challenges.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <a itemprop="relatedLink" href="#services" class="btn btn-default">Learn More...</a>
                            </div>
                          </div>
                        </div>
                        <div itemprop="specialty" id="tilePricing" class="tile tile--shadow col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="tile-wrap">
                            <div class="tile--title col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="tile--graphic">
                                <img src={pricing} class="img-responsive graphic" alt="Pricing" />
                              </div>
                              <div class="header">Pricing</div>
                            </div>
                            <div class="tile--body col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <h3>Tiered Pricing to Fit Different Scales of Operations</h3>
                              <p>Our team has experience in the aviation industry and with the FAA. Having this dual set of perspectives helps us understand both sides of the process and how to satisfy both.</p>
                            </div>
                            <div class="tile--learn-more col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <a itemprop="relatedLink" href="#pricing" class="btn btn-default">Learn More...</a>
                            </div>
                          </div>
                        </div>
                        <div class="tile tile-serviceCard tile--shadow col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="tile-wrap  col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h5>SMS Service Card</h5>
                            <a itemprop="relatedLink" id="adobe" href="/content/docs/ServiceCards/NCG_SMS_Services.pdf" target="_blank" rel="noreferrer"><AdobeFileLogo />Aviation Safety Management Systems Services</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div itemprop="mainContentOfPage" id="approach" class="page-section page-section--approach col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                  <div class="mainpage-wrapper">
                    <div class="site-layout-title">
                      <div class="site-layout--graphic">
                        <div class="graphic-wrap">
                          <img src={approach} class="img-responsive graphic" alt="SMS Approach" />
                        </div>
                      </div>
                      <h2>Approach</h2>
                    </div>
                    <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                      <h2>Understanding what the FAA is looking for in your SMS</h2>
                      <p>The FAA recognizes scalability of an SMS implementation based on the Operator’s complexity, volume of data, workforce size, and size of the management layer. Building a roadmap for compliance should focus on what you specifically need and how to get the most value out of your investment.</p>
                      <p>As with most mandated programs, the key to a successful and sustainable implementation is to make the program valuable to the Operator. The SMS should provide value beyond compliance. It should provide better levels of safety and quality, and improve the way the organization approaches safety.</p>
                      <p>To do that, each Operator needs to have an SMS that fits their unique organization. While NCG has many proven approaches and tools, we understand that the SMS must be tailored to each organization. Part of that is figuring out where the organization is today and how it is looking to grow. Our approach for getting started is to keep it as simple as practical, focusing on what is needed by your organization for success and getting your SMS accepted by the FAA.</p>
                    </div>
                    <div id="one" itemprop="specialty" class="image-pane col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="image-pane-graphic graphic-wrap graphic-wrap--right image-pane-graphic--approach">
                        <img src={faaSms} class="visible-print  img-responsive" alt="FAA SMS" />
                      </div>
                      <div class="image-pane--caption">
                        <p>FAA SMS requirements are in place for Part 121 Certificate Holders. </p>
                        <p>In addition, the countries in the European Aviation Safety Agency (EASA) may require a Part 135 operator to have an SMS acceptable to the FAA. What this means is if you fly to Europe, you must apply for and receive EASA authorization. Part of the approval process is to have a state (FAA) recognized SMS in accordance with Annex 19.</p>
                      </div>
                    </div>
                  </div>
                  <div itemprop="specialty" class="page-section page-section--approach-FAA col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="mainpage-wrapper">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <h2>NCG SMS Approach to Working Through FAA Requirements</h2>
                        <div class="white-box white-box--trans">
                          <p>NCG helps define what must be done, what are your future targets, and what can be model options for down the road. Once targets and objectives are defined, NCG works with you to build roadmaps that lay out efforts and dependencies from start to finish.</p>
                          <p>The next step is to make it all happen. NCG focuses on empowering the people in your organization to be more effective and able to support the SMS as it continues to evolve with the organization. NCG ensures that the organization has the means to measure effectiveness and support continuous improvements in a managed way.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div itemprop="specialty" class="page-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="mainpage-wrapper">
                      <div id="three" class="image-pane col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="image-pane-graphic image-pane-graphic--approach">
                          <img src={approach4} class="img-responsive" alt="NCG data integration services" />
                        </div>
                        <div class="image-pane--caption">
                          <p>NCG data integration services can connect your existing safety systems with your SMS. We help organizations implement robust analytics that create information to support decision making.</p>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 pull-left ">
                        <h2>Putting Technology to Work in Supporting your SMS</h2>
                        <p>Safety data can be voluminous and complex—not suitable for a paper exercise. As a system integrator, NCG has the expertise to connect data from across your enterprise and implement the best tools to support analytics.</p>
                        <p>NCG uses the latest technologies and cloud based tools to engage people from across the entire organization and securely share data. NCG has easy to use tools for safety reporting and risk management that focus on helping people get jobs done and not having to learn complex software.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div itemprop="mainContentOfPage" id="experience" class="page-section page-section--experience col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                  <div class="mainpage-wrapper">
                    <div class="sub-title">
                      <div class="site-layout-title">
                        <div class="site-layout--graphic">
                          <div class="graphic-wrap">
                            <img src={experience} class="img-responsive graphic" alt="Experience" />
                          </div>
                        </div>
                        <h2>Experience</h2>
                      </div>
                    </div>
                    <div itemprop="specialty" class="col-lg-9 col-md-9 col-sm-9 col-xs-12 ">
                      <div class="sub-title">
                        <h2>Our Experience and Proven Track Record in Safety, Risk Management,
                        Compliance, and Program Development.</h2>
                      </div>
                      <p>The NCG Team has decades of experience building compliant programs and helping organizations of all sizes and complexities develop programs for safety, risk management, security, and compliance.</p>
                      <p>We have designed and built frameworks that have grown with the organization. We have helped organizations develop the frameworks with incentives and controls to successfully influence corporate culture to align with strategic objectives.</p>
                      <div class="page-section page-section--mini col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div class="graphic-wrap col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <img src={buildingFAA} class="img-responsive graphic" alt="Building FAA" />
                        </div>
                        <div itemprop="specialty" class="col-pad-none col-lg-10 col-md-10 col-sm-10 col-xs-12 pull-right">
                          <h3>Building FAA-Compliant SMS Programs</h3>
                          <p>Our Team has worked with Part 121 Operators to develop and add to existing SMS programs. Our efforts have included developing and implementing risk management procedures and processes, as well as integrating systems to connect disparate data in existing safety programs. We have developed pragmatic and tailored policies and procedures that match an Operator’s people, culture, and structures to best guide and controls efforts across the enterprise.</p>
                        </div>
                      </div>
                      <div itemprop="specialty" class="page-section page-section--mini col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div class="graphic-wrap col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <img src={smsProgram} class="img-responsive graphic" alt="Assessing Existing SMS Programs" />
                        </div>
                        <div class="col-pad-none col-lg-10 col-md-10 col-sm-10 col-xs-12 pull-right">
                          <h3>Assessing Existing SMS Programs</h3>
                          <p>Our Team has helped Operators understand where they currently stand and where the gaps are to compliance. We have done this through mapping current programs to the FAA SMS DCTs and highlighted gaps to achieving FAA SMS active compliance and how to go about addressing them. We have built roadmaps tailored to the specific Operator’s current structures to accomplish SMS compliance in alignment with other strategic objectives.</p>
                        </div>
                      </div>
                      <div itemprop="specialty" class="page-section page-section--mini col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div class="graphic-wrap col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <img src={safetyServices} class="img-responsive graphic" alt="Safety Culture Services" />
                        </div>
                        <div class="col-pad-none col-lg-10 col-md-10 col-sm-10 col-xs-12 pull-right ">
                          <h3>Safety Culture Services</h3>
                          <p>Our Team has helped organizations understand the influences and drivers of their corporate culture so that they can influence the integration of safety throughout.
                            We have helped Operators leverage reward and incentive structures, messaging, and reinforcing actions to promote safety as part of everyday business. We have helped Operators measure their current culture and be able to track effectiveness of actions to influence it.</p>
                        </div>
                      </div>
                      <div itemprop="specialty" class="page-section page-section--mini col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div class="graphic-wrap col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <img src={safetyTraining} class="img-responsive graphic" alt="Safety and Risk Management Training" />
                        </div>
                        <div class="col-pad-none col-lg-10 col-md-10 col-sm-10 col-xs-12 pull-right">
                          <h3>Safety and Risk Management Training</h3>
                          <p>We have developed innovative training techniques tailored to an Operator’s environment. We have used distributed learning, retrieval practice, and other learning solutions to get concepts like risk management to stick. We have delivered onsite training, developed training materials, and facilitated interactive workshops on Safety Management.</p>
                        </div>
                      </div>
                    </div>
                    <div class="image-pane col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="image-pane--flex">
                        <img src={federalAdmin} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="Federal Aviation Administration" />
                        <img src={united} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="United" />
                        <img src={skywest} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="Skywest" />
                        <img src={expressJet} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="Expressjet" />
                        <img src={american} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="American" />
                        <img src={eagle} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="Eagle" />
                        <img src={southwest} class="client-logo col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xs-12" alt="Southwest" />
                      </div>
                    </div>
                  </div>
                </div>
                <div itemprop="mainContentOfPage" id="services" class="page-section page-section--services col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                  <div class="mainpage-wrapper">
                    <section>
                      <div class="sub-title">
                        <div class="site-layout-title">
                          <div class="site-layout--graphic">
                            <div class="graphic-wrap">
                              <img src={service} class="img-responsive graphic" alt="Services" />
                            </div>
                          </div>
                          <h2>Services</h2>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div itemprop="specialty" class="sub-title">
                          <h2>Our SMS Services scale for any size operator to enhance and evolve existing, or build new, compliant Safety Management Systems.</h2>
                        </div>
                      </div>
                      <div class="tile-wrapper">
                        <div class="tile">
                          <div itemprop="specialty" class="tile-title">
                            <div class="graphic-wrap">
                              <img src={smsDevelop} class="img-responsive graphic" alt="SMS Development" />
                            </div>
                            <h3>SMS Development</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCG SMS Program Development services build compliant and sustainable Safety Management Systems that provide business value for the Operator.  Our approach empowers your organization to get the most out of your SMS and keep it going as the organization evolves.</p>
                        </div>
                        <div class="tile">
                          <div itemprop="specialty" class="tile-title">
                            <div class="graphic-wrap">
                              <img src={gapAnalysis} class="img-responsive graphic" alt="Gap Analysis" />
                            </div>
                            <h3>SMS Program Gap Analysis</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCG Gap Analysis services provide a third party view of your current SMS program and identification of compliance gaps and process improvement recommendations.</p>
                        </div>
                        <div class="tile">
                          <div itemprop="specialty" class="tile-title">
                            <div class="graphic-wrap">
                              <img src={smsTraining} class="img-responsive graphic" alt="SMS Training" />
                            </div>
                            <h3>SMS Training</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCG provides Safety Management System (SMS) program and Safety Risk Management training at your facilities in classroom and interactive workshop formats.</p>
                        </div>
                        <div class="tile">
                          <div class="tile-title">
                            <div class="graphic-wrap">
                              <img src={smsDCT} class="img-responsive graphic" alt="SMS DCT Mapping" />
                            </div>
                            <h3>SMS DCT Mapping</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>The FAA’s Data Collection Tools are the basis for their evaluation of an SMS. NCG provides mapping of your programs, policies, procedures, and manuals to the DCTs.</p>
                        </div>
                        <div class="tile">
                          <div class="tile-title">
                            <div class="graphic-wrap">
                              <img src={smsRoadmap} class="img-responsive graphic" alt="SMS Roadmaps" />
                            </div>
                            <h3>SMS Improvement Roadmaps</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>Getting from here to there…<br />
                            NCG can provide a detailed roadmap for operators to get from current state to a compliant or enhanced SMS. NCG roadmaps give your organization the map and initiative level details to get things done.
                        </p>
                        </div>
                        <div class="tile">
                          <div class="tile-title">
                            <div class="graphic-wrap">
                              <img src={smsProcedure} class="img-responsive graphic" alt="SMS Policy & Procedure Development" />
                            </div>
                            <h3>SMS Policy &amp; Procedure Development</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCH crafts SMS policies and procedures tailored to your team and organizational structures. Our pragmatic and custom approaches provides an Operator with the documented direction and specific work instructions to drive SMS efforts across the entire enterprise.</p>
                        </div>
                        <div class="tile">
                          <div class="tile-title">
                            <div class="graphic-wrap">
                              <img src={safetyCulture} class="img-responsive graphic" alt="Safety Culture Assessments" />
                            </div>
                            <h3>Safety Culture Assessments</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCG’s Safety Culture Assessments helps organizations better understand their current corporate culture as it pertains to safety. We highlight drivers and frameworks that drive the culture and how that insight can be used to influence positive change.</p>
                        </div>
                        <div class="tile">
                          <div class="tile-title">
                            <div class="graphic-wrap">
                              <img src={orgDevelopment} class="img-responsive graphic" alt="Organization Structure Development" />
                            </div>
                            <h3>Organization Structure Development</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCG designs and builds a compliant SMS organizational structure that best matches the Operator. We develop roles and responsibilities for all participants, along with supporting charters and procedural processes to build a sustainable organization that will evolve with the Operator.</p>
                        </div>
                        <div class="tile">
                          <div class="tile-title">
                            <div class="graphic-wrap">
                              <img src={progress} class="img-responsive graphic" alt="Safety Culture Evolution Services" />
                            </div>
                            <h3>Safety Culture Evolution Services</h3>
                            <div class="graphic-wrap graphic-wrap--plane">
                              <img src={plane} class="img-responsive graphic " alt="Plane" />
                            </div>
                          </div>
                          <p>NCG helps organizations implement techniques and structures to foster safety in their corporate cultures. We implement processes to measure progress and use those measurements to refine efforts and foster the target culture in the organization.</p>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div itemprop="mainContentOfPage" id="pricing" class="page-section page-section--pricing col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                  <section>
                    <div class="sub-title pricing-title">
                      <div class="site-layout-title">
                        <div class="site-layout--graphic">
                          <div class="graphic-wrap">
                            <img src={pricing} class="img-responsive graphic" alt="Pricing" />
                          </div>
                        </div>
                        <h2>Pricing</h2>
                      </div>
                    </div>
                    <div class="mainpage-wrapper">
                      <div class="sub-title pricing-content">
                        <h2>Pricing to Fit Different Levels of Complexity and Size</h2>
                      </div>
                      <div class="tile-wrapper">
                        <div class="tile-inner--wrapper">
                          <div id="oneTile" class="tile">
                            <div class="tile-title">
                              <div class="graphic-wrap ">
                                <img src={smsGearGreen} class="img-responsive graphic" alt="SMS Development Services" />
                              </div>
                              <h3>SMS Development Services</h3>
                            </div>
                            <h4>SMS Development | Organization Structure Development | Policy &amp; Procedure Development</h4>
                            <p>SMS Development Services begin at <b>$15,000</b> for support and direction to build a complaint SMS.</p>
                            <Button href="#" onClick={() => openForm()} type="button" class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                            <RFPForm  rfpType="SMS" open={modal} closeForm={closeForm} /> 
                          </div>
                        </div>
                        <div class="tile-inner--wrapper">
                          <div id="twoTile" class="tile">
                            <div class="tile-title">
                              <div class="graphic-wrap ">
                                <img src={search} class="img-responsive graphic" alt="SMS Assessments" />
                              </div>
                              <h3>SMS Assessments</h3>
                            </div>
                            <h4>SMS Program Gap Analysis | Improvement Roadmaps | FAA SMS DCT Mapping</h4>
                            <p>SMS Assessment Services begin at <b>$10,000</b> for assessment and mapping services.</p>
                            <Button href="#" onClick={() => openForm()} type="button" class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                          </div>
                        </div>
                        <div class="tile-inner--wrapper">
                          <div id="threeTile" class="tile">
                            <div class="tile-title">
                              <div class="graphic-wrap ">
                                <img src={peopleGreen} class="img-responsive graphic graphic--large" alt="Safety Culture Services" />
                              </div>
                              <h3>Safety Culture Services</h3>
                            </div>
                            <h4>Safety Culture Assessments | Safety Culture Evolution Services</h4>
                            <p>Safety culture assessments and services to influence your corporate culture start at <b>$8,500</b>.</p>
                            <Button href="#" onClick={() => openForm()} type="button" class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                            </div>
                        </div>
                        <div class="tile-inner--wrapper">
                          <div id="fourTile" class="tile">
                            <div class="tile-title">
                              <div class="graphic-wrap ">
                                <img src={training} class="img-responsive graphic graphic--large" alt="Safety Management System (SMS) Training" />
                              </div>
                              <h3>SMS Training</h3>
                            </div>
                            <h4>Safety Management | Risk Management Theory and Practice Training</h4>
                            <p>Onsite safety and risk management training session start at <b>$2,500</b>.</p>
                            <Button href="#" onClick={() => openForm()} type="button" class="btn btn-default btn-sm " data-toggle="modal" data-target="#request">Submit an RFP or Request Contact</Button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SMSMicrosite;