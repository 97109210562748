import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classNames from "classnames";

//image
import experienceSkills from "images/images/mergers/section/topics/experience-skills.png"

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    borderRadius: 8,
    margin: 10,
    [theme.breakpoints.down(768)]:{
      fontSize: "1em !important",
    },
    [theme.breakpoints.down(568)]:{
      fontSize: "0.8em !important",
    },
  },
  closeButtonSection: {
    textAlign: "right",
    "& button": {
      [theme.breakpoints.down(768)]:{
        fontSize: "0.8em !important",
      },
      [theme.breakpoints.down(568)]:{
        fontSize: "0.6em !important",
      },
    },
  },
  customPadding: {
    paddingLeft: "45px !important",
    paddingRight: "45px !important"
  }
}));
export const ExperienceSkillsModal = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.formContainer}>
      <h2>{`Experience & Skills`}</h2>
      <div className={classNames("page-section",classes.customPadding)}>
        <img src={experienceSkills} class="img-responsive" alt="Experience Skills" />
      </div>
      <div className={classes.closeButtonSection}>
        <Button type="button" class="btn btn-default" onClick={() => props.setOpenExperienceModal(false)}>Close</Button>
      </div>
    </Grid>
  );
}

export default ExperienceSkillsModal;
