import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingLeft: 25,
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  // LIST
  unorderedList: {
    paddingLeft: 20,
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 0,
  },
  listItem: {
    fontSize: variables.fontBody,
    letterSpacing: ".08em",
    paddingTop: 10,
    color: variables.textPrimary,
  },
}));

const PPEnsuringPerformance = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Ensuring System Performance
      </Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/ensuring-performance" variant="active">Ensuring System Performance</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Independent Verification &amp; Validation (IV&amp;V)
                </li>
                <li className={classes.sideBarListItem}>
                  Risk Management
                </li>
                <li className={classes.sideBarListItem}>
                  Business Process Management
                </li>
                <li className={classes.sideBarListItem}>
                  Web Based Technology Assessment
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2">
                State of Maine ALMS IV&amp;V
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                The State of Maine’s Department of Professional and Financial Regulations needed an Independent Verification and Validation (IV&amp;V) of their Agency License Management System (ALMS). ALMS is used to manage the application, fulfillment, and tracking of licenses and permits. Drivers behind the IV&amp;V need ensured proper project level risk management and scalability in the future.
              </Typography>
              <Typography variant="body1">
                The Department wanted to compare the level of technical knowledge and supporting capabilities they had in house to their vendors to find how to operate, maintain, and improve their system. They wanted to have the necessary knowledge and information to continue to support the system regardless of changes in technology, vendor, or scope of mission.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG took a comprehensive approach looking at previous and current implementations, processes, procedures, infrastructure, and controls. Technical and management considerations were assessed independently and then together providing a realistic assessment. Progression of the system to date was compared to requirements as well as the effectiveness or project execution to predict future sustainability.
              </Typography>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                NCG delivered an assessment of the project’s strong points that should be cultivated and areas that needed to be addressed. Specific steps, measures, and governance approaches were identified in both technical and process areas. These steps provided a function to help drive project and management activities moving forward.
              </Typography>
              <ul className={classes.unorderedList}>
                <li className={classes.listItem}>
                  NCG assessed the adequacy of current system documentation and models.
                </li>
                <li className={classes.listItem}>
                  NCG assessed what additional specifications, configurations, or other documentation is needed.
                </li>
                <li className={classes.listItem}>
                  NCG looked at options, where appropriate, for other technology platforms that could support the ALMS in part or whole.
                </li>
                <li className={classes.listItem}>
                  NCG identified developmental, maintenance, and enhancement portability—ability to have functions transferred to other entities.
                </li>
                <li className={classes.listItem}>
                  NCG identified risk factors for the system that could impact current or future operations.
                </li>
              </ul>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default PPEnsuringPerformance;