import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
dateWrapper: {
  display: "block",
},
dateText: {
  fontStyle: "italic",
  fontFamily: "Helvetica Neue, Helvetica, sans-serif",
  opacity: ".8",
  fontSize: "14px",
},
}));

const MediaDate = (props) => {
  const classes = useStyles(props);
    return (
      <div className={classes.dateWrapper}>
        <Typography variant="caption" className={classes.dateText}>
          {props.children}
        </Typography>
      </div>
    )
  }

export default MediaDate;