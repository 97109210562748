import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

//image
import orgWorkFlow from "images/images/orchestration/portal/organization-workflow.jpg"

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    borderRadius: 8,
    margin: 10,
  },
  content: {
    width: 600,
    height: 365,
    padding: "0 25px 20px 25px"
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 0,
    alignItems: "center"
  },
  subHeaderContainer: {
    padding: "0px 10px 35px 10px",
  },
  formSection: {
    paddingTop: 0,
    width: 300,
    paddingBottom: 10,
  },
  closeButtonSection: {
    textAlign: "right"
  }

}));
export const SecurityPortalModal = (props) => {
  const classes = useStyles();
    
        return (
          <Grid className={classes.formContainer}>
            <h2>{`Security Portal`}</h2>
            <div class="page-section">
              <img src={orgWorkFlow} class="img-responsive" alt="Organization Workflow" />
            </div>
            <div className={classes.closeButtonSection}>
                <Button type="button" class="btn btn-default" onClick={() => props.setOpenModal(false)}>Close</Button>
            </div>
          </Grid>
        );
}

export default SecurityPortalModal;
