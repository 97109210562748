import ncgStar2 from "images/microsite/stars2/NCG_compass_Masthead.gif";
let menuItems =
  [
    { label: 'Services & Experience', href: '/stars2/services' },
    { label: 'NCG STARS II Team', href: '/stars2/ncg_team' },
    { label: 'NCG Info', href: '/stars2/about_ncg' },
    { label: 'About GSA STARS II', href: '/stars2/about_stars2' },
  ]
const Star2NavBar = (props) => {
  let currentMenu = menuItems.filter(item => item.href === props.href);
  menuItems = menuItems.filter(item => item.href !== props.href);
  menuItems = currentMenu.concat(menuItems);
  return (
    <div>
      <div id="banner"> <a href="/"><img src={ncgStar2} alt="Northcross Group" width="864" height="74" border="0" /></a>
        <table width="100%" border="0" cellspacing="0" cellpadding="1">

          <tr>
            <td width="200">&nbsp;</td>
            <td width="700" align="right" valign="top">
              <a class="nav" href="/">NCG Home Page</a> |
              {menuItems.map((item) => {
                return (
                  <>
                    {(props.href === item.href) ? <a class="nav" href="/stars2"> NCG STARS II Home</a> :
                      <a class="nav" href={item.href}>{item.label}</a>
                    }
                    <label> | </label>
                  </>
                )
              })}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
export default Star2NavBar;