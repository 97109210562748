import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

const AngleDoubleLeftIcon = (props) => {
  return (
    <FontAwesomeIcon icon={faAngleDoubleLeft} color="#24cfba" size="lg" style={{ marginRight: "5px" }} />
  );
};

export default AngleDoubleLeftIcon;
