import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    paddingBottom: 80,
    [theme.breakpoints.down(763)]: {
      paddingBottom: 55,
    },
    [theme.breakpoints.up(763)]: {
      paddingBottom: 45,
    },
    [theme.breakpoints.up(780)]: {
      paddingBottom: 75,
    },
  }
}));
const menuItems =
  [
    { label: 'SMS Approach', href: '#approach' },
    { label: 'SMS Experience', href: '#experience' },
    { label: 'SMS Services', href: '#services' },
    { label: 'Pricing', href: '#pricing' },
    { label: 'Contact', href: '#request', rfpType: 'SMS', contactForm: true }
  ]
const SMSNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar bgColor="blue" menuItems={menuItems} elementInView={props.elementInView}/>
    </div>
  );
}
export default SMSNavBar;