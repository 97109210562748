import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import slide1Image from "../../../images/images/microsite/privacy-services/pp-slider-1.png";
import slide2Image from "../../../images/images/microsite/privacy-services/pp-slider-2.png";
import slide3Image from "../../../images/images/microsite/privacy-services/pp-slider-3.png";
import slide4Image from "../../../images/images/microsite/privacy-services/pp-slider-4.png";
import slide5Image from "../../../images/images/microsite/privacy-services/pp-slider-5.png";
import slide6Image from "../../../images/images/microsite/privacy-services/pp-slider-6.png";
import slide7Image from "../../../images/images/microsite/privacy-services/pp-slider-7.png";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: "inherit",
    width: "100%",
    backgroundColor: variables.ncgBlue4,
    display: "block",
    position: "relative",
  },
  slideBackground: {
    height: "inherit",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    display: "block",
    position: "absolute",
    right: 0,
    top: 0,
    '&::after': {
      display: "block",
      height: "inherit",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      backgroundColor: "rgba(5,62,111,0.6)",
      mixBlendMode: "screen",
    },
  },

  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
  },
  slide5Background: {
    backgroundImage: "url(" + slide5Image + ")",
  },
  slide6Background: {
    backgroundImage: "url(" + slide6Image + ")",
  },
  slide7Background: {
    backgroundImage: "url(" + slide7Image + ")",
  },
  customTitle: {
    fontSize: "50px",
  },
  slideTitle: {
    fontSize: "50px",
  },
  customCarousel: {
    height: "fit-content",
  },
}));

const PrivacyServicesCarousel = () => {

  const classes = useStyles();

  return (
    <div class="micro">
      <div class="privacy-services">
        <CustomCarousel sliderShadow={true}>
          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide1Background, "carousel")}>
              <div class="item item-1">
                <div class="carousel-text">
                  <div class="title-wrapper col-xxlg-5 col-xlg-6 col-lg-6 col-md-7 col-sm-10 col-xs-12">
                    <h1 className={classes.slideTitle}>
                      Privacy Program and Assessment Services
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xlg-5 col-lg-5 col-md-6 col-sm-10 col-xs-12">
                    <h3>
                      Protecting the Privacy of Organizational and Client Data
                    </h3>
                    <p class="large">
                      Enterprise level services to address the unique threats to privacy across the complete digital and physical data life cycle.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide2Background, "carousel")}>
              <div class="item item-2">
                <div class="carousel-text">
                  <div class="title-wrapper col-xxlg-5 col-xlg-6 col-lg-7 col-md-7 col-sm-10 col-xs-12">
                    <h1 className={classes.slideTitle}>
                      Privacy Program Design &amp; Implementation
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xlg-5 col-lg-5 col-md-7 col-sm-10 col-xs-12">
                    <h3>
                      Tailored Programs that Ensure Compliance and Provide Business Value
                    </h3>
                    <p class="large">
                      Creating and institutionalizing sustainable programs that provide privacy capabilities to address compliance requirements while delivering improved data management and insight.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide3Background, "carousel")}>
              <div class="item item-3">
                <div class="carousel-text">
                  <div class="title-wrapper col-xxlg-5 col-xlg-6 col-lg-6 col-md-7 col-sm-10 col-xs-12">
                    <h1 className={classes.slideTitle}>
                      Privacy Breach &amp; Incident Management
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xlg-5 col-lg-6 col-md-7 col-sm-10 col-xs-12">
                    <h3>
                      Team Preparation and Expert Services to Deal with Incidents and Breaches
                    </h3>
                    <p class="large">
                      Proactive preparation to make sure your team is ready to handle an event with breach planning and exercises for all levels of your team. The NCG Team has the subject matter expertise, technical capabilities, and tools to advise and support your team when incidents do occur.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide4Background, "carousel")}>
              <div class="item item-4">
                <div class="carousel-text">
                  <div class="title-wrapper col-xxlg-5 col-xlg-6 col-lg-5 col-md-7 col-sm-10 col-xs-12 float-right">
                    <h1 className={classes.slideTitle}>
                      Risk-Based Approach to Privacy
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xlg-5 col-lg-5 col-md-7 col-sm-10 col-xs-12">
                    <h3>
                      Privacy Threats are Different from Data Security Threats
                    </h3>
                    <p class="large">
                      Implement a risk based approach to managing data privacy across the entire enterprise. Ensure risk management processes are tuned to the specific threat and impact threshold for Personally Identifiable Information (PII).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide5Background, "carousel")}>

              <div class="item item-5">
                <div class="carousel-text">
                  <div class="title-wrapper col-xxlg-5 col-xlg-6 col-lg-5 col-md-7 col-sm-10 col-xs-12">
                    <h1 className={classes.slideTitle}>
                      Privacy Data Management
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xxlg-4 col-xlg-5 col-lg-5 col-md-7 col-sm-10 col-xs-12">
                    <h3>
                      Control and Agility in Management of Personally Identifiable Information (PII) Data
                    </h3>
                    <p class="large">
                      Develop and maintain line of sight and effective management of PII data across the entire life cycle—addressing requirements while also gaining utility from that data to improve customer experience and operational efficiencies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide6Background, "carousel")}>
              <div class="item item-6">
                <div class="carousel-text">
                  <div class="title-wrapper col-xxlg-5 col-xlg-5 col-lg-5 col-md-7 col-sm-10 col-xs-12 float-right">
                    <h1 className={classes.slideTitle}>
                      Privacy Engineering
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xxlg-4 col-xlg-5 col-lg-5 col-md-7 col-sm-10 col-xs-12">
                    <h3>
                      Engineering Privacy Controls and Capabilities into Systems &amp; Applications
                    </h3>
                    <p class="large">
                      Incorporate Privacy considerations across the system and application development life cycle—building it in from the beginning. Leverage these considerations to improve analytics and business intelligence objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(classes.slide)}>
            <div className={classNames(classes.slideBackground, classes.slide7Background, "carousel")}>
              <div class="item item-7">
                <div class="carousel-text long-text">
                  <div class="title-wrapper col-xxlg-7 col-xlg-7 col-lg-7 col-md-7 col-sm-10 col-xs-12 float-right">
                    <h1 className={classNames(classes.customTitle)}>
                      Privacy Impact Assessments / Data Protection Impact Assessments
                    </h1>
                  </div>
                  <div class="sub-text-wrapper col-xxlg-6 col-xlg-6 col-lg-6 col-md-7 col-sm-10 col-xs-12">
                    <h3>
                      Understanding the Current State and Risks to Privacy Across the Enterprise
                    </h3>
                    <p class="large">
                      Third-party Privacy Assessments to meet GDPR and CCPA compliance requirements. Design and development of internal programs to conduct regular assessments to support internal oversight and governance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomCarousel>
      </div>
    </div>
  )
}

export default PrivacyServicesCarousel;