import React from "react";
import ncgLogo from "images/ncg-logo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: "100%",
    width: "100%",
    display: "block",
    backgroundImage: "url(" + ncgLogo + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const NcgLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer}/>
  );
};

export default NcgLogo;
