import {useEffect, useState} from "react";

//carousel
import RiskManagementCarousel from "./RiskManagementCarousel"

//images
import program from "images/images/microsite/rm/icons/programs.png"
import benefit from "images/images/microsite/rm/icons/benefits.png"
import tools from "images/images/microsite/rm/icons/tools.png"
import assessment from "images/images/microsite/rm/icons/assessments.png"
import cloud1 from "images/images/microsite/rm/rm-programs/cloud-1.png"
import cloud3 from "images/images/microsite/rm/rm-programs/cloud-3.png"
import umbrella from "images/images/microsite/rm/rm-programs/umbrella.png"
import umbrellaSmall from "images/images/microsite/rm/rm-programs/umbrella-small.png"
import submarine from "images/images/microsite/rm/rm-programs/submarine.png"
import simple from "images/images/microsite/rm/rm-programs/tiles/simple-small.png"
import agility from "images/images/microsite/rm/rm-programs/tiles/agility-small.png"
import sustainability from "images/images/microsite/rm/rm-programs/tiles/sustainability-small.png"
import inclusive from "images/images/microsite/rm/rm-programs/tiles/inclusive-small.png"
import support from "images/images/microsite/rm/rm-programs/tiles/support-small.png"
import efficiency from "images/images/microsite/rm/rm-programs/tiles/efficiency-small.png"
import rmPortalTool from "images/images/microsite/rm/rm-tools/rmportal-logo.png"
import rmPortalDevice from "images/images/microsite/rm/rm-tools/rmportal-device-display.png"
import cloudTools from "images/images/microsite/rm/rm-tools/cloud-tools.png"
import dataDetails from "images/images/microsite/rm/rm-tools/data-details.png"
import actionPlan from "images/images/microsite/rm/rm-tools/action-plans.png"
import manageChange from "images/images/microsite/rm/rm-tools/manage-change.png"

import RiskManagementNavBar from "./riskManagementNavBar";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const RiskManagementMicrosite = (props) => {
  const [elementId, setElementId] = useState("");
  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  })


  return (
    <>
    <RiskManagementNavBar elementInView={elementId}/>
      <BackToTopCustom />
      <RiskManagementCarousel />
      <div class="bootstrap">
        <div class="crunch">
          <div class="micro">
            <div class="micro-new">
              <div class="risk-micro">
                <html lang="en">
                  <body class="preload microsite microsite-rm microsite-rm-desktop microsite-new" data-spy="scroll" data-target="#navbarCollapse" data-offset="50">

                    <div class="page-wrap microsite microsite-new microsite-rm">
                      <div class="page-section page-section--site-layout col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="mainpage-wrapper">
                          <div class="tile-collection-wrap">
                            <div class="tile-collection">
                              <div id="tileRM" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h5>Risk Management Programs</h5>
                                  <div class="tile--graphic">
                                    <img src={program} alt="risk management programs" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Understand your risk, make a plan to manage it, and measure progress.</p>
                                  </div>
                                </div>
                              </div>
                              <div id="tileBenefits" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h5>Risk Management Benefits</h5>
                                  <div class="tile--graphic">
                                    <img src={benefit} alt="risk management benefits" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Make risk management a part of your corporate culture and generate business value.</p>
                                  </div>
                                </div>
                              </div>
                              <div id="tileTools" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h5>Risk Management Tools</h5>
                                  <div class="tile--graphic">
                                    <img src={tools} alt="risk management tool" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Engage people across the organization, track progress, and support decision making.</p>
                                  </div>
                                </div>
                              </div>
                              <div id="tileAssessments" class="tile tile--shadow ">
                                <div class="tile-wrap">
                                  <h5>Risk Management Assessments</h5>
                                  <div class="tile--graphic">
                                    <img src={assessment} alt="risk management assessment" class="img-responsive graphic" />
                                  </div>
                                  <div class="tile--body">
                                    <p>Third-party assessments for independent views and insight.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="programs" class="page-section page-section--programs col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={program} alt="risk management program" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Risk Management Programs</h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--intro col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="cliff-background col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                          <div class="mainpage-wrapper">
                            <div class="text-wrapper">
                              <h4 class="col-lg-8 col-md-8 col-sm-9 col-xs-9">Risk Management is a proven way to make practical business decisions that protect your systems and information.</h4>
                              <p class="first-paragraph col-lg-6 col-md-6 col-sm-6 col-xs-7">Risk Management helps you get your arms around the challenges you face and take steps to manage your specific risk.</p>
                              <div class="cloud-paragraph col-lg-9 col-md-7 col-sm-7 col-xs-8s">
                                <span class="cloud col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                  <img src={cloud1} alt="cloud" class="img-responsive graphic" />
                                </span>
                                <p class="second-paragraph col-lg-8 col-md-10 col-sm-11 col-xs-7">Getting started with Risk Management does not need to be complex—starting with the basics and building as needed is very effective.</p>
                              </div>
                              <p class="third-paragraph col-lg-6 col-md-6 col-sm-6 col-xs-7">Organizations get the greatest benefit from Risk Management when it becomes part of the corporate culture—how things are on a day-to-day basis.</p>
                              <h4 class="col-lg-8 col-md-7 col-sm-9 col-xs-8">Risk Management is a set of techniques that can be used at all levels of your organization to better understand what can impact you and what you have in place to prevent or minimize the impact. It helps you make good business decisions about the things you can do to improve your overall posture.</h4>
                              <div class="cloud-paragraph two col-lg-11 col-md-8 col-sm-6 col-xs-8">
                                <span class="cloud col-lg-4 col-md-0 col-sm-0 col-xs-12">
                                  <img src={cloud3} alt="cloud" class="img-responsive graphic" />
                                </span>
                                <p class="fourth-paragraph col-lg-6 col-md-9 col-sm-12 col-xs-12">Risk Management helps your organization prioritize efforts given finite resources when addressing an ever-changing threat landscape.</p>
                              </div>
                              <p class="fifth-paragraph col-lg-6 col-md-6 col-sm-7 col-xs-8">Effective risk management programs engage your organization at all levels and work across all aspects of how the organization operates and evolves.</p>
                            </div>
                          </div>
                        </div>
                        <div class="page-section--intro-second-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="umbrella-graphic col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={umbrella} alt="umbrella" class="img-responsive graphic large-screen" />
                            <img src={umbrellaSmall} alt="umbrella" class="img-responsive graphic small-screen" />
                          </div>
                          <div class="mainpage-wrapper">
                            <div class="text-wrapper">
                              <h4 class="col-xlg-10 col-lg-11 col-md-8 col-sm-9 col-xs-11">Risk Management functions should align with your organization and its operations. Cookie-cutter templates may seem like a fast path forward, but they usually fall short of working well with existing processes and functions.</h4>
                              <p class="first-paragraph col-xxlg-6 col-lg-5 col-md-6 col-sm-7 col-xs-7">Risk efforts should be  tailored to fit your current business practices. With this tailored fit, risk management processes can align with the way things work in your organization.</p>
                              <p class="second-paragraph col-lg-5 col-md-5 col-sm-7 col-xs-7 col-xxs-8">When they align, they grow together and allow risk management to support other business functions.</p>
                            </div>
                          </div>
                          <div class="ocean-gradient">
                            <div class="ocean">
                              <div class="wave"></div>
                              <div class="wave"></div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section--intro-third-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="submarine-graphic col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={submarine} alt="submarine" class="col-xlg-5 col-lg-6 col-md-6 col-sm-6 col-xs-6 img-responsive graphic" />
                          </div>
                          <div class="ocean-gradient-overlay col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="text-wrapper">
                                <h4 class="col-lg-9 col-md-8 col-sm-10 col-xs-12">Risk Management helps you understand your risks, make thoughtful decisions about them, and manage efforts to deal with them.</h4>
                                <div class="paragraph-container col-lg-7 col-md-8 col-sm-12 col-xs-12">
                                  <p class="first-paragraph col-lg-10 col-md-9 col-sm-8 col-xs-8">When you start a risk management program, you get your arms around the risk to your systems. You learn the challenges your business may face.</p>
                                  <p class="second-paragraph col-lg-10 col-md-9 col-sm-8 col-xs-8">Then you set up a programmatic scope that’s right for you. You set up processes to limit risk. You involve your whole team to get it started.</p>
                                  <p class="second-paragraph col-lg-10 col-md-9 col-sm-8 col-xs-8">Once you start it, you keep it going. You learn what works and what should change. You flow what you’ve learned back to adjust your approach.</p>
                                  <p class="second-paragraph col-lg-10 col-md-9 col-sm-8 col-xs-8">In time, risk management just becomes part of what you do every day.</p>
                                </div>

                                <div class="radar-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <ul class="radar col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li class="circle"><span><p>Know your system...<br />Learn the challenges...</p></span></li>
                                    <li class="circle"><span><p>Get it started.<br />Measure progress...<br />Make changes...</p></span></li>
                                    <li class="circle"><span><p>Study your environment...<br />Scope your efforts...</p></span></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section--program-features col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="white-banner header col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <h4 class="">Your Business Can Take Advantage of Risk Management</h4>
                              <p class="">Risk Management uses continuous, closed loop monitoring and assessment of your organization’s systems and controls, involving people, processes, data, and technology</p>
                            </div>
                          </div>
                          <div class="inset">
                            <div class="mainpage-wrapper">
                              <p>You can build an effective risk management program by:</p>
                            </div>
                          </div>
                          <div class="list-container">
                            <ul class="features col-xxlg-7 col-xlg-8 col-lg-8 col-md-10 col-sm-11 col-xs-11">
                              <li><p>Clearly defining roles and responsibilities.</p></li>
                              <li><p>Evolving risk management functions as the organization changes.</p></li>
                              <li><p>Identifying and empowering specific people to drive the risk management program.</p></li>
                              <li><p>Engaging people at all levels of your organization to be part of risk management processes.</p></li>
                              <li><p>Establishing metrics to know what’s working and what needs to change.</p></li>
                              <li><p>Supporting risk management functions with tools, expertise, and resources.</p></li>
                              <li><p>Establishing leadership support with executive and steering functions to drive efforts.</p></li>
                              <li><p>Aligning risk management processes with organizational structures and leveraging existing management and reporting functions.</p></li>
                              <li><p>Ensuring risk management processes are sustainable, without impacting other business functions.</p></li>
                              <li><p>Integrating risk management objectives with other business functions to avoid duplication of efforts and to achieve economies of scale.</p></li>
                            </ul>
                          </div>
                        </div>
                        <div class="page-section--program-attributes col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="attribute-container">
                            <div class="mainpage-wrapper">
                              <div class="img-tile small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={simple} alt="program simple" class=" img-responsive graphic small start-simple" />
                              </div>
                              <div class="text-tile start-simple left">
                                <div class="text-wrapper">
                                  <h6>Start Simple – then build</h6>
                                  <p>You can start with some simple risk management measures, then modify as your needs change. As your business grows, your program should grow with you.</p>
                                </div>
                              </div>
                              <div class="img-tile large">
                                <div class="graphic large start-simple"></div>
                              </div>
                              <div class="img-tile large">
                                <div class="graphic large agility"></div>
                              </div>
                              <div class="img-tile small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={agility} alt="agility" class="img-responsive graphic small agility" />
                              </div>
                              <div class="text-tile agility right">
                                <div class="text-wrapper">
                                  <h6>Agility</h6>
                                  <p>Risk management frameworks are designed to change based on both internal and external factors. They are not rigid, but accommodate change easily to maintain applicability and currency.</p>
                                </div>
                              </div>
                              <div class="img-tile small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={sustainability} alt="sustainability" class="img-responsive graphic small sustainability" />
                              </div>
                              <div class="text-tile sustainability left">
                                <div class="text-wrapper">
                                  <h6>Currency & Sustainability</h6>
                                  <p>Risk management is not a one-time set of efforts. It is a set of capabilities that will support and help drive many different typical business functions in ways that best keep the business safe and secure.</p>
                                </div>
                              </div>
                              <div class="img-tile large">
                                <div class="graphic large sustainability"></div>
                              </div>
                              <div class="img-tile large">
                                <div class="graphic large inclusive"></div>
                              </div>
                              <div class="img-tile small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={inclusive} alt="inclusive & empowering" class=" img-responsive graphic small inclusive" />
                              </div>
                              <div class="text-tile inclusive right">
                                <div class="text-wrapper">
                                  <h6>Inclusive & Empowering</h6>
                                  <p>Risk management should engage people from all areas across the organization. People at all levels should be engaged to support and benefit from the insight and direction provided by risk management efforts.</p>
                                </div>
                              </div>
                              <div class="img-tile small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={support} alt="decision & value support" class="img-responsive graphic small support" />
                              </div>
                              <div class="text-tile support left">
                                <div class="text-wrapper">
                                  <h6>Decision & Value Support</h6>
                                  <p>Risk management provides a current and detailed understanding of what your organization needs to protect and your are protecting against. This provides real data to make prudent business decisions of what to do and where to invest to provide the best value.</p>
                                </div>
                              </div>
                              <div class="img-tile large">
                                <div class="graphic large support"></div>
                              </div>
                              <div class="img-tile large">
                                <div class="graphic large efficiency"></div>
                              </div>
                              <div class="img-tile small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={efficiency} alt="efficiency & complimentary" class="img-responsive graphic small efficiency" />
                              </div>
                              <div class="text-tile efficiency right">
                                <div class="text-wrapper">
                                  <h6>Efficiency & Complimentary</h6>
                                  <p>Risk management requires effort and resources, but these functions can be accomplished efficiently and in ways that support other business objectives. Risk management should positively contribute to investment and operational efforts across the organization.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="benefits" class="page-section page-section--benefits col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={benefit} alt="benefit" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Risk Management Benefits</h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section parallax dynamic col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="page-section section-one col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="text-wrapper">
                                <div class="text-container col-lg-7 col-md-7 col-sm-10 col-xs-10">
                                  <h3>Effective Risk Management Programs change the dynamic of how the organization and its people deal with risks. Things are not left to chance, but are instead handled based on thoughtful business and data driven considerations.</h3>
                                </div>
                                <div class="text-container col-lg-6 col-md-6 col-sm-8 col-xs-10">
                                  <p>Risk management programs give people line-of-sight to where the business stands by means of meaningful measures and metrics.  This provides shared understanding of where improvements can be made—what is working and what can be improved.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-section parallax evolve col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="page-section section-two col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="text-wrapper">
                                <div class="text-container col-lg-7 col-md-7 col-sm-10 col-xs-10">
                                  <h3>Effective programs engrain risk management capabilities into the corporate culture.  Risk management becomes part of how things are done on a daily basis across every part of the organization. </h3>
                                </div>
                                <div class="text-container col-lg-6 col-md-6 col-sm-8 col-xs-10">
                                  <p>As the organization evolves and grows, risk management adapts accordingly because it is a program designed to handle changing factors.</p>
                                  <p>This agility keeps risk management programs effective methods to keep  information and systems safe and secure as the organization progresses.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tools" class="page-section page-section--tools col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={tools} alt="risk management tools" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Risk Management Tools</h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section page-section--rmportal col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="mainpage-wrapper">
                            <div class="text-wrapper col-lg-12 col-md-8 col-sm-9 col-xs-10 col-xxs-12">
                              <div class="importal-logo">
                                <img src={rmPortalTool} alt="risk management portal tool" class="img-responsive graphic" />
                              </div>
                              <h4 class="col-lg-12 col-md-11 col-sm-12 col-xs-12">NCG Orchestration Risk Management Framework Portal:<br />
                                <div class="smaller">Cloud-Based Tools for Risk Assessments, Roadmaps, and Lessons Learned</div>
                              </h4>
                            </div>
                          </div>
                          <div class="imp-devices col-lg-10 col-md-12 col-sm-12 col-xs-12">
                            <img src={rmPortalDevice} alt="risk management portal device display" class="img-responsive graphic" />
                          </div>
                        </div>
                        <div class="page-section--orchestration col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="banner col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="banner-text">
                                <p>Getting your arms around your risk, systems, and information can be challenging.</p>
                              </div>
                            </div>
                          </div>
                          <div class="mainpage-wrapper photos">
                            <ul>
                              <li class="first col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="photo-container">
                                  <div class="first photo"></div>
                                </div>
                                <div class="text-container">
                                  <p>Managing all the data and reporting can be almost impossible without automation, and typical compliance and risk management software can be complicated and expensive.</p>
                                </div>
                              </li>
                              <li class="second col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="text-container desktop">
                                  <p>NCG developed the Orchestration Risk Management Framework Portal as scalable cloud‐based solution that can be used by everyone in your organization to participate in a tailored risk management program.</p>
                                </div>
                                <div class="photo-container">
                                  <div class="second photo"></div>
                                </div>
                                <div class="text-container mobile">
                                  <p>NCG developed the Orchestration Risk Management Framework Portal as scalable cloud‐based solution that can be used by everyone in your organization to participate in a tailored risk management program.</p>
                                </div>
                              </li>
                              <li class="third col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="photo-container">
                                  <div class="third photo"></div>
                                </div>
                                <div class="text-container">
                                  <p>We have developed the Risk Management Portal based on NIST standards and our decades of experiences helping organization build and sustain effective program. These tools empower people across your organization by helping them know what is needed, when it is needed, and how your team can collaborate to execute risk management effectively.</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="page-section page-section--functions col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="banner col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="banner-text-container col-xxlg-7 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h4>NCG cloud-based tools organize and perform risk management processes and develop roadmaps to make progress on specific efforts—all tailored to fit your organization.</h4>
                              </div>
                            </div>
                          </div>
                          <div class="cloud-devices graphic col-xxlg-6 col-xlg-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <img src={cloudTools} alt="cloud tools" class="img-responsive graphic" />
                          </div>
                          <div class="inset">
                            <div class="inset-text-container col-lg-7 col-md-7 col-sm-10 col-xs-11">
                              <p>To make sure the fit continues to be a good one, the Risk Management Portal has functions for you to measure the effectiveness of efforts and capture lessons learned. These inputs become the basis to make improvements and maintain currency with your current operating environment.</p>
                            </div>
                          </div>
                          <div class="data-details half-banner left col-xxlg-8 col-lg-9 col-md-10 col-sm-12 col-xs-12">
                            <div class="vector-graphic col-xxlg-8 col-xlg-7 col-lg-6 col-md-5 col-sm-4 col-xs-4 col-xxs-10">
                              <img src={dataDetails} alt="data details" class="img-responsive graphic" />
                            </div>
                            <div class="text-wrapper">
                              <h6>Create and manage current systems and data details, along with their associated risks:</h6>
                              <ul>
                                <li>Inventory enterprise systems, subsystems, their location, boundaries, and owners </li>
                                <li>Inventory type of information processed, stored, or shared on the system, and users</li>
                                <li>Inventory what is in place to protect those assets and understand what threats are applicable to your specific situation</li>
                                <li>Analyze the impacts to your business if any of the threats are realized and make thoughtful decisions of what to do about those risks.</li>
                              </ul>
                            </div>
                          </div>
                          <div class="action-plans half-banner right col-xxlg-7 col-lg-9 col-md-10 col-sm-12 col-xs-12">
                            <div class="vector-graphic mobile col-xxlg-7 col-xlg-7 col-lg-6 col-md-5 col-sm-4 col-xs-4 col-xxs-10">
                              <img src={actionPlan} alt="action plan" class="img-responsive graphic" />
                            </div>
                            <div class="text-wrapper">
                              <h6>Create & Manage Roadmaps and Action Plans</h6>
                              <ul>
                                <li>Based on your business decisions, create roadmaps of what you need to get done</li>
                                <li>For specific effort you are ready to move forward with, create plans and list out tasks</li>
                                <li>Set milestones and timelines, and make resources assignments</li>
                                <li>Track progress and completion</li>
                                <li>Measure effectiveness and support continuous improvements</li>
                              </ul>
                            </div>
                            <div class="vector-graphic large col-xxlg-7 col-xlg-7 col-lg-6 col-md-5 col-sm-4 col-xs-4">
                              <img src={actionPlan} alt="action plan" class="img-responsive graphic" />
                            </div>
                          </div>
                          <div class="manage-change half-banner left col-lg-9 col-md-10 col-sm-12 col-xs-12">
                            <div class="vector-graphic col-xxlg-8 col-xlg-7 col-lg-6 col-md-5 col-sm-4 col-xs-4 col-xxs-10">
                              <img src={manageChange} alt="manage change" class="img-responsive graphic" />
                            </div>
                            <div class="text-wrapper">
                              <h6>Manage Change & Maintain Currency</h6>
                              <ul>
                                <li>Captures changes for new systems and interfaces</li>
                                <li>Account for internal and external changes across the operating environment</li>
                                <li>Update factors related to changes in markets, customer base, suppliers, and other key considerations</li>
                                <li>Maintain alignment with current organization structures and personnel</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="assess" class="page-section page-section--assessments col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                        <div class="site-layout-title">
                          <div class="mainpage-wrapper">
                            <div class="site-layout-title-inner">
                              <div class="site-layout--graphic circle">
                                <div class="graphic-wrap">
                                  <img src={assessment} alt="risk management assessments" class="img-responsive graphic" />
                                </div>
                              </div>
                              <h2>Risk Management Assessments</h2>
                            </div>
                          </div>
                        </div>
                        <div class="page-section intro col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="banner intro col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="banner-content">
                                <div class="intro graphic col-lg-3 col-md-3 col-sm-3 col-xs-2 col-xxs-10"></div>
                                <div class=" banner-text-container col-lg-10 col-md-8 col-sm-10 col-xs-12">
                                <h4>NCG Third Party Risk Assessments</h4>
                                  <p>NCG provides third party risk assessments that provide you with unbiased, external view of your organization’s security posture. Third-party risk assessments are required under many compliance frameworks, and provide a cross-check on internal efforts regardless of being a requirement or not.
                                </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="inset">
                            <div class="mainpage-wrapper">
                              <h5>Third Party Assessment Process</h5>
                            </div>
                          </div>
                          <div class="body-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mainpage-wrapper">
                              <div class="first-paragraph col-lg-10 col-md-11 col-sm-12 col-xs-12">
                                <p>There are many types of Assessments designed for different regulatory constructs, industries and types of organizations Compliance constructs include: NIST, ISO, HIPAA, CCPA, CMMC, EASA, FFIEC, FISMA, GDPR, GLBA, IATA, NERC, FERC, SEC OCIE, SOX, PCI-DSS,  SSAE, etc.). We help you pick the right basis for your organization—to meet requirements and provide the best value.</p>
                              </div>
                              <div class="assessment-process container">
                                <div class="assessment-process graphic mobile col-lg-2 col-md-4 col-sm-4 col-xs-10"></div>
                                <div class="text-container col-lg-7 col-md-8 col-sm-8 col-xs-11">
                                  <p>NCG’s assessment process is structured to provide you and stakeholders participating in the process with clearly defined expectations and responsibilities. Throughout the assessment process we provide context and explanation of steps to empower participants in their day-to-day jobs and to have better understanding of their overall roles in the organization’s risk management.</p>
                                </div>
                                <div class="assessment-process graphic large col-lg-2 col-md-4 col-sm-4 col-xs-4"></div>
                              </div>
                              <div class="assessment-findings container">
                                <div class="assessment-process graphic mobile col-lg-2 col-md-4 col-sm-4 col-xs-10"></div>
                                <div class="text-container col-lg-7 col-md-8 col-sm-8 col-xs-11">
                                  <p>We provide assessment findings, supported by applicable technical and procedural details. Based on those findings, we provide recommendations for important actions and improvements. We help organizations review findings and recommendations to create their own implementation roadmaps.</p>
                                </div>
                                <div class="assessment-findings graphic large col-lg-2 col-md-4 col-sm-4 col-xs-4"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </body>
                </html>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}
export default RiskManagementMicrosite;