import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import BackToTopCustom from "components/layout/BackToTopCustom";
import Carousel from "components/layout/carousel.component";
import Spotlight from "components/layout/spotlight.js";
import PageSection from "components/layout/pageSection.component";
import PageSectionContentLeft from "components/layout/pageSectionContentLeft.component";
import PageSectionContentRight from "components/layout/pageSectionContentRight.component";
import LinkDefault from "components/layout/linkDefault.component";
import Image from "components/layout/image.component";
import ContentContainer from "components/layout/contentContainer.component";
import PageWrapper from "components/layout/pageWrapper.component";
import TextWrapper from "components/layout/textWrapper.component";
import variables from "../../../style/variables.module.scss";
import classNames from "classnames";
// IMAGES
import processImage from "../../../images/images/index/page/process.jpg";
import processMobileImage from "../../../images/images/index/page/mobile/process.jpg";
import complianceImage from "../../../images/images/index/page/compliance.jpg";
import federalImage from "../../../images/images/index/page/federal.jpg";
import federalMobileImage from "../../../images/images/index/page/federal.jpg";
import capabilitiesImage from "../../../images/images/index/page/capabilities.jpg";
import orchestrationImage from "../../../images/images/index/page/OrchestrationCloudLogo.png";
import teamImage from "../../../images/images/index/page/team.jpg";
import teamMobileImage from "../../../images/images/index/page/team.jpg";
import serviceImage from "../../../images/images/index/page/service-graphic.jpg";
import serviceMobileImage from "../../../images/images/index/page/service-graphic.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    borderTop: "6px solid #1593cd",
    // paddingTop: "3rem",
  },
  listItem: {
    fontSize: variables.fontBody,
    letterSpacing: ".08em",
    color: variables.textPrimary,
  },
  unorderedList: {
    paddingLeft: 20,
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 0,
  },
  strong: {
    fontWeight: "bold",
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "2.5em",
    lineHeight: "1.125em",
    marginTop: 30,
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  textContainer: {
    position: "relative",
  },
  textLeft: {
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  textRight: {
    paddingLeft: 40,
    [theme.breakpoints.down('md')]: {
      paddingRight: 90,
      paddingLeft: 60,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingTop: 30,
    },
  },
  textVideo: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 50,
      paddingRight: 20,
      paddingLeft: 20,
      margin: "0px auto",
    },
  },
  customColumn: {
    [theme.breakpoints.down('md')]: {
      paddingRight: 60,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
      margin: "0px auto",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      paddingRight: 90,
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 20,
      paddingLeft: 20,
      margin: "0px auto",
    },
  },
  textColumn: {
    paddingTop: 30,
  },
  spotlightContainer: {
    borderBottom: "1px solid #d5e1eb",
    height: "fit-content",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 85,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
  },
  mainContentSpotlight: {
    display: "block!important",
    margin: "0px auto",
  },
  carouselWrapper: {
    height: 750,
    position: "relative",
  },
  prLink: {
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.up('lg')]: {
      display: "none",
    },
  },
  icon: {
    fontSize: "3em",
    marginBottom: 3,
  },
}));

const MainPage = () => {
  const classes = useStyles();
  return (
    <PageWrapper >
      <BackToTopCustom/>
      <div className={classes.carouselWrapper}>
        <Carousel />
      </div>
      <div className={classes.mainContent}>
        <div class={classes.spotlightContainer}>
          <Container maxWidth="lg" className={classes.mainContentSpotlight}>
            <Spotlight />
          </Container>
        </div>
        {/* INTRO */}
        <PageSectionContentRight
          variant="video"
          src={"https://www.youtube.com/embed/m2gQesgo98M"}
        >
          <Grid item xs={12} sm={12} md={6} lg={5} className={classNames(classes.textContainer, classes.textLeft, classes.textVideo)}>
            <TextWrapper variant="h2">
              <Typography variant="h2">Northcross Group Helps Organizations with Challenges and Opportunities</Typography>
              <Typography variant="body1">Northcross Group (NCG) works to meet our clients biggest challenges for growth and sustainability in today’s digital business environment.</Typography>
              <Typography variant="body1">We leverage our experience in business process and program management, along with our knowledge in technology and cybersecurity to address organizational objectives.</Typography>
              <Typography variant="body1">Our mix of technical and business acumen helps ensure that people and processes are part of the approach for success just as much as technology and infrastructure. We make sure this is done securely and in compliance with requirements.</Typography>
            </TextWrapper>
          </Grid>
        </PageSectionContentRight>

        {/* SERVICES & CAPABILITIES */}
        <PageSectionContentRight
          variant="absolute"
          image={capabilitiesImage}
          width={"1000px"}
          id="uniting"
        >
          <Grid item xs={12} sm={12} md={7} lg={6} className={classNames(classes.textContainer, classes.textLeft)}>
            <TextWrapper variant="h2">
              <Typography variant="h2">Bringing People, Data, Technology, and Security Together</Typography>
              <Typography variant="body1">To accomplish tasks, you must take a systemic view of how people, data, and technology interact. In addition, confidentiality, integrity, and availability of data must also be considered to produce desired results. This must all be done in a way that supports an organization’s needs and values.</Typography>
              <Typography variant="body1">We take a systemic view of every project. We work with the technology pieces, and also with the underlying data and the people that will use it. These fundamentals—technology, data, and people—are all part of the equation for success.</Typography>
              <Typography variant="body1">Some consulting firms focus on just one of these fundamental areas, or look at them as separate silos. This approach puts the burden back on the client to bring things together in the end. NCG is different. We make sure that all areas with their interrelationships are covered and provide a complete solution.</Typography>
              <Typography variant="h5">FOR EACH ENGAGEMENT WE STRIVE TO:</Typography>
              <ul className={classes.unorderedList}>
                <li className={classes.listItem}>Understand the Organization We Are Supporting</li>
                <li className={classes.listItem}>Have a Complete Grasp of the Requirements and Goals</li>
                <li className={classes.listItem}>Construct a Plan to Accomplish the Work</li>
                <li className={classes.listItem}>Implement a Framework to Measure Progress/Success</li>
                <li className={classes.listItem}>Foster Communication and Collaboration Throughout the Engagement</li>
              </ul>
              <LinkDefault href="/capabilities">Learn more about our Services &amp; Capabilities</LinkDefault>
            </TextWrapper>
          </Grid>
        </PageSectionContentRight>

        {/* PAST PERFORMANCE */}
        <PageSectionContentLeft
          image={processImage}
          mobileImage={processMobileImage}
          id="understanding" 
        >
          <Grid item xs={12} sm={8} md={8} lg={8} className={classNames(classes.textContainer, classes.textRight)}>
            <TextWrapper variant="h2">
              <Typography variant="h2">Understanding Your Needs to Craft the Best Solution</Typography>
              <Typography variant="body1">We begin our client engagement with a discussion of the client’s challenges and our advice to focus our attention on what needs to be done. Our services emphasize using technology to meet business objectives and elevate businesses to new levels of operations, markets, and profitability. NCG consultants have both practical technology and business experience to use technology to solve business problems. We leverage all of our tools and capabilities to work proactively each and every day to execute and deliver in a wide range of business environments.</Typography>
              <Typography variant="body1">We work hard to understand your specific business realities—both the challenges and opportunities. When technology is applied effectively it can leverage new opportunities for a business. However, when there are disconnects, technology can become a frustration or worse to the business. We believe that technology is a set of tools for business with incredible potential.</Typography>
              <Typography variant="body1">Changing that potential into tangible business value requires a combination of skills. NCG consultants have both practical technology and business experience. This cross-discipline helps us understand the business challenge, what approach makes the most sense, and how to implement it. Our broad perspective helps us execute and deliver in a wide range of business environments.</Typography>
              <LinkDefault href="/past-performance">Learn more about our Past Performance</LinkDefault>
            </TextWrapper>
          </Grid>
        </PageSectionContentLeft>

        {/* COMPLIANCE & RISK MANAGEMENT */}
        <PageSectionContentRight
          image={complianceImage}
          id="compliance"
        >
          <Grid item xs={12} sm={8} md={10} lg={8} className={classNames(classes.textContainer, classes.textLeft)}>
            <TextWrapper variant="h2">
              <Typography variant="h2">Compliance &amp; Risk Management</Typography>
              <Typography variant="body1">Compliance and risk management are fundamental concepts for organizations in highly regulated industries to have working effectively and efficiently. When working well, they help an organization protect itself from impact and exposure—even providing benefits for operations and business functions. When they don't work—they are costly, time consuming, frustrating, and can impact your business.</Typography>
              <Typography variant="body1">NCG has broad experience across regulatory and industry compliance frameworks. We work with organizations to understand what is needed compared to what are future targets, and develop prioritized roadmaps for your compliance. We approach compliance with a risk management perspective that aligns to an organization's overall risk program.</Typography>
              <Typography variant="body1">Effective risk management programs need to align to an organization and its operations—working with them and not against them. We help organizations build risk programs, or enhance existing ones, to be productive assets for the organization.</Typography>
              <Typography variant="body1">People are key components to the success and value of compliance and risk management programs. We take an approach of empowering your people for success in dealing with compliance and risk challenges. We provide services and tools to craft sustainable programs with supporting data integration, processes, and infrastructure for your team.</Typography>
              <LinkDefault href="../../../../content/docs/ServiceCards/NCG_Compliance_RiskMgmt.pdf" variant="download" target="_blank">Download our Compliance &amp; Risk Management Service Card</LinkDefault>
            </TextWrapper>
          </Grid>
        </PageSectionContentRight>

        {/* CORPORATE INFORMATION */}
        <PageSectionContentLeft
          image={serviceImage}
          mobileImage={serviceMobileImage}
          id="delivering"
        >
          <Grid item xs={12} sm={8} md={8} lg={8} className={classNames(classes.textContainer, classes.textRight)}>
            <TextWrapper variant="h2">
              <Typography variant="h2">Business &amp; Technology Tools and Services</Typography>
              <Typography variant="body1">NCG has an approach that allows us to understand each individual organization we support. We take into account the sector and markets the organization works in and how it is organized to operate.</Typography>
              <Typography variant="body1">We tailor work plans, implement progress and success metrics, and foster communication and collaboration to match the specific operation environment.</Typography>
              <Typography variant="body1">NCG provides clients with complete solutions that address technology components and account for organizational needs. Consultants at NCG improve business operations, markets, and profitability by using technology as a tool for business. Moreover, NCG team members have developed specific tools to address common capabilities in several areas.</Typography>
              <LinkDefault href="/about-ncg/corporate-background">Learn more about our Corporate Information</LinkDefault>
            </TextWrapper>
          </Grid>
        </PageSectionContentLeft>

        {/* ORCHESTRATION */}
        <PageSection
          id="digitalConsult"
        >
          <Grid item xs={12} sm={8} md={8} lg={7} className={classNames(classes.textContainer, classes.textLeft, classes.customColumn)}>
            <TextWrapper variant="h2">
              <Typography variant="h2">Consulting Services in Today’s Digital Environment</Typography>
              <Typography variant="body1">We use technology and data to actively engage people and make sure they are focused on the most important tasks. We have tools that are focused on actions that support particular business events. These tools are configured for specific industries and various operation processes.</Typography>
              <Typography variant="body1">We are an integration and cybersecurity firm with a proven record in the public, private, and defense sectors. We have supported organizations through complex conversions, migrations, integrations, deployments, and program development. NCG has built sustainable programs to better secure, grow, and manage these organizations.</Typography>
              <Typography variant="body1">We refer to our tools as <span class={classes.strong}>Orchestration Tools</span> because they engage and connect different types of users inside and outside an organization with data and work-flows specific to a given objective. While each organization and challenge is unique, <span className={classes.strong}>Orchestration Tools</span> provide a framework and targeted technology that can orchestrate efforts, save time, reduce frustration, and mitigate risk.</Typography>
              <LinkDefault href="/capabilities/orchestration-platform">Learn more about our Orchestration Platform</LinkDefault>
            </TextWrapper>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} className={classes.contentWrapper}>
            <ContentContainer variant="customWidth">
              <Image url={orchestrationImage} variant="customWidth" />
            </ContentContainer>
            <div className={classes.textColumn}>
              <Typography variant="body1"><span class={classes.strong}>Orchestration Tools</span> are designed to bring people, processes, infrastructure, and data together for a specific purpose. These tools can be used for one-time events, or to support the sustainability of ongoing programs.</Typography>
              <Typography variant="body1"><span class={classes.strong}>Orchestration Tools</span> help organizations get their arms around important efforts with an execution focus and help people at all levels of the organization work off the same page.</Typography>
            </div>
          </Grid>
        </PageSection>

        {/* MARKET SUPPORT */}
        <PageSectionContentLeft
          image={federalImage}
          mobileImage={federalMobileImage}
          id="fedCommercial"
        >
          <Grid item xs={12} sm={12} md={8} lg={8} className={classNames(classes.textContainer, classes.textRight)}>
            <TextWrapper variant="h2">
              <Typography variant="h2" className={classes.headingOver}>Best of Both Worlds</Typography>
              <Typography variant="h5" className={classes.headingUnder}>INDUSTRY BEST PRACTICES AND FEDERAL INSIGHT</Typography>
              <Typography variant="body1">We work in the private sector with highly regulated industries that support critical infrastructure industries (financial services, healthcare, energy, manufacturing, transportation, agriculture, and information technology). We also work with the federal government in the civilian and defense sectors.</Typography>
              <Typography variant="body1">Our team has experience working from the perspectives of both the regulator and industries being regulated. We bring to industry in-depth knowledge of regulatory frameworks and guidance. To the federal space, we bring best practices and the latest innovations from industry.</Typography>
              <LinkDefault href="/markets">Learn more about the Markets we support</LinkDefault>
            </TextWrapper>
          </Grid>
        </PageSectionContentLeft>

        {/* NCG TEAM */}
        <PageSectionContentLeft
          image={teamImage}
          mobileImage={teamMobileImage}
          id="teamExperience"
        >
          <Grid item xs={12} sm={12} md={8} lg={8} className={classNames(classes.textContainer, classes.textRight)}>
            <TextWrapper variant="h2">
              <Typography variant="h2" className={classes.headingOver}>The NCG Team</Typography>
              <Typography variant="h5" className={classes.headingUnder}>HIGHLY EXPERIENCED IN THE PUBLIC AND PRIVATE SECTORS</Typography>
              <Typography variant="body1">NCG is comprised of highly skilled resources with proven abilities to deliver on mission objectives in the federal and private sectors. NCG’s experienced and cleared staff (up to TS SCI active clearances) is supported by the firm’s industry best practices and processes with tools and controls to ensure quality delivery and results.</Typography>
              <LinkDefault href="/about">Learn more about our Team</LinkDefault>
            </TextWrapper>
          </Grid>
        </PageSectionContentLeft>
      </div>
    </PageWrapper>
  )
}

export default MainPage;