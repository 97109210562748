import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  // LIST
  unorderedList: {
    paddingLeft: 20,
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 0,
  },
  listItem: {
    fontSize: variables.fontBody,
    letterSpacing: ".08em",
    paddingTop: 10,
    color: variables.textPrimary,
  },
}));

const PPRevenueImprovements = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Revenue Cycle Improvements</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/revenue-improvements" variant="active">Revenue Cycle Improvements</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Cash Acceleration
                </li>
                <li className={classes.sideBarListItem}>
                  Reduction in AR days
                </li>
                <li className={classes.sideBarListItem}>
                  Implemented Quality &amp; Productivity Standards
                </li>
                <li className={classes.sideBarListItem}>
                  Staff Mentoring
                </li>
                <li className={classes.sideBarListItem}>
                  Staffing Optimization
                </li>
                <li className={classes.sideBarListItem}>
                  Accounting Coordinate
                </li>
                <li className={classes.sideBarListItem}>
                  Team Leadership &amp; Management Support
                </li>
                <li className={classes.sideBarListItem}>
                  Team Building
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2">
                Franklin Memorial Hospital
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                Franklin Community Health Network includes Franklin Memorial Hospital as well as Franklin Community Physicians. Both entities provide services that are billed through a central business office (CBO). Although each entity has dedicated software and billing teams, the past years had seen significant management turnover within the CBO with corresponding alternate department structures attempted. Support areas were identified:
              </Typography>
              <ul className={classes.unorderedList}>
                <li className={classes.listItem}>
                  Revenue Cycle Management
                </li>
                <li className={classes.listItem}>
                  Department level organizational structure
                </li>
                <li className={classes.listItem}>
                  Definitions authority and responsibility
                </li>
                <li className={classes.listItem}>
                  Coordination with other departments including Accounting, Case Management, and Patient Registration
                </li>
              </ul>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG fulfilled the role of Interim Manager of Hospital Accounts Receivable. In this capacity, NCG took responsibility for the performance of staff and managed operations across billing. Performance quality controls and measures were defined, communicated and implemented. Improvements to the department structure, communications channels and issue resolution management procedures were designed and implemented.
              </Typography>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                Franklin Memorial Hospital closed its FYE with increased cash on hand, third party receivables over 90 days under 20%, and AR Days at an organization historic low of 50.0. Franklin Memorial hospital completely met Franklin Community Health Network financial and reporting requirements of FCHN accounting and external auditors smoothly and within deadlines.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default PPRevenueImprovements;