import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import variables from "../../style/variables.module.scss";

const useStyles = makeStyles((theme) => ({
  listItemLink: {
    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
    fontWeight: 500,
    fontSize: 13,
    color: variables.ncgBlue3,
    display: "inline-block",
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: "normal",
    '&:hover:hover': {
      textDecoration: "underline",
    },
  },
  listItem: {
    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
    fontWeight: 400,
    fontSize: 13,
    color: variables.ncgBlue3,
    display: "inline-block",
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: "normal",
  },
}));

const BreadcrumbItem = (props) => {
  const classes = useStyles(props);
  if (props.variant === "link") {
    return (
      <Link href={props.href} className={classNames(classes.listItemLink)}>{props.children}</Link>
    );
  }
  if (props.variant === "active") {
    return (
      <Link className={classNames(classes.listItem, classes.listItemChild)}>{props.children}</Link>
    );
  }
    else  {
      return (
        <Link className={classNames(classes.listItem, classes.listItemChild)}>{props.children}</Link>
      )
    }

};

export default BreadcrumbItem;