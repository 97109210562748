
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import classNames from "classnames";
const useStyles = makeStyles((theme) => ({
  imageHeight: {
    height: "100%",
    width: "auto",
    display: "block",
  },
  imageWidth: {
    height: "auto",
    width: "100%",
    display: "block",
  },
}));

const Image = (props) => {
  const classes = useStyles(props);
  if (props.variant === "customWidth") {
    return (
      <>
        <img src={props.url} className={classes.imageWidth} alt={props.alt}/>
      </>
    );
  }
  else if(props.height!=null){
    return (
      <>
        <img src={props.url} className={classes.imageHeight} alt={props.alt}/>
      </>
    )
  }
  else {
    return (
      <>
        <img src={props.url} className={classNames("graphic", "img-responsive", props.className)} alt={props.alt}/>
      </>
    )
  }
}

export default Image;