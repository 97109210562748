import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "2.5em",
    lineHeight: "1.125em",
    marginTop: 30,
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
}));

const PPTechnologyBusiness = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Technology to Further Business</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/technology-business" variant="active">Technology to Further Business</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Integrating Technology and the Business
                </li>
                <li className={classes.sideBarListItem}>
                  Business System Analysis
                </li>
                <li className={classes.sideBarListItem}>
                  Project Management
                </li>
                <li className={classes.sideBarListItem}>
                  System Development
                </li>
                <li className={classes.sideBarListItem}>
                  System Integration
                </li>
                <li className={classes.sideBarListItem}>
                  Web Services
                </li>
                <li className={classes.sideBarListItem}>
                  WebMethods Service Oriented Architecture (SOA)
                </li>
                <li className={classes.sideBarListItem}>
                  Governance and Service Management
                </li>
                <li className={classes.sideBarListItem}>
                  Fidelity Deposit &amp; Loan Systems / Fiserv ITI Premier
                </li>
                <li className={classes.sideBarListItem}>
                  XML/XSL
                </li>
                <li className={classes.sideBarListItem}>
                  Oracle DBMS
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2" className={classes.headingOver}>
                Branch Automation
              </Typography>
              <Typography variant="h2" className={classes.headingUnder}>
                TD Banknorth
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                TD Banknorth had struggled to implement a branch automation system that would help streamline their ability to service customers and help increase product sales. The impetus for this project began in the early 1990’s going through a number of false starts, vendor changes, and management changes. The latest iteration was again facing delays and subsystem failures.
              </Typography>
            </Grid>
            <Grid item m={12} md={9} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG stepped in to take control of the project. NCG pulled together the disparate technology and business components of the project and recast the plan based on the reality of the current situation.
              </Typography>
              <Typography variant="body1">
                Disciplined processes were implemented to control development efforts and coordinate integration activities.
              </Typography>
              <Typography variant="body1">
                Cross-disciplined working groups were constituted to take ownership of key areas and functions. A tiered readiness process was implemented as part of an overall risk management process. This infrastructure supported governance functions, as well as fostering communication and collaboration.
              </Typography>
              <Typography variant="body1">
                NCG reviewed and updated technical approaches and plans to ensure synchronization between systems, subsystems, and interfaces. NCG ensured coordination with the Bank’s WebMethods SOA implementation and the various internal and external interfacing systems.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                TD Banknorth has fully implemented its branch automation system across its footprint. The system is providing desired efficiency and effectiveness gains. This implementation was completed as planned and under budget.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default PPTechnologyBusiness;