// imports
import { Drawer, AppBar, Grid, Container, MenuList, Link, IconButton, Toolbar, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useState, Fragment, useEffect, useMemo } from "react";
import NcgLogo from "../layout/ncgLogo";
import NcgWhiteLogo from "../layout/ncgWhiteLogo";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import variables from "style/variables.module.scss";

import RFPForm from "../microsites/rfpForm.component";

// import css
import 'style/navbar.css';

// import js
import "../../scripts/custom"
import scrollClassFunction from "helpers/scrollClassFunction ";
import NcgFullWhiteLogo from "components/layout/ncgFullWhiteLogo";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 80,
    background: "linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%)",
    [theme.breakpoints.down(600)]: {
      height: "fit-content",
    },
    '& #mobileNavbar': {
      [theme.breakpoints.up(768)]: {
        display: "none!important",
      },
    },
    '& #desktopNavbar': {
      [theme.breakpoints.down(768)]: {
        display: "none!important",
      },
    },
  },
  blueAppBar: {
    background: "linear-gradient(to right,#092c58 0,#2773bd 100%)",
  },
  whiteAppBar: {
    background: "linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255) 100%)",
  },
  neonAppBar: {
    background: (`
      linear-gradient(
        90deg,
        #37AFA1 0%, #2FABDA 25%, #398AE5 50%, #5359E6 75%, #7C2DE1 100%
      )
    `),
    color: "#fff"
  },
  logoCell: {
    display: "flex",
    alignItems: "center",
    maxHeight: 70,
    justifyContent: "flex-start",
    height: "100%",
  },
  navWrapper: {
    height: "100%",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  topRow: {
    display: "flex",
    width: "100%",
    height: "inherit",

    mainpageWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  navContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  navMenu: {
    display: "flex",
    height: "100%",
    justifyContent: "right",
    listStyle: "none",
    margin: 0,
    padding: 0,
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
  },
  navLink: {
    textAlign: "center",
    lineHeight: "20px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "20%",
  },
  linkTitle: {
    color: "white",
    fontSize: 14,
    fontFamily: "Helvetica Neue",
    padding: 10,
    width: "100%",
    display: "block",
    '&:hover:hover': {
      color: "white",
      backgroundColor: "rgba(0, 142, 255, 0.1)",  
    },
  },
  linkTitleWhite: {
    color: "#9d9d9d",
    fontSize: 14,
    fontFamily: "Helvetica Neue",
    padding: 10,
    '&:hover:hover': {
      backgroundColor: "rgba(0, 142, 255, 0.1)",
    },
  },
  linkIcon: {
    display: "flex",
    alignItems: "center",
  },
  navLinkLogo: {
    minWidth: 190,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  linkLogo: {
    height: 50,
    width: 150,
    minWidth: 150,
    backgroundColor: "transparent",
  },

  // APPLY ON SCROLL 
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  collapsed: {
    display: "flex",
    [theme.breakpoints.up('lg')]: {
      display: "none",
    },
  },
  containerWrapper: {
    height: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  collapsedNavLink: {
    padding: 12,
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down(900)]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  collapsedLinkTitle: {
    padding: 0,
    maxHeight: "100%",
    width: "100%",
    [theme.breakpoints.down(900)]: {
      fontSize: 13,
      lineHeight: "1.1rem",
    },
  },
  // MOBILE
  whiteMobileNavBar: {
    backgroundColor: "white",
    color: variables.ncgBlue3,
    display: "flex",
    justifyContent: "space-between",
  },
  blueMobileNavBar: {
    background: "linear-gradient(to right,#092c58 0,#2773bd 100%)",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
  },
  menuButton: {
    position: "absolute",
    right: 15,
  },
  menuPaper: {
    minWidth: 600,
    position: "absolute",
    top: 64,
    right: 0,
    borderRadius: 0,
    width: "100%",
    borderTop: "3px solid #1091cc",
  },
  mobileMenuList: {
    padding: 0,
    width: "100%",
    borderBottom: "3px solid #1091cc",
    '&:hover': {
      "& $mobileMenuItem": {
        borderColor: "#1091cc",
      },
    },
  },
  mobileMenuItem: {
    padding: 0,
    '&:first-of-type': {
      "& $mobileLink": {
        borderTop: "none",
      },
    },
    '&:last-of-type': {
      "& $mobileLink": {
        borderBottom: "none",
      },
    },
  },
  mobileLink: {
    padding: "20px 10px 20px 50px",
    width: "100%",
    borderBottom: "1px solid rgba(122, 150, 179, 0.51)",
    borderTop: "1px solid rgba(122, 150, 179, 0.51)",
    color: variables.textBlue,
    textTransform: "uppercase",
    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
    fontWeight: 500,
    fontSize: variables.fontBody2,
    letterSpacing: ".08em",
    lineHeight: "normal",
    display: "block",
    '&:hover': {
      backgroundColor: variables.ncgBlue4,
      color: "white!important",
    },
    whiteSpace: "pre-wrap",
  },
}));


const MicrositeNavBar = (props) => {
  const { bgColor = "blue" } = props;
  // modal pop ups
  const [modal, setModal] = useState(false);
  const openForm = (event) => {
    setModal(true);
  }
  const closeForm = () => {
    setModal(false);
  }
  //mobile states
  const [open, setOpen] = useState(false);
  const openNav = (event) => {
    setOpen(true);
  }
  const closeNav = () => {
    setOpen(false);
  }
  const classes = useStyles();

  const renderedLogo = useMemo(() => {
    switch (bgColor) {
      case "white":
        return <NcgLogo />;
      case "neon":
        return <NcgFullWhiteLogo />;
      case "blue": 
      default:
        return <NcgWhiteLogo />;
    }
  }, [bgColor]);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      scrollClassFunction(props.menuItems, props.elementInView, document)
    );
    return () => window.removeEventListener("scroll", scrollClassFunction);
  }, [props.elementInView, props.menuItems]);

  return (
    <AppBar
      position="fixed"
      className={
        classNames(
          classes.appBar,
          bgColor === "white" && classes.whiteAppBar,
          bgColor === "blue" && classes.blueAppBar,
          bgColor === "neon" && classes.neonAppBar,
        )
      }
    >
      <div className={classNames(classes.topRow, classes.desktop)} id="desktopNavbar">
        <Container maxWidth="lg">
          <div className={"navContainer"}>
            <Grid item xs={3} md={2} lg={2} xl={2} className={classes.logoCell}>
              <Link href="/" className={classes.linkLogo}>
                {renderedLogo}
              </Link>
            </Grid>
            <Grid item xs={9} md={10} lg={10} xl={10} className={classes.navWrapper}>

              <ul className={classNames(classes.navMenu, "navbar-nav")}>
                {props.menuItems.map((item) => {
                  return (!item.contactForm) ? (
                    <li className={classNames(classes.navLink, "microNav")}>
                      <Link
                        href={`${item.href}`}
                        className={
                          classNames(
                            bgColor === "white" && classes.linkTitleWhite,
                            bgColor !== "white" && classes.linkTitle
                          )
                        }
                        name={`${item.label}`}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ) : (
                      <li className={classNames(classes.navLink, "microNav")}>
                        <Link
                          onClick={() => openForm()}
                          href={`${item.href}`}
                          className={
                            classNames(
                              bgColor === "white" && classes.linkTitleWhite,
                              bgColor !== "white" && classes.linkTitle
                            )
                          }
                          name={`${item.label}`}
                        >
                          {item.label}
                        </Link>
                        <RFPForm open={modal} closeForm={closeForm} rfpType={item.rfpType} />
                      </li>
                    )
                }
                )}
              </ul>
            </Grid>
          </div>
        </Container>
      </div>
      {/* VIEW ON SCROLL AND BELOW 1279 PX / LG BP */}
      <div className={classNames("navContainer", classes.collapsed)} id="desktopNavbar">
        <Container maxWidth="lg" className={classes.containerWrapper}>
          <div className={"navContainer"}>
            <Grid item className={classNames(classes.navLinkLogo, classes.collapsed)}>
              <Link href="/" className={classes.linkLogo}>
                {renderedLogo}
              </Link>
            </Grid>
            <Grid item xs={9} md={10} lg={10} xl={10} className={classes.navWrapper}>
              <ul className={classNames(classes.navMenu, "navbar-nav")}>
                {props.menuItems.map((item) => {
                  return (!item.contactForm) ? (
                    <li className={classNames(classes.navLink, classes.collapsedNavLink, "microNav")}>
                      <Link
                        href={`${item.href}`}
                        className={
                          classNames(
                            classes.collapsedLinkTitle,
                            bgColor === "white" && classes.linkTitleWhite,
                            bgColor !== "white" && classes.linkTitle
                          )
                        }
                        name={`${item.label}`}
                      >
                        {(item.label === "RM Tools") ? <Fragment>RM Tools</Fragment> : item.label}
                      </Link>
                    </li>
                  ) : (
                      <li className={classNames(classes.navLink, classes.collapsedNavLink, "microNav")}>
                        <Link
                          href={`${item.href}`}
                          onClick={() => openForm()}
                          className={
                            classNames(
                              classes.collapsedLinkTitle,
                              bgColor === "white" && classes.linkTitleWhite,
                              bgColor !== "white" && classes.linkTitle
                            )
                          }
                          name={`${item.label}`}
                        >
                          {item.label}
                        </Link>
                        <RFPForm open={modal} closeForm={closeForm} rfpType={item.rfpType} />
                      </li>
                    )
                }
                )}
              </ul>
            </Grid>
          </div>
        </Container>
      </div>
      {/* MOBILE NAVBAR */}
      <Toolbar 
        className={
          classNames(
            classes.mobileNavBar,
            bgColor === "white" && classes.whiteMobileNavBar,
            bgColor === "blue" && classes.blueMobileNavBar,
            bgColor === "neon" && classes.neonAppBar
          )
        }
        id="mobileNavbar"
      >
        <Link href="/" className={classes.linkLogo}>
          {renderedLogo}
        </Link>
        <IconButton
          className={classes.menuButton}
          size="large"
          edge="start"
          color={"inherit"}
          aria-label="menu"
          sx={{ mr: 2 }}
          variant="contained"
          disableElevation
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={() => openNav()}
        >
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          anchor='top'
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={closeNav}
          className={classes.menuPaper}>
          <MenuList
            className={classes.mobileMenuList}
          >
            {props.menuItems.map((item) => {
              return (!item.contactForm) ? (
                <MenuItem className={classes.mobileMenuItem}>
                  <Link href={item.href} onClick={closeNav} className={classes.mobileLink}>
                    {item.label}
                  </Link>
                </MenuItem>
              )
                :
                (
                  <MenuItem className={classes.mobileMenuItem}>
                    <Link onClick={() => openForm()} className={classes.mobileLink}>
                      {item.label}
                    </Link>
                    <RFPForm open={modal} closeForm={closeForm} rfpType={item.rfpType} />
                  </MenuItem>
                )
            })}
          </MenuList>
        </Drawer>
      </Toolbar>
      
    </AppBar>
    
  )
}

export default MicrositeNavBar;