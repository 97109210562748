import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import slide1Image from "../../../images/images/microsite/rm/slider/information-600.png";
import slide2Image from "../../../images/images/microsite/rm/slider/connect-600.png";
import slide3Image from "../../../images/images/microsite/rm/slider/secure-600.png";
import slide4Image from "../../../images/images/microsite/rm/slider/decisions-600.png";
import classNames from "classnames";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 850,
    width: "100%",
    display: "block",
    position: "relative",
  },
  slideBackground: {
    height: 700,
    width: "50%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "block",
    position: "absolute",
    right: 50,
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down('lg')]: {
      height: 850,
      top: 0,
      right: 0,
      transform: "unset",
    },
    [theme.breakpoints.down('md')]: {
      top: "unset",
      bottom: 0,
      width: "65%",
      backgroundPosition: "left 75%",
    },
    [theme.breakpoints.down('sm')]: {
      top: "unset",
      bottom: 0,
      width: "80%",
      backgroundPosition: "left 75%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      backgroundPosition: "left 75%",
    },
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
  },
  innerContent: {
    display: "flex",
    height: 850,
    width: "100%",
    position: "relative",
  },
  innerContentGradient: {
    '&::after': {
      display: "block",
      height: 850,
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(90deg, rgba(5,63,111,1) 0%, rgba(17,70,113,1) 30%, rgba(5,62,111,0.6) 60%, rgba(17,107,182,0) 100%)",
    },
  },
  innerContentGradientInvert: {
    '&::after': {
      background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%)",
      [theme.breakpoints.down('sm')]: {
        background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%)",
        display: "none",
      },
    },
  },
  textContainer: {
    display: "flex",
    height: 850,
    zIndex: 100,
    position: "relative",
    [theme.breakpoints.down('md')]: {
      marginLeft: 60,
      marginRight: 30,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 40,
    },
  },
  textWrapper: {
    paddingLeft: 60,
    paddingTop: 120,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 600,
    lineHeight: "1.3em",
    fontSize: 50,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 44,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 38,
    },
  },
  slideHeaderDark: {
    color: "#092c58",
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
    marginBottom: 5
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "#00549a",
    fontWeight: "bold",
    lineHeight: "1.4em",
    fontSize: 30,
    paddingTop: 30,
    paddingLeft: 50,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
}));


const RiskManagementCarousel = () => {
  const classes = useStyles();

  const Slides =
    [{
      content: "Your systems and information are the lifeblood of your business. They connect your business to your team, partners, and customers.",
    },
    {
      content: "When people connect to your systems—through the cloud, with a smart device or any other means—there are risks that should be understood and managed.",
    },
    {
      content: "Keeping your systems running smoothly and securely is critical to maintaining operations and meeting customer needs.",
    },
    {
      content: "Risk management gives you a practical approach to understanding your systems and making sound business decisions to keep them safe.",
    },
    ]

  return (
    <CustomCarousel height={850} sliderShadow={true}>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide1Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradientInvert, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={7} sm={7} md={7} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Risk Management Services</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.sliderCaption)}>{Slides[0].content}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide2Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradientInvert, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={7} sm={7} md={7} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Risk Management Services</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.sliderCaption)}>{Slides[1].content}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide3Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradientInvert, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={7} sm={7} md={7} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Risk Management Services</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.sliderCaption)}>{Slides[2].content}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={classNames(classes.slide)}>
        <div className={classNames(classes.slideBackground, classes.slide4Background)} />
        <div className={classNames(classes.innerContent, classes.innerContentGradientInvert, classes.innerContentGradient)}>
          <Container maxWidth="lg" className={classes.textContainer}>
            <Grid xs={7} sm={7} md={7} lg={7} className={classes.textWrapper}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Risk Management Services</Typography>
              <Typography variant="h3" className={classNames(classes.slideCaption, classes.sliderCaption)}>{Slides[3].content}</Typography>
            </Grid>
          </Container>
        </div>
      </div>
    </CustomCarousel>
  )
}

export default RiskManagementCarousel;