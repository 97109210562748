import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    display: "block",
    position: "relative",
    paddingBottom: 100,
    zIndex: 5,
    backgroundColor: "white",
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 50,
    },
  },
}));

const PageWrapper = (props) => {
  const classes = useStyles(props);
  return (
      <div className={classes.pageWrapper}>
        {props.children}
      </div>
  )
}

export default PageWrapper;