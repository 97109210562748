import Star2NavBar from "components/microsites/stars2/stars2NavBar";
import getAdobe from "images/microsite/stars2/get_adobe_reader.gif";
import s2AboutNCG from "images/microsite/stars2/S2_aboutNCG.jpg"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const Star2Service = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="stars2">
        <div id="container" align="center">
          <Star2NavBar href="/stars2/about_ncg" />
          <div id="srv_leftbar">
            <p><a class="leftnav" href="/">Visit NCG's Corporate Website</a></p>
            <p><strong>NCG Accessibility Statement:</strong><br />
              <br />
              <em>Northcross Group (NCG) is committed to making its websites  accessible to all NCG customers and staff in accordance with provisions of  Section 508 of the Rehabilitation Act Amendments of 1998.</em></p>
            <p><strong>Section 508 VPAT for NCG Services</strong></p>
            <p><a target="_blank" href="/content/docs/stars2/ITI_VPAT_v1_3_10242011.pdf">NCG VPAT document </a>describes how the NCG services and products comply with the provision of Section 508 Standards. </p>
            <p>A <a target="_blank" rel="noreferrer" href="http://www.itic.org/index.php?src=gendocs&amp;ref=vpat&amp;category=resources">Voluntary Product Accessibility Template</a> (VPAT) is a standardized form developed by the Information Technology Industry Council to show how a software product meets key regulations of Section 508 of the Rehabilitation Act.</p>
            <p><a href="http://www.adobe.com/products/acrobat/readstep2.html" target="_blank" rel="noreferrer"><img src={getAdobe} alt="Get Adobe Acrobat Reader" width="112" height="33" border="0" /></a></p>
            <p align="left">&nbsp;</p>
            <p align="left">&nbsp;</p>
            <p align="left">&nbsp;</p>
          </div>
          <div id="contentbanner"><img src={s2AboutNCG} width="680" height="200" alt="About NCG" /></div>
          <div id="contents">
            <p>The NCG Team is comprised of professionals with excellent  track records in the public and private sectors.  Our consultants bring backgrounds in Defense,  Intelligence, and Civilian; as well as expertise gained in various private  sector industries in financial services, logistics, security, and energy.</p>
            <p>NCG has built robust processes and methods to streamline  efforts and ensure success with a focus on execution to deliver real value for  our clients.  We excel in highly complex  and challenging environments, where innovation and problem solving are key to  delivering results.</p>
            <table align="center" border="1" cellspacing="1" cellpadding="1">
              <tr valign="top">
                <td colspan="2" align="center" valign="top" bgcolor="#D3DCf2"><h1 align="center">NCG Team Qualifications</h1> </td>
              </tr>
              <tr>
                <td align="left" valign="top"><p><strong>Certifications &amp; Qualifications</strong>:
              </p>
                  <ul class="square">
                    <li>SBA 8(a)</li>
                    <li>PMP</li>
                    <li>CCIE</li>
                    <li>CISSP</li>
                    <li>MCSE</li>
                    <li>MCSD</li>
                    <li>ITIL </li>
                    <li>CMMI </li>
                    <li>ISO 9000</li>
                    <li>CSM </li>
                    <li>SAINT</li>
                    <li>BizFlow</li>
                  </ul></td>
                <td align="left" valign="top"><p><strong>Market Expertise:</strong> </p>
                  <ul class="square">
                    <li>Information Security</li>
                    <li>Cyber Security</li>
                    <li>Transportation / Logistics</li>
                    <li>Health  Care</li>
                    <li>Banking  / Finance</li>
                    <li>Energy</li>
                    <li>Government  – State/Federal</li>
                    <li>Manufacturing</li>
                    <li>eCommerce</li>
                    <li>Aviation</li>
                  </ul></td>
              </tr>
              <tr>
                <td align="left" valign="top"><p><strong>Contract Experience</strong><br />
                </p>
                  <ul class="square">
                    <li>Federal &amp; State Levels</li>
                    <li>Leading Integrated Teams</li>
                    <li>Supporting Integrated Teams</li>
                    <li>IDIQ</li>
                    <li>GWAC</li>
                    <li>GSA</li>
                  </ul></td>
                <td align="left" valign="top"><p><strong>Core Skills </strong><strong> </strong></p>
                  <ul class="square">
                    <li>Problem solving</li>
                    <li>Communications</li>
                    <li>Collaboration</li>
                    <li>Relationship Development</li>
                    <li>Ability to Execute</li>
                    <li>System Safety</li>
                  </ul></td>
              </tr>
              <tr>
                <td align="left" valign="top"><p><strong>Clearances:</strong></p>
                  <ul class="square">
                    <li>Civilian, Defense, and Intelligence</li>
                    <li>Secret - TS/SCI</li>
                  </ul></td>
                <td align="left" valign="top"><p><strong>Spoken Languages:</strong></p>
                  <ul class="square">
                    <li>English</li>
                    <li>French</li>
                    <li>Hungarian</li>
                    <li>Japanese</li>
                    <li>Lithuanian</li>
                    <li>Mandarin</li>
                    <li>Vietnamese</li>
                  </ul></td>
              </tr>
            </table>
            <p>&nbsp;</p>
            <div class="footer">
              <p class="footer_style">&copy;2011 Northcross Group Corporation. All rights reserved. </p>
            </div>
          </div>
          <div id="rightbar">
            <h2>Office Locations &amp; Contact Information</h2>
            <p>
              <strong>Portland, Maine Headquarters</strong>
              <br />
              100 Middle Street
              <br />
              East Tower, #203
              <br />
              Portland, Maine 04101
              <br />
              207.699.5540 (phone)
              <br />
              207.699.2113 (fax)
            </p>
            <p> 
              <strong>Washington D.C. Office</strong>
              <br />
              1101 Wilson Boulevard
              <br />
              6th Floor
              <br />
              Arlington, VA 22209
              <br />
              703.351.3397 (phone)
              <br />
              703.351.5298 (fax)
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Star2Service;