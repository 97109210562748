import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import EFSectionContainer from "../shared/EFSectionContainer";
import appliedTechnology from "../../../../images/microsite/educationFoundation/appliedTechnology.png";
import collegiateWork from "../../../../images/microsite/educationFoundation/collegiateWork.png";
import problemSolving from "../../../../images/microsite/educationFoundation/problemSolving.png";

const periwinkle = "#5359E6";

const useStyles = makeStyles((theme) => ({
  mainHeadingContainer: {
    marginRight: "auto",
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  targetsGrid: {
    maxWidth: 1000,
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
      marginBottom: 0
    }
  },
  imageGridItem: {
    position: "relative",
    minHeight: 160,
  },
  containImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  containImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  stretchGrid: {
    alignSelf: "stretch",
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 670
    }
  },
  textColumnContent: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 16,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  textGridItem: {
    alignSelf: "center"
  },
  mainHeading: {
    fontSize: 42,
    color: periwinkle,
    fontWeight: 700,
  },
  subheading: {
    color: periwinkle,
    fontWeight: 700,
  },
  cardsContainer: {
    position: "relative"
  }
}));


export default function EFEmpowermentSection() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const align = isMobile ? "center" : "left";

  return (
    <EFSectionContainer
      scrollId="Empowerment-Targets"
      maxWidth="none"
      sibling
      stretch
    >
      <div className={classes.mainHeadingContainer}>
        <Typography className={classes.mainHeading} variant="h2">
          Empowerment Targets
        </Typography>
      </div>
      <Grid className={classes.targetsGrid} spacing={isMobile ? 0 : 8} container>
        <Grid className={classes.imageGridItem} item xs={12} sm={5} lg={4} xl={3}>
          <div className={classes.containImageContainer}>
            <img
              src={appliedTechnology}
              className={classes.containImage}
              alt=""
              role="presentation"
            />
          </div>
        </Grid>
        <Grid className={classes.textGridItem} item xs={12} sm={7} lg={8} xl={9}>
          <div className={classes.textColumnContent}>
            <Typography variant="h3" className={classes.subheading} align={align}>
              Applied Technology
            </Typography>
            <Typography color="inherit" variant="body1" align={align} paragraph>
              Tech is not a separate body of study, but a set of tooling that
              supports and extends all areas of study and business.
              Not knowing how to leverage technology is a major disadvantage.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.targetsGrid} spacing={isMobile ? 0 : 8} container>
        <Grid className={classes.imageGridItem} item xs={12} sm={5} lg={4} xl={3}>
          <div className={classes.containImageContainer}>
            <img
              src={collegiateWork}
              className={classes.containImage}
              alt=""
              role="presentation"
            />
          </div>
        </Grid>
        <Grid className={classes.textGridItem} item xs={12} sm={7} lg={8} xl={9}>
          <div className={classes.textColumnContent}>
            <Typography variant="h3" className={classes.subheading} align={align}>
              AP &amp; Collegiate Work
            </Typography>
            <Typography color="inherit" variant="body1" align={align} paragraph>
              Technology and cybersecurity areas of study
              have not evolved at the same pace of their application
              in the marketplace. Traditional academic pursuits can be
              augmented with real-world application.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.targetsGrid} spacing={isMobile ? 0 : 8} container>
        <Grid className={classes.imageGridItem} item xs={12} sm={5} lg={4} xl={3}>
          <div className={classes.containImageContainer}>
            <img
              src={problemSolving}
              className={classes.containImage}
              alt=""
              role="presentation"
            />
          </div>
        </Grid>
        <Grid className={classes.textGridItem} item xs={12} sm={7} lg={8} xl={9}>
          <div className={classes.textColumnContent}>
            <Typography variant="h3" className={classes.subheading} align={align}>
              Problem Solving with Technology
            </Typography>
            <Typography color="inherit" variant="body1" align={align} paragraph>
              NCG's provides management, technical and subject matter experts
              to work with your team to establish, operate, and enhance GRC
              program functions.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </EFSectionContainer>
  );
}
