// imports
import { Drawer, AppBar, Typography, Grid, Container, MenuList, Link, IconButton, Toolbar, MenuItem } from "@material-ui/core";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import variables from "style/variables.module.scss";
import classNames from "classnames";
import React, { useState} from "react";
import NcgLogo from "./ncgLogo";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Twitter from "images/social-media/twitter-30px.png";
import Fb from "images/social-media/facebook-30px.png";
import Youtube from "images/social-media/youtube-30px.png";
import Instagram from "images/social-media/instagram-30px.png";
import LinkedIn from "images/social-media/linkedin-30px.png";
// import css
import 'style/navbar.css';


const customTheme = createTheme({
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  })
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%)",
    '& #mobileNavbar': {
      [customTheme.breakpoints.between('xs','xs')]: {
        display: "flex!important",
      },
    },
    '& #smallNavbar': {
      [customTheme.breakpoints.between('sm','md')]: {
        display: "flex!important",
      },
    },
  },
  navBarWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  contactContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  logoCell: {
    display: "flex",
    alignItems: "center",
    maxWidth: 250,
    maxHeight: 70,
    justifyContent: "center",
    height: "100%",
  },
  contactCell: {
    display: "inline-flex",
    flexDirection: "column",
    textAlign: "right",
    alignItems: "flex-end",
  },
  topRow: {
    display: "flex",
    width: "100%",

    mainpageWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  contactRow: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    paddingLeft: 5,
    fontSize: "1em",
  },
  contactLink: {
    paddingRight: 5,
  },
  ncgTeal2: {
    color: variables.ncgTeal2,
  },
  contactIcon: {
    fontSize: "1rem",
  },
  socialMediaWrapper: {
    display: "flex",
    paddingTop: 5,
    justifyContent: "flex-end",
  },
  socialMediaLink: {
    height: 22,
    width: 22,
    display: "inline-block",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginLeft: 10,
    marginRight: 10,
  },
  twitter: {
    backgroundImage: "url(" + Twitter + ")",
    marginRight: 0,
  },
  youtube: {
    backgroundImage: "url(" + Youtube + ")",
  },
  linkedIn: {
    backgroundImage: "url(" + LinkedIn + ")",
  },
  instagram: {
    backgroundImage: "url(" + Instagram + ")",
  },
  fb: {
    backgroundImage: "url(" + Fb + ")",
  },
  navContainer: {
    display: "flex",
    borderBottom: "2px solid #fff",
    background: "linear-gradient(to right, #40aada 0%, #1091cc 100%)",
  },
  navMenu: {
    display: "flex",
    height: "auto",
    listStyle: "none",
    margin: 0,
    padding: 0,
    paddingLeft: 0,
    width: "unset",
  },
  navLink: {
    textAlign: "center",
    borderLeft: "1px solid #8bdafe",
    borderRight: "1px solid #8bdafe",
    width: "25%",
    paddingTop: 0,
    lineHeight: "20px",
    '&:active:': {
      backgroundColor: "rgba(15, 81, 144, 0.78)",
    },
  },
  linkTitle: {
    color: "#eee",
    fontWeight: "400",
    fontSize: 14,
    fontFamily: "Helvetica Neue, Helvetica",
    padding: 10,
    display: "block",
    '&:hover:hover': {
      color: "white",
    },
  },
  linkIcon: {
    display: "flex",
    alignItems: "center",
  },
  navLinkLogo: {
    minWidth: 190,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  linkLogo: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 150,
    },
  },
  linkLogoCollapsed: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  contactIconLarge: {
    fill: "white",
    fontSize: 26,
  },
  navLinkPhone: {
    minWidth: "fit-content",
    textAlign: "center",
    justifyContent: "center",
    borderLeft: "1px solid #8bdafe",
    borderRight: "1px solid #8bdafe",
    width: "7%",
    padding: 12,
    display: "flex",
  },
  navLinkEmail: {
    minWidth: "fit-content",
    textAlign: "center",
    justifyContent: "center",
    borderLeft: "1px solid #8bdafe",
    width: "7%",
    padding: 12,
    display: "flex",
  },
  containerWrapper: {
    padding: 0,
  },
  collapsedNavLink: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "center",
  },
  collapsedLinkTitle: {
    width: "100%",
    padding: 24,
    height: "100%",
    textAlign: "center",
  },
  smallNavbar: {
    display: "none",
  },
  // MOBILE
  mobileNavBar: {
    backgroundColor: "white",
    display: "none",
    justifyContent: "space-between",
  },
  menuButton: {
    color: variables.ncgBlue3,
    position: "absolute",
    right: 15,
  },
  menuPaper: {
    minWidth: 600,
    position: "absolute",
    top: 64,
    right: 0,
    borderRadius: 0,
    width: "100%",
    borderTop: "3px solid #1091cc",
  },
  mobileMenuList: {
    padding: 0,
    width: "100%",
    borderBottom: "3px solid #1091cc",
    '&:hover': {
      "& $mobileMenuItem": {
        borderColor: "#1091cc",
      },
    },
  },
  mobileMenuItem: {
    padding: 0,
    '&:first-of-type': {
      "& $mobileLink": {
      borderTop: "none",
      },
    },
    '&:last-of-type': {
      "& $mobileLink": {
        borderBottom: "none",
      },
    },
  },
  mobileLink: {
    padding: "20px 10px 20px 50px",
    width: "100%",
    borderBottom: "1px solid rgba(122, 150, 179, 0.51)",
    borderTop: "1px solid rgba(122, 150, 179, 0.51)",
    textTransform: "uppercase",
    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
    fontWeight: 500,
    fontSize: variables.fontBody2,
    letterSpacing: ".08em",
    color: variables.textBlue,
    lineHeight: "normal",
    display: "block",
    '&:hover': {
      backgroundColor: variables.ncgBlue4,
      color: "white!important",
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openNav = (event) => {
    setOpen(true);
  }
  const closeNav = () => {
    setOpen(false);
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <div className={classNames(classes.topRow, classes.desktop,classes.topNavbar, "topNavbar")}>
        <Container maxWidth="lg">
          <div className={classes.contactContainer}>
            <Grid item xs={6} md={3} lg={3} xl={3} className={classes.logoCell}>
              <Link href="/" className={classes.linkLogo}>
                <NcgLogo />
              </Link>
            </Grid>
            <Grid item xs={6} md={3} lg={3} xl={3} className={classes.contactCell}>

              <Typography variant="body2" className={classNames(classes.phoneWrapper, classes.contactRow, classes.ncgTeal2)}>
                <PhoneIcon className={classes.contactIcon} />
                <Link href="/about-ncg/contact" className={classNames(classes.ncgTeal2, classes.link, classes.contactLink)}>
                  Contact
                </Link>
                207.699.5540
              </Typography>

              <Typography variant="body2" className={classNames(classes.emailWrapper, classes.contactRow, classes.ncgTeal2)}>
                <EmailIcon className={classes.contactIcon} />
                <Link href="mailto:info@northcrossgroup.com" className={classNames(classes.ncgTeal2, classes.link)}>
                  info@northcrossgroup.com
                </Link>
              </Typography>

              <div className={classNames(classes.socialMediaWrapper, classes.contactRow)}>
                <Link target="_blank" href="https://www.instagram.com/northcrossgroup/" className={classNames(classes.socialMediaLink, classes.instagram)}></Link>
                <Link target="_blank" href="https://www.youtube.com/channel/UCk4tUHE4HEbs81yxhknqO1Q" className={classNames(classes.socialMediaLink, classes.youtube)}></Link>
                <Link target="_blank" href="https://www.facebook.com/northcrossgroup/" className={classNames(classes.socialMediaLink, classes.fb)}></Link>
                <Link target="_blank" href="https://www.linkedin.com/company/northcross-group/mycompany/" className={classNames(classes.socialMediaLink, classes.linkedIn)}></Link>
                <Link target="_blank" href="https://twitter.com/NorthcrossGroup" className={classNames(classes.socialMediaLink, classes.twitter)}></Link>
              </div>
            </Grid>
          </div>
        </Container>
      </div>
      <div className={classNames(classes.navContainer, classes.desktop,classes.topNavbar, "topNavbar")}>
        <Container maxWidth="lg" className={classes.containerWrapper}>
          <ul className={classNames(classes.navMenu,"navLinkMenu")}>
            <li className={classNames(classes.navLink,"markets")}>
              <Link href="/markets" className={classNames(classes.linkTitle)}>
                Markets Served
              </Link>
            </li>
            <li className={classNames(classes.navLink,"capabilities")}>
              <Link href="/capabilities" className={classNames(classes.linkTitle)}>
                Services &amp; Capabilities
              </Link>
            </li>
            <li className={classNames(classes.navLink,"past-performance")}>
              <Link href="/past-performance" className={classNames(classes.linkTitle)}>
                Past Performance
              </Link>
            </li>
            <li className={classNames(classes.navLink,"about")}>
              <Link href="/about" className={classNames(classes.linkTitle)}>
                About NCG
              </Link>
            </li>
          </ul>
        </Container>
      </div>
      {/* VIEW ON SCROLL AND BELOW 1279 PX / LG BP */}
      <div id="smallNavbar" className={classNames(classes.navContainer,classes.smallNavbar)}>
        <Container maxWidth="lg" className={classes.containerWrapper}>
          <ul className={classNames(classes.navMenu,"navLinkMenu")}>
            <li className={classNames(classes.navLinkLogo)}>
              <Link href="/" className={classes.linkLogo}>
                <NcgLogo />
              </Link>
            </li>
            <li className={classNames(classes.navLink, classes.collapsedNavLink,"markets")}>
              <Link href="/markets" className={classNames(classes.linkTitle, classes.collapsedLinkTitle)}>
                Markets Served
              </Link>
            </li>
            <li className={classNames(classes.navLink, classes.collapsedNavLink,"capabilities")}>
              <Link href="/capabilities" className={classNames(classes.linkTitle, classes.collapsedLinkTitle)}>
                Services &amp; Capabilities
              </Link>
            </li>
            <li className={classNames(classes.navLink, classes.collapsedNavLink,"past-performance")}>
              <Link href="/past-performance" className={classNames(classes.linkTitle, classes.collapsedLinkTitle)}>
                Past Performance
              </Link>
            </li>
            <li className={classNames(classes.navLink, classes.collapsedNavLink,"about")}>
              <Link href="/about" className={classNames(classes.linkTitle, classes.collapsedLinkTitle)}>
                About NCG
              </Link>
            </li>
            <li className={classNames(classes.navLinkPhone)}>
              <Link href="/about-ncg/contact" className={classes.linkIcon}>
                <PhoneIcon className={classes.contactIconLarge} />
              </Link>
            </li>
            <li className={classNames(classes.navLinkEmail)}>
              <Link href="mailto:info@northcrossgroup.com" className={classes.linkIcon}>
                <EmailIcon className={classes.contactIconLarge} />
              </Link>
            </li>
          </ul>
        </Container>
      </div>

      {/* MOBILE NAVBAR */}
      <Toolbar id="mobileNavbar" className={classes.mobileNavBar}>
        <Link href="/" className={classes.linkLogo}>
          <NcgLogo />
        </Link>
        <IconButton
          className={classes.menuButton}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          variant="contained"
          disableElevation
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={() => openNav()}
        >
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          anchor='top'
          id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            open={open}
            onClose={closeNav}
            className={classes.menuPaper}>
          <MenuList
            className={classes.mobileMenuList}
          >
            <MenuItem className={classes.mobileMenuItem}>
              <Link href="/markets" onClick={closeNav} className={classes.mobileLink}>
                Markets Served
              </Link>
            </MenuItem>
            <MenuItem className={classNames(classes.mobileMenuItem)}>
              <Link href="/capabilities" onClick={closeNav} className={classNames(classes.mobileLink)}>
                Services &amp; Capabilities
              </Link>
            </MenuItem>
            <MenuItem className={classes.mobileMenuItem}>
              <Link href="/past-performance" onClick={closeNav}className={classes.mobileLink}>
                Past Performance
              </Link>
            </MenuItem>
            <MenuItem className={classes.mobileMenuItem}>
              <Link href="/about" onClick={closeNav} className={classes.mobileLink}>
                About NCG
              </Link>
            </MenuItem>
          </MenuList>
        </Drawer>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar;