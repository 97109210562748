import { Container, Typography, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/custom-css/slick-theme-custom.css";
import slide1Image from "../../../images/images/banking/slider/slide_one/one.jpg";
import slide2Image from "../../../images/images/banking/slider/slide_two/two.jpg";
import slide3Image from "../../../images/images/banking/slider/slide_three/three.jpg";
import slide4Image from "../../../images/images/banking/slider/slide_four/four.jpg";
import slide5Image from "../../../images/images/banking/slider/slide_five/five.jpg";
import slide6Image from "../../../images/images/banking/slider/slide_six/six.jpg";
import slide7Image from "../../../images/images/banking/slider/slide_seven/seven.jpg";
import slide8Image from "../../../images/images/banking/slider/slide_eight/eight.jpg";
import slide9Image from "../../../images/images/banking/slider/slide_nine/nine.jpg";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
import ButtonCustom from "components/layout/buttonCustom.component";

//carousel
import CustomCarousel from "../../utils/customCarousel.component"

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 800,
    width: "100%",
    backgroundColor: variables.ncgBlue4,
    display: "block",
    position: "relative",
  },
  slideBackground: {
    height: "inherit",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    display: "block",
    position: "absolute",
    right: 0,
    top: 0,
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
  },
  slide5Background: {
    backgroundImage: "url(" + slide5Image + ")",
  },
  slide6Background: {
    backgroundImage: "url(" + slide6Image + ")",
  },
  slide7Background: {
    backgroundImage: "url(" + slide7Image + ")",
  },
  slide8Background: {
    backgroundImage: "url(" + slide8Image + ")",
  },
  slide9Background: {
    backgroundImage: "url(" + slide9Image + ")",
  },
  innerContent: {
    display: "flex",
    height: "inherit",
    width: "100%",
    position: "relative",
  },
  innerContentGradient: {
    '&::after': {
      display: "block",
      height: "inherit",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(to right, rgba(231, 237, 242, 1) 0%, rgba(253, 253, 253, 0.85) 50%, rgba(253, 253, 253, 0.6) 80%, rgba(148, 210, 232, 0) 100%)",
    },
  },
  textContainer: {
    display: "flex",
    height: "inherit",
    zIndex: 100,
    position: "relative",
    paddingTop: 145,
    [theme.breakpoints.down('md')]: {
      paddingTop: 75,
    },
  },
  textWrapper: {
    marginLeft: 130,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 90,
    paddingRight: 200,
    [theme.breakpoints.down('md')]: {
      paddingRight: 100,
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 500,
    lineHeight: "1.3em",
    fontSize: "34px",
    paddingLeft: 0,
  },
  slideHeaderDark: {
    color: variables.ncgBlue4,
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
    marginBottom: 10,
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "white",
    textShadow: "1px 1px 1px rgb(0 0 0 / 58%)",
    fontSize: "22px",
    fontWeight: 300,
    lineHeight: "1.9em",
    maxWidth: 590,
    [theme.breakpoints.down('md')]: {
      maxWidth: 460,
    },
  },
  sliderCaptionGreen: {
    color: "#019fa1",
    textShadow: "none",
    marginTop: 5,
    lineHeight: "1.8em",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  buttonWrapper: {
    position: "relative",
    paddingTop: 20,
  },
}));


const BankingFinancialCarousel = () => {
  const classes = useStyles();
 
  const Slides =
    [{
      class: classes.slide1Background,
      title: "Managing Growth – Ensuring the Success of Critical Events",
      content: "Customer expectations, regulatory oversight, and reputational protection drive financial institutions to ensure key events go off without a hitch.",
      href: "#intro"
    },
    {
      class: classes.slide2Background,
      title: "Mergers & Acquisitions",
      content: "Northcross Group has teams highly skilled and experienced in the technical and management aspects unique to mergers and acquisitions.",
      href: "#mergers"
    },
    {
      class: classes.slide3Background,
      title: "Deployment Command Center Preparation & Management",
      content: "Northcross Group helps financial institutions craft deployment programs that engage all the appropriate parties, proactively manage risks, ensures a team that is ready for challenges that may occur.",
      href: "#deploy"
    },
    {
      class: classes.slide4Background,
      title: "Platform Migration and Data Conversions",
      content: "Northcross Group has the program execution and banking subject matter expertise to address the technical and procedural elements.",
      href: "#platform"
    },
    {
      class: classes.slide5Background,
      title: "Cybersecurity",
      content: "The Northcross Group team has information security capabilities honed in the private and public sectors including defense and intelligence coupled with extensive financial systems expertise.",
      href: "#cybersecurity"
    },
    {
      class: classes.slide6Background,
      title: "Core System Blueprinting and Assessment",
      content: "Northcross Group helps financial institutions capture the knowledge that is critical to successfully planning changes and enhancements.",
      href: "#core"
    },
    {
      class: classes.slide7Background,
      title: "Vendor Management",
      content: "NCG helps organizations implement the tools and processes to oversee and govern third party relationships on a day-to-day basis and effectively manage transition processes.",
      href: "#vendor"
    },
    {
      class: classes.slide8Background,
      title: "Project & Program Services",
      content: "The Northcross Group has a proven track record driving system deployments, platform migrations, and upgrades in the high availability/high visibility banking industry.",
      href: "#project"
    },
    {
      class: classes.slide9Background,
      title: "Analytics",
      content: "The ability to leverage data can differentiate the delivery of products and services to customers and drive operational processes. NCG has the technical capabilities matched with the banking expertise to help your organization unlock the potential in your data.",
      href: "#analytics"
    }
    ]
  

  function GetSlide(slideBackground,title,content,href) {
  return (
    <div className={classNames(classes.slide)}>
      <div className={classNames(classes.slideBackground, slideBackground)} />
      <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
        <Container maxWidth="lg" className={classes.textContainer}>
          <Grid xs={11} sm={8} md={8} lg={8} className={classes.textWrapper}>
            <Link href={href}>
              <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>{title}</Typography>
            </Link>
            <Typography variant="h3" className={classNames(classes.slideCaption, classes.sliderCaptionGreen)}>{content}</Typography>
            <div className={classes.buttonWrapper}>
              <ButtonCustom typeOf="bigButtonPrimary" href={href} >Learn More</ButtonCustom>
            </div>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

  return (
    <CustomCarousel >
      {Slides.map((slide)=>
      {
        return GetSlide(slide.class,slide.title,slide.content,slide.href)
      })}
    </CustomCarousel>
  )
}

export default BankingFinancialCarousel;