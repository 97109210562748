import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "2.5em",
    lineHeight: "1.125em",
    marginTop: 30,
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  // LIST
  unorderedList: {
    paddingLeft: 20,
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 0,
  },
  listItem: {
    fontSize: variables.fontBody,
    letterSpacing: ".08em",
    paddingTop: 10,
    color: variables.textPrimary,
  },
}));

const PPInfoSecurity = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Information Technology Security</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/info-security" variant="active">Information Security</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Information System Security Policies &amp; Procedures
                </li>
                <li className={classes.sideBarListItem}>
                  Financial Security Risk Assessment
                </li>
                <li className={classes.sideBarListItem}>
                  Third Party Security Assessment
                </li>
                <li className={classes.sideBarListItem}>
                  PCI Assessment
                </li>
                <li className={classes.sideBarListItem}>
                  HR Policy Compliance
                </li>
                <li className={classes.sideBarListItem}>
                  Infrastructure Security
                </li>
              </ul>
            </div>
          </Grid>

          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2" className={classes.headingOver}>
                Risk Assessments
              </Typography>
              <Typography variant="h2" className={classes.headingUnder}>
                H.M. Payson &amp; Company
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                H.M. Payson sought a broad based assessment of their overall security position. From policies, to plans, and even physical security wanted an independent assessment of their current security infrastructure along with identification of any areas for improvement.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG leveraged proprietary security risk assessment model to assess H.M. Payson’s internal and external in addition to third-party outsourced systems. NCG’s security model has specific components specifically geared towards financial institutions—internal systems as well as outsourced partners. It takes specific note of regulatory provisions including SOX, HIPAA, and PCI compliance as part of the assessment. NCG assessed H.M. Payson’s internal, external, and third-party outsource infrastructure, security policies, configurations, architecture, and performed scans across each platform. Detailed reviews of configuration management, IT policies and procedures, HR policies, physical security policies and entry point configurations were conducted as well. NCG audited and assessed seventeen essential areas based on BS ISO/IEC17799, PCI, FISMA, and ITIL:
              </Typography>
              <ul className={classes.unorderedList}>
                <li className={classes.listItem}>
                  Security policy and process
                </li>
                <li className={classes.listItem}>
                  Security organization and personnel
                </li>
                <li className={classes.listItem}>
                  Asset management and classification
                </li>
                <li className={classes.listItem}>
                  Human resources security
                </li>
                <li className={classes.listItem}>
                  Physical and environmental security
                </li>
                <li className={classes.listItem}>
                  Network Security and Operations Management
                </li>
                <li className={classes.listItem}>
                  Security access controls
                </li>
                <li className={classes.listItem}>
                  Information Systems Third Party Integration, Development, and Maintenance
                </li>
                <li className={classes.listItem}>
                  IT Security Policy
                </li>
                <li className={classes.listItem}>
                  Information Security Incident Management
                </li>
                <li className={classes.listItem}>
                  Business Continuity Management
                </li>
                <li className={classes.listItem}>
                  Compliance
                </li>
                <li className={classes.listItem}>
                  Configuration Management
                </li>
                <li className={classes.listItem}>
                  Hardening Guides
                </li>
                <li className={classes.listItem}>
                  Patch Management
                </li>
                <li className={classes.listItem}>
                  Software Development Methodology
                </li>
                <li className={classes.listItem}>
                  System Development Life Cycle Methodology
                </li>
              </ul>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                NCG provided H.M. Payson with a broad yet detailed view of security across their organization including connections points with external partners. Areas of improvement were identified and specific action steps were provided.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>

  )
}

export default PPInfoSecurity;