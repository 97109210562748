import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import classNames from "classnames";
import PageWrapper from "components/layout/pageWrapper.component";
import Banner from "components/layout/banner.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
// IMAGES
import marketBanner from "../../../images/images/banners/market.jpg";
import marketsFederal from "../../../images/images/markets/federal.png";
import marketsCommercial from "../../../images/images/markets/commercial.png";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  column: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 70,
    paddingBottom: 30,
  },
  imageWrapper: {
    height: 160,
    width: 160,
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  commercialImage: {
    backgroundImage: "url(" + marketsCommercial + ")",
  },
  federalImage: {
    backgroundImage: "url(" + marketsFederal + ")",
  },
  linkWrapper: {
    paddingTop: 20,
  },
}));

const MarketsPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner variant="subheader" subheader={"NCG provides services in the federal and private sectors"} backgroundImage={marketBanner}>
        Markets Served</Banner>
      <PageWrapper >
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item className={classes.column} sm={12} md={6} lg={6} xl={6}>
            <div className={classNames(classes.imageWrapper, classes.federalImage)} />
            <Typography variant="h2">Federal Sector</Typography>
            <Typography variant="body1">
              NCG is comprised of highly skilled resources with proven ability to deliver on mission objectives in the Federal sector. NCG’s experienced and cleared staff (up to TS SCI active clearances) is supported by the firm’s industry best practices and processes with tools and controls to ensure quality delivery and results.
            </Typography>
            <Typography variant="body1">
              For the federal market, NCG has a team with extensive experience in the civilian, defense, and intelligence areas. NCG holds multiple federal contract vehicles and is an 8(a) certified firm.
            </Typography>
            <div className={classes.linkWrapper}>
              <LinkDefault href="/markets/federal-services">Learn more: Federal Sector Services</LinkDefault>
            </div>
          </Grid>
          <Grid item className={classes.column} sm={12} md={6} lg={6} xl={6}>
            <div className={classNames(classes.imageWrapper, classes.commercialImage)} />
            <Typography variant="h2">Commercial Sector</Typography>
            <Typography variant="body1">
              In the private sector, NCG provides services to organizations in highly regulated industries that are subject to federal regulations and must meet significant customer expectations for their services. NCG works extensively with clients in the banking and healthcare industries and provides program and project management, business analytics, and cybersecurity services such as risk assessments and vulnerability scanning. NCG helped banking and healthcare industries improve their security posture by performing vulnerability tests and implementing risk management strategies.
            </Typography>
            <div className={classes.linkWrapper}>
              <LinkDefault href="/markets/commercial-services">Learn more: Commercial Sector Services</LinkDefault>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>

  )
}

export default MarketsPage;