import React, { useState } from 'react';
import { Button } from "@material-ui/core";

//carousel
import MergerAcquistionCarousel from "./MergerAcquisitionCarousel"

//images
import tailorSupport from "images/images/mergers/section/topics/tailor-support.png"
import experienceSkill from "images/images/mergers/section/topics/experience-skills.png"
import toolsAccelerators from "images/images/mergers/section/topics/tools-accelerators.png"
import diligenceDesktop from "images/images/mergers/section/diligence.jpg"
import diligenceMobile from "images/images/mergers/section/mobile/diligence-mobile.jpg"
import tsaDeal from "images/images/mergers/section/tsa-deal.jpg"
import integrationDesktop from "images/images/mergers/section/integration.jpg"
import integrationMobile from "images/images/mergers/section/mobile/integration.jpg"
import deployLiveDesktop from "images/images/mergers/section/deployLive.jpg"
import deployLiveMobile from "images/images/mergers/section/mobile/deployLive.jpg"
import transitionDesktop from "images/images/mergers/section/transition.jpg"
import transitionMobile from "images/images/mergers/section/mobile/transition.jpg"

//modal
import TailorSupportModal from "./tailorSupportModal.component"
import ExperienceSkillsModal from "./experienceSkillsModal.component"
import CustomModal from "../../utils/CustomModal";

//fontawesome Icons
import AdobeFileLogo from "../../utils/AdobeFileLogo";
import ExpandIcon from "../../utils/expandIcon";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const MergerAcquistionMicrosite = (props) => {

  //Modal
  const [openTailorModal, setOpenTailorModal] = useState(false);

  const toggleTailorForm = () => {
    setOpenTailorModal(!openTailorModal);
  };

  const openTailorForm = () => {
    setOpenTailorModal(true);
  };

  const [openExperienceModal, setOpenExperienceModal] = useState(false);

  const toggleExperienceForm = () => {
    setOpenExperienceModal(!openExperienceModal);
  };

  const openExperienceForm = () => {
    setOpenExperienceModal(true);
  };

  return (
    <>
      <BackToTopCustom />
      <MergerAcquistionCarousel />
      <div class="bootstrap">
        <div class="crunch">
          <div class="page-wrap page-wrap--no-pad">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                <li class="active">Mergers &amp; Acquisition</li>
              </ul>
            </div>
            <div id="intro" class="page-section page-section--striped " style={{ backgroundColor: "#fff" }}>
              <div class="mainpage-wrapper ">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12" >
                  <h1 class="headings--over">Mergers &amp; Acquisitions</h1>
                  <h3 class="headings--under">Delivering on Expectations</h3>
                  <p>While merger and acquisition activities can be catalysts for growth and market capture, they do not guarantee value and require careful risk management to succeed. NCG provides IT and security services across the investment life cycle. From initial due diligence to legal interoperability, NCG has the tools and expertise to help businesses realize their objectives. After the event, NCG also facilitates project functions and ensures businesses handle task follow-ups effectively.</p>
                  <p>NCG performs IT due diligence, deal and TSA strategy, and readiness testing. Moreover, consultants help organize and implement security controls that coordinate enterprise-wide communication, tailoring its command center planning to individual organizations.</p>
                </div>
                <div itemscope itemtype="http://schema.org/WPSidebar" class="sideBar col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div class=" wrapper">
                    <h5>M&amp;A Service Cards</h5>
                    <ul class="list">
                      <li><a target="_blank" rel="noreferrer" itemprop="relatedLink" href="/content/docs/mergers/NCG_InvestmentLifeCycle_ITservices_2014.pdf">Investment Life Cycle</a></li>
                      <li><a target="_blank" rel="noreferrer" itemprop="relatedLink" href="/content/docs/ServiceCards/NCG_Equity_ITDiligence_2014.pdf">Investment Life Cycle Services</a></li>
                      <li><a target="_blank" rel="noreferrer" itemprop="relatedLink" href="/content/docs/mergers/NCG_M_A_Integration_Services_2014.pdf">M&amp;A Integration Services</a></li>
                    </ul>
                    <a id="adobe" target="_blank" rel="noreferrer" href="https://get.adobe.com/reader/"><h5><AdobeFileLogo />Download Adobe Reader</h5></a>
                  </div>
                </div>
                <div itemprop="mainContentOfPage" class="flex-wrap col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                  <div class="flex-item col-lg-4 col-md-4 col-sm-4 col-xs-12"  >
                    <div class="graphic-wrap">
                      <img src={tailorSupport} class="img-responsive graphic" alt="Tailor Support" />
                    </div>
                      <Button onClick={() => openTailorForm()} class="btn btn-expand btn-lg">Click to Expand <ExpandIcon /></Button>
                    <div itemprop="specialty" class="topictext">
                      <p>Tailor your support across project roles and responsibilities, NCG brings a cohesive team with expertise to assist or drive as needed.</p>
                    </div>
                  </div>
                  <div class="flex-item col-lg-4 col-md-4 col-sm-4 col-xs-12"  >
                    <div class="graphic-wrap">
                      <img src={experienceSkill} class="img-responsive graphic" alt="Experience & Skills" />
                    </div>
                      <Button onClick={() => openExperienceForm()} class="btn btn-expand btn-lg">Click to Expand <ExpandIcon /></Button>
                    <div itemprop="specialty" class="topictext">
                      <p> <b>Experience &amp; Skills</b><br />NCG has the technical and business acumen and capabilities to drive execution and success.</p>
                    </div>
                  </div>
                  <div class="flex-item flex-item--tools col-lg-4 col-md-4 col-sm-4 col-xs-12" >
                    <div class="graphic-wrap">
                      <img src={toolsAccelerators} class="img-responsive graphic" alt="Accelerators Tools" />
                    </div>
                    <div itemprop="specialty" class="topictext">
                      <p>Tools &amp; Accelerators Tools based on industry standards and real world experience are used to leverage best practices and baselines.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="diligence" class="page-section page-section--striped ">
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--right imgCropped col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={diligenceDesktop} class="img-responsive graphic graphic--desktop" alt="IT Diligence" />
                  <img src={diligenceMobile} class="img-responsive marginT graphic graphic--mobile" alt="IT Diligence" />
                </div>
                <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2>IT Due Diligence</h2>
                  <p>We provide evaluation of targets, market position, and customer base with a focus on technology compatibility and integration risks. NCG uses a multipoint structured analysis to assess the stability, performance, and capabilities of potential investments with detailed forecasts and planning.</p>
                  <p>NCG uses a systems based risk management approach to forecast opportunities and challenges in an IT investment. This approach accounts for both technology and the process to operate that capability. NCG accounts for transition effort and the steps that will be needed for conversions and business process changes. Each of these may have various levels of effort and complexity determinations.</p>
                </div>
              </div>
            </div>
            <div id="TSA" class="page-section page-section--striped">
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--left  col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={tsaDeal} class="img-responsive img-rounded marginT " alt="TSA Deal" />
                </div>
                <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 > Deal and TSA Strategy</h2>
                  <p>NCG takes an integrated approach working with corporate development, legal, owners, and broker representatives throughout the process. We help translate technical and operational considerations into objectives for agreement terms and conditions.</p>
                  <p>NCG assists your negotiation team to navigate through deals and Transition Service Agreement (TSA) negotiations by providing insight to minimize internal and external exposure as well as minimize transition investment. NCG will identify continuing and transition services to include in agreement structures, resource forecasts, and direct plus indirect costs for management and execution during the transition to smoothly navigate any deal.</p>
                  <p>As part of the agreement structure, NCG will make sure that the development of contingency and mitigation plans are a part of the process. NCG will validate assumptions, understand your risk posture, and identify changes to the forecast and measure of ROI throughout the process and deal structure.
            </p>
                </div>
              </div>
            </div>
            <div id="integration" class="page-section page-section--striped ">
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--right imgCropped col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={integrationDesktop} class="img-responsive img-rounded  hidden-sm hidden-xs" alt="Integration and Transition" />
                  <img src={integrationMobile} class="img-responsive img-rounded marginT hidden-lg hidden-md" alt="Integration and Transition" />
                </div>
                <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 class="headings--over">Integration and Transition</h2>
                  <h5 class="headings--under">Conversion, Migration, and Testing</h5>
                  <p>NCG provides technical and program support for planning and executing for system and readiness testing. We also ensure correct secure data conversion, platform migration, and business process engineering are used.</p>
                  <p>NCG helps organizations implement the proper security controls, measures, and monitoring to support efforts. NCG facilitates coordination with stakeholders to plan business process, execute changes in management, and governance of all the technical and development activities.</p>
                </div>
              </div>
            </div>
            <div id="deployment" class="page-section page-section--striped">
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--left imgCropped col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={deployLiveDesktop} class="img-responsive img-rounded  hidden-sm hidden-xs" alt="Deploy live" />
                  <img src={deployLiveMobile} class="img-responsive img-rounded marginT hidden-lg hidden-md" alt="Deploy live" />
                </div>
                <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 class="headings--over">Command Center &amp; Go-Live Deployment Management</h2>
                  <h5 class="headings--under">Readiness and Transition Services</h5>
                  <p>NCG provides Mock Event exercise planning and implementation which includes fallback and contingency planning for comprehensive readiness. NCG tailors its Command Center Planning services to your organization and your deal to make sure communication plans, event logistics, run book development, and consolidation are tuned to your specific needs.
            </p>
                  <p>NCG has tools and proven methodologies for reporting and sharing information as a component of an overall event management. This includes robust, yet easy-to-use, incident management and messaging infrastructure and coordination.</p>
                </div>
              </div>
            </div>
            <div id="transition" class="page-section page-section--striped">
              <div class="mainpage-wrapper ">
                <div class="graphic-wrap graphic-wrap--right imgCropped col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={transitionDesktop} class="img-responsive img-rounded hidden-sm hidden-xs " alt="Transition Service" />
                  <img src={transitionMobile} class="img-responsive img-rounded hidden-lg hidden-md " alt="Transition Service" />
                </div>
                <div itemprop="specialty" class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h2 class="headings--over">Post Transition Service</h2>
                  <h5 class="headings--under">Transition to Business as Usual and Closure</h5>
                  <p>NCG facilitates the transition of any outstanding event items to business by support or project functions as needed, to ensure all task follow-ups are handled effectively.</p>
                  <p>After the event, NCG has processes for organizations to conduct gap analysis so any lessons learned can be captured for future efforts.</p>
                </div>
              </div>
            </div>
            <CustomModal open={openTailorModal} onClose={toggleTailorForm}>
              <TailorSupportModal setOpenTailorModal={setOpenTailorModal} />
            </CustomModal>

            <CustomModal open={openExperienceModal} onClose={toggleExperienceForm}>
              <ExperienceSkillsModal setOpenExperienceModal={setOpenExperienceModal} />
            </CustomModal>
            <div id="tailorSupport" class="modal fade" role="dialog">
              <div class="modal-dialog">

                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">Tailor Your Support</h4>
                  </div>
                  <div class="modal-body">
                    <div class="page-section page-section--striped ">
                      <img src="images/images/mergers/section/topics/tailor-support.png" class="img-responsive" alt="Tailor Support" />
                    </div>
                  </div>
                  <div class="modal-footer" style={{ border: "none" }}>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            <div id="experienceSkills" class="modal fade" role="dialog">
              <div class="modal-dialog">

                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">Experience &amp; Skills</h4>
                  </div>
                  <div class="modal-body">
                    <div class="page-section page-section--striped ">
                      <img src="images/images/mergers/section/topics/experience-skills.png" class="img-responsive" alt="Experience Skills" />
                    </div>
                  </div>
                  <div class="modal-footer" style={{ border: "none" }}>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MergerAcquistionMicrosite;