import { createTheme } from "@material-ui/core/styles";
import variables from "./variables.module.scss";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

function pxToRem(value) {
  return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});
const theme = createTheme({
  palette: {
    primary: {
      light: variables.ncgBlue0,
      main: variables.ncgBlue1,
      dark: variables.ncgBlue2,
    },
    secondary: {
      light: variables.ncgBlue3,
      main: variables.ncgBlue4,
      dark: variables.ncgBlue5,
    },
    text: {
      primary: variables.textPrimary,
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Helvetica, Helvetica Neue",
    letterSpacing: ".08em",

    h1: {
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSize: variables.fontH1,
      marginTop: 30,
      marginBottom: 20,
      color: variables.ncgBlue4,
      fontWeight: 500,
      lineHeight: "1.2em",
      textShadow: "1px 1px 1px #fff",
      transition: "top 0.35s ease-in-out",
      padding: 0,
      letterSpacing: ".08em",
      // [theme.breakpoints.down('sm')]: {
      //   fontSize: variables.fontH2,
      // },
    },
    h2: {
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSize: variables.fontH2,
      color: variables.ncgBlue2,
      fontWeight: 500,
      lineHeight: "1.25em",
      marginTop: 20,
      marginBottom: 10,
      letterSpacing: ".08em",
    },
    h3: {
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSize: variables.fontH3,
      marginTop: 20,
      marginBottom: 10,
      color: variables.ncgBlue1,
      lineHeight: "1.25em",
      fontWeight: 500,
      letterSpacing: ".08em",
    },
    h4: {
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSize: variables.fontH4,
      color: variables.ncgTeal2,
      marginTop: 20,
      marginBottom: 10,
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSize: variables.fontH5,
      color: variables.textSecondary,
      lineHeight: "1.5em",
      fontWeight: 700,
      marginTop: 25,
      textTransform: "uppercase",
      letterSpacing: ".08em",
    },
    h5Under: {
      fontFamily: "Helvetica Neue, Helvetica, sans-serif",
      fontSize: variables.fontH5,
      color: variables.ncgBlue2,
      lineHeight: "1.5em",
      fontWeight: 700,
      marginTop: 25,
      textTransform: "uppercase",
      letterSpacing: ".08em",
    },
    body1: {
      fontSize: variables.fontBody,
      color: variables.textPrimary,
      lineHeight: "1.875em",
      letterSpacing: ".08em",
      display: "block",
      paddingTop: 5,
      paddingBottom: 10,
    },
    body2: {
      fontSize: variables.fontBody2,
      color: variables.textPrimary,
      lineHeight: "1.875em",
      letterSpacing: ".08em",
    },
  },
  background: {
    default: "#ffffff",
  },
breakpoints,
  overrides: {

    MuiTypography: {
      h1: {
        [breakpoints.down("sm")]: {
          fontSize: pxToRem(32)
        }
      },
      h2: {
        [breakpoints.down("sm")]: {
          fontSize: pxToRem(26)
        }
      },
      h3: {
        [breakpoints.down("sm")]: {
          fontSize: pxToRem(24)
        }
      },
      h4: {
        [breakpoints.down("sm")]: {
          fontSize: pxToRem(22)
        }
      },
      h5: {
        [breakpoints.down("sm")]: {
          fontSize: pxToRem(14)
        }
      },
      body1: {
        [breakpoints.down("sm")]: {
          fontSize: pxToRem(14)
        }
      },
    },

    MuiLink: {
      root: {
        fontFamily: "Helvetica Neue, Helvetica",
        '&:hover:hover': {
          color: variables.ncgBlue3,
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiInputBase: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiBreadcrumbs: {
      root: {
        padding: 0,
        },
      separator: {
        color: variables.ncgBlue3,
        fontSize: 16,
        lineHeight: "normal",
        paddingTop: 5,
        paddingBottom: 5,
      },
      li: {
        paddingTop: 0,
      },
    },

  },
});

export default theme;
