import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    [theme.breakpoints.down(763)]: {
      paddingBottom: 55,
    },
    [theme.breakpoints.up(763)]: {
      paddingBottom: 45,
    },
    [theme.breakpoints.up(780)]: {
      paddingBottom: 75,
    },
  }
}));
const menuItems =
  [
    { label: 'Programmatic Approach', href: '#Programmatic-Approach' },
    { label: 'Services', href: '#Services' },
    { label: 'Getting Started', href: '#Getting-Started' },
  ]
const GRCNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar menuItems={menuItems} elementInView={props.elementInView}/>
    </div>
  );
}
export default GRCNavBar;