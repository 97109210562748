import { Grid, makeStyles, Typography } from "@material-ui/core";
import EFSectionContainer from "../shared/EFSectionContainer";
import addressingObstacles from "../../../../images/microsite/educationFoundation/addressingObstacles.png";

const periwinkle = "#5359E6";

const useStyles = makeStyles((theme) => ({
  imageGridItem: {
    position: "relative",
    minHeight: 400
  },
  containImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  containImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  stretchGrid: {
    alignSelf: "stretch",
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 670
    }
  },
  heading: {
    marginBottom: 32,
    color: periwinkle,
    fontSize: 42,
    fontWeight: 700,
  },
  textColumnContent: {
    paddingLeft: 60,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 40,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  textGridItem: {
    alignSelf: "center"
  },
}));


export default function EFDEISection() {
  const classes = useStyles();
  return (
    <EFSectionContainer scrollId="Addressing-Obstacles" maxWidth="none" stretch>
      <Grid className={classes.stretchGrid} container>
        <Grid className={classes.imageGridItem} item xs={12} sm={4} md={6}>
          <div
            className={classes.containImageContainer}
          >
            <img
              src={addressingObstacles}
              className={classes.containImage}
              alt=""
              role="presentation"
            />
          </div>
        </Grid>
        <Grid className={classes.textGridItem} item xs={12} sm={8} md={6}>
          <div className={classes.textColumnContent}>
            <Typography variant="h2" className={classes.heading}>
              Addressing Obstacles &amp; Barriers
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              While there are excellent AP and collegiate computer science,
              cybersecurity, and coding course offerings—applied technology
              and threat risk management consideration remains sparse across
              educational disciplines.
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              K-12 schools have strong coding clubs to augment academic
              studies, but participation in these clubs can be challenging
              for students that lack a basic set of technical skills
              and experiences.
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              These gaps result in a general workforce with insufficient
              skills to best leverage technology to tackle challenges
              and opportunities. They also result in exposure to threats
              and those seeking to do us harm leveraging technology.
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              Together we learn about how integral code is to our lives
              and businesses. We build skills with technology that will
              help with school and job opportunities.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </EFSectionContainer>
  );
}
