import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classNames from "classnames";

//image
import framework from "images/images/orchestration/page/orchestration-framework.png"

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    borderRadius: 8,
    margin: 10,
  },
  content: {
    width: 600,
    height: 365,
    padding: "0 25px 20px 25px"
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 0,
    alignItems: "center"
  },
  subHeaderContainer: {
    padding: "0px 10px 35px 10px",
  },
  formSection: {
    paddingTop: 0,
    // backgroundColor: "orange",
    width: 300,
    paddingBottom: 10,
  },
  closeButtonSection: {
    textAlign: "right"
  }

}));
export const OrchestrationFrameworkModal = (props) => {
  const classes = useStyles();
    
        return (
          <Grid className={classNames(classes.formContainer, "crunch" )}>
            <h2>{`Orchestration Framework`}</h2>
            <div class="page-section">
              <img src={framework} class="img-responsive" alt="Orchestration Platform" />
            </div>
            <div className={classes.closeButtonSection}>
                <Button type="button" class="btn btn-default" onClick={() => props.setOpenModal(false)}>Close</Button>
            </div>
          </Grid>

        );
}

export default OrchestrationFrameworkModal;
