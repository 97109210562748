import React, {useState} from "react";

// section images
import development from "../../../images/images/microsite/privacy-services/privacy-program-development.png";
import dataManagementEngineering from "../../../images/images/microsite/privacy-services/privacy-data-management-engineering.png";
import breach from "../../../images/images/microsite/privacy-services/privacy-breach-im.png";
import developmentIcon from "../../../images/images/microsite/privacy-services/privacy-program-development-icon.png";
import assessment from "../../../images/images/microsite/privacy-services/privacy-assessments.png";
import breachIcon from "../../../images/images/microsite/privacy-services/privacy-breach-im-icon.png";
import engineeringIcon from "../../../images/images/microsite/privacy-services/privacy-data-management-engineering-icon.png";
import assessmentIcon from "../../../images/images/microsite/privacy-services/privacy-assessments-icon.png";
import developmentLarge from "../../../images/images/microsite/privacy-services/privacy-program-development-large.png";
import riskBasedApproachInfographic from "../../../images/images/microsite/privacy-services/risk-based-approach-infographic.png";
import tailoring from "../../../images/images/microsite/privacy-services/tailoring-privacy.png";
import dataDrivenProcess from "../../../images/images/microsite/privacy-services/data-driven-process.png";
import ongoingSupport from "../../../images/images/microsite/privacy-services/ongoing-support.png";
import breachLarge from "../../../images/images/microsite/privacy-services/privacy-breach-im-large.png";
import imCapabilities from "../../../images/images/microsite/privacy-services/im-capabilities.png";
import breachResponse from "../../../images/images/microsite/privacy-services/breach-response.png";
import engineeringLarge from "../../../images/images/microsite/privacy-services/privacy-data-management-engineering-large.png";
import riskBasedApproach from "../../../images/images/microsite/privacy-services/risk-base-approach.png";
import dataManagement from "../../../images/images/microsite/privacy-services/privacy-data-management.png";
import engineering from "../../../images/images/microsite/privacy-services/privacy-engineering.png";
import assessmentsLarge from "../../../images/images/microsite/privacy-services/privacy-assessments-large.png";
import detailedInsight from "../../../images/images/microsite/privacy-services/detailed-insight.png";

import PrivacyServicesNavBar from "./privacyServicesNavBar.js";

//carousel
import PrivacyServicesCarousel from "./PrivacyServicesCarousel"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";
import { useEffect } from 'react';

const PrivacyServicesMicrosite = (props) => {
  const [elementId, setElementId] = useState("");

  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  })

  return (
    <>
    <PrivacyServicesNavBar elementInView={elementId}/>
      <BackToTopCustom />
      <PrivacyServicesCarousel />
      <div class="bootstrap" >
        <div class="crunch">
          <div class="micro">
            <div class="privacy-services">
              <div class="micro-new">
                <html lang="en">
                  <head>
                    <meta name="viewport" content="width-device=width, initial-scale=1.0" />
                    <title>Northcross Group - Management &amp; Technology Consulting</title>
                  </head>
                  <body class="preload microsite-new microsite microsite-privacy microsite-privacy-desktop privacy" data-spy="scroll" data-target="#navbarCollapse" data-offset="50">
                    <div class="page-section page-section--site-layout col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="mainpage-wrapper">
                        <div class="tile-collection-wrap">
                          <div class="tile-collection">
                            <div id="tilePrivacyProgramDevelopment" class="tile tile--shadow">
                              <div class="tile-wrap">
                                <h5>Privacy Program Development</h5>
                                <div class="tile--graphic">
                                  <img src={development} class="img-responsive graphic" alt="privacy program devlopment" />
                                </div>
                                <div class="tile--body">
                                  <p>Programs to meet compliance and provide business value</p>
                                </div>
                                <div class="tile--learn-more">
                                  <a itemprop="relatedLink" href="#privacy-program-development" class="btn btn-default">Learn More...</a>
                                </div>
                              </div>
                            </div>
                            <div id="tilePrivacyBreachIM" class="tile tile--shadow">
                              <div class="tile-wrap">
                                <h5>Privacy Breach &amp; Incident Management</h5>
                                <div class="tile--graphic">
                                  <img src={breach} class="img-responsive graphic" alt="privacy breach" />
                                </div>
                                <div class="tile--body">
                                  <p>Preparation and Response Services</p>
                                </div>
                                <div class="tile--learn-more">
                                  <a itemprop="relatedLink" href="#privacy-breach-im" class="btn btn-default">Learn More...</a>
                                </div>
                              </div>
                            </div>
                            <div id="tilePrivacyDataManagement" class="tile tile--shadow">
                              <div class="tile-wrap">
                                <h5>Privacy Data Management &amp; Engineering</h5>
                                <div class="tile--graphic">
                                  <img src={dataManagementEngineering} class="img-responsive graphic" alt="privacy data management engineering" />
                                </div>
                                <div class="tile--body">
                                  <p>Integrating Privacy into Existing Development & Management</p>
                                </div>
                                <div class="tile--learn-more">
                                  <a itemprop="relatedLink" href="#privacy-data-manage-engineering" class="btn btn-default">Learn More...</a>
                                </div>
                              </div>
                            </div>
                            <div id="tilePrivacyAssessments" class="tile tile--shadow">
                              <div class="tile-wrap">
                                <h5>Privacy Assessments</h5>
                                <div class="tile--graphic">
                                  <img src={assessment} class="img-responsive graphic" alt="privacy assessments" />
                                </div>
                                <div class="tile--body">
                                  <p>GDPR and CCPA Compliant Assessments</p>
                                </div>
                                <div class="tile--learn-more">
                                  <a itemprop="relatedLink" href="#privacy-assessments" class="btn btn-default">Learn More...</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="privacy-program-development" class="page-section page-section--program-development col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                      <div class="site-layout-title">
                        <div class="mainpage-wrapper">
                          <div class="site-layout-title-inner">
                            <div class="site-layout--graphic circle">
                              <div class="graphic-wrap">
                                <img src={developmentIcon} class="img-responsive graphic" alt="privacy program development icon" />
                              </div>
                            </div>
                            <h2>Privacy Program: Design, Development, Implementation, and Operations</h2>
                          </div>
                        </div>
                      </div>

                      <div class="page-section page-section--intro left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-7 col-xs-6 image-wrapper">
                          <img class="col-lg-12 col-md-11 col-sm-12 col-xs-12 img-responsive graphic" src={developmentLarge} alt="privacy program development" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-10 text-wrapper">
                            <h3 class="secondary-color-1">
                              Tailoring programs to meet your specific compliance requirements and needs with a sustainable foundation to grow and evolve with the organization
                            </h3>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                              Privacy assessments are a powerful tool to understand current state risks and what can be done to mitigate those risks. Insight gathered helps identify where gaps exist and how processes can be improved.  Privacy Impact Assessments (PIA) are required under several compliance constructs and Data Protection Impact Assessments (DPIA) are required under the General Data Protection Regulation (GDPR).
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                              NCG provides their party Privacy Impact Assessments and GDPR compliant Data Protection Impact Assessments.  We also help organizations develop and implement internal privacy assessment programs.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="page-section page-section--risk-based-approach-pd left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 text-wrapper">
                            <h3 class="secondary-color-1">
                              Risk-Based Approach to Program Development
                            </h3>
                            <p class="heavy">
                              NCG uses risk-based principles when building privacy programs and improvement efforts.
                            </p>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 large-mobile-image-wrapper">
                            <img class="img-responsive graphic float-none" alt="risk based approach infographic" src={riskBasedApproachInfographic} />
                            </div>
                        </div>
                      </div>

                      <div class="page-section page-section--tailoring-privacy left extra-margin col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-11 col-md-12 col-sm-12 col-xs-9 image-wrapper image-mobile">
                          <img class="col-lg-12 col-md-12 col-sm-12 col-xs-12 img-responsive graphic" src={tailoring} alt="tailoring privacy" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-8 col-sm-10 col-xs-9 text-wrapper">
                            <h3 class="secondary-color-1">
                              Tailoring Privacy Programs to the Organization
                            </h3>
                            <p class="col-lg-10 col-md-10 col-sm-10 col-xs-9">
                              Industry requirements, market considerations, and organizational structures are key factors that play into the design of an effective and sustainable program. Stakeholder engagement from initial design through to implementation helps ensure alignment and the capture of business value specific to the organization.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-9">
                              NCG works with organizational leadership and management to develop a custom plan for the organization. These solutions delineate clear expectations and responsibilities, working within organizational constructs instead of against them.
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-11 col-md-12 col-sm-12 col-xs-12 image-wrapper image-desktop">
                          <img class="col-lg-12 col-md-12 col-sm-12 col-xs-12 img-responsive graphic" src={tailoring} alt="tailoring privacy" />
                        </div>
                      </div>

                      <div class="page-section page-section--data-driven-process right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-12 col-md-12 col-sm-12 col-xs-12 img-responsive graphic float-right" src={dataDrivenProcess} alt="data driven process" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-wrapper float-right">
                            <h3 class="secondary-color-1">
                              Data Driven Processes and Decision Making
                            </h3>
                            <p class="col-lg-8 col-md-8 col-sm-9 col-xs-9 float-right">
                              Effective programs provide data to support efforts and decision making. This is gained from detailed knowledge of the entire data life cycle, inclusive of processing and utilization, that can leverage data wherever and whenever it exists.
                            </p>
                            <p class="col-lg-8 col-md-8 col-sm-9 col-xs-9 float-right">
                              This access to data across all its states supports compliance requirements and risk management. It also provides opportunity for analytics and business intelligence to better manage customer experience and improve products and services.
                            </p>
                            <p class="col-lg-8 col-md-8 col-sm-9 col-xs-9 float-right">
                              NCG uses a combination of tools and proven methods to capture current state structures and processing in both electronic and physical formats across the entire enterprise. We capture the process flow and life cycle transitions data takes to properly understand the data in all at each point in the business processes.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="page-section page-section--ongoing-support extra-margin-small left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-7 col-xs-8 image-wrapper float-right">
                          <img class="col-lg-12 col-md-12 col-sm-12 col-xs-12 img-responsive graphic float-none" src={ongoingSupport} alt="ongoing support" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-wrapper">
                            <h3 class="secondary-color-1">
                              Ongoing Support & Continuous Improvements
                            </h3>
                            <p class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                              Privacy Programs need to evolve with the organization as it grows.  Program support functions must adapt to changing needs by measuring effectiveness and implementing improvements as appropriate.
                            </p>
                            <p class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                              NCG program designs and operations build the agility to identify and incorporate improvements from the start. We ensure a structural foundation engineered for versatility; with training, tools, and support capabilities that acclimate to the maturity level of the organization.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="privacy-breach-im" class="page-section page-section--privacy-breach-im col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                      <div class="site-layout-title">
                        <div class="mainpage-wrapper">
                          <div class="site-layout-title-inner">
                            <div class="site-layout--graphic circle">
                              <div class="graphic-wrap">
                                <img src={breachIcon} class="img-responsive graphic" alt="privacy breach icon" />
                              </div>
                            </div>
                            <h2>Privacy Breach and Incident Management</h2>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--intro right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-10 col-md-12 col-sm-12 col-xs-12 img-responsive graphic float-right" src={breachLarge} alt="privacy breach large" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-xxs-6 text-wrapper float-right">
                            <h3 class="secondary-color-2">
                              Be prepared for incidents with established and practiced processes, along with trusted partner expert support to assist when issues are encountered
                            </h3>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-xxs-10 float-right">
                              Ensure your teams have the capabilities to best protect the organization from privacy data breaches. These capabilities include effective functions to monitor, identify, respond, and recover from a range of system incidents.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-xxs-10 float-right">
                              Plans and processes are developed and rehearsed to ensure stakeholders from across the organization understand their roles and expectations when an incident occurs. These readiness functions focus efforts and help foster effective collaboration during an actual event.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-8 col-xs-9 col-xxs-10 float-right">
                              NCG develops programs and frameworks to deal with incidents in a managed way. These structures establish consistent methods to classify events from basic incidents to privacy data breaches, and then initiate the appropriate set of steps to address them. This includes support for incident managers to engage the right resources, facilitation of tasks, and structuring of communications.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-9 col-xs-11 col-xxs-10 float-right">
                              NCG conducts exercises to ensure people across teams understand the processes in place and the roles they play. We also help keep the processes up to date and reflective of changes in the operating environment and organizational structures.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-10 col-xs-12 col-xxs-10 float-right">
                              When incidents do occur, NCG provides the technical and subject matter expertise to assist your teams and coordinate with legal, regulator, and other applicable third parties.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--im-capabilities left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-10 col-md-12 col-sm-12 col-xs-12 img-responsive graphic" src={imCapabilities} alt="im capabilities" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-6 col-md-7 col-sm-8 col-xs-8 text-wrapper">
                            <h3 class="secondary-color-2">
                              Honing Organization Specific Incident Management Capabilities
                            </h3>
                            <p class="col-lg-12 col-md-10 col-sm-9 col-xs-9">
                              Incident management procedures need to be comprehensive yet scalable to handle a range of possible scenarios specific to the organization and its operations. Processes need the rigor of  management steps and and proper memorialization of events to support applicable notifications and disclosures. Internal teams need to understand their roles and how they can work together, to engage in a consistent and effective manner when needed.
                            </p>
                            <p class="col-lg-12 col-md-10 col-sm-9 col-xs-9">
                              NCG helps organizations develop plans and processes that fit their operations and organizational dynamics, providing a robust capability to deal with incidents. NCG has tools to help organizations manage efforts with teams of all sizes and disciplines. NCG facilitates exercises and tests for teams to hone their skills and be ready when needed.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--breach-response right extra-margin-small col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-12 col-md-12 col-sm-12 col-xs-12 img-responsive graphic float-right" src={breachResponse} alt="breach response" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-8 text-wrapper float-right">
                            <h3 class="secondary-color-2">
                              Breach Response, Remediation, & Recovery
                            </h3>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-9 float-right">
                              Breach response efforts must start by understanding the source and taking actions to stop the immediate threat and  prevent further damage. Lessons learned are captured and best practices memorialized to improve preventative and response measures going forward.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-9 float-right">
                              Once the situation is under control, strategically designed processes allow for a graceful transition back to normal operations. These processes also improve mitigation efforts through lessons learned, strengthening the prevention of similar events in the future.
                            </p>
                            <p class="col-lg-7 col-md-7 col-sm-7 col-xs-9 float-right">
                              NCG provides technical and subject matter experts to advise and support your team to address the incident through all the recovery steps. This includes post incident notification processes and coordination with legal and regulatory partners—as well as addressing any vulnerabilities that may still represent exposure.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="privacy-data-manage-engineering" class="page-section page-section--privacy-data-management-engineering col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                      <div class="site-layout-title">
                        <div class="mainpage-wrapper">
                          <div class="site-layout-title-inner">
                            <div class="site-layout--graphic circle">
                              <div class="graphic-wrap">
                                <img src={engineeringIcon} class="img-responsive graphic" alt="privacy data management engineering icon" />
                              </div>
                            </div>
                            <h2>Privacy Data Management &amp; Privacy Engineering</h2>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--intro left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 image-wrapper">
                          <img class="col-lg-10 col-md-12 col-sm-12 col-xs-12 img-responsive graphic" src={engineeringLarge} alt="privacy data management engineering large" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-wrapper">
                            <h3 class="secondary-color-3">
                              Frameworks and approaches that proactively incorporate Privacy considerations into all aspects of the organization’s systems, technologies, and practices
                            </h3>
                            <p class="col-lg-7 col-md-8 col-sm-8 col-xs-8">
                              The concept of Privacy by Design makes privacy part of the default considerations that should go into all aspects of an organization’s systems and infrastructure. This approach establishes privacy as a principle of all data handling, spanning from security to technical standards, privacy governs the entire data life cycle.
                            </p>
                            <p class="col-lg-7 col-md-8 col-sm-8 col-xs-8">
                              NCG helps organizations build or transform existing data management and engineering efforts to embed and institutionalize privacy across design, implementation, and operations. We help integrate privacy as mutually supportive to security, safety, and other controls that provide business value.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--risk-approach-privacy stripe col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="parallelogram teal col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-8 image-wrapper">
                            <img class="col-lg-10 col-md-12 col-sm-12 col-xs-12 img-responsive graphic" src={riskBasedApproach} alt="risk based approach" />
                          </div>
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-6 col-md-6 col-sm-7 col-xs-7 text-wrapper float-right">
                            <h3 class="secondary-color-3">
                              Risk Based Approach to Privacy
                            </h3>
                            <p class="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                              Privacy risks have different factors and considerations compared to data security. Risk management programs must account for these variances.
                            </p>
                            <p class="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                              Data threat management  needs to address privacy, security, and other objectives in a complimentary fashion, influencing how things are done on a daily basis across the enterprise.
                            </p>
                            <p class="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                              NCG helps organizations integrate privacy provisions for Personally Identifiable Information (PII) into enterprise risk management processes in conjunction with other data control objectives. We help organizations accomplish economies of scale with effort and investments to meet compliance, generate business value, and institutionalize concepts into the corporate culture.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--privacy-data-management left col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-10 col-md-11 col-sm-12 col-xs-12 img-responsive graphic" src={dataManagement} alt="privacy data management" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-8 col-sm-9 col-xs-8 text-wrapper">
                            <h3 class="secondary-color-3">
                              Privacy Data Management
                            </h3>
                            <p class="col-lg-7 col-md-8 col-sm-8 col-xs-9">
                              Privacy considerations apply to PII data across its life cycle, from collection to creation, analysis, use, storage, dissemination, disclosure, and disposal. Privacy practices cut across technical, legal, regulatory, and policy domains in an organization. Standards, procedures, and processing all contribute to privacy control functions. These privacy operations are supported by defined accountability and responsibility of internal and external parties that interact with PII.
                            </p>
                            <p class="col-lg-7 col-md-8 col-sm-8 col-xs-9">
                              NCG helps organizations gain a complete and detailed view of data; where it exists, how it moves, how it is accessed, how it is processed, and how it changes across its life cycle. We help organizations design and implement effective controls and processes for privacy that also provide business value.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--privacy-engineering extra-margin stripe col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="parallelogram teal col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 image-wrapper">
                            <img class="col-lg-10 col-md-12 col-sm-12 col-xs-12 img-responsive graphic" src={engineering} alt="privacy engineering" />
                          </div>
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-6 col-md-6 col-sm-7 col-xs-7 text-wrapper float-right">
                            <h3 class="secondary-color-3">
                              Privacy Engineering
                            </h3>
                            <p class="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                              Integrating privacy considerations into system engineering builds better data control and management from the start, delivering reliable knowledge of where PII data exists and how it is used. Doing so provides granular and effective manageability of the data as well as the ability to control how PII is processed and handled. Capabilities and requirements are accomplished with privacy controls that manage risks as part of their core functions.
                            </p>
                            <p class="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                              NCG helps organizations apply privacy considerations into engineering design and development frameworks. We help implement controls, training, peer support, and review processes that develop internal capabilities and competencies for privacy across engineering functions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="privacy-assessments" class="page-section page-section--privacy-assessments col-lg-12 col-md-12 col-sm-12 col-xs-12 anchor">
                      <div class="site-layout-title">
                        <div class="mainpage-wrapper">
                          <div class="site-layout-title-inner">
                            <div class="site-layout--graphic circle">
                              <div class="graphic-wrap">
                                <img src={assessmentIcon} class="img-responsive graphic" alt="privacy assessment icon" />
                              </div>
                            </div>
                            <h2>Privacy Impact Assessments & Data Protection Impact Assessments</h2>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--intro left extra-margin col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-11 col-md-11 col-sm-12 col-xs-12 img-responsive graphic" src={assessmentsLarge} alt="privacy assessments" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-7 col-md-7 col-sm-8 col-xs-8 text-wrapper">
                            <h3 class="secondary-color-4">
                              Capturing a detailed understanding of an organization’s current state of privacy, risks, and what steps can help bolster that position
                            </h3>
                            <p class="col-lg-11 col-md-11 col-sm-9 col-xs-9">
                              Privacy assessments are a powerful tool to understand current state risks and what can be done to mitigate those risks. Insight gathered helps identify where gaps exist and how processes can be improved.  Privacy Impact Assessments (PIA) are required under several compliance constructs and Data Protection Impact Assessments (DPIA) are required under the General Data Protection Regulation (GDPR).
                            </p>
                            <p class="col-lg-11 col-md-11 col-sm-9 col-xs-9">
                              NCG provides third-party Privacy Impact Assessments and GDPR compliant Data Protection Impact Assessments. We also help organizations develop and implement internal privacy assessment programs.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-section page-section--detailed-insight right extra-margin col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 image-wrapper">
                          <img class="col-lg-10 col-md-10 col-sm-12 col-xs-12 img-responsive graphic float-right" src={detailedInsight} alt="detailed insight" />
                        </div>
                        <div class="mainpage-wrapper">
                          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-wrapper float-right">
                            <h3 class="secondary-color-4">
                              Detailed Insight to Manage Risks, Plan Improvements, and Address Compliance Requirements
                            </h3>
                            <p class="col-lg-8 col-md-9 col-sm-8 col-xs-9 float-right">
                              Corrective actions and improvements are identified and incorporated into roadmaps and remediation plans. The assessment examines the potential risks to the data subjects and to the enterprise. It considers applicable privacy compliance requirements, along with all the measures the organization has in place as safeguards and controls. Corrective actions and improvements are identified to incorporate into roadmaps and implementation plans.
                            </p>
                            <p class="col-lg-8 col-md-9 col-sm-8 col-xs-9 float-right">
                              NCG privacy assessments are designed to meet compliance requirements and provide organizations with actionable intelligence to understand exposure and take pragmatic steps to improve their overall posture.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </body>
                </html>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PrivacyServicesMicrosite;