import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const DownloadIcon = () => {
  return (
    <FontAwesomeIcon icon={faDownload} color="#12b892" size="lg" style={{ marginRight: "5px" }} />
  );
};

export default DownloadIcon;
