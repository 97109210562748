import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    paddingBottom: 80,
    [theme.breakpoints.down(763)]: {
      paddingBottom: 55,
    },
  }
}));
const menuItems =
  [
    { label: 'Privacy Program Development', href: '#privacy-program-development' },
    { label: 'Privacy Breach & Incident Management', href: '#privacy-breach-im' },
    { label: 'Privacy Data Management & Engineering', href: '#privacy-data-manage-engineering' },
    { label: 'Privacy Assessments', href: '#privacy-assessments' },
    { label: 'Contact', href: '#request', rfpType: 'Privacy', contactForm: true }
  ]
const PrivacyServicesNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar bgColor="blue" menuItems={menuItems} elementInView={props.elementInView}/>
    </div>
  );
}
export default PrivacyServicesNavBar;