import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Image from "components/layout/image.component";
import Video from "components/layout/video.component";
import ContentContainer from "components/layout/contentContainer.component";
import classNames from "classnames";


const useStyles = makeStyles((theme) => ({
  pageSectionContentRight: {
    position: "relative",
    display: "flex",
    alignItems: "center",

  },
  pageSectionContentRightAbsolute: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column-reverse",
    },
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "fit-content",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      paddingTop: 60,
    },
  },
  contentWrapperVideo: {
    height: "100%",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
      paddingTop: 60,
    },
  },

  contentWrapperAbsolute: {
    position: "absolute",
    right: 0,
    width: "auto",
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 60,
    '& .graphic--desktop': {
        display: "block",
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse",
    },
  },
  sectionWrapperVideo: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column-reverse",
    },
  },
  sectionWrapperAbsolute: {
    justifyContent: "flex-start",
    zIndex: 100,
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  containerAbsolute: {
    position: "absolute",
    display: "flex",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  contentWrapperMobile: {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      width: "100%",
    },
  },
  label: {
    display: "block",
    color: "white",
  },
  textContainer: {
    paddingRight: 40,
  },
    textGradient: {
    '&::after': {
      display: "block",
      height: "100%",
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 10,
      background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 34%, rgba(255,255,255,0.7) 60%, rgba(255,255,255,0) 100%)",
    },
      [theme.breakpoints.down('sm')]: {
        '&::after': {
          display: "none",
        }
      },
  },
  firstOfSections: {
    paddingTop: "0px",
    paddingBottom: "20px",
  }
}));


const PageSectionContentRight = (props) => {
  const classes = useStyles(props);

  if (props.variant === "absolute") {
    return (
      <div id={props.id} className={classNames(classes.pageSectionContentRight, classes.textGradient, classes.pageSectionContentRightAbsolute)} >
        <Container maxWidth="lg">
          <Grid lg={12} className={classNames(classes.sectionWrapperAbsolute, classes.sectionWrapper)}>
            {props.children}
          </Grid>
        </Container>
        <div className={classNames(classes.contentWrapperAbsolute, classes.contentWrapper)}>
          <ContentContainer width={props.width} variant="customWidth">
            <Image url={props.image} variant="customWidth"/>
          </ContentContainer>
        </div>
        <Grid item  className={classNames(classes.contentWrapperMobile)}>
          <ContentContainer width={"100%"} variant="customWidth">
            <Image url={props.image} variant="customWidth" />
          </ContentContainer>
        </Grid>
      </div>
    );
  }
  if (props.variant === "video") {
    return (
      <div id={props.id} className={classes.pageSectionContentRight}>
        <Container maxWidth="lg">
          <Grid xs={12} sm={12} md={12} lg={12} className={classNames(classes.sectionWrapper, classes.sectionWrapperVideo, classes.firstOfSections)}>
            {props.children}
            <Grid item xs={12} sm={12} md={6} lg={6} className={classNames(classes.contentWrapper, classes.contentWrapperVideo)}>
                <Video src={props.src}/>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
  else {
    return (
      <div id={props.id} className={classes.pageSectionContentRight}>
        <Container maxWidth="lg">
          <Grid lg={12} className={classes.sectionWrapper}>
            {props.children}
            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.contentWrapper}>
              <Image className={"graphic--desktop"} url={props.image} />
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default PageSectionContentRight;