import { Grid, makeStyles, Typography } from "@material-ui/core";
import EFSectionContainer from "../shared/EFSectionContainer";
import techBusiness from "../../../../images/microsite/educationFoundation/techBusiness.png";

const neon = "#398AE5";
const neonGradient = (
  "linear-gradient(90deg, #2FABDA 0%, #398AE5 50%, #7C2DE1 100%)"
);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 200,
    paddingTop: 0,
    [theme.breakpoints.up("md")]: {
      paddingTop: 40
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 80
    }
  },
  heading: {
    marginBottom: 24,
    color: neon,
    fontStyle: "italic",
    fontWeight: 700,
    textShadow: "none",
    [`@supports (
      (background-clip: text) or (-webkit-background-clip: text)
    ) and (
      (text-fill-color: transparent) or (-webkit-text-fill-color: transparent)
    )`]: {
      "background": neonGradient,
      "background-clip": "text",
      "text-fill-color": "transparent",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
  },
  imageGridItem: {
    position: "relative",
    [theme.breakpoints.up('md')]: {
      minHeight: 400
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 571 // Native height
    }
  },
  textGridItem: {
    display: "flex",
    alignItems: "center"
  },
  stretchGrid: {
    alignSelf: "stretch",
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 670
    }
  },
  containImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  containImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  textColumnContent: {
    paddingLeft: 60,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 40,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
      paddingLeft: 0,
    },
  },
}));


export default function EFUnderSplashSection() {
  const classes = useStyles();
  return (
    <EFSectionContainer
      className={classes.container}
      maxWidth="none"
      disableMinHeight
      center
    >
      <Grid container>
        <Grid className={classes.imageGridItem} item xs={0} sm={4} lg={6}>
          <div
            className={classes.containImageContainer}
          >
            <img
              src={techBusiness}
              className={classes.containImage}
              alt=""
              role="presentation"
            />
          </div>
        </Grid>
        <Grid className={classes.textGridItem} item xs={12} sm={8} lg={6}>
          <div className={classes.textColumnContent}>
            <Typography variant="h2" className={classes.heading}>
              Technology and code are intrinsically woven into every aspect
              of our lives.
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              Success in business is dependent on an understanding of the value
              and risks technology poses.
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              No matter the field of study, students need to understand how
              to leverage and be threat-aware.
            </Typography>
            <Typography color="inherit" variant="body1" paragraph>
              NCG works with K-12, college, and post-graduate students
              and educators with tools, materials, and industry expertise
              to augment existing curriculum to better prepare students for
              today's marketplace and position for the future.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </EFSectionContainer>
  );
}
