import React from "react";
import NcgBlueLogo from "images/ncg-white-logo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: "100%",
    width: "100%",
    display: "block",
    backgroundImage: "url(" + NcgBlueLogo
     + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const NcgWhiteLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer} />
  );
};

export default NcgWhiteLogo;
