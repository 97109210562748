import { Container, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { EF_NAV_BAR_HEIGHT, EF_NAV_BAR_HEIGHT_MOBILE } from "../EFNavBar";

const white = "#F5F8FF";
const purple = "#3854B9";

const useStyles = makeStyles((theme) => ({
  muiContainer: {
    minHeight: `calc(100vh - ${EF_NAV_BAR_HEIGHT}px)`,
    padding: "150px 170px",
    overflowX: "hidden",

    // Must match EFSectionNoPaddingGridItem's values
    [theme.breakpoints.down("lg")]: {
      padding: "120px 140px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "80px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: `calc(100vh - ${EF_NAV_BAR_HEIGHT_MOBILE}px)`,
      padding: "70px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "70px 20px",
    },
  },
  minHeightDisabled: {
    minHeight: 0
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  stretch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "stretch",
  },
  sibling: {
    "&$muiContainer": {
      paddingTop: 0
    }
  },
  colorDefault: {
    backgroundColor: white,
    color: purple,
  },
  absolutePositioningContainer: {
    position: "relative",
    overflow: "hidden"
  },
  scrollTarget: {
    position: "absolute",
    top: -EF_NAV_BAR_HEIGHT,
    right: 0,
    left: 0,
    height: 1,
    [theme.breakpoints.down("sm")]: {
      top: -EF_NAV_BAR_HEIGHT_MOBILE
    }
  },
  siblingScrollTarget: {
    top: (EF_NAV_BAR_HEIGHT * -2)
  }
}));


export default function EFSectionContainer(props) {
  const classes = useStyles();
  const {
    centered = false,
    children,
    className,
    color,
    disableMinHeight = false,
    maxWidth = "lg",
    scrollId,
    sibling = false,
    stretch = false
  } = props;

  return (
    <div className={classes.absolutePositioningContainer}>
      {!!scrollId && (
        <div
          id={scrollId}
          className={
            classNames(
              classes.scrollTarget,
              sibling && classes.siblingScrollTarget
            )
          }
        />
      )}
      <Container
        maxWidth={maxWidth}
        className={
          classNames(
            classes.muiContainer,
            centered && classes.centered,
            disableMinHeight && classes.minHeightDisabled,
            stretch && classes.stretch,
            sibling && classes.sibling,
            !color && classes.colorDefault,
            className
          )
        }
      >
        {children}
      </Container>
    </div>
  )
}