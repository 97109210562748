import React, { useEffect, useState } from 'react';
import { Link } from "@material-ui/core";

import ContactForm from "./CMMCContactForm";
//images
import cmmcLogoMed from "images/images/microsite/cmmc/cmmc-orchestration-logo-medium.png"
import ropBadge from "images/images/microsite/cmmc/rop-badge.png"
import rpBadge from "images/images/microsite/cmmc/rp-badge.png"
import orgNeed from "images/images/microsite/cmmc/cmmc-org-needs.png"
import knowStart from "images/images/microsite/cmmc/know-where-youre-starting-comp.png"
import plan from "images/images/microsite/cmmc/plan-the-work-comp.png"
import workThePlan from "images/images/microsite/cmmc/work-the-plan-comp.png"
import programmatic from "images/images/microsite/cmmc/programmatic-approach.png"
import orchestraionLogo from "images/images/microsite/cmmc/cmmc-orchestration-portal-logo-2.png"
import progress from "images/images/microsite/cmmc/progress-oversight.png"
import automation from "images/images/microsite/cmmc/automation-support-updates.png"
import documentation from "images/images/microsite/cmmc/documentation-management.png"
import workflow from "images/images/microsite/cmmc/workflow-facilitation.png"

import CMMCNavBar from "./cmmcNavBar.js"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const CMMCMicrosite = (props) => {
  const [elementId, setElementId] = useState("");
  useEffect(() => {
    function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
      return (
        rect.top <= 70 && rect.bottom >= 70
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor")
      for (let item of anchorList) {
        if (isElementInViewport(item)) {
          setElementId(item.id)
        }
      }
    }
  })

  const [modal, setModal] = useState(false);
  const openForm = (event) => {
    setModal(true);
  }
  const closeForm = () => {
    setModal(false);
  }
  return (
    <>
    <CMMCNavBar elementInView={elementId}/>
      <BackToTopCustom />
      <div class="micro" id="top">
        <div class="bootstrap">
          <div class="cmmc-micro">
            <div class="crunch">
              <div class="micro-new"></div>
              <html lang="en">
                <head>
                  <meta name="viewport" content="width-device=width, initial-scale=1.0" />
                </head>
                <body class="preload microsite microsite-cmmc microsite-cmmc-desktop cmmc" data-spy="scroll" data-target="#navbarCollapse" data-offset="50">

                  <div class="preload microsite microsite-cmmc microsite-cmmc-desktop cmmc">
                    <div class="container-fluid intro-splash">
                      <div class="gradient-overlay white left-to-right row">
                        <div class="splash-content-container">
                          <div class="col-xlg-6 col-lg-7 col-md-8 col-sm-8 col-xs-12 float-none main-content">
                            <div class="intro-splash-wrapper">
                              <img src={cmmcLogoMed} class="CMMClogo logo-desktop img-responsive " alt="NorthCross Group - CMMC" />
                              <div class="col-lg-10 col-md-10 col-sm-10 col-xs-8 main-content-wrapper">
                                <h1 class="primary-color">DoD Cybersecurity Maturity Model Certification (CMMC) Preparation &amp; Implementation Services</h1>
                                <div class="button-container">
                                  <a class="btn btn-primary btn-lg gradient" target="_blank" rel="noreferrer" href="https://cmmcquestionnaire.com/">CMMC questionnaire</a>
                                  <p class="secondary-color">Take a free questionnaire to see where you might stand…</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="badges col-xlg-6 col-lg-6 col-md-12 col-sm-8">
                            <div class="badge-wrapper col-xlg-6 col-lg-6 col-md-12 col-sm-12">
                              <img src={ropBadge} class="img-responsive " alt="NorthCross Group - CMMC - ROP Registered Badge" />
                            </div>
                            <div class="badge-wrapper col-xlg-6 col-lg-6 col-md-12 col-sm-12">
                              <img src={rpBadge} class="img-responsive " alt="NorthCross Group - CMMC - RP Registered Badge" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-section shadow-top service-areas">
                      <div class="video what-is-cmmc container">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-container">
                          <h3 class="secondary-color">Understanding DoD's CMMC</h3>
                          <p class="light">The Cybersecurity Maturity Model Certification (CMMC) is a new Department of Defense (DoD) process to ensure that all suppliers in the Defense Industrial Base have a base level of cybersecurity capability. DoD is planning to set CMMC Maturity Level requirements in RFPs starting in late 2020. DoD suppliers will need to be certified by a designated third party. CMMC self-certification will not be accepted. Suppliers that are not certified will not be able to win new DoD contracts individually or as part of a team.</p>
                          <a href='/blog/blog-post-cmmc'>NCG Blog Post: Preparation and Support for the Department of Defense Cybersecurity Maturity Model Certification (CMMC)</a>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 video-wrapper">
                          <iframe title="understandCMMC" width="560" height="315" src="https://www.youtube.com/embed/mEW2WxkOEIE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                      <div class="press-release">
                        <div class="press-release-wrapper">
                          <div class="pr-label">
                            <h4 class="med-bue">Press Release</h4>
                            <p class="date light">July 6th, 2020</p>
                          </div>
                          <div class="pr-link">
                            <a href="https://www.prnewswire.com/news-releases/ncg-offers-dod-vendors-crucial-tools-to-support-new-cmmc-cybersecurity-certification-requirements-301088019.html?tc=eml_cleartime">NCG Offers DoD Vendors Crucial Tools To Support New (CMMC) Cybersecurity Certification Requirements</a>
                          </div>
                        </div>
                      </div>


                      <div class="container tile-section">
                        <div class="tile-area needs-to-do col-lg-4 col-md-4 col-sm-4 col-xs-8">
                          <span class="tile-title">
                            <h3 class="secondary-color">What your organization needs to do for CMMC Certification</h3>
                          </span>
                          <p class="light">Cybersecurity and DoD procurement experts help you demonstrate what you have in place and can leverage to meet CMMC certification targets.</p>
                        </div>
                        <div class="tile-area establish-certification col-lg-4 col-md-4 col-sm-4 col-xs-8">
                          <span class="tile-title">
                            <h3 class="secondary-color">Establish certification targets, budgets, and timelines</h3>
                          </span>
                          <p class="light">Develop a certification readiness plan that fits your business and aligns with other inflight efforts.</p>
                        </div>
                        <div class="tile-area implementation-and-support col-lg-4 col-md-4 col-sm-4 col-xs-8">
                          <span class="tile-title">
                            <h3 class="secondary-color">Get going with implementation and tracking support</h3>
                          </span>
                          <p class="light">Management, design, and implementation support to accomplish certification readiness targets and get the most cybersecurity value for your organization.</p>
                        </div>
                      </div>
                    </div>
                    <div class="page-section shadow-top-inset ncg-cybersecurity">
                      <div class="background-left">
                        <div class="gradient-overlay right-to-left blue shadow-top-inset">
                          <div class="container">
                            <div class="text-wrapper float-right col-lg-6 col-md-7 col-sm-7 col-xs-8">
                              <h2 class="white">NCG has a proven track record implementing Cybersecurity Programs and demonstrating compliance</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="shadow-top">
                        <div class="container sub-info">
                          <div class="col-lg-6 col-md-6 col-cm-6 col-xs-6 sub-text">
                            <p class="large light">NCG’s Team is comprised of Cybersecurity Program experts with over 14 years helping organizations improve their cyber posture and demonstrate compliance.</p>
                          </div>
                          <div class="col-lg-6 col-md-6 col-cm-6 col-xs-6 sub-text float-right">
                            <p>Our Team has extensive experience with DoD contracting, other Federal cyber programs, and system integration across industries. Core competencies include:</p>
                            <ul>
                              <li><p>HIPAA Requirements</p></li>
                              <li><p>NIST Guidelines</p></li>
                              <li class="bullet-wrap"><p class="bullet-wrap">Manufacturing Control &amp; SCADA Systems</p></li>
                              <li><p>FISMA Compliance</p></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-section new-dod-process shadow-bottom-inset">
                      <div class="background-right shadow-top-inset">
                        <div class="col-lg-6 col-md-6 col-cm-6 col-xs-6 text-box shadow-top-inset">
                          <div class="text-wrapper">
                            <h2 class="secondary-color">Cybersecurity Maturity Model Certification (CMMC)</h2>
                            <p>CMMC is a new DoD process to measure supplier institutionalization of cybersecurity capabilities</p>
                            <p class="secondary-color heavy large">Certification will be required for all new DoD contracts starting in 2020</p>
                            <p>CMMC—is an effort by the Department of Defense to enhance the protection of information in the Defense Industrial Base.
                              <br />
                              <br />
                              Maturity Levels measure the level of capability and institutionalization of cybersecurity in an organization.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-section certification-needs shadow-top">
                      <div class="video cmmc-getting-started container">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-container">
                          <h3 class="secondary-color">Getting Ready for Certification</h3>
                          <p class="light">For some companies the CMMC process will be straightforward and similar to other maturity model frameworks and audits they currently support. For those with robust cybersecurity practices, but have not been through similar reviews, the effort may focus on documentation and being able to represent their current state compared to CMMC model. For other organizations, getting ready for certification could entail implementation and documentation of new practices and processes.</p>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 video-wrapper">
                          <iframe title="gettingStartCMMC" width="560" height="315" src="https://www.youtube.com/embed/Y_5UmLkrUd4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                      <div class="container">
                        <h3 class="secondary-color">What Your Organization will need for CMMC Certification</h3>
                        <p class="light">Companies need to demonstrate cybersecurity  practice and process performance, along with supporting documentation, to become certified.</p>
                        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12 graphic-aid">
                          <img src={orgNeed} class="img-responsive" alt="Organization need" />
                        </div>
                        <div class="reference col-lg-12 col-xs-8">
                          <p class="heavy">Reference</p>
                          <p>Office of the Under Secretary of Defense for Acquisition &amp; Sustainment Cybersecurity Maturity Model Certification (CMMC)</p>
                          <a class="secondary-color" href="https://www.acq.osd.mil/cmmc/draft.html">https://www.acq.osd.mil/cmmc/draft.html</a>
                        </div>
                      </div>
                    </div>
                    <div class="page-section shadow-top-inset ncg-arms-around">
                      <div class="background-right">
                        <div class="gradient-overlay left-to-right blue shadow-top-inset">
                          <div class="container">
                            <div class="text-wrapper col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <h2 class="white">NCG helps you wrap your arms around everything that needs to be accomplished and get it done.</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="shadow-top">
                        <div class="container tile-section">
                          <div class="tile-area starting col-lg-4 col-md-4 col-sm-4 col-xs-7">
                            <h3 class="primary-color">Know Where You are Starting</h3>
                            <div class="graphic col-lg-8 col-md-8 col-sm-12 col-xs-12">
                              <img src={knowStart} alt="Know Where You are Starting" class="img-responsive" />
                            </div>
                            <ul>
                              <li>Leverage what is in place</li>
                              <li>Understand what is needed</li>
                              <li>Understand how the model is organized and what the model is looking for</li>
                            </ul>
                          </div>
                          <div class="tile-area plan-the-work col-lg-4 col-md-4 col-sm-4 col-xs-7">
                            <h3 class="primary-color">Plan the Work</h3>
                            <div class="graphic col-lg-8 col-md-8 col-sm-12 col-xs-12">
                              <img src={plan} alt="Plan the Work" class="img-responsive" />
                            </div>
                            <ul>
                              <li>What certification level do you need</li>
                              <li>How can you leverage complimentary or inflight efforts</li>
                              <li>What prioritization makes the best business and cybersecurity value</li>
                            </ul>
                          </div>
                          <div class="tile-area work-the-plan col-lg-4 col-md-4 col-sm-4 col-xs-7">
                            <h3 class="primary-color">Work the Plan</h3>
                            <div class="graphic col-lg-11 col-md-12 col-sm-12 col-xs-12">
                              <img src={workThePlan} alt="Work the Plan" class="img-responsive" />
                            </div>
                            <ul>
                              <li>Prioritize efforts</li>
                              <li>Track progress and measure results</li>
                              <li>Make adjustments as needed</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="cmmc-services" class="page-section implement-doc-support anchor">
                      <div class="header-banner">
                        <div class="container">
                          <h2 class="white">NCG CMMC Services</h2>
                        </div>
                      </div>
                      <div class="background-left shadow-top-inset">
                        <div class="gradient-overlay shadow-top-inset right-to-left white">
                          <div class="container">
                            <div class="text-wrapper float-right col-lg-6 col-md-6 col-sm-6 col-xs-8">
                              <h2 class="secondary-color">Implementation, Documentation, &amp; Prep Support</h2>
                              <p class="light large">NCG provides management, design, and integration services to help your organization implement and be ready for certification</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid shadow-top">
                        <div class="video ncg-cmmc-services container">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-container">
                            <h3 class="secondary-color">Services for Certification Readiness</h3>
                            <p class="light">NCG helps organizations accomplish certification objectives in a managed and targeted manner that makes the most business sense for your company’s unique circumstances. NCG’s CMMC Orchestration services follow NCG’s proven delivery model that integrates people, data, technology, and processes—focused on delivery of business objectives.</p>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 video-wrapper">
                            <iframe title="cmmcServices" width="560" height="315" src="https://www.youtube.com/embed/O-wUwc7YSpU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 shadow-top-inset">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 border-cell">
                          <div class="col-lg-12 col-md-11 col-sm-8 col-xs-11 process-design-implement cell-wrapper">
                            <span class="cell-title">
                              <h3 class="primary-color">Process Design &amp; Implementation</h3>
                            </span>
                            <p class="light">NCG works with your team to craft processes that meet CMMC specifications and provide value for your organization. Our automated tools streamline documentation creation and provide real-time tracking of progress.
                              <br />
                              <br />
                              These same tools provide a fast and easy way to figure out where changes are needed and maintain currency to keep up with internal and external changes.
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 border-cell">
                          <div class="col-lg-12 col-md-11 col-sm-8 col-xs-11 team-audit-preparation cell-wrapper">
                            <span class="cell-title">
                              <h3 class="primary-color">Team Audit Preparation</h3>
                            </span>
                            <p class="light">Having your team ready and knowing what to expect from a certification examination will help things go smoothly. NCG provides team preparation and training that includes mock exercises and information organization. We help make sure that people across your organization are ready for the certification process.</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 border-cell force">
                          <div class="col-lg-12 col-md-11 col-sm-8 col-xs-11 practice-implement-perform cell-wrapper">
                            <span class="cell-title">
                              <h3 class="primary-color">Practice Implementation &amp; Performance</h3>
                            </span>
                            <p class="light">NCG provides planning, technical and project management of practice implementation efforts. We help you work with third-party vendors and ensure a good fit for the organization and its operations.
                              <br />
                              <br />
                              We validate and verify both capability design and performance for your operating environment. We provide tools to streamline documentation development and to maintain currency over time.
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 border-cell">
                          <div class="col-lg-12 col-md-11 col-sm-8 col-xs-11 agile-program-approach cell-wrapper">
                            <span class="cell-title">
                              <h3 class="primary-color">Sustainable &amp; Agile Programmatic Approach</h3>
                            </span>
                            <p class="light">Beyond initial certification, efforts taken should become a sustainable program that provides value for the organization. NCG designs and implements frameworks to keep things going, maintain currency, and the agility to evolve with the organization as it grows.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-section shadow-top-inset ncg-training">
                      <div class="background-right">
                        <div class="gradient-overlay left-to-right blue shadow-top-inset">
                          <div class="container">
                            <div class="text-wrapper col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <h2 class="white">NCG Information &amp; Training Sessions</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="shadow-top">
                        <div class="container intro">
                          <h3 class="primary-color">General CMMC Information Series</h3>
                          <p class="light">
                            The DoD Cybersecurity Maturity Model Certification (CMMC) is a new maturity model to measure cybersecurity capabilities across all DoD suppliers. DoD is targeting Certification Level requirements to start appearing in RFPs in late 2020. Suppliers without the required Certification Levels will not be able to win new contracts individually or as a part of a team.
                          </p>
                          <div class="button-container">
                            <Link class="btn btn-primary btn-lg gradient download" href="#" itemprop="relatedLink" onClick={() => openForm()}>
                              <p>Course Info & Pricing</p>
                            </Link>
                            <ContactForm open={modal} closeForm={closeForm} />
                          </div>
                        </div>
                        <div class="container sessions">
                          <div class="training-session-card cmmc-100">
                            <div class="card-title-container">
                              <h4 class="primary-color heavy">CMMC-100 Introduction to the DOD Cybersecurity Maturity Model Certification (CMMC)</h4>
                            </div>

                            <div class="card-wrapper">
                              <div class="row">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  synopsis:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  An overview of the CMMC model and its foundations in FAR/DFAR requirements and NIST 800-171 specifications. The session will explore what organizations will need to have in place to achieve various levels of certification, along with best practices for getting started.
                                </p>
                              </div>
                              <div class="row audience">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  audience:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  all DoD suppliers, all learning tracks
                                </p>
                              </div>
                              <div class="row duration">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  duration:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  30 minutes
                                </p>
                              </div>
                              <div class="row format">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  format:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  online, scheduled
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="training-session-card cmmc-200">
                            <div class="card-title-container">
                              <h4 class="primary-color heavy">CMMC-200 Developing a CMMC Plan &amp; Strategy for Your Organization</h4>
                            </div>

                            <div class="card-wrapper">
                              <div class="row">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  synopsis:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  An interactive session to help organizations develop an approach and working level plan to get certified under CMMC. The session will focus on ways to prioritize and organize efforts tailored to your specific organization. Course materials will include planning templates to help organizations understand their specific scope of efforts and how balance costs, timelines, and levels of effort.
                                </p>
                              </div>
                              <div class="row audience">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  audience:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  all DoD suppliers, all learning tracks
                                </p>
                              </div>
                              <div class="row duration">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  duration:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  30 minutes
                                </p>
                              </div>
                              <div class="row format">
                                <p class="card-info-title heavy col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                  format:
                                </p>
                                <p class="card-info-text col-lg-10 col-md-10 col-sm-10 col-xs-8">
                                  online, scheduled
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="header-banner">
                          <div class="container">
                            <h3 class="white">CMMC: Cybersecurity Practice &amp; Process Implementation and Certification Readiness Series</h3>
                          </div>
                        </div>
                        <div class="container learning-tracks">
                          <h3 class="primary-color">Series Learning Tracks</h3>
                          <div class="col-lg-4 col-md-6 col-sm-7 col-xs-6 learning-track-tile executive-management">
                            <h4 class="white heavy">Executive &amp; Management</h4>
                            <p class="light">
                              Material designed for organizational leaders and senior management focused on understanding scope, approach options, levels of effort, and potential pitfalls.
                            </p>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-7 col-xs-6 learning-track-tile cybersecurity-it">
                            <h4 class="white heavy">Cybersecurity &amp; IT</h4>
                            <p class="light">
                              Material that gets into the implementation and operational components of technologies, tools, and operations for cybersecurity practices and processes.
                            </p>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-7 col-xs-6 learning-track-tile compliance-risk">
                            <h4 class="white heavy">Compliance &amp; Risk</h4>
                            <p class="light">
                              Material focused on initial and ongoing compliance demonstrations, along with a programmatic approach for oversight and governance across the organization.
                            </p>
                          </div>
                        </div>
                        <div class="container series-curriculum">
                          <h3 class="primary-color">Series Curriculum</h3>
                          <div class="curriculum-wrapper col-lg-9">
                            <h4 class="secondary-color heavy">301 - Understanding CMMC Maturity Levels</h4>
                            <p class="light">
                              Exploration of CMMC Maturity Level regulatory and specification basis and cybersecurity objectives and how these will impact how an organization operates on range of levels.
                            </p>
                            <div class="row">
                              <p class="heavy duration">duration:</p>
                              <p class="light info">30 Minutes</p>
                            </div>
                            <div class="row">
                              <p class="heavy applicable-tracks">applicable tracks:</p>
                              <p class="light info">Executive &amp; Management | Cybersecurity &amp; IT | Compliance &amp; Risk</p>
                            </div>
                          </div>
                          <div class="curriculum-wrapper col-lg-9">
                            <h4 class="secondary-color heavy">302 - CMMC Domains &amp; Capabilities</h4>
                            <p class="light">
                              Review of the Model’s Domain and Capability organization of cybersecurity Practices and applicability to Processes across Maturity Levels, and approaches to best plan efforts for a specific organization’s operations.
                            </p>
                            <div class="row">
                              <p class="heavy duration">duration:</p>
                              <p class="light info">30 Minutes</p>
                            </div>
                            <div class="row">
                              <p class="heavy applicable-tracks">applicable tracks:</p>
                              <p class="light info">Executive &amp; Management | Cybersecurity &amp; IT | Compliance &amp; Risk</p>
                            </div>
                          </div>
                          <div class="curriculum-wrapper col-lg-9">
                            <h4 class="secondary-color heavy">303 - CMMC Domains &amp; Capabilities</h4>
                            <p class="light">
                              Dive into the details of the different CMMC Practices and look at technical options, examples, and considerations for implementation and operations. Review model examples and reference details to better understand Model expectations.
                            </p>
                            <div class="row">
                              <p class="heavy duration">duration:</p>
                              <p class="light info">6 Hours</p>
                            </div>
                            <div class="row">
                              <p class="heavy applicable-tracks">applicable tracks:</p>
                              <p class="light info">Cybersecurity &amp; IT | Compliance &amp; Risk</p>
                            </div>
                          </div>
                          <div class="curriculum-wrapper col-lg-9">
                            <h4 class="secondary-color heavy">304 - CMMC Processes</h4>
                            <p class="light">
                              Exploration of the CMMC Process models and their applications across Domains and Maturity Levels. Gain an understanding of the CERT Resilience Management Model process improvement approach that is the basis for the CMMC Processes.
                            </p>
                            <div class="row">
                              <p class="heavy duration">duration:</p>
                              <p class="light info">90 minutes</p>
                            </div>
                            <div class="row">
                              <p class="heavy applicable-tracks">applicable tracks:</p>
                              <p class="light info">Cybersecurity &amp; IT | Compliance &amp; Risk</p>
                            </div>
                          </div>
                          <div class="curriculum-wrapper col-lg-9">
                            <h4 class="secondary-color heavy">305 - CMMC Certification Preparation</h4>
                            <p class="light">
                              Ensure your team is ready for a certification review with this walk through of preparation steps and activities that can help set your entire team up for success. Explore best practices around organization of materials and conducting exercises with key personnel, as well as common mistakes to look out for.
                            </p>
                            <div class="row">
                              <p class="heavy duration">duration:</p>
                              <p class="light info">6 Hours</p>
                            </div>
                            <div class="row">
                              <p class="heavy applicable-tracks">applicable tracks:</p>
                              <p class="light info">Executive &amp; Management | Cybersecurity &amp; IT | Compliance &amp; Risk</p>
                            </div>
                          </div>
                        </div>
                        <div class="curriculum-table container">
                          <div class="button-container">
                            <Link class="btn btn-primary btn-lg gradient download" href="#" itemprop="relatedLink" onClick={() => openForm()}>
                              <p>Course Info & Pricing</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container-fluid shadow-top page-section cmmc-programmatic-approach">
                      <div class="container">
                        <h2 class="light">Programmatic Approach for Sustainability &amp; Agility</h2>
                        <p class="large light">Framework to keep things going, maintain currency, and evolve with the organization</p>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-10 col-xs-12 image-container">
                        <img src={programmatic} alt="Programmatic Approach for Sustainability & Agility" class="img-responsive" />
                      </div>
                      <p class="light">NCG uses a programmatic approach to institutionalize the cybersecurity practices called for in the CMMC model across your organization. We help find the best ways to integrate cybersecurity efforts in the short and long run, leverage other efforts for economies of scale, and empower people for success. A programmatic approach helps build cybersecurity into the corporate culture—part of how things are done on a day-to-day basis.</p>
                    </div>
                    <div id="cmmc-orchestration-portal" class="container-fluid shadow-top page-section cmmc-orchestration-portal anchor">
                      <div class="container">
                        <div class="col-lg-9 col-md-9 col-sm-10 col-xs-10 orchestration-logo">
                          <img src={orchestraionLogo} class="img-responsive" alt="Orchestraion Logo" />
                        </div>
                        <div class="clearfix">
                          <div class="col-lg-7 col-md-7 col-sm-8 col-xs-8 clearfix">
                            <p class="large light">Secure Cloud-based Tracking and Management of Certification Preparation and Ongoing Program Maintenance</p>
                          </div>
                        </div>
                        <div class="portal-demo">
                          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-7 demo-tile progress-oversight">
                            <h3 class="primary-color">Progress Oversight</h3>
                            <span class="col-lg-10 col-md-10 col-sm-10 col-xs-10 graphic">
                              <img src={progress} alt="Progress Oversight" class="img-responsive" />
                            </span>
                            <p class="light">Track progress and measure accomplishments through Maturity Levels with real-time status and report updates</p>
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-7 demo-tile automation">
                            <h3 class="primary-color">Automation to Support Updates</h3>
                            <span class="col-lg-10 col-md-10 col-sm-10 col-xs-10 graphic">
                              <img src={automation} alt="Automation to Support Updates" class="img-responsive" />
                            </span>
                            <p class="light">Tools to identify where changes may be needed based on model and other changes to quickly and easily maintain currency</p>
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-7 demo-tile documentation-management">
                            <h3 class="primary-color">Documentation Management</h3>
                            <span class="col-lg-10 col-md-10 col-sm-10 col-xs-10 graphic">
                              <img src={documentation} alt="Documentation Management" class="img-responsive" />
                            </span>
                            <p class="light">Guided documentation creation and updates required under the Model</p>
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-7 demo-tile workflow-facilitation">
                            <h3 class="primary-color">Workflow Facilitation</h3>
                            <span class="col-lg-10 col-md-10 col-sm-10 col-xs-10 graphic">
                              <img src={workflow} alt="Workflow Facilitation" class="img-responsive" />
                            </span>
                            <p class="light">Process management of reviews and approvals across the organization</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="cmmc-questionnaire" class="page-section shadow-top-inset container-fluid baseline-questionnaire anchor">
                      <div class="container">
                        <h2 class="secondary-color capitalize">where do you currently stand?</h2>
                        <div class="content-container">
                          <p class="large light">
                            Take NCG’s free baseline questionnaire to see where your current Maturity Level may be and what it may take to get to your target.
                            <br />
                            <br />
                            Create an account and take as much time as you need to complete it. You can even create accounts for your team to answer questions.
                          </p>
                          <div class="button-container">
                            <a class="btn btn-primary btn-lg gradient" target="_blank" rel="noreferrer" href="https://cmmcquestionnaire.com/">CMMC questionnaire</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </body>
              </html>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CMMCMicrosite;