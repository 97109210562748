import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Image from "components/layout/image.component";
import Video from "components/layout/video.component";
import ContentContainer from "components/layout/contentContainer.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  pageSectionContentLeft: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "fit-content",
    width: "100%",
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 60,
    },
  },
  contentWrapperAbsolute: {
    position: "absolute",
    left: 0,
    width: "auto",
    zIndex: 0,
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 60,
    '& .graphic--mobile': {
      [theme.breakpoints.down('xs')]: {
        display: "block",
      },
    },
    '& .graphic--desktop': {
      [theme.breakpoints.up('sm')]: {
        display: "block",
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  sectionWrapperAbsolute: {
    justifyContent: "flex-end",
    zIndex: 100,
    position: "relative",
  },
  containerAbsolute: {
    position: "absolute",
    display: "flex",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  label: {
    display: "block",
    color: "white",
  },
  textContainer: {
    paddingLeft: 40,
  },
}));


const PageSectionContentLeft = (props) => {
  const classes = useStyles(props);

  if (props.variant === "absolute") {
    return (
      <div id={props.id} className={classes.pageSectionContentLeft} >
        <div className={classNames(classes.contentWrapperAbsolute, classes.contentWrapper)}>
          <ContentContainer width={props.width} variant="customWidth">
            <Image url={props.image} variant="customWidth"/>
          </ContentContainer>
        </div>

        <Container maxWidth="lg">
          <Grid md={12} lg={12} className={classNames(classes.sectionWrapperAbsolute, classes.sectionWrapper)}>
            {props.children}
          </Grid>
        </Container>
      </div>
    );
  }
  if (props.variant === "video") {
    return (
      <div id={props.id} className={classes.pageSectionContentLeft}>
        <Container maxWidth="lg">
          <Grid md={12} lg={12} className={classes.sectionWrapper}>
            {props.children}
            <Grid item lg={3} className={classes.contentWrapper}>
              <ContentContainer width={props.width} variant="customWidth">
                <Video url={props.video} variant="customWidth"/>
              </ContentContainer>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
  else {
    return (
      <div id={props.id} className={classes.pageSectionContentLeft}>
        <Container maxWidth="lg">
          <Grid md={12} lg={12} className={classes.sectionWrapper}>
            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.contentWrapper}>
              <Image className={"graphic--desktop"} url={props.image} />
              <Image className={"graphic--mobile"} url={props.mobileImage} />
            </Grid>
            {props.children}
          </Grid>
        </Container>
      </div>
    )
  }
}

export default PageSectionContentLeft;