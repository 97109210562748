import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",

    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
  // LIST
  unorderedList: {
    paddingLeft: 20,
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 0,
  },
  listItem: {
    fontSize: variables.fontBody,
    letterSpacing: ".08em",
    paddingTop: 10,
    color: variables.textPrimary,
  },
}));

const PPBusinessContinuityPlanning = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner variant="small" backgroundImage={performanceBanner} className={classes.smallBanner}>
        Business Continuity Planning - Business Consulting Firms</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/business-continuity" variant="active">Business Continuity Planning</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Business Continuity Planning
                </li>
                <li className={classes.sideBarListItem}>
                  Financial Security Risk Assessment
                </li>
                <li className={classes.sideBarListItem}>
                  Disaster Recovery Planning
                </li>
                <li className={classes.sideBarListItem}>
                  Contingency of Operations Planning
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2">
                Volk Packaging
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                Volk Packaging sought to ensure their ability to service customers after a catastrophic event. Beyond the recovery of computer systems and access to data, Volk wanted a detailed plan that provided for the complete resumption of critical business processes across the organization.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG worked with Volk to map out all aspects of their critical business processes and infrastructure. A Business Continuity Plan that accounted for resources needed to restore critical functions, the duration and order of restoration, specific action by specific parties, and where these actions would be executed was developed.
              </Typography>
              <Typography variant="body1">
                Communication, collaboration, and coordination infrastructures were designed to assign responsibilities to key people. A process for decision making as well as command and control were also developed to account for unforeseen variables. These infrastructures promoted flexibility and a way systematically manage events.
              </Typography>
              <Typography variant="body1">
                Coordination and agreements were facilitated with friendly competitors to provide contingency options and infrastructure proactively.
              </Typography>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                Volk Packaging has a detailed plan in place to recover quickly from catastrophic events. Processes and procedures are in place to guide activities and communication both internally and externally. Steps are in place to ensure the plan is kept current and relevant—and always ready to be implemented if needed.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default PPBusinessContinuityPlanning;