import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../../style/variables.module.scss";
import BackToTopCustom from "components/layout/BackToTopCustom";

// IMAGES
import performanceBanner from "../../../../images/images/banners/perform-subpage.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingLeft: 25,
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  // PAGE SECTIONS
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  subSection: {
    paddingTop: 30,
  },
}));

const PPGrowingOperations = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={performanceBanner}>
        Growing Operations &amp; Markets</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/past-performance" variant="link">Past Performance</BreadcrumbItem>
          <BreadcrumbItem href="/performance/growing-operations" variant="active">Growing Operations &amp; Markets</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>NCG CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItem}>
                  Integrating Technology and the Business
                </li>
                <li className={classes.sideBarListItem}>
                  Business System Analysis
                </li>
                <li className={classes.sideBarListItem}>
                  Data System Conversion
                </li>
                <li className={classes.sideBarListItem}>
                  Project Management
                </li>
                <li className={classes.sideBarListItem}>
                  System Platform Migration
                </li>
                <li className={classes.sideBarListItem}>
                  Web Services
                </li>
                <li className={classes.sideBarListItem}>
                  WebMethods Service Oriented Architecture (SOA) Governance and Service Management
                </li>
                <li className={classes.sideBarListItem}>
                  Fidelity Deposit &amp; Loan Systems / Fiserv ITI Premier
                </li>
                <li className={classes.sideBarListItem}>
                  BM Content Manager / CMOD
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={8} className={classNames(classes.pageSection)}>
            <Grid item lg={12} className={classes.titleWrapper}>
              <Typography variant="h2">
                TD Banknorth Acquisition Support
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={12} className={classes.subSection}>
              <Typography variant="h3">
                Challenge
              </Typography>
              <Typography variant="body1">
                TD Banknorth needed control and management support for the technical components of an acquisition. They needed technical leadership to help bridge technology related activities with their various business lines.
              </Typography>
              <Typography variant="body1">
                TD Banknorth used an aggressive acquisition strategy to fuel its growth. Making these acquisitions a smooth process for bank customers, internal operations, and from a profitability perspective requires the synchronization of many technical and business process components.
              </Typography>
              <Typography variant="body1">
                TD Banknorth did not have a repeatable process for acquisitions. Technology efforts were handled on an ad hoc basis—often leaving projects and tasks outstanding years after the legal completion of an acquisition causing significant budget and operational challenges.
              </Typography>
            </Grid>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Value Delivered
              </Typography>
              <Typography variant="body1">
                NCG came in to manage the technology components of TD Banknorth’s acquisition of Interchange Bank in New Jersey. This support included coordination with the Bank’s different business lines to ensure a smooth process—executed effectively and efficiently.
              </Typography>
              <Typography variant="body1">
                NCG helped design, plan, and execute multiple system conversions and platform migrations ranging from Internet Banking, middleware/SOA infrastructure, core banking systems, and optical systems.
              </Typography>
            </div>
            <div className={classes.subSection}>
              <Typography variant="h3">
                Results
              </Typography>
              <Typography variant="body1">
                NCG helped TD Banknorth accomplish its acquisition of Interchange bank on time and under budget. NCG helped ensure that all technology efforts were completed and eliminated the long list of outstanding items that had encumbered the Bank in previous acquisitions.
              </Typography>
              <Typography variant="body1">
                NCG helped develop a number of model conversion and migration processes for the Bank. NCG used these model processes as the basis for an Acquisition Template. This web-based template provides the Bank with a repeatable and scalable acquisition process from a technology perspective.
              </Typography>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default PPGrowingOperations;