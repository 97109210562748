import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
// import { useMediaQuery } from "@material-ui/core";
import { Grid, Button, Input, InputLabel } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import ContactService from "../../../services/contact.service";
import CMMCModal from "../../utils/cmmc-modal.component";
import CMMCSplash from "../../../images/images/microsite/cmmc/registration-modal.jpg";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({ 
  root: {
    "& p": {
      padding: 0,
    },
  },
  modal: {
    display: "block !important",
    overflowX: "hidden",
    overflowY: "auto",
    position: "fixed !important",
  },
  formWrapper: {
    width: "fit-content",
    margin: "0px auto",
  },
  formInput: {
    boxShadow: "0 1px 19px 3px rgb(0 0 0 / 5%)",
    borderRadius: 3,
    height: 20,
    float: "none",
    width: 220,
    padding: 5,
    marginLeft: "10 !important",
    backgroundColor: "transparent",
    [theme.breakpoints.down(568)]:{
      width: 200,
    },
  },
  formLabel: {
    paddingRight: 5,
    fontSize: 14,
    position: "relative",
    color: "#4091CE",
    display: "inline-block",
    maxWidth: "100%",
    marginBottom: 5,
    fontWeight: 700,
    [theme.breakpoints.down(992)]:{
      fontSize: 12,
    },
    [theme.breakpoints.down(568)]:{
      paddingRight: 2,
      display: "block",
    },
  },
  header: {
    backgroundColor: "#0b3e6f",
    color: "#fff",
    border: "1px solid #0b3e6f",
    textAlign: "center",
    padding: 20,
  },
  imageTextWrapper: {
    backgroundColor: "rgba(64,145,206,0.55)",
    padding: "50px 30px",
    [theme.breakpoints.down(768)]:{
      padding: "40px 70px",
    },
    [theme.breakpoints.down(568)]:{
      padding: "40px 30px",
    },
  },
  imageText: {
    textAlign: "left",
    paddingTop: 0,
    fontSize: "1.05em",
    letterSpacing: "0.08em",
    lineHeight: "1.875em",
    fontWeight: 600,
    maxWidth: 700,
    position: "relative",
    margin: 0,
    zIndex: 5,
    color: "#fff",
    [theme.breakpoints.down(1200)]:{
      fontSize: "0.9em"
    },
    [theme.breakpoints.down(768)]:{
      lineHeight: "1.8em",
    },
    [theme.breakpoints.down(568)]:{
      margin: "0px auto",
      maxWidth: 400,
      textAlign: "left",
      fontSize: "0.8em",
    },
  },
  intro: {
    padding: "40px 40px 25px 40px",
    textAlign: "left",
    display: "block",
    margin: "0px auto",
    maxWidth: 510,
    [theme.breakpoints.down(768)]:{
      padding: "30px 20px",
    },
    [theme.breakpoints.down(568)]:{
      padding: 20,
      maxWidth: 400,
    },
    [theme.breakpoints.down(480)]:{
      maxWidth: 300,
    },
  },
  introText: {
    fontSize: 20,
    marginTop: 10,
    marginBottom: 10,
    color: "#164C93",
    lineHeight: "1.6em",
    letterSpacing: "0.08em",
    [theme.breakpoints.down(992)]:{
      fontSize: 18,
    },
    [theme.breakpoints.down(568)]:{
      fontSize: 14,
    },
  },
  learningTrackWrapper: {
    width: "fit-content",
    fontSize: 18,
    textAlign: "center",
    maxWidth: 700,
    display: "block",
    margin: "0px auto",
    position: "relative",
  },
  leftColumn: {
    backgroundImage: 'url(' + CMMCSplash + ')',
    backgroundPosition: "left center",
    position: "relative",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    padding: 0,
    [theme.breakpoints.down(1200)]:{
      backgroundPosition: "center center",
    },
    [theme.breakpoints.up(992)]:{
      width: '50%',
      float: 'left',
    },
    [theme.breakpoints.down(992)]:{
      width: '100%',
    },
    [theme.breakpoints.down(768)]:{
      height: 'fit-content',
      padding: 0,
      backgroundSize: '100% auto',
      display: "block",
      float: "none",
      flex: "unset",
    },
    [theme.breakpoints.down(568)]:{
      backgroundSize: "cover",
    },
  },
  rightColumn: {
    padding: 0,
    float: "right",    
  },
  listItem: {
    fontSize: 14.7,
    fontWeight: "normal",
    padding: "10px 0 0 0 0 !important",
    paddingTop: 5,
    color: "#164C93 !important",
    position: "relative",
    letterSpacing: "0.08em",
    textAlign: "left",
  },
  lowerTextPadding: {
    paddingBottom: "1.5em !important",
  },
  modalContent: {
    position: "relative",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    borderRadius: 6,
    border: "none",
    fontFamily: "Helvetica, sans-serif",
  },
  requiredField: {
    display: "block",
    textAlign: "right",
  },
  requiredFieldText: {
    display: "block",
    fontSize: 8,
    fontStyle: "italic",
    textAlign: "right",
    color: "#bd2e37",
    paddingRight: 5,
    [theme.breakpoints.down(568)]:{
      textAlign: "center",
      paddingTop: 5,
    },
  },
  unorderedList: {
    display: "block",
    paddingLeft: 20,
    listStyleType: "disc",
    position: "relative",
    color: "#164C93 !important",
  },
  learningTrack: {
    width: "fit-content",
    fontSize: 18,
    color: "#164C93",
    paddingTop: 0,
    [theme.breakpoints.down(992)]:{
      fontSize: 14,
    },
    [theme.breakpoints.down(568)]:{
      fontSize: 12,
    },
  },
  viewTerms: {
    paddingRight: 25,
    fontSize: 10,
    textDecoration: "underline",
    fontWeight: "bold",
    paddingTop: 5,
    color: "#4091CE",
    letterSpacing: "0.08em",
    cursor: "pointer",
    [theme.breakpoints.down(568)]:{
      paddingRight: 0,
    },
  },
  agreeLabel: {
    marginBottom: 0,
  },
  agreeCheckbox: {
    display: "inline-block",
    width: 15,
    marginTop: 0,
    height: 15,
    marginBottom: 3,
  },
  agreeToTerms: {
    paddingRight: 5,
    marginTop: 15,
    marginBottom: 15,
    marginRight: "auto",
    display: "block",
    textAlign: "right",
  },
  formElement: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: "auto",
    display: "block",
    textAlign: "right",
    [theme.breakpoints.down(568)]:{
      textAlign: "center",
    },
  },
  captchaContainer: {
    margin: "10 0 20 auto",
    paddingTop: 10,
    display: "inline-block",
  },
  buttonWrapper: {
    textAlign: "center",
    paddingBottom: 40,
    display: "block"
  },
  registerButton: {
    fontSize: 12,
    color: "#fff",
    padding: "1rem 3rem",
    height: "auto",
    width: "auto",
    boxShadow: "0 1px 19px 3px rgb(0 0 0 / 5%)",
    borderRadius: 3,
    float: "none",
    border: "0 none",
    fontWeight: 600,
    letterSpacing: 1,
    backgroundColor: "#164C93",
    fontFamily: "verdana",
    background: "linear-gradient(to bottom,  #164C93 50%, #11396f 50%)",
  },
  headerText: {
    fontSize: 24,
    color: "#fff",
    fontWeight: "bold",
    margin: "0px 0 0 0",
    display: "inline-block",
    position: "relative",
    lineHeight: "1.6em",
  },
  modalBody: {
    padding: 0,
    width: '100%',
    display: 'flex',
    margin: '0px auto',
    position: 'relative',
    textAlign: 'right',
    [theme.breakpoints.down(768)]:{
      flexDirection: "column",
    },
  },

}));

const CMMCContactForm = (props) => {
  //styles
  const classes = useStyles();

  //form data
  let formData;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    if (firstName && lastName && email && validReCaptcha) {
      const link = document.createElement("a");
      link.download = `NCG_CMMC_Training_August_2020.pdf`;
      link.href = "/content/docs/cmmc/NCG_CMMC_Training_August_2020.pdf"
      link.click();

      formData = {
        ...formData,
        firstname: firstName,
        lastname: lastName,
        company: company,
        email: email,
        title: title,
        phonenumber: phone,
        reCaptchaResponse: validReCaptcha
      }
      ContactService.submitContactInfo(formData)
      props.closeForm()
    }
  }

  //recaptcha stuff
  const [validReCaptcha, setValidReCaptcha] = useState(false);
  const onReCaptchaChange = (value) => {
    setValidReCaptcha(value);
  }
  const onReCaptchaExpire = (value) => {
    setValidReCaptcha(null);
  }

  return (
    <CMMCModal
      className={classes.modal}
      open={props.open}
      onClose={() => props.closeForm()}
    >
      <div className={classNames(classes.modalContent, classes.root)}>
        <form className={classes.modalContent}>
          <div className={classes.header}>
            <h2 className={classes.headerText}>Download NCG’s CMMC Course Information and Pricing</h2>
          </div>
          <Grid className={classes.modalBody}>
            <Grid item xs={12} sm={12} md={6} lg={6} 
              className={classes.leftColumn}
            >
              <div className={classes.imageTextWrapper}>
                <p className={classNames(classes.imageText, classes.lowerTextPadding)}>
                  The DoD Cybersecurity Maturity Model Certification (CMMC) is a new maturity model to measure cybersecurity capabilities across all DoD suppliers.
              </p>
                {/* <br/> */}
                <p className={classes.imageText}>
                  NCG offers information and training sessions to help current and prospective DoD suppliers understand the model and develop a best fit approach to certification.
              </p>
              </div>
            </Grid>
            <Grid className={classes.rightColumn} item xs={12} sm={12} md={6} lg={6}>
              <div container className={classes.intro}>
                <div className={classes.introText}>
                  NCG offers general CMMC information session along with more detailed courses with separate learning tracks for:
                </div>
                  <ul className={classNames(classes.unorderedList, classes.learningTrackWrapper)}>
                    <li className={classes.listItem}>
                      <p className={classes.learningTrack}>Executives &amp; Managers</p>
                    </li >
                    <li className={classes.listItem}>
                      <p className={classes.learningTrack}>Cybersecurity and IT Professionals</p>
                    </li>
                    <li className={classes.listItem}>
                      <p className={classes.learningTrack}>Compliance and Risk Professionals</p>
                    </li>
                  </ul>
              </div>
              <div className={classes.formWrapper}>
                <div className={classes.formElement}>
                  <InputLabel
                    lg={6}
                    className={classes.formLabel}
                    for="firstname"
                  >*First Name
                </InputLabel>
                  <Input
                    lg={6}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={classes.formInput}
                    type="text"
                    id="firstname"
                    required />
                </div>
                <div className={classes.formElement}>
                  <InputLabel
                    className={classes.formLabel}
                    for="lastname"
                  >*Last Name
                </InputLabel>
                  <Input
                    onChange={(e) => setLastName(e.target.value)}
                    className={classes.formInput}
                    type="text"
                    id="lastname"
                    required />
                </div>
                <div className={classes.formElement}>
                  <InputLabel
                    className={classes.formLabel}
                    for="company"
                  >Company
                </InputLabel>
                  <Input
                    onChange={(e) => setCompany(e.target.value)}
                    className={classes.formInput}
                    type="text"
                    id="company" />
                </div>
                <div className={classes.formElement}>
                  <InputLabel
                    className={classes.formLabel}
                    for="title"
                  >Title
                </InputLabel>
                  <Input
                    onChange={(e) => setTitle(e.target.value)}
                    className={classes.formInput}
                    type="text"
                    id="title" />
                </div>
                <div className={classes.formElement}>
                  <InputLabel
                    className={classes.formLabel}
                    for="phone"
                  >Phone
                </InputLabel>
                  <Input
                    onChange={(e) => setPhone(e.target.value)}
                    className={classes.formInput}
                    type="text"
                    id="phonenumber" />
                </div>
                <div className={classes.formElement}>
                  <InputLabel
                    className={classes.formLabel}
                    for="email"
                  >*Email
                </InputLabel>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.formInput}
                    type="text"
                    id="email"
                    required />
                </div>
                <div className={classes.requiredField}>
                  <p className={classes.requiredFieldText}>* Required Field</p>
                </div>
                <div className={classNames(classes.formElement, classes.agreeToTerms)}>
                  <InputLabel
                    className={classNames(classes.formLabel, classes.agreeLabel)}
                    for="terms">I agree to the NCG Terms of Use
                </InputLabel>
                  <Input
                    className={classes.agreeCheckbox}
                    disableUnderline={true}
                    type="checkbox"
                    required />
                  <br />
                  <a className={classes.viewTerms} target="_blank" rel="noreferrer" href={"/terms-of-use"}>View NCG’S Terms of Use</a>
                </div>
                <div className={classNames(classes.formElement)}>
                  <div className={classNames(classes.captchaContainer)}>
                    <ReCAPTCHA
                      sitekey="6LdRxoAcAAAAAHQOmPpABlFV4hrPtNXEa-X-W258"
                      onChange={onReCaptchaChange}
                      onExpired={onReCaptchaExpire} />
                  </div>
                </div>
                <div className={classNames(classes.buttonWrapper)}>
                  <Button
                    onClick={() => { handleSubmit() }}
                    name="register"
                    id="register"
                    type="submit"
                    className={classNames(classes.registerButton)} >
                    Register!
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </CMMCModal>
  );
}
export default CMMCContactForm;