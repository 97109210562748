import Star2NavBar from "components/microsites/stars2/stars2NavBar";
import s2Services from "images/microsite/stars2/S2_Services.gif";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const Star2Service = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="stars2">
        <div id="container" align="center">
          <Star2NavBar href="/stars2/services" />
          <div id="srv_leftbar">
            <h2>NCG STARS II Computer Systems Design Services - NAICS #541512:</h2>
            <p>- Computer  systems integration design consulting services </p>
            <p>- Computer  systems integration analysis and design services </p>
            <p>- Information  management computer systems integration design services </p>
            <p>- Computer  software consulting services or consultants </p>
            <p>- Computer  integrator services </p>
            <p>- Computer  network systems design services</p>
            <p>- CAD  (computer-aided design) systems integration design services </p>
            <p>- CAE  (computer-aided engineering) systems integration design </p>
            <p>- CAM  (computer-aided manufacturing) systems integration design </p>
            <p>- Computer  hardware consulting services or consultants </p>
            <p>- Local  Area Network (LAN) computer systems integration design services </p>
            <p>- Office  automation computer systems integration design services	  </p>
          </div>
          <div id="contentbanner"><img src={s2Services} width="680" height="200" alt="NCG STARS2 Services and Experience" /></div>
          <div id="contents">
            <h2>It&rsquo;s about having a plan and driving efforts to deliver the most value out of your technology investment.</h2>
            <p>Technology can help you do more with less—to be more  effective and efficient.  That goal is  achieved by finding the best fit your organization and what needs to be  accomplished.  To do this, you need to  understand what you really need in the short and long runs.  You need to articulate requirements for both  internal and external audiences.  The  right course to accomplish your objectives needs to be charted out, with the  means to create the successful designs.</p>
            <p>Northcross Group (NCG) provides the skills, expertise, and  commitment to see you through every step.   We bridge between your organization and the technology to ensure that  projects deliver on their promises.  We  never loose site of the integration point between you and the technology that  delivers the most value.</p>
            <p>From concept exploration to operational transition, our  consulting services will ensure communication and collaboration between  stakeholders.  We identify and work  through business process management changes and opportunities.  We capture, prepare for, and guide  integration into production environments and interfaces with other systems.</p>
            <p>NCG builds relationships with our customers and the other  companies we work with.  There is great  opportunity in what technology can do for an organization, and we work hard to  deliver those results.</p>
            <p><strong>NCG Technical Areas of Expertise</strong></p>
            <blockquote>
              <p><strong>Enterprise Level:</strong> J2EE, .NET, Services Oriented Architecture (SOA), N-Tier Data Architectures </p>
              <p><strong>Business Process Management</strong>: BizFlow</p>
              <p><strong>Analysis and Design:</strong> UML, IBM Rational Software/RUP, CA AllFusion, SAS</p>
              <p><strong>Development</strong>:  SQL, XML, XSL, ASP.NET, JSP, PHP, GIS, Flash/Flex</p>
              <p><strong>Database Management:</strong> MS SQL Server, Oracle, DB2, Data Warehousing design and programming <br />
              </p>
            </blockquote>
            <p>&nbsp;</p>
            <div class="footer">
              <p class="footer_style">&copy;2011 Northcross Group Corporation. All rights reserved. </p>
            </div>
          </div>
          <div id="rightbar">
            <h2>Performance Examples:</h2>
            <p><strong>DHS Regional Cyber Security Assessments<br />
            </strong>NCG performed risk assessments on critical infrastructure in the Boston region as part of a regional cyber security preparedness effort.</p>
            <p><strong>Electronic Health Records Meaningful Use Assessments</strong><br />
      NCG is working with hospitals across New England to conduct system assessments under the Health Information Technology for Economic and Clinical Health (HITECH) Meaningful Use of Electronic Records programs.</p>
            <p><strong>System Integration Supporting Mergers and Acquisitions</strong><br />
          NCG has worked with TD Bank through eight acquisitions and supporting integration efforts.
        </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Star2Service;