import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const AdobeFileLogo = (props) => {
  return (
    <FontAwesomeIcon icon={faFilePdf} color="red" size="lg" style={{ marginRight: "5px" }} />
  );
};


export default AdobeFileLogo;
