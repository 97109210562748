import Star2NavBar from "components/microsites/stars2/stars2NavBar";
import gsa from "images/microsite/stars2/ContractHolder_GSA_jpg.gif";
import s2Team from "images/microsite/stars2/S2_Team.jpg";
import getAdobe from "images/microsite/stars2/get_adobe_reader.gif";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const Star2Service = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="stars2">
        <div id="container" align="center">
          <Star2NavBar href="/stars2/ncg_team" />
          <div id="srv_leftbar">
            <h2>NCG  Contract # GS-06F-1153Z</h2>
            <h2>NCG  DUNS# 808835826</h2>
            <h2>CAGE Code #59CU6</h2>
            <p><a target="_blank" rel="noreferrer" href="https://www.sam.gov/SAMPortal/sam/helpPage/SAM_Reg_Status_Help_Page.html">NCG&rsquo;S Central Contractor Recrod (CCR) Record</a></p>
            <p><a target="_blank" rel="noreferrer" href="http://www.gsa.gov/8astars2">GSA STARS II Website</a></p>
            <p><a target="_blank" rel="noreferrer" href="http://www.gsa.gov/8astars2"><img src={gsa} alt="GSA Contract Holder Logo" width="100" height="40" border="0" /></a></p>
          </div>
          <div id="contentbanner"><img src={s2Team} width="680" height="200" alt="NCG STARS 2 Team" /></div>
          <div id="contents">
            <p>The NCG STARS II Management Team is comprised  of individuals with a wealth of Federal contract management experiences.  Our Team is dedicated to the delivery of the  highest level of service and value to Federal Clients under STARS II.</p>
            <p>
              <strong>Christopher Bender,  President<br />
          NCG STARS II Program Manager<br />
                <a href="mailto:cjbender@northcrossgroup.com">cjbender@northcrossgroup.com</a> </strong><br />
  Christopher Bender has over 20 years of Federal  contract experience and brings NCGs corporate commitment and focus on driving  success with STARS II.&nbsp; Having supported many federal clients in a variety  of System Design roles, Mr. Bender approaches technology from a practical  perspective—as a driver for progress and innovation. Mr. Bender looks forward to  continuing his efforts through STARS II to help federal agencies ensure  technology supports the people and processes vital to their success. </p>
            <p><strong>David Wininger, CTO<br />
          NCG STARS II Security &amp; Compliance Officer<br />
              <a href="mailto:dwininger@northcrossgroup.com">dwininger@northcrossgroup.com </a><br />
            </strong>
        David Wininger has 30 years of Federal contract experience supporting the U.S.  Intelligence community, the Department of Defense, and commercial clients,  including extensive federal contract management experience within a wide range  of agencies. Mr. Wininger has substantial experience administering Federal  Government contracts and is well-versed in the FAR and other key regulations  related to federal contracting.&nbsp; Mr. Wininger oversees our internal  Federal Contracts compliance and monitoring procedures, which ensures full  understanding and compliance with all the terms and conditions of STARS II.&nbsp; </p>
            <p><strong>Marc Ashley, Public Sector Vice President<br />
          NCG STARS II Marketing Lead</strong><br />
              <a href="mailto:mashley@northcrossgroup.com"><strong>mashley@northcrossgroup.com </strong></a><br />
          Marc Ashley brings over 20 years of business  development and marketing experience in the public sector. Recognized as a  skilled communicator Mr. Ashley assists federal agencies make informative  decisions and help them achieve their small business socioeconomic goals<br />
              <br />
              <strong>Brian Bakke, Business Development Manager<br />
  NCG STARS II Client Relationship Lead<br />
                <a href="mailto:bbakke@northcrossgroup.com">bbakke@northcrossgroup.com </a><br />
              </strong>
          Brian Bakke builds relationships with new and existing customers working  to best fit NCG capabilities with client needs.  Mr. Bakke focuses on developing a thorough  understanding of a client and ensuring a best working relationship throughout  an engagement life cycle.  </p>
            <p><strong>Vaida Lowell, Operations Manager<br />
          NCG Contracts Administration Lead<br />
              <a href="mailto:vlowell@northcrossgroup.com">vlowell@northcrossgroup.com</a> <br />
            </strong>
      Vaida Lowell manages all aspects of  contract administration inclusive of status reporting, quality assurance, and  invoicing.  Ms. Lowell is also responsible  for ensuring the proper mapping of NCG resources to efforts based on various skills  and experience mixes. </p>

            <div class="footer">
              <p class="footer_style">&copy;2011 Northcross Group Corporation. All rights reserved. </p>
            </div>
          </div>

          <div id="rightbar">
            <p> <a target="_blank" href="/content/docs/stars2/NCG_STARS2_Overview.pdf">NCG STARS II Flyer</a></p>
            <p><a href="http://www.adobe.com/products/acrobat/readstep2.html" target="_blank" rel="noreferrer"><img src={getAdobe} alt="Get Adobe Acrobat Reader" width="112" height="33" border="0" /></a></p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Star2Service;