import $ from 'jquery';
//Navbar
$(function () {
  var pathname= window.location.pathname;
  var topNavbar = $(".topNavbar");
  var smallNavbar = $("#smallNavbar");
  var aboutSideBar = $("#aboutSideBar");
  var teamInfo = $("#teamInfo");
  var introSection =$("#introSection")
  var $window = $(window);
  //====Active Navbar by path ====
  if(pathname!=="/"){
    var activeNav='';
    activeNav=pathname.replace('/', '.');
    var newActiveNav = activeNav.split('/', 1)[0];
    $(newActiveNav).addClass("active");
  }
  //====Active Navbar by path ====

  //====Active Microsite Navbar Menu ====
  $(function (){
    $(".micrositeNavLink").click(function () {
      $(".microNav").removeClass("active")
      $(this).parent().addClass("active")
    });
  });
  //====Active Microsite Navbar Menu ====
  function setSmallNavbar(){
    if ($(window).width() <= 767) {
      smallNavbar.css("display", "none");
    }else{
      smallNavbar.css("display", "flex");
    }
  }
  function init() {
    var initDistanceY = window.pageYOffset || document.documentElement.scrollTop,
      initShrinkOn = 190;
    if (initDistanceY > initShrinkOn) {
      if ($(window).width() <= 1279) {
        topNavbar.css("display", "none");
        setSmallNavbar();
        if (teamInfo) {
          teamInfo.css("top", "55px");
        }
        if(introSection){
          introSection.css("position", "absolute");
        }
        aboutSideBar.addClass("fixed");
      }
    } else {
      if ($(window).width() > 1279) {
        topNavbar.css("display", "flex");
        smallNavbar.css("display", "none");
        if (teamInfo) {
          teamInfo.css("top", "150px");
        }
      }
    }
    window.addEventListener('scroll', function (e) {
      var scrollDistanceY = window.pageYOffset || document.documentElement.scrollTop,
        scrollShrinkOn = 190;
      if (scrollDistanceY > scrollShrinkOn) {
          topNavbar.css("display", "none");
          setSmallNavbar();
          if (teamInfo) {
            teamInfo.css("top", "55px");
          }
          if(introSection){
            introSection.css("position", "absolute");
          }
          aboutSideBar.addClass("fixed");
      } else {
        if ($(window).width() > 1279) {
          topNavbar.css("display", "flex");
          smallNavbar.css("display", "none");
          if (teamInfo) {
            teamInfo.css("top", "150px");
          }
        }
      }
    });
  }
  window.onload = init;
  function resize() {
    function initResize() {
      var initResizeDistanceY = window.pageYOffset || document.documentElement.scrollTop,
        initResizeShrinkOn = 190;
        if (initResizeDistanceY > initResizeShrinkOn) {
          topNavbar.css("display", "none");
        } else {
          if ($(window).width() <= 1279) {
            topNavbar.css("display", "none");
            setSmallNavbar();
            if (teamInfo) {
              teamInfo.css("top", "55px");
            }
            if(introSection){
              introSection.css("position", "absolute");
            }
          } else {
            topNavbar.css("display", "flex");
            smallNavbar.css("display", "none");
            if (teamInfo) {
              teamInfo.css("top", "150px");
            }
          }
        }
      window.addEventListener('scroll', function (e) {
        var resizeScrollDistanceY = window.pageYOffset || document.documentElement.scrollTop,
          resizeScrollShrinkOn = 190;
        if (resizeScrollDistanceY > resizeScrollShrinkOn) {
          topNavbar.css("display", "none");
        } else {
          if ($(window).width() <= 1279) {
            topNavbar.css("display", "none");
            setSmallNavbar();
            if (teamInfo) {
              teamInfo.css("top", "55px");
            }
            if(introSection){
              introSection.css("position", "absolute");
            }
            aboutSideBar.addClass("fixed");

          } else {
            topNavbar.css("display", "flex");
            smallNavbar.css("display", "none");
            if (teamInfo) {
              teamInfo.css("top", "150px");
            }
          }
        }
      });
    }
    window.onresize = initResize;
    if ($(window).width() <= 1279) {
      topNavbar.css("display", "none");
    }
  }
  $window
          .resize(resize)
          .trigger('resize');
});
//Navbar

$(function (){
  var teamInfo = document.getElementById('teamInfo');
  var overlay = $(".slide-reveal-overlay");

  $('#trigger').click(function () {
    overlay.css("display", "block");
    $(teamInfo).addClass('open');
  });
  $(overlay).click(function () {
    overlay.css("display", "none");
    $(teamInfo).removeClass('open');
  });
});

