import { Container, Typography, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style/custom-css/slick-theme-custom.css";
import classNames from "classnames";
import variables from "../../style/variables.module.scss";
// IMAGES
import slide1Image from "../../images/images/index/slider/slide_one/one.jpg";
import slide2Image from "../../images/images/index/slider/slide_two/two.jpg";
import slide3Image from "../../images/images/index/slider/slide_three/three.jpg";
import slide4Image from "../../images/images/index/slider/slide_four/four.jpg";
import slide5Image from "../../images/images/index/slider/slide_five/five.jpg";
import slide6Image from "../../images/images/index/slider/slide_six/six.jpg";
import slide7Image from "../../images/images/index/slider/slide_seven/seven.jpg";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    textAlign: "center",
    color: "white",
  },
  slider: {
    height: 815,
    width: "100%",
  },
  slide: {
    height: 750,
    width: "100%",
    backgroundColor: variables.ncgBlue4,
    display: "block",
    position: "relative",
  },
  slideWhite: {
    backgroundColor: "white",
  },
  slideBackground: {
    height: 1050,
    width: "75%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    display: "block",
    position: "absolute",
    right: 0,
    top: 0,
  },
  slide1Background: {
    backgroundImage: "url(" + slide1Image + ")",
  },
  slide2Background: {
    backgroundImage: "url(" + slide2Image + ")",
    backgroundPosition: "right center",
  },
  slide3Background: {
    backgroundImage: "url(" + slide3Image + ")",
    height: 830,
    backgroundPosition: "-241px 112%",
    [theme.breakpoints.down('lg')]: {
      width: 1500,
      right: 100,
      backgroundPosition: "center center",
      backgroundSize: "100% auto",
    },
    [theme.breakpoints.down('md')]: {
      width: 1200,
      right: 40,
    },
  },
  slide4Background: {
    backgroundImage: "url(" + slide4Image + ")",
    height: 1300,
    backgroundPosition: "right center",
  },
  slide5Background: {
    backgroundImage: "url(" + slide5Image + ")",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "right center",
    },
  },
  slide6Background: {
    backgroundImage: "url(" + slide6Image + ")",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "right center",
    },
  },
  slide7Background: {
    backgroundImage: "url(" + slide7Image + ")",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "right center",
    },
  },
  innerContent: {
    display: "flex",
    height: 850,
    width: "100%",
    position: "relative",
  },
  innerContentGradient: {
    '&::after': {
      display: "block",
      height: 850,
      width: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      background: "linear-gradient(90deg, rgba(5,63,111,1) 0%, rgba(17,70,113,1) 30%, rgba(5,62,111,0.6) 60%, rgba(17,107,182,0) 100%)",
    },
  },
  innerContentGradientInvert: {
    '&::after': {
      background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%)",
      [theme.breakpoints.down('sm')]: {
        background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0.6) 85%, rgba(255,255,255,0) 100%)",
      },
    },
  },
  textContainer: {
    display: "flex",
    height: 1050,
    zIndex: 100,
    position: "relative",
    [theme.breakpoints.down('xl')]: {
      paddingLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 50,
    },
  },
  textWrapper: {
    paddingTop: 300,
  },
  slideHeader: {
    color: "white",
    textShadow: "2px 2px 2px #092c58",
    fontWeight: 500,
    lineHeight: "1.3em",
    fontSize: "40px",
    maxWidth: 500,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: "32px",
    },
    
  },
  slideHeaderDark: {
    color: variables.ncgBlue4,
    textShadow: "1px 1px 2px rgba(175, 194, 208, 1)",
  },
  slideCaption: {
    fontFamily: "Helvetica, sans-serif",
    color: "white",
    textShadow: "1px 1px 1px rgb(0 0 0 / 58%)",
    fontSize: "22px",
    fontWeight: 300,
    lineHeight: "1.9em",
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: "18px",
      maxWidth: 430,
    },
  },
  captionListItem: {
    fontFamily: "Helvetica, sans-serif",
    color: "white",
    textShadow: "1px 1px 1px rgb(0 0 0 / 58%)",
    fontSize: "22px",
    fontWeight: 300,
    lineHeight: "1.7em",
    maxWidth: 600,
    '&:first-of-type': {
      paddingTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "18px",
      lineHeight: "1.5em",
    },
  },
  slideCaptionDark: {
    color: variables.ncgBlue4,
    textShadow: "none",
    [theme.breakpoints.down('md')]: {
      maxWidth: 350,
    },
  },
  captionList: {
    paddingLeft: 25,
  },
  buttonWrapper: {
    position: "relative",
    paddingTop: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },

}));

const Carousel = (props) => {
  const classes = useStyles();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
  };

  return (
    <>
      <Slider {...settings} className={classes.slider}>
        <div className={classNames(classes.slide, classes.slide1)}>
          <div className={classNames(classes.slideBackground, classes.slide1Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#uniting">
                  <Typography variant="h1" className={classNames(classes.slideHeader)}>Uniting People, Data, Technology, and Security</Typography>
                  <Typography variant="h3" className={classes.slideCaption}>Providing accessible programs and applications suited for your needs</Typography>
                </Link>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classNames(classes.slide, classes.slide2)}>
          <div className={classNames(classes.slideBackground, classes.slide2Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#understanding">
                  <Typography variant="h1" className={classNames(classes.slideHeader)}>Understanding Your Business Objectives</Typography>
                </Link>
                <Typography variant="h3" className={classes.slideCaption}>Starts with your needs to craft the best business solutions</Typography>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classNames(classes.slide, classes.slide3, classes.slideWhite, classes.invert)}>
          <div className={classNames(classes.slideBackground, classes.slide3Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient, classes.innerContentGradientInvert)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#compliance">
                  <Typography variant="h1" className={classNames(classes.slideHeader, classes.slideHeaderDark)}>Compliance &amp; Risk Management</Typography>
                </Link>
                <Typography variant="h3" className={classNames(classes.slideCaption, classes.slideCaptionDark)}>Services and tools focused on empowering your team</Typography>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classNames(classes.slide, classes.slide4)}>
          <div className={classNames(classes.slideBackground, classes.slide4Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#delivering">
                  <Typography variant="h1" className={classNames(classes.slideHeader)}>Delivering Complete Business Solutions</Typography>
                </Link>
                <Typography variant="h3" className={classes.slideCaption}>From people to processes to systems—
                  NCG addresses the complete picture</Typography>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classNames(classes.slide, classes.slide5)}>
          <div className={classNames(classes.slideBackground, classes.slide5Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#digitalConsult">
                  <Typography variant="h1" className={classNames(classes.slideHeader)}>Consulting for Today's Digital Business Environment</Typography>
                </Link>
                <Typography variant="h3" className={classes.slideCaption}>
                  <ul className={classes.captionList}>
                    <li className={classes.captionListItem}>Driving and executing strategic change</li>
                    <li className={classes.captionListItem}>Supporting stability, performance &amp; scalability</li>
                    <li className={classes.captionListItem}>Working collaboratively and securely</li>
                  </ul>
                </Typography>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classNames(classes.slide, classes.slide6)}>
          <div className={classNames(classes.slideBackground, classes.slide6Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#fedCommercial">
                  <Typography variant="h1" className={classNames(classes.slideHeader)}>Commercial and Federal Markets</Typography>
                </Link>
                <Typography variant="h3" className={classes.slideCaption}>In both the private and federal sectors, NCG helps organizations complete key objectives</Typography>
              </Grid>
            </Container>
          </div>
        </div>
        <div className={classNames(classes.slide, classes.slide7)}>
          <div className={classNames(classes.slideBackground, classes.slide7Background)} />
          <div className={classNames(classes.innerContent, classes.innerContentGradient)}>
            <Container maxWidth="lg" className={classes.textContainer}>
              <Grid lg={8} className={classes.textWrapper}>
                <Link href="/#teamExperience">
                  <Typography variant="h1" className={classNames(classes.slideHeader)}>Highly Experienced Consulting Team</Typography>
                </Link>
                <Typography variant="h3" className={classes.slideCaption}>NCG consultants have decades of proven experience and are focused on execution</Typography>
              </Grid>
            </Container>
          </div>
        </div>
      </Slider>
    </>
  )
}

export default Carousel;