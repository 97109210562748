import { Typography, Container, Link, Grid, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import variables from "style/variables.module.scss";
import classNames from "classnames";
import React from "react";
import NcgLogo from "./ncgLogo";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Twitter from "images/social-media/twitter-30px.png";
import Fb from "images/social-media/facebook-30px.png";
import Youtube from "images/social-media/youtube-30px.png";
import Instagram from "images/social-media/instagram-30px.png";
import LinkedIn from "images/social-media/linkedin-30px.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "block",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    padding: 0,
  },
  footerContainer: {
    backgroundColor: variables.ncgGray2,
  },
  footerWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingTop: 30,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      flexWrap: "wrap",
    },
  },
  logoCell: {
    display: "flex",
    alignItems: "center",
    maxWidth: 250,
    height: 70,
    marginRight: 20,
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      maxWidth: 200,
      flexDirection: "column",
    },
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
    paddingRight: 15,
    paddingTop: 12,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingTop: 30,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  row: {
    display: "flex",
    padding: "0px 15px",
    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap",
    },
  },
  topRow: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  bottomRow: {
    paddingTop: 10,
  },
  address: {
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 20,
      width: "100%",
      maxWidth: "100%",
      flexGrow: 1,
    },
  },
  listTitle: {
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: "1.5em",
    display: "flex",
    textTransform: "uppercase",
    margin: 0,
    color: variables.fontColorH5,
  },
  locationIcon: {
    fontSize: "1.1rem",
    marginLeft: 5,
    fill: variables.ncgTeal1,
  },
  footerList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    paddingBottom: 10,
    position: "relative",
  },
  footerListItem: {
    fontSize: 12,
    padding: 0,
    color: variables.textSecondary,
    lineHeight: "1.5em",
  },
  addressList: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 30,
    },
  },
  map: {
    position: "absolute",
    margin: "10px 0px",
    [theme.breakpoints.up('sm')]: {
      display: "none",
    },
  },
  mapLink: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    fontSize: 15,
    border: "1px solid rgba(63, 89, 128, 0.28)",
    padding: 11,
    height: 40,
    borderRadius: 5,
    width: "fit-content",
    backgroundColor: "#ffffff",
    margin: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  mapsIcon: {
    fontSize: "1.3rem"
  },
  siteLinksWrapper: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
      flexGrow: 1,
    },
  },
  listItemLink: {
    color: variables.ncgBlue3,
    fontSize: 12,
    lineHeight: ".8em",
    fontWeight: 500,
    '&:hover:hover': {
      textDecoration: "underline",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    }
  },
  listTitleLink: {
    fontWeight: "bold",
  },
  phoneNumber: {
    fontWeight: "bold",
    fontSize: 12,
    display: "inline",
    color: variables.ncgTeal2,
    paddingLeft: 5,
  },
  flexListItem: {
    display: "flex",
    alignItems: "center",
  },
  childLink: {
    color: variables.textSecondary,
    fontSize: 12,
    lineHeight: "1em",
    fontWeight: 500,
    '&:hover:hover': {
      textDecoration: "underline",
    },
  },
  emailIcon: {
    fontSize: "1rem",
    marginRight: 5,
    fill: variables.ncgBlue2,
  },
  socialMediaWrapper: {
    display: "flex",
    paddingTop: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: "space-between",
      width: "100%",
      maxWidth: "100%",
      flexGrow: 1,
    },
  },
  socialMediaLink: {
    height: 26,
    width: 26,
    display: "inline-block",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginLeft: 5,
    marginRight: 5,
  },
  twitter: {
    backgroundImage: "url(" + Twitter + ")",
    marginRight: 0,
  },
  youtube: {
    backgroundImage: "url(" + Youtube + ")",

  },
  linkedIn: {
    backgroundImage: "url(" + LinkedIn + ")",
  },
  instagram: {
    backgroundImage: "url(" + Instagram + ")",
    marginLeft: 0,
  },
  fb: {
    backgroundImage: "url(" + Fb + ")",
  },
  hiddenSm: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: "none"
    },
  },
  hiddenMd: {
    [theme.breakpoints.between('md', 'lg')]: {
      display: "none"
    },
  },
  hiddenLg: {
    [theme.breakpoints.between('lg', 'xl')]: {
      display: "none"
    },
  },
  copyrightContainer: {
    backgroundColor: variables.ncgBlue4,
    width: "100%",
  },
  copyrightWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },
  legalDocs: {
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
    listStyle: "none",
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
  },
  legalDoc: {
    display: "inline-block",
    paddingLeft: 10,
    paddingRight: 10,
  },
  docLink: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    '&:hover:hover': {
      textDecoration: "underline",
      color: "white",
    },
  },
  copyright: {
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      display: "block",
      paddingRight: 0,
      paddingBottom: 5,
    },
  },
  copyrightText: {
    color: "white",
    fontSize: 12,
    padding: 0,
  },
  noTopPadding: {
    paddingTop: 0,
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <div position="sticky" className={classes.footer}>
        <div className={classes.footerContainer}>
          <Container maxWidth="lg">
            <div className={classes.footerWrapper}>
              <Grid item xs={12} sm={12} md={2} lg={2} className={classes.logoCell}>
                <NcgLogo />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10} className={classes.columnWrapper}>
                <Grid xs={12} sm={12} md={12} lg={12} className={classNames(classes.row, classes.topRow)}>
                  {/* PORTLAND ADDRESS */}
                  <Grid item xs={4} sm={4} md={4} lg={4} className={classNames(classes.address, classes.mainAddress)}>
                    <ul className={classNames(classes.footerList, classes.addressList)}>
                      <li className={classes.noTopPadding}>
                        <Typography variant="h5" className={classes.listTitle}>
                          Portland, ME
                          <Link href="http://maps.google.com/maps?f=q&hl=en&geocode=&q=100+Middle+Street%3B+Portland,+Maine+04101&sll=37.0625,-95.677068&sspn=40.324283,92.8125&ie=UTF8&t=h&ll=43.667313,-70.247698&spn=0.02173,0.036564&z=14&iwloc=addr&source=embed">
                            <LocationOnIcon className={classes.locationIcon} />
                          </Link>
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          100 Middle St.
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          East Tower, Suite #203
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          Portland, ME 04101
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          207.699.5540
                        </Typography>
                      </li>
                      <li className={classNames(classes.map, classes.hiddenLg, classes.hiddenMd, classes.hiddenSm, classes.noTopPadding)}>
                        <Link href="http://maps.google.com/maps?f=q&hl=en&geocode=&q=100+Middle+Street%3B+Portland,+Maine+04101&sll=37.0625,-95.677068&sspn=40.324283,92.8125&ie=UTF8&t=h&ll=43.667313,-70.247698&spn=0.02173,0.036564&z=14&iwloc=addr&source=embed" className={classes.mapLink}>
                          Maps
                          <LocationOnIcon className={classNames(classes.locationIcon, classes.mapsIcon)} />
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                  {/* VA ADDRESS */}
                  <Grid item xs={4} sm={4} md={4} lg={4} className={classNames(classes.address, classes.vaAddress)}>
                    <ul className={classNames(classes.footerList, classes.addressList)}>
                      <li className={classes.noTopPadding}>
                        <Typography variant="h5" className={classes.listTitle}>
                          DC METRO
                          <Link href="https://www.google.com/maps/place/1101+Wilson+Blvd,+Arlington,+VA+22209/@38.8954841,-77.0748085,892m/data=!3m2!1e3!4b1!4m6!3m5!1s0x89b7b65a3baef5b7:0x9e310e7ea6ce34df!8m2!3d38.89548!4d-77.0699376">
                            <LocationOnIcon className={classes.locationIcon} />
                          </Link>
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          1101 Wilson Blvd.
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          6th Floor
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          Arlington, VA 22209
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          703.351.3397
                        </Typography>
                      </li>
                      <li className={classNames(classes.map, classes.hiddenLg, classes.hiddenMd, classes.hiddenSm, classes.noTopPadding)}>
                        <Link href="https://www.google.com/maps/place/1101+Wilson+Blvd,+Arlington,+VA+22209/@38.8954841,-77.0748085,892m/data=!3m2!1e3!4b1!4m6!3m5!1s0x89b7b65a3baef5b7:0x9e310e7ea6ce34df!8m2!3d38.89548!4d-77.0699376" className={classes.mapLink}>
                          Maps
                          <LocationOnIcon className={classNames(classes.locationIcon, classes.mapsIcon)} />
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                  {/* MI ADDRESS */}
                  <Grid item xs={4} sm={4} md={4} lg={4} className={classNames(classes.address, classes.MIAddress)}>
                    <ul className={classes.footerList}>
                      <li className={classes.noTopPadding}>
                        <Typography variant="h5" className={classes.listTitle}>
                          MARQUETTE, MI
                          <Link href="https://www.google.com/maps/place/101+W+Washington+St,+Marquette,+MI+49855/@46.5430296,-87.3949799,17z/data=!3m1!4b1!4m5!3m4!1s0x4d4e2098f28ccb47:0xf6801a0c971aa464!8m2!3d46.5430296!4d-87.3927912">
                            <LocationOnIcon className={classes.locationIcon} />
                          </Link>
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          101 W. Washington St.
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          Suite #4
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          Marquette, MI 49855
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography variant="body2" className={classes.footerListItem}>
                          906.451.5910
                        </Typography>
                      </li>
                      <li className={classNames(classes.map, classes.hiddenLg, classes.hiddenMd, classes.hiddenSm, classes.noTopPadding)}>
                        <Link href="https://www.google.com/maps/place/101+W+Washington+St,+Marquette,+MI+49855/@46.5430296,-87.3949799,17z/data=!3m1!4b1!4m5!3m4!1s0x4d4e2098f28ccb47:0xf6801a0c971aa464!8m2!3d46.5430296!4d-87.3927912" className={classes.mapLink}>
                          Maps
                          <LocationOnIcon className={classNames(classes.locationIcon, classes.mapsIcon)} />
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} className={classNames(classes.row, classes.bottomRow)}>
                  {/* SITE LINKS */}
                  <Grid item xs={6} sm={6} md={4} lg={4} className={classes.siteLinksWrapper}>
                    <ul className={classes.footerList}>
                      <li className={classes.noTopPadding}>
                        <Typography variant="h5" className={classes.listTitle}>
                          Site Links
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography className={classes.footerListItem}>
                          <Link className={classes.listItemLink} href="/about">
                            About NCG
                          </Link>
                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography className={classes.footerListItem}>
                          <Link className={classes.listItemLink} href="/markets">
                            Markets Served
                          </Link>

                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography className={classes.footerListItem}>
                          <Link className={classes.listItemLink} href="/capabilities">
                            Services &amp; Capabilities
                          </Link>

                        </Typography>
                      </li>
                      <li className={classes.noTopPadding}>
                        <Typography className={classes.footerListItem}>
                          <Link className={classes.listItemLink} href="/past-performance">
                            Past Performance
                          </Link>

                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                  {/* CONTACT */}
                  <Grid item xs={6} sm={6} md={4} lg={4} className={classes.contactWrapper}>
                    <ul className={classes.footerList}>
                      <li className={classes.noTopPadding}>
                        <Link href="/about-ncg/contact" className={classNames(classes.listItemLink, classes.listTitleLink)}>
                          Contact
                        </Link>
                        <Typography variant="body2" className={classes.phoneNumber}>
                          207.699.5540
                        </Typography>
                      </li>
                      <li className={classNames(classes.flexListItem, classes.noTopPadding)}>
                        <MailOutlineIcon className={classes.emailIcon} />
                        <Link href="mailto:info@northcrossgroup.com" className={classNames(classes.listItemLink, classes.childLink)}>
                          General Inquiries
                        </Link>
                      </li>
                    </ul>
                    <ul className={classes.footerList}>
                      <li className={classes.noTopPadding}>
                        <Link href="/about-ncg/career-opportunities/careers" className={classNames(classes.listItemLink, classes.listTitleLink)}>
                          Career Opportunities
                        </Link>
                      </li>
                      <li className={classNames(classes.flexListItem, classes.noTopPadding)}>
                        <MailOutlineIcon className={classes.emailIcon} />
                        <Link href="mailto:careers@northcrossgroup.com" className={classNames(classes.listItemLink, classes.childLink)}>
                          Resume &amp; Cover Letter
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                  {/* SOCIAL MEDIA */}
                  <Grid item xs={8} sm={4} md={4} lg={4} className={classes.socialMediaWrapper}>
                    <Link target="_blank" href="https://www.instagram.com/northcrossgroup/" className={classNames(classes.socialMediaLink, classes.instagram)}></Link>
                    <Link target="_blank" href="https://www.youtube.com/channel/UCk4tUHE4HEbs81yxhknqO1Q" className={classNames(classes.socialMediaLink, classes.youtube)}></Link>
                    <Link target="_blank" href="https://www.facebook.com/northcrossgroup/" className={classNames(classes.socialMediaLink, classes.fb)}></Link>
                    <Link target="_blank" href="https://www.linkedin.com/company/northcross-group/mycompany/" className={classNames(classes.socialMediaLink, classes.linkedIn)}></Link>
                    <Link target="_blank" href="https://twitter.com/NorthcrossGroup" className={classNames(classes.socialMediaLink, classes.twitter)}></Link>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        {/* COPYRIGHT AND TERMS OF USE DOCS */}
        <div className={classes.copyrightContainer}>
          <Container maxWidth="lg" className={classes.copyrightWrapper}>
            <ul className={classes.legalDocs}>
              <li className={classNames(classes.legalDoc, classes.noTopPadding)}>
                <Link href="/privacy-statement" className={classes.docLink}>
                  Privacy Statement
                </Link>
              </li>
              <li className={classNames(classes.legalDoc, classes.noTopPadding)}>
                <Link href="/cookie-notice" className={classes.docLink}>
                  Cookie Notice
                </Link>
              </li>
              <li className={classNames(classes.legalDoc, classes.noTopPadding)}>
                <Link href="/terms-of-use" className={classes.docLink}>
                  Terms of Use
                </Link>
              </li>
            </ul>
            <div className={classes.copyright}>
              <Typography className={classes.copyrightText}>
                ©2024 Northcross Group Corporation.
              </Typography>
            </div>
          </Container>
        </div>
      </div>

    </>
  )
}

export default Footer;