
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import variables from "style/variables.module.scss";
import classNames from "classnames";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonBig: {
    padding: "15px 20px",
  },
  buttonSmall: {
    padding: "7px 15px",
  },
  button: {
    padding: "10px 15px",
  },

  // LABELS
  buttonLabel: {
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 400,
    fontSize: variables.buttonLabel,
    padding: 0,
    display: "flex",
    lineHeight: "normal",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      fontSize: ".8em",
      fontWeight: "bold",
    },
  },
  buttonLabelBig: {
    textTransform: "uppercase",
    letterSpacing: "0.15em",
    fontSize: variables.buttonLabelBig,
    [theme.breakpoints.down('sm')]: {
      fontSize: "13px",
    },
  },
  buttonLabelSmall: {
    textTransform: "none",
    letterSpacing: "0.03em",
    fontSize: variables.buttonLabelSmall,
  },
  buttonLabelWhite: {
    color: "white",
    "&:hover": {
      color: variables.ncgGray3,
      transition: "background-color 0.5s, color 0.5s",
    },
  },
  buttonLabelBlue: {
    color: variables.ncgBlue1,
    fontWeight: 700,
    "&:hover": {
      color: variables.ncgBlue3,
      transition: "background-color 0.5s, color 0.5s",
    },
  },

  // BACKGROUND COLORS
  whiteButton: {
    border: "1px solid #bfced9",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: variables.ncgGray2,
      borderColor: "#D0D9F3",
    },
  },
  primaryLightButton: {
    backgroundColor: variables.ncgBlue0,
    "&:hover": {
      backgroundColor: variables.ncgBlue1,
    },
  },
  primaryButton: {
    backgroundColor: variables.ncgBlue1,
    "&:hover": {
      backgroundColor: variables.ncgBlue2,
    },
  },
  primaryDarkButton: {
    backgroundColor: variables.ncgBlue2,
    "&:hover": {
      backgroundColor: variables.ncgBlue3,
    },
  },
  darkPrimaryButton: {
    backgroundColor: "#123761",
    "&:hover": {
      backgroundColor: variables.ncgBlue4,
    },
  },
  secondaryLightButton: {
    backgroundColor: variables.ncgBlue3,
    "&:hover": {
      backgroundColor: variables.ncgBlue4,
    },
  },
  secondaryButton: {
    backgroundColor: variables.ncgBlue4,
    "&:hover": {
      backgroundColor: variables.ncgBlue5,
    },
  },
  secondaryDarkButton: {
    backgroundColor: variables.ncgBlue5,
    "&:hover": {
      backgroundColor: variables.activeNavbar,
    },
  },
  tertiaryLightButton: {
    backgroundColor: variables.ncgTeal1,
    "&:hover": {
      backgroundColor: variables.ncgBlue2,
    },
  },
  tertiaryDarkButton: {
    backgroundColor: variables.ncgTeal2,
    "&:hover": {
      backgroundColor: variables.ncgBlue1,
    },
  },
}));

const ButtonCustom = (props) => {
  const classes = useStyles(props);

  if (props.typeOf === "smallButtonWhite") {
    return (
      <>
        <Button onClick={props.onClick} href={props.href} variant="contained" className={classNames(classes.button, classes.buttonSmall, classes.whiteButton)}>
          <Typography variant="body1" className={classNames(classes.buttonLabel, classes.buttonLabelSmall, classes.buttonLabelBlue)}>
            {props.children}
          </Typography>
        </Button>
      </>
    );
  }
  if (props.typeOf === "bigButtonPrimary") {
    return (
      <>
        <Button onClick={props.onClick} href={props.href} variant="contained" className={classNames(classes.button, classes.buttonBig, classes.primaryDarkButton)}>
          <Typography variant="body1" className={classNames(classes.buttonLabel, classes.buttonLabelBig, classes.buttonLabelWhite)}>
            {props.children}
          </Typography>
        </Button>
      </>
    );
  }
  if (props.typeOf === "fullWidthWhite") {
    return (
      <>
        <Button fullWidth onClick={props.onClick} href={props.href} className={classNames(classes.button, classes.buttonBig, classes.whiteButton)}>
          <Typography variant="body1" className={classNames(classes.buttonLabel, classes.buttonLabelSmall, classes.buttonLabelBlue)}>
            {props.children}
          </Typography>
        </Button>
      </>
    );
  }
  if (props.typeOf === "fullWidthDarkPrimary") {
    return (
      <>
        <Button fullWidth onClick={props.onClick} href={props.href} variant="contained" className={classNames(classes.button, classes.buttonBig, classes.darkPrimaryButton)}>
          <Typography variant="body1" className={classNames(classes.buttonLabel, classes.buttonLabelBig, classes.buttonLabelBlue)}>
            {props.children}
          </Typography>
        </Button>
      </>
    );
  }
  return (
    <>
      <Button onClick={props.onClick} href={props.href} variant="contained" className={classNames(classes.button, classes.primaryDarkButton)}>
        <Typography variant="body1" className={classNames(classes.buttonLabel, classes.buttonLabelWhite)}>
          {props.children}
        </Typography>
      </Button>
    </>
  );
};

export default ButtonCustom;