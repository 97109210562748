import { Link, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ButtonCustom from "./buttonCustom.component";

const useStyles = makeStyles((theme) => ({
  serviceCardContainer: {
    boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.16)",
    background: "rgba(0,0,0,0.03)",
    borderRadius: 5,
    padding: "5px 0px 20px",
    height: "420px",
    borderTop: "5px solid rgba(8, 66, 125)",
    "&:hover": {
      boxShadow:  "0px 2px 4px 1px rgba(8, 66, 125, 0.36)",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center"
  },
  cardTitle: {
    margin: "14px 14px 0px",
    fontSize: "18px",
    fontWeight: 600,
    maxWidth: "240px",
  },
  cardTitleContainer: {
    height: 90,

  },
  cardImage: {
    height: "140px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down('md')]: {
      objectPosition: "50% 30%",
    },
  },
  imageMargin: {
    marginLeft: "30%",
    width: "70%",
  },
  imageBottomMargin: {
    objectPosition: "50% 80%",
  },
  cardDescription: {
    padding: "8px",
    width: "100%",
    maxWidth: "345px",
    margin: "5px auto",
    height: "80px",
  },
  cardButton: {
    margin: "10px 8px 8px",
    height: "40px",
  },
  imageWrapper: {
    backgroundColor: "#EFEFEF"
  }
}))
const ServiceCard = (props) => {
  const { image, title, description, link, imageMargin, imageBottomMargin } = props
  const classes = useStyles();
  return (
    <Link
      color="inherit"
      href={link}
    >
      <Grid container className={classes.serviceCardContainer}>
        <Grid item xs={12} className={classNames(classes.center, classes.cardTitleContainer)}>
          <h4
            className={classes.cardTitle}
          >
            {title}
          </h4>
        </Grid>
        <Grid item xs={12} className={classNames(classes.center, imageMargin && classes.imageWrapper)}>
          <img
            src={image}
            className={
              classNames(
                classes.cardImage,
                imageMargin && classes.imageMargin,
                imageBottomMargin && classes.imageBottomMargin
              )
            }
            alt="service"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classNames(classes.center, classes.cardDescription)}
        >
          {description}
        </Grid>
        <Grid
          item
          xs={12}
          className={classNames(classes.center, classes.cardButton)}
        >
          <ButtonCustom
            href={link}
            typeOf="smallButtonWhite"
          >
            Learn More
          </ButtonCustom>
        </Grid>
      </Grid>
    </Link>
  )
}

export default ServiceCard;
