import { Grid, makeStyles, Typography } from "@material-ui/core";
import academicPartnerships from "../../../../images/microsite/educationFoundation/academicPartnerships.jpg";
import curriculumDevelopment from "../../../../images/microsite/educationFoundation/curriculumDevelopment.jpg";
import educationSponsorship from "../../../../images/microsite/educationFoundation/educationSponsorship.jpg";
import internshipsCoOps from "../../../../images/microsite/educationFoundation/internshipsCoOps.jpg";
import classNames from "classnames";
import EFSectionContainer from "../shared/EFSectionContainer";

const white = "#F5F8FF";
const periwinkle = "#5359E6";


const useStyles = makeStyles((theme) => ({
  basicContainer: {
    minHeight: "0 !important"
    // Okay I admit it, you caught me, I'm using an !important
  },
  sectionHeader: {
    paddingTop: 40,
    marginBottom: -40,
    color: periwinkle,
    fontSize: 42,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      marginBottom: 0
    }
  },
  cardContainer: {
    position: "relative",
    zIndex: 1,
    color: white,
  },
  cardInsetShadow: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    boxShadow: (
      `0px -14px 20px -18px #000000 inset,
      0px 14px 20px -18px #000000 inset,
      0px 14px 12px -14px #7C2DE1 inset,
      0px -14px 12px -14px #7C2DE1 inset`
    )
  },
  cardsGrid: {
    minHeight: 400,
    justifyContent: "stretch"
  },
  card: {
    padding: "128px 32px",
    position: "relative",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    border: "0 solid #FFFFFF99",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    "&:not(:last-of-type)": {
      [theme.breakpoints.down("sm")]: {
        borderRightWidth: 0,
        borderBottomWidth: 3
      }
    },
    [theme.breakpoints.down("sm")]: {
      padding: "104px 32px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "64px 32px",
    }
  },
  card1: {
    backgroundImage: `url(${curriculumDevelopment})`,
    borderRightWidth: 3,
    borderBottomWidth: 3,
    "&:before": {
      backgroundImage: (
        `linear-gradient(270deg, #37AFA1 25%, rgba(55, 175, 161, 0) 100%)`
      ),
    }
  },
  card2: {
    backgroundImage: `url(${internshipsCoOps})`,
    borderBottomWidth: 3,
    "&:before": {
      backgroundImage: (
        `linear-gradient(270deg, #5359E6 25%, rgba(83, 89, 230, 0) 100%)`
      ),
      transform: "scaleX(-1)"
    }
  },
  card3: {
    backgroundImage: `url(${academicPartnerships})`,
    backgroundSize: "cover",
    borderRightWidth: 3,
    "&:before": {
      backgroundImage: (
        `linear-gradient(270deg, #398AE5 25%, rgba(57, 138, 229, 0) 100%)`
      ),
    }
  },
  card4: {
    backgroundImage: `url(${educationSponsorship})`,
    backgroundSize: "cover",
    "&:before": {
      backgroundImage: (
        `linear-gradient(270deg, #902DE1 25%, rgba(124, 45, 225, 0) 100%)`
      ),
      transform: "scaleX(-1)"
    }
  },
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    textShadow: "2px 2px 1px #2E2BAC4D",
    [theme.breakpoints.up("lg")]: {
      padding: "0 32px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 16px"
    }
  },
  cardHeader: {
    width: "100%",
    marginTop: 8,
    marginBottom: 24,
    textShadow: "3px 2px 2px #2E2BAC4D"
  },
  mainHeading: {
    marginRight: "auto",
    marginBottom: 32
  },
  targetsGrid: {
    maxWidth: 1000,
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  imageGridItem: {
    position: "relative",
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  containImageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  containImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  stretchGrid: {
    alignSelf: "stretch",
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 670
    }
  },
  textColumnContent: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 16,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  textGridItem: {
    alignSelf: "center"
  },
}));


export default function EFInitiatives() {
  const classes = useStyles();
  return (
    <>
      <EFSectionContainer
        maxWidth="none"
        className={classes.basicContainer}
        scrollId="Initiatives"
      >
        <Typography
          variant="h2"
          align="left"
          className={classes.sectionHeader}
          paragraph
        >
          Current Initiatives
        </Typography>
      </EFSectionContainer>
      <div className={classes.cardContainer}>
        <div className={classes.cardInsetShadow} />
        <Grid className={classes.cardsGrid} container>
          <Grid
            xs={12}
            md={6}
            className={classNames(classes.card, classes.card1)}
            item
          >
            <Grid className={classes.cardContent} container>
              <Grid xs={6} md={5} lg={4} item>&nbsp;</Grid>
              <Grid xs={6} md={7} lg={8} item>
                <Typography
                  variant="h3"
                  align="right"
                  color="inherit"
                  className={classes.cardHeader}
                >
                  Curriculum Development
                </Typography>
                <Typography variant="body1" align="right" color="inherit">
                  Development of tools, learning supplements, and project-based
                  activities to support technology and cybersecurity skill
                  development for high school development.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={6}
            className={classNames(classes.card, classes.card2)}
            item
          >
            <Grid className={classes.cardContent} container>
              <Grid xs={6} item>
                <Typography
                  variant="h3"
                  align="left"
                  color="inherit"
                  className={classes.cardHeader}
                  paragraph
                >
                  Internships &amp; Co&#8209;Ops
                </Typography>
                <Typography variant="body1" align="left" color="inherit">
                  Professional opportunities and targeted training to support
                  real-world client demands under expert supervision.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={6}
            className={classNames(classes.card, classes.card3)}
            item
          >
            <Grid className={classes.cardContent} container>
              <Grid xs={6} item>&nbsp;</Grid>
              <Grid xs={6} item>
                <Typography
                  variant="h3"
                  align="right"
                  color="inherit"
                  className={classes.cardHeader}
                  paragraph
                >
                  Academic Partnerships
                </Typography>
                <Typography variant="body1" align="right" color="inherit">
                  NCG works with Universities to continuously improve curriculum
                  and provide students with real world opportunities to sharpen
                  skills and road test new capabilities.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={6}
            className={classNames(classes.card, classes.card4)}
            item
          >
            <Grid className={classes.cardContent} container>
              <Grid xs={6} item>
                <Typography
                  variant="h3"
                  align="left"
                  color="inherit"
                  className={classes.cardHeader}
                  paragraph
                >
                  Educational Sponsorship
                </Typography>
                <Typography variant="body1" align="left" color="inherit">
                  Working directly with student groups and educators providing
                  access to subject matter experts, resources, and financial
                  support for technology and cybersecurity initiatives.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <EFSectionContainer maxWidth="none" className={classes.basicContainer} />
    </>
  );
}
