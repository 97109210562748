import React from "react";
import ncgFullWhiteLogoUrl from "images/ncg-full-white-logo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: "100%",
    width: "100%",
    display: "block",
    backgroundImage: `url(${ncgFullWhiteLogoUrl})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px center",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function NcgFullWhiteLogo() {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer} />
  );
};
