import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons'

const ExpandIcon = () => {
  return (
    <FontAwesomeIcon icon={faExpandAlt} color="#00b0ba" size="lg" style={{ marginLeft: "5px" }}/>
  );
};

export default ExpandIcon;
