import MicrositeNavBar from "../micrositeNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navPadding: {
    paddingBottom: 80,
    [theme.breakpoints.down(763)]: {
      paddingBottom: 55,
    },
  }
}));
const menuItems =
  [
    { label: 'RM Programs', href: '#programs' },
    { label: 'RM Benefits', href: '#benefits' },
    { label: 'RM Tools', href: '#tools' },
    { label: 'RM Assessments', href: '#assess' },
    { label: 'Contact', href: '#request', rfpType: 'Risk Management', contactForm: true }
  ]
const RiskManagementNavBar = (props) => {
  const classes = useStyles();

  return (
    <div class={classes.navPadding}>
      <MicrositeNavBar bgColor="blue" menuItems={menuItems} elementInView={props.elementInView}/>
    </div>
  );
}
export default RiskManagementNavBar;