import { makeStyles } from "@material-ui/core/styles";
import React from 'react'

const useStyles = makeStyles((theme) => ({
  videoWrapper: {
    paddingBottom: "56.25%",
    position: "relative",
    height: "inherit",
    width: "inherit",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
}));

const Video = (props) => {
  const classes = useStyles(props);
  return (
      // <ReactPlayer url={props.url} className={classes.video} />
      <div className={classes.videoWrapper}>
        <iframe className={classes.iframe} width="560" height="315" src={props.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    
  )
}

export default Video;