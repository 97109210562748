import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textWrapperH1: {
    marginBottom: 30,
  },
  textWrapperH2: {
    marginBottom: 30,
  },
  textWrapperH3: {
    marginBottom: 20,
  },
  textWrapperH4: {
    marginBottom: 20,
  },
  textWrapperH5: {
    marginBottom: 25,
  },
}));

const TextWrapper = (props) => {
  const classes = useStyles();

  if (props.variant === "h1") {
    return (
      <div className={classes.textWrapperH1}>
        {props.children}
      </div>
    )
    }
  if (props.variant === "h2") {
    return (
      <div className={classes.textWrapperH2}>
        {props.children}
      </div>
    )
  }
  if (props.variant === "h3") {
    return (
      <div className={classes.textWrapperH3}>
        {props.children}
      </div>
    )
  }
  if (props.variant === "h4") {
    return (
      <div className={classes.textWrapperH4}>
        {props.children}
      </div>
    )
  }
  if (props.variant === "h5") {
    return (
      <div className={classes.textWrapperH5}>
        {props.children}
      </div>
    )
  }
  else {
    return (
      <div className={classes.textWrapper}>
        {props.children}
      </div>
    )
  }
};

export default TextWrapper;
