const scrollClassFunction = (menuItems, elementInView, document) => {
  menuItems.forEach((item) => {
    if (`#${elementInView}` !== item.href) {
      let inactiveButtonList = document.getElementsByName(item.label);
      inactiveButtonList.forEach((inactiveButton) => {
        let inactiveButtonWrapper = inactiveButton.parentElement;
        inactiveButtonWrapper.classList.remove("scroll");
      });
    } else if (`#${elementInView}` === item.href) {
      let activeButtonList = document.getElementsByName(item.label);
      activeButtonList.forEach((activeButton) => {
        let activeButtonWrapper = activeButton.parentElement;
        activeButtonWrapper.classList.add("scroll");
      });
    }
  });
};

export default scrollClassFunction;
