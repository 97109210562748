import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classNames from "classnames";

//image
import platform from "images/images/orchestration/page/tools-support.png"

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "-webkit-center",
    flexDirection: "column",
    borderRadius: 8,
    margin: 10,
    width: "auto",
    height: "50%"
  },
  closeButtonSection: {
    textAlign: "right"
  }

}));
export const OrchestrationToolModal = (props) => {
  const classes = useStyles();
    
        return (
          <Grid className={classNames(classes.formContainer, "crunch" )}>
            <h2>{`Tools & Support`}</h2>
            <div>
              <img id="orchestrationTool" src={platform} class="img-responsive" alt="Orchestration Platform" />
            </div>
            <div className={classes.closeButtonSection}>
                <Button type="button" class="btn btn-default" onClick={() => props.setOpenModal(false)}>Close</Button>
            </div>
          </Grid>

        );
}

export default OrchestrationToolModal;
